import React from "react";
import "../styles/Modals/style.scss";
import Test1 from "../assets/imgHome/Test1.png";

function Modal({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <label>Agregando al carrito (2 artículos)</label>
        </div>
        <div className="body d-flex ">
         {/*  <div className="d-flex leftContainer">
            <div className="imgContainer">
              <img src={Test1} />
            </div>
            <div className="productDetail">
              <h5>Aceite</h5>
              <label>SKU #123456-789</label>
              <div className="counter">
                <button>-</button>
                <label>1</label>
                <button>+</button>
                <label id="money">$6,000 MXN</label>
              </div>
              <label id="unitPrice">Unitario $3,000</label>
            </div>
          </div> */}
          <div className="rightContainer">
            <label>Hay 3 artículos en tu carrito ahora</label>
            <div className="d-flex price">
              <label>Total en carrito:</label>
              <label>$6,000 MXN</label>
            </div>
            <button className="btn payButtonBottom">Proceder al pago</button>
          </div>
        </div>
        {/* <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button>Continue</button>
        </div> */}
      </div>
    </div>
  );
}

export default Modal;
