import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { GET_COMPARE_MOV_TYPE, GET_TB } from "../../graphql/Query";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Box, Modal } from "@material-ui/core";
import { GenericTable } from "./GenericTable";
import Title from "antd/lib/typography/Title";
import Form from "react-bootstrap/Form";
//import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  FcAlphabeticalSortingAz,
  FcAlphabeticalSortingZa,
} from "react-icons/fc";
import Tooltip from "@mui/material/Tooltip";
import "../../styles/TooTip/style.css";
import ChartBestSellinngsPieces from "../ChartDahsboard/ChartBestSellinngsPieces";
import GenericModalCompareDis from "./GenericModalCompareDis";
import { SiCheckmarx } from "react-icons/si";
import { FiEdit3 } from "react-icons/fi";
import { AiFillSave } from "react-icons/ai";
import "../../styles/styleDashBoard.css";
export default function StatsTable({
  dataTable,
  optionSelect,
  idGroup,
  reOrderdata,
}) {
  const [parteMarket, setParteMarket] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [statusSwitch, setStatusSwitch] = useState(false);
  const [dataChartHerDistri, setDataChartHerDistri] = useState([]);
  const [dataLabelsChartDis, setDataLabelsChartDis] = useState([]);
  const [leyend, setLeyend] = useState([]);
  const [parte, setParte] = useState(0);
  const [dataCompareModal, setDataCompareModal] = useState([]);
  const [columnsTableModal, setColumnsTableModal] = useState([]);
  const [tasaB, setTasaB] = useState(0);
  const [editTB, setEditTB] = useState(true);
  const [newTB, setNewTB] = useState("");

  // console.log(dataTable, "datos que llegan a la tabla");

  //Grafica comparar con hermano distribuidor
  const [getCompareMovType] = useLazyQuery(GET_COMPARE_MOV_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted({ getCompareMovType }) {
      //debugger;
      ////console.log(getCompareMovType, 'getCompareMovType')
      setDataCompareModal(getCompareMovType);
      setColumnsTableModal([
        { title: "Parte", field: "parte" },
        { title: "Distribuidor", field: "distribuidor" },
        { title: "Tipo de movimento", field: "tipo_movimiento" },
        { title: "Piezas disponibles", field: "numPartesDisponibles" },
      ]);
      let labels = [];
      let leyendTypeMov = [];
      const piezasDis1 = getCompareMovType[0]?.numPartesDisponibles || 0;
      const piezasDis2 = getCompareMovType[1]?.numPartesDisponibles || 0;
      const piezasDis3 = getCompareMovType[2]?.numPartesDisponibles || 0;
      getCompareMovType.forEach((obj) => {
        return (
          labels.push(obj.distribuidor), leyendTypeMov.push(obj.tipo_movimiento)
        );
      });
      setLeyend(leyendTypeMov);
      setDataLabelsChartDis(labels);
      setDataChartHerDistri([piezasDis1, piezasDis2, piezasDis3]);
    },
    onError(err) {
      //console.log(err);
    },
  });
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };
  const handleDetailMarket = ({ target }) => {
    //console.log(disabled, "estatus del swich");
    const { value } = target;
  };
  const handleTool = (id_parte) => {
    setParte(id_parte);
    getCompareMovType({
      variables: {
        idParte: parseInt(id_parte),
        group: parseInt(idGroup),
      },
    });
    handleOpen();
  };

  const getIdParte = () => {
    /*  setParteMarket(id_parte)
     //console.log(parteMarket) */
  };

  const changeStatusSwich = (e) => {};
  // const currency = (number) => {
  //   return new Intl.NumberFormat('en-MX', { style: 'currency', currency: '$' }).format(number);
  // };

  const [getTB] = useLazyQuery(GET_TB, {
    fetchPolicy: "no-cache",
    onCompleted({ getTB }) {
      console.log(getTB[0].tasa);
      setTasaB(getTB[0].tasa);
      if (dataTable?.length > 0) {
        //console.log("if?");
        dataTable?.map(
          ({
            id_parte,
            Name,
            tipo_movimiento,
            distribuidor,
            PrecioVenta,
            Costo_pieza,
            statusMarket,
          }) => {
            current += 1;
            template += `<tr style={{ background: "#EAF0F6" }}>
                <td>${id_parte}</td>
                <td>${Name}</td>
                <td style="color:#FF0000;"">${tipo_movimiento}</td>
                <td>${distribuidor}</td>
                <td>${formatter.format(filterFloat(Costo_pieza))}</td>
                <td>${formatter.format(filterFloat(PrecioVenta))}</td>
                <td style="display: flex;align-items: center;justify-content: center; height: 100%;"">
                  ${
                    // ReactDOMServer.renderToString(<SiCheckmarx />)
                    ReactDOMServer.renderToString(
                      <Form>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          key={id_parte}
                          // onChange={changeStatusSwich}
                          checked={statusMarket}
                          disabled={true}
                        />
                      </Form>
                    )
                    // : statusMarket == 1
                    // ? ReactDOMServer.renderToString(
                    //     <SiCheckmarx style={{ color: "green" }} />
                    //   )
                    // ReactDOMServer.renderToString(<SiCheckmarx />)
                  }
                    
    
               
                </td>
                <td>${getTB[0].tasa}</td>
    
              </tr>`;

            if (current == dataTable?.length) {
              setTimeout(function () {
                countdown("el"); // You used `el`, not `element`?
              }, 1000);

              function countdown(el) {
                const temp = document.getElementById("tbodyexample");
                temp && (temp.innerHTML = template);
                //console.log("is finish");
              }
            }
          }
        );
      } else {
        setTimeout(function () {
          countdown("el"); // You used `el`, not `element`?
        }, 1000);

        function countdown(el) {
          const temp = document.getElementById("tbodyexample");
          temp && (temp.innerHTML = "Sin resultados ");
          //console.log("else?");
        }
      }
    },
    onError(e) {
      console.log("Error", e);
    },
  });

  useEffect(() => {
    getTB({
      variables: {
        a: 0,
      },
    });
  }, [dataTable, tasaB]);

  const saveTBbyEdit = () => {
    setEditTB(true);
    if (newTB != "") {
      getTB({
        variables: {
          a: 2,
          b: parseInt(newTB, 10),
        },
      });
    }
  };

  const onChangeEdit = ({ target }) => {
    setNewTB(target.value);
  };

  const renderTable = () => {
    let template = "";
    let current = 0;
    //console.log(dataTable);
    if (dataTable?.length > 0) {
      //console.log("if?");
      dataTable?.map(
        ({
          id_parte,
          Name,
          tipo_movimiento,
          distribuidor,
          PrecioVenta,
          Costo_pieza,
          statusMarket,
        }) => {
          current += 1;
          template += `<tr style={{ background: "#EAF0F6" }}>
              <td>${id_parte}</td>
              <td>${Name}</td>
              <td>${tipo_movimiento}</td>
              <td>${distribuidor}</td>
              <td>${formatter.format(filterFloat(Costo_pieza))}</td>
              <td>${formatter.format(filterFloat(PrecioVenta))}</td>
              <td style="display: flex;align-items: center;justify-content: center; height: 100%;"">
              ${
                // ReactDOMServer.renderToString(<SiCheckmarx />)
                ReactDOMServer.renderToString(
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      key={id_parte}
                      // onChange={changeStatusSwich}
                      checked={statusMarket}
                      disabled={true}
                    />
                  </Form>
                )
                // : statusMarket == 1
                // ? ReactDOMServer.renderToString(
                //     <SiCheckmarx style={{ color: "green" }} />
                //   )
                // ReactDOMServer.renderToString(<SiCheckmarx />)
              }
                  
  
             
              </td>
              <td>${tasaB}</td>
  
            </tr>`;

          if (current + 1 === dataTable?.length) {
            countdown("el"); // You used `el`, not `element`?

            function countdown(el) {
              document.getElementById("tbodyexample").innerHTML = template;
              //console.log("is finish");
            }
          }
        }
      );
    } else {
      countdown("el"); // You used `el`, not `element`?

      function countdown(el) {
        const temp = document.getElementById("tbodyexample");
        temp && (temp.innerHTML = "Sin resultados ");
        //console.log("else?");
      }
    }
  };

  const tipo = localStorage.getItem("typeUser");
  function cmpEdad(a, b) {
    return a.Costo_pieza - b.Costo_pieza;
  }
  function cmpEdadmayor(a, b) {
    return b.Costo_pieza - a.Costo_pieza;
  }

  function cmpEdad2(a, b) {
    return a.PrecioVenta - b.PrecioVenta;
  }
  function cmpEdadmayor2(a, b) {
    return b.PrecioVenta - a.PrecioVenta;
  }
  function cmpEdad3(a, b) {
    return a.statusMarket - b.statusMarket;
  }
  function cmpEdadmayor3(a, b) {
    return b.statusMarket - a.statusMarket;
  }
  const ordenarId = (target) => {
    //console.log(target);
    switch (target) {
      case "parte_asc":
        reOrderdata("vat.id_parte ASC");
        // dataTable.sort((x, y) => x.id_parte.localeCompare(y.id_parte));
        document.getElementById("parte_des").style.display = "initial";
        document.getElementById("parte_asc").style.display = "none";
        // renderTable();
        break;
      case "parte_des":
        reOrderdata("vat.id_parte DESC");
        // dataTable.sort((x, y) => y.id_parte.localeCompare(x.id_parte));
        document.getElementById("parte_des").style.display = "none";
        document.getElementById("parte_asc").style.display = "initial";
        // renderTable();
        break;
      case "description_asc":
        reOrderdata("vat.parte ASC");
        // dataTable.sort((x, y) => x.Name.localeCompare(y.Name));
        document.getElementById("description_des").style.display = "initial";
        document.getElementById("description_asc").style.display = "none";
        // renderTable();
        break;
      case "description_des":
        reOrderdata("vat.parte DESC");
        // dataTable.sort((x, y) => y.Name.localeCompare(x.Name));
        document.getElementById("description_des").style.display = "none";
        document.getElementById("description_asc").style.display = "initial";
        // renderTable();
        break;
      case "tipo_asc":
        // dataTable.sort((x, y) =>
        //   x.tipo_movimiento.localeCompare(y.tipo_movimiento)
        // );
        reOrderdata("vat.tipo_movimiento asc");
        document.getElementById("tipo_des").style.display = "initial";
        document.getElementById("tipo_asc").style.display = "none";
        // renderTable();
        break;
      case "tipo_des":
        reOrderdata("vat.tipo_movimiento desc");
        // dataTable.sort((x, y) =>
        //   y.tipo_movimiento.localeCompare(x.tipo_movimiento)
        // );
        document.getElementById("tipo_des").style.display = "none";
        document.getElementById("tipo_asc").style.display = "initial";
        // renderTable();
        break;
      case "distribuidor_asc":
        reOrderdata("vat.distribuidor asc");
        // dataTable.sort((x, y) => x.distribuidor.localeCompare(y.distribuidor));
        document.getElementById("distribuidor_des").style.display = "initial";
        document.getElementById("distribuidor_asc").style.display = "none";
        // renderTable();
        break;
      case "distribuidor_des":
        reOrderdata("vat.distribuidor desc");
        // dataTable.sort((x, y) => y.distribuidor.localeCompare(x.distribuidor));
        document.getElementById("distribuidor_des").style.display = "none";
        document.getElementById("distribuidor_asc").style.display = "initial";
        // renderTable();
        break;
      case "venta_asc":
        reOrderdata("vat.Costo_pieza desc");
        // dataTable.sort(cmpEdadmayor);
        document.getElementById("venta_des").style.display = "initial";
        document.getElementById("venta_asc").style.display = "none";
        // renderTable();
        break;
      case "venta_des":
        reOrderdata("vat.Costo_pieza asc");
        // dataTable.sort(cmpEdad);
        document.getElementById("venta_des").style.display = "none";
        document.getElementById("venta_asc").style.display = "initial";
        // renderTable();
        break;
      case "precio_asc":
        reOrderdata("vat.Precio_de_Venta desc");
        // dataTable.sort(cmpEdadmayor2);
        document.getElementById("precio_des").style.display = "initial";
        document.getElementById("precio_asc").style.display = "none";
        // renderTable();
        break;
      case "precio_des":
        reOrderdata("vat.Precio_de_Venta asc");
        // dataTable.sort(cmpEdad2);
        document.getElementById("precio_des").style.display = "none";
        document.getElementById("precio_asc").style.display = "initial";
        // renderTable();
        break;
      case "market_asc":
        reOrderdata("cpg.statusMarket asc");
        // dataTable.sort(cmpEdadmayor3);
        document.getElementById("market_des").style.display = "initial";
        document.getElementById("market_asc").style.display = "none";
        // renderTable();
        break;
      case "market_des":
        reOrderdata("cpg.statusMarket desc");
        // dataTable.sort(cmpEdad3);
        document.getElementById("market_des").style.display = "none";
        document.getElementById("market_asc").style.display = "initial";
        // renderTable();
        break;
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  let template = "";
  let current = 0;

  // ${
  //   optionSelect !== "" ? (
  //     <td>
  //       <tooltip
  //         style={{ cursor: "pointer" }}
  //         title="Comparar con otro distribuidor"
  //         values={{ color: "red" }}
  //       >
  //         <HiDotsVertical onClick={() => handleTool(id_parte)} />
  //       </tooltip>
  //     </td>
  //   ) : null
  // }

  // <Form>
  //   <Form.Check
  //     type="switch"
  //     id="custom-switch"
  //     key={id_parte}
  //     onChange={changeStatusSwich}
  //     checked={statusMarket}
  //     //disabled=${disabled}
  //   />
  // </Form>
  return (
    <>
      <GenericModalCompareDis
        dataChartHerDistri={dataChartHerDistri}
        dataLabelsChartDis={dataLabelsChartDis}
        open={open}
        handleClose={handleClose}
        dataCompareModal={dataCompareModal}
        columnsTableModal={columnsTableModal}
        parte={parte}
      />

      <div className="d-flex bottomContainerClient">
        <div className="tContainer" style={{ width: "100%" }}>
          <table className="table table-striped tableContainer">
            <thead className="sticky">
              <tr className="sticky titeColumns">
                <th>
                  No. parte{" "}
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="parte_asc"
                    onClick={() => {
                      ordenarId("parte_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="parte_des"
                    onClick={() => {
                      ordenarId("parte_des");
                    }}
                  />
                </th>
                <th>
                  Descripción
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="description_asc"
                    onClick={() => {
                      ordenarId("description_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="description_des"
                    onClick={() => {
                      ordenarId("description_des");
                    }}
                  />
                </th>
                <th>
                  Tipo de movimiento
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="tipo_asc"
                    onClick={() => {
                      ordenarId("tipo_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="tipo_des"
                    onClick={() => {
                      ordenarId("tipo_des");
                    }}
                  />
                </th>
                <th>
                  Distribuidor
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="distribuidor_asc"
                    onClick={() => {
                      ordenarId("distribuidor_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="distribuidor_des"
                    onClick={() => {
                      ordenarId("distribuidor_des");
                    }}
                  />
                </th>
                <th>
                  Costo de pieza
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="venta_asc"
                    onClick={() => {
                      ordenarId("venta_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="venta_des"
                    onClick={() => {
                      ordenarId("venta_des");
                    }}
                  />
                </th>
                <th>
                  Precio de venta
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="precio_asc"
                    onClick={() => {
                      ordenarId("precio_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="precio_des"
                    onClick={() => {
                      ordenarId("precio_des");
                    }}
                  />
                </th>
                <th style={{ textAlign: "center" }}>
                  Market Place
                  <FcAlphabeticalSortingAz
                    style={{ cursor: "pointer" }}
                    id="market_asc"
                    onClick={() => {
                      ordenarId("market_asc");
                    }}
                  />
                  <FcAlphabeticalSortingZa
                    style={{ cursor: "pointer", display: "none" }}
                    id="market_des"
                    onClick={() => {
                      ordenarId("market_des");
                    }}
                  />
                </th>
                {optionSelect !== "" ? <th>Acción</th> : null}
                <th style={{ textAlign: "center" }}>
                  {editTB ? (
                    <span className="titeColumns">
                      Tasa de Beneficio{" "}
                      <FiEdit3 onClick={() => setEditTB(false)} />
                    </span>
                  ) : (
                    <span>
                      <input type="text" onChange={onChangeEdit} />
                      <AiFillSave onClick={() => saveTBbyEdit()} />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              id="tbodyexample"
              className="textContainTable bodyTable"
            ></tbody>
          </table>
        </div>

        {/*  <div className="graphData">
        <div className="chartContainer">
          <Chart
            labels={labels}
            dataChart={dataChart}
          />
        </div>
      </div> */}
      </div>
    </>
  );
}
