import React,{useState,useEffect} from "react";

import NavComp from "../Navbar/index";
import LeftAside from "./LeftAside";
// import MainContainer from "./mainContainer";
import "../../styles/LeftAside/style.scss";
import "../../styles/mainContainer/style.scss";
import Administrator from "./Administrator";

const DashboardAdmin =()=>{
  
  const [showWindow, setShowWindow] = useState(false);

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);
    var token = localStorage.getItem("token");
    //console.log(token);
    return (
      <React.Fragment>
        <NavComp />
        {token ? ( 
        <div className="mainDashboard d-flex">
            {showWindow ? <LeftAside /> : null}
          <Administrator />
        </div>
          ):(
            <div className="d-flex noAuth"><label>Sin Autorización</label></div>
          )
        }
      </React.Fragment>
    );
  
}

export default DashboardAdmin;