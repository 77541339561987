import React, { Fragment } from "react";

import NavComp from "../Navbar/index";
import SecondNav from "../Ecommerce/secondNav";
import SelectMenu from "../selectMenu";
import ProductResults from "./productResults";
import Footer from "../Footer";

import "../../styles/Login/style.scss";
import "../../styles/Ecommerce/style.scss";

class SearchProducts extends React.Component {
  render() {
    return (
      <Fragment>
        <NavComp />
        <div className="mainHomeTop d-flex flex-column">
          <SecondNav />
          <SelectMenu />
          <ProductResults />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default SearchProducts;
