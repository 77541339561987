import React, { useEffect, useState } from "react";
import "../../styles/InsideProduct/style.scss";
import Test2 from "../../assets/imgHome/Test2.png";

export default function ProductCard() {
  return (
    <React.Fragment>
      <div className="card">
        <img className="card-img-top" src={Test2} alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Sensor de Oxígeno</h5>
        </div>
      </div>
    </React.Fragment>
  );
}
