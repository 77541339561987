import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import cuenta from '../../assets/usuario.svg';
import campana from '../../assets/campana.svg';
import shoppingCart from '../../assets/shopping-cart-solid.svg';
import { Modal } from '@mui/material';
import ModalCart from '../ShoppingCart/modalCart';
import { useSelector } from 'react-redux';

const overrideStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'flex-start',
	overflowY: 'auto',
};

function Icons() {
	const [modalShopping, setModalShopping] = useState(false);
	const shoppingReducer = useSelector((state) => state.rootReducers.shoop);

	return (
		<div className=' d-flex justify-content-around icons'>
			<Link>
				<img src={cuenta} />
			</Link>
			<Link>
				<img src={campana} />
			</Link>
			<button
				onClick={() => setModalShopping((state) => !state)}
			>
				<img src={shoppingCart} />
				{shoppingReducer.length > 0 && <label>{shoppingReducer.length}</label>}
			</button>
			{
				<Modal
					open={modalShopping}
					onClose={() => setModalShopping((state) => !state)}
					sx={overrideStyle}
				>
					<ModalCart setShoppingModal={setModalShopping} />
				</Modal>
			}
		</div>
	);
}

export default Icons;
