import React from "react";

export default function SearhBar() {
  return (
    <div className="searchContainer">
      <div className="searchBar d-flex">
        <input
          type="Search"
          placeholder="Buscar inventario, Clientes, Ofertas..."
          className="inputBar"
        />
        <button className="btn btn-primary">Buscar</button>
      </div>
    </div>
  );
}
