import React from "react";
import { Box, Modal } from "@material-ui/core";
import ChartBestSellinngsPieces from "../ChartDahsboard/ChartBestSellinngsPieces";
import Title from "antd/lib/typography/Title";
import { GenericTable } from "./GenericTable";

const GenericModalCompareDis = ({
  dataChartHerDistri,
  dataLabelsChartDis,
  open,
  handleClose,
  dataCompareModal,
  columnsTableModal,
  parte,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "Flex" }}>
            <div style={{ width: "70%" }}>
              <Title
                style={{ fontSize: 18, fontWeight: 700, color: "#022741" }}
              >
                Comparar con distribuidor hermano
              </Title>
            </div>
          </div>
          {/* <ChartBestSellinngsPieces
                        width='90%'
                        title={`No.Parte: ${parte}`}
                        dataBarPieces={dataChartHerDistri}
                        labels={dataLabelsChartDis}
                        indexAxis="y"
                        boxShadow="0"
                        borderRadius={50}
                        label={'piezas disponibles'} /> */}
          <div className="tContainer" style={{ width: "100%", marginTop: 18 }}>
            <GenericTable columns={columnsTableModal} body={dataCompareModal} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default GenericModalCompareDis;
