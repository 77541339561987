import React, { useState, useEffect } from "react";

import NavComp from "../Navbar/index";
import SecondNav from "./secondNav";
import TopImage from "./topImage";
import Card from "../cards";
import Footer from "../Footer";
import "../../styles/Ecommerce/style.scss";
import TopImg from "../../assets/topImage.jpg";
import NewTag from "../newTag";
//imgHome
import Test1 from "../../assets/imgHome/Test1.png";
import Test2 from "../../assets/imgHome/Test2.png";
import Test3 from "../../assets/imgHome/Test3.png";
import Test4 from "../../assets/imgHome/Test4.png";
import Test5 from "../../assets/imgHome/Test5.png";
import Test6 from "../../assets/imgHome/Test6.png";
import Test7 from "../../assets/imgHome/Test7.png";
import Test8 from "../../assets/imgHome/Test8.png";
import Test9 from "../../assets/imgHome/Test9.png";
import Test10 from "../../assets/imgHome/Test10.png";
import Test11 from "../../assets/imgHome/sponsors.png";
//IconsHome
import Truck from "../../assets/truck.svg";
import CreditCard from "../../assets/creditCard.svg";
import Chats from "../../assets/comments.svg";
import IdCard from "../../assets/idCard.svg";
import createBarrel from "../../assets/image/imageMarcas/createBarrel.svg";
import "../../styles/Cards/style.scss";
import leadsGrenn from "../../assets/image/imageMarcas/leadsGreen.svg";
import techinasa from "../../assets/image/imageMarcas/techinasa.svg";
import videoPike from "../../assets/image/imageMarcas/videoPike.svg";
import carrentals from "../../assets/image/imageMarcas/carrentals.svg";
import easySpeedy from "../../assets/image/imageMarcas/easySpeedy.svg";
import creative from "../../assets/image/imageMarcas/creative.svg";
import carvoodoo from "../../assets/image/imageMarcas/carvoodoo.svg";
import iqvia from "../../assets/image/imageMarcas/iqvia.svg";
import impact from "../../assets/image/imageMarcas/impact.svg";
import GenericCard from "../Dashboard/GenericCard";
import {
  GET_ALL_PRODUCTS,
  GET_MARKET,
  GET_MARKET_CATEGORIES,
} from "../../graphql/Query";
import { useLazyQuery, useQuery } from "@apollo/client";

import compraAhora from "../../assets/marketplace/compra-ahora.svg";
import wheels from "../../assets/marketplace/wheels.svg";
import hotSale from "../../assets/marketplace/hot-sale.svg";
import faros from "../../assets/marketplace/faros.svg";
import discos from "../../assets/marketplace/discos.svg";
import carEnvio from "../../assets/marketplace/carEnvio.svg";
import { AiOutlineLine } from "react-icons/ai";
import { Link } from "react-router-dom";

const Ecommerce = () => {
  const [isMenuShown, setisMenuShown] = useState(false);
  const [hasOpacity, setHasOpacity] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [styles, setStyles] = useState({});
  const [cardImage, setCardImage] = useState([]);
  const [nameParte, setNameParte] = useState([]);
  const [marcaDescription, setMarcaDescription] = useState([]);
  const [precioParte, setPrecioParte] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const [arrayCards, setArrayCards] = useState([]);

  const [get_All_Market] = useLazyQuery(GET_MARKET, {
    fetchPolicy: "no-cache",
    onCompleted({ getMarketProducts }) {
      setArrayCards(getMarketProducts);
      //console.log("+++++++++++++++++++++++++++++");
      //console.log(getMarketProducts);
    },
  });

  const [get_All_Market_Category] = useLazyQuery(GET_MARKET_CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted({ getCategories }) {
      setCategorias(getCategories);
      //console.log("_______________________________");
      //console.log(getCategories);
    },
  });

  useEffect(() => {
    get_All_Market({
      variables: {
        pag: 0,
        id: "",
        marca: "",
        categoria: "",
      },
    });

    get_All_Market_Category({
      variables: {
        a: "",
      },
    });

    setStyles({
      opacity: hasOpacity ? "1.5" : "",
      backgroundColor: hasOpacity ? " rgba(0,0,0,0.6)" : "",
      filter: hasOpacity ? "brightness(0.4)" : "",
    });
    if (hasOpacity) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";
  }, [hasOpacity]);

  return (
    <React.Fragment>
      <NavComp />
      <div
        className="mainHomeTop d-flex flex-column"
        style={{ backgroundColor: "#d4e1ec", backgroundColor: "#d4e1ec" }}
      >
        {/* <SecondNav
          setHasOpacity={setHasOpacity}
          isMenuShown={isMenuShown}
          setisMenuShown={setisMenuShown}
          disabled={disabled}
          setDisabled={setDisabled}
        /> */}
        <div
          style={styles}
          onClick={() => {
            setisMenuShown(false);
            setHasOpacity(false);
            setDisabled(false);
          }}
        >
          <TopImage />
          <div className="mainMidContainer ">
            <div className="containerMid d-flex ">
              <div className="containerHorizontal d-flex">
                <div className="card" id="containerHorizontalImage">
                  <img src={carEnvio} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerHorizontalImage">
                  <img src={CreditCard} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerHorizontalImage">
                  <img src={Chats} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerHorizontalImage2">
                  <img src={IdCard} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
              </div>
            </div>
            {/* <div className="containerMid d-flex " style={{ display: "flex" }}>
              <AiOutlineLine color="#A6DBFD" fontSize=" xx-large" />
              <h3 style={{ fontWeight: 700 }}>Promociones</h3>
            </div>
            <div className="containerMid d-flex ">
              <div className="leftContainer" style={{ width: "70%" }}>
                <div className="card" id="TopLeftImageContainer">
                  <img
                    src={compraAhora}
                    className="card-img-top"
                    style={{ height: "99%" }}
                  />
                  <div className="textImg d-flex">Contenedor 1</div>
                </div>
              </div>
              <div
                className="rightContainer d-flex flex-column"
                style={{ width: "30%" }}
              >
                <div
                  className="card"
                  id="TopRightImageContainer"
                  style={{ width: "99%" }}
                >
                  <img src={wheels} className="card-img-top" />
                  <div className="textImg d-flex">Contenedor 1</div>
                </div>
                <div
                  className="card"
                  id="TopRightImageContainer"
                  style={{ width: "99%" }}
                >
                  <img src={hotSale} className="card-img-top" />
                  <div className="textImg d-flex">Contenedor 1</div>
                </div>
              </div>
            </div> */}
            <div className="containerMid d-flex " style={{ display: "flex" }}>
              <AiOutlineLine color="#A6DBFD" fontSize=" xx-large" />
              {/* <h3 style={{ fontWeight: 700 }}>Novedades</h3> */}
            </div>

            <div className="containerMid2">
              <div className="containerNewItems2">
                <GenericCard
                  arrayCards={arrayCards}
                  limit={2}
                  categories={categorias}
                />
              </div>
            </div>
            <div
              style={{
                marginLeft: "5%",
                color: "#027CBB",
                fontWeight: 600,
                fontSize: 18,
                display: "flex",
                marginTop: 20,
                marginRight: 45,
                justifyContent: "end",
              }}
            >
              {/* <Link to={"/AllProducts"}>Ver todos los productos</Link> */}
            </div>
            {/*  <div className="containerMid d-flex " style={{ display: "flex" }}>
              <AiOutlineLine color="#A6DBFD" fontSize=" xx-large" />
              <h3 style={{ fontWeight: 700 }}>Más vendidos</h3>
            </div>
            <div className="containerMid d-flex ">
              <div className="containerItems2 d-flex">
                <div
                  className="card containerNewItemsImg1"
                  id="containerNewItemsImg"
                >
                  <img src={faros} className="card-img-top" />
                </div>
                <div className="card" id="containerNewItemsImg">
                  <img src={discos} className="card-img-top" />
                </div>
              </div>
            </div>
            <div className="containerMid d-flex " style={{ display: "flex" }}>
              <AiOutlineLine color="#A6DBFD" fontSize=" xx-large" />
              <h3 style={{ fontWeight: 700 }}>Categorias Destacadas</h3>
            </div>
            <div className="containerMid d-flex flex-column ">
              <div className="containerItems3 d-flex">
                <div className="card" id="containerNewItemsImg">
                  <img src={Test5} className="card-img-top" />
                  <div className="textImg">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerNewItemsImg2">
                  <img src={Test6} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerNewItemsImg">
                  <img src={Test7} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
              </div>
              <div className="containerItems3 d-flex">
                <div className="card" id="containerNewItemsImg">
                  <img src={Test8} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerNewItemsImg2">
                  <img src={Test9} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
                <div className="card" id="containerNewItemsImg">
                  <img src={Test10} className="card-img-top" />
                  <div className="textImg d-flex">Lorem ipsum dolor sit</div>
                </div>
              </div>
            </div> */}
            <div className="containerMid d-flex " style={{ display: "flex" }}>
              <AiOutlineLine color="#A6DBFD" fontSize=" xx-large" />
              <h3 style={{ fontWeight: 700 }}>Las mejores Marcas</h3>
            </div>
            <div className="containerMid d-flex ">
              <div className="containerSponsors d-flex">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    gridTemplateRows: "1fr 1fr",
                    justifyItems: " center",
                  }}
                >
                  <img src={leadsGrenn} />
                  <img src={createBarrel} />
                  <img src={techinasa} />
                  <img src={videoPike} />
                  <img src={carrentals} />
                  <img src={easySpeedy} />
                  <img src={creative} />
                  <img src={carvoodoo} />
                  <img src={iqvia} />
                  <img src={impact} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Ecommerce;
