import React from 'react';
import { useSelector } from 'react-redux';
import '../../styles/Shopping/style.scss';
import ProductsCart from './productsCart';
import { useHistory } from 'react-router-dom';

export default function ShoppingDetail() {
	const shoppingValues = useSelector((state) => state.rootReducers.shoop);
	const navigate = useHistory();

	const getTotalAmount = () => {
		let money = 0;
		shoppingValues.forEach((element) => {
			money +=
				Number(element.Precio_de_Venta) * element.cuantity +
				10 * element.cuantity;
		});

		const parsedMoney = money.toLocaleString('es-MX', {
			style: 'currency',
			currency: 'MXN',
		});

		return parsedMoney;
	};

	const handlePayment = () => {
		navigate.push('/payment');
	};

	return (
		<React.Fragment>
			<div className='mainContainer'>
				<div className=' d-flex topSection '>
					<div className='table'>Confirmación de pedido</div>
				</div>
				<div className=' d-flex tableList '>
					<div className='table'>Producto</div>
					<div className='table'>Descripción</div>
					<div className='table'>Precio Unitario</div>
					<div className='table'>Cantidad</div>
					<div className='table'>Subtotal</div>
				</div>
				<div className='d-flex productList '>
					{shoppingValues.map((element, index) => (
						<ProductsCart
							key={index}
							Image={element.Image}
							id_parte={element.id_parte}
							SKU={element.SKU}
							Dimensiones={element.Dimensiones}
							Proveedor={element.Proveedor}
							Peso={element.Peso}
							existencias={element.existencias || 0}
							Precio_de_Venta={element.Precio_de_Venta}
							cuantity={element.cuantity}
							index={index}
							name={element.Name}
						/>
					))}
				</div>
				<div className='d-flex total'>
					<div className='spaceTotalPrice'>Total</div>
					<div className='totalLabel'>{getTotalAmount()}</div>
				</div>
				<div className='d-flex topSection'>
					<button className='btn payButton' onClick={handlePayment}>
						Realizar el pago
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}
