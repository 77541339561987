import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

import Login from "../screens/Login";
import LoginDistributor from "../screens/LoginDistributor";
import RecoverPass from "../screens/RecoverPassword";
import RecoverPassDistributor from "../screens/RecoverPasswordDistributor";
import Dashboard from "../screens/Dashboard";
import DashboardDistributor from "../screens/DashboardDistributor";
import Notifications from "../screens/Notifications";
import OptionsNotifications from "../screens/notificationsOptions";
import Ecommerce from "../screens/eCommerce";
import Cliente from "../screens/Client";
import InsideProduct from "../screens/insideProduct";
import SearchProducts from "../screens/SearchProducts";
import Shopping from "../screens/Shopping";
import TableMov from "../components/Dashboard/TableMov";
import TableMovDis from "../components/DashboardDistributor/TableMov";
import ClientStore from "../components/Dashboard/ClientStore";
import ClientStoreDistributor from "../components/DashboardDistributor/ClientStore";
import AllProducts from "../components/Ecommerce/AllProducts";
import DashboardAdmin from "../components/DashboardAdmin/DashboardAdmin";
import ClientStoreAdmin from "../components/DashboardAdmin/ClientStoreAdmin";
import StartMkt from "../screens/StartMkt";
import CatMkt from "../screens/catMkt";
import MercadoPagoScreen from '../screens/MercadoPago';
import PaymentScreen from '../screens/MercadoPago/PaymentScreen';

const typeDefs = gql`
  extend input SellInfo {
    ID: ID
    id_parte: String
    id_grupo: Int
    id_distribuidor: Int
    email: String
    cuantity: Int
    email: String
    Proveedor: String
    Precio_de_Venta: String
  }

  extend input Identification {
    type: String
    number: String
  }

  extend input Payer {
    email: String
  }
`;

function RouterApp() {
  const client = new ApolloClient({
    link: createUploadLink({
      uri: "https://api.dev.ballena.therocketcode.com/",
      // uri: "http://localhost:5001/api",
    }),
    cache: new InMemoryCache(),
    typeDefs,
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          {/* <Route exact path="/distributor">
            <LoginDistributor />
          </Route> */}
          <Route exact path='/payment-result/:id'>
            <PaymentScreen />
          </Route>
          <Route exact path="/recover">
            <RecoverPass />
          </Route>
          <Route exact path="/recoverdis">
            <RecoverPassDistributor />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/dashboardMovimientos">
            <TableMov />
          </Route>
          <Route exact path="/ClientStore">
            <ClientStore />
          </Route>
          <Route exact path="/ClientStoreAdmin">
            <ClientStoreAdmin />
          </Route>
          <Route exact path="/dashboardMovimientosDis">
            <TableMovDis />
          </Route>
          <Route exact path="/dashboardAdmin">
            <DashboardAdmin />
          </Route>
          <Route exact path="/dashboardDis">
            <DashboardDistributor />
          </Route>
          <Route exact path="/dashboardDis/ClientStore">
            <ClientStoreDistributor />
          </Route>
          <Route exact path="/notifications">
            <Notifications />
          </Route>
          <Route exact path="/setup">
            <OptionsNotifications />
          </Route>
          <Route exact path="/store">
            <Ecommerce />
          </Route>
          <Route exact path="/AllProducts">
            <AllProducts />
          </Route>
          <Route exact path="/cliente">
            <Cliente />
          </Route>
          <Route exact path="/searchProducts">
            <SearchProducts />
          </Route>
          <Route exact path="/insideProduct">
            <InsideProduct />
          </Route>
          <Route exact path="/shopping">
            <Shopping />
          </Route>
          {/* <Route exact path="/test">
            <Test />
          </Route> */}

          <Route exact path="/start">
            <StartMkt />{" "}
          </Route>
          <Route exact path="/categorias/:category/:categoryId">
            <CatMkt />{" "}
          </Route>
          <Route exact path="/payment">
            <MercadoPagoScreen />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}
export default RouterApp;
