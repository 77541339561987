import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { GET_SALES_MARKET } from "../../graphql/Query";
const initialState = {
  labels: [],
  datasets: [
    {
      label: "Actual",
      data: [],
      backgroundColor: [],
      borderColor: "Blue",
      /* hoverBackgroundColor: [], */
      cutout: "75%",
      spacing: 5,
      borderRadius: 0,
    },
    {
      label: "Anterior",
      data: [],
      lineTension: 0,
      fill: false,
      borderColor: "yellow",
      backgroundColor: "transparent",
      borderDash: [5, 5],
      pointBorderColor: "yellow",
      pointBackgroundColor: "yellow",
      pointRadius: 3,
      pointHoverRadius: 5,
      pointHitRadius: 30,
      pointBorderWidth: 1,
      pointStyle: "rectRounded",
    },
  ],
};
function ChartLineMarket({
  dataActual,
  datanterior,
  labelActual,
  labrlAnterior,
  height = 0,
  weight = "90%",
  title,
  labels,
  backgroundColor = ["yellow"],
  backgroundColor2 = ["blue"],
  numeroToTal = "",
  handleClick,
  num = 2.9,
}) {
  const validateHeight = height === 0 ? null : height;
  const validateWeight = weight === 0 ? null : weight;
  const numCenter = num === 0 ? null : num;
  const [total, setTotal] = useState(numeroToTal);
  //console.log('dataDonut', dataDonut)
  const [data, setData] = useState(initialState);
  const [showWindow, setShowWindow] = useState(false);
  // filtros ABC admin
  const [getSalesMonthl] = useLazyQuery(GET_SALES_MARKET, {
    fetchPolicy: "no-cache",
    onCompleted({ getSalesMonthly }) {
      // debugger
      // console.log("step----------------1");
      // console.log(getSalesMonthly);
      // const disFilter = [];

      // labels.map((obj, i) => {
      //   if (obj !== "__typename") {
      //     disFilter.push(obj);
      //   }
      // });

      console.log("getSalesMonthly");
    },
    onError(err) {
      console.log(err);
    },
  });

  // getSalesMonthl({
  //   variables: {
  //     dist: 0,
  //     periodo: "",
  //   },
  // });

  useEffect(() => {
    setData({
      ...data,
      labels: [...labels],
      datasets: [
        {
          ...data.datasets[0],
          data: dataActual,
          backgroundColor: [...backgroundColor2],
          /*  hoverBackgroundColor: [...hoverBackgroundColor] */
        },
        {
          ...data.datasets[1],
          data: datanterior,
          backgroundColor: [...backgroundColor],
          /*  hoverBackgroundColor: [...hoverBackgroundColor] */
        },
      ],
    });
    setTotal(numeroToTal);
  }, [datanterior, dataActual, total, numeroToTal]);

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  if (!total || !numeroToTal) return null;
  if (showWindow) {
    return (
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        <Line
          options={{
            scales: {
              y: {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                    return "$" + value;
                  },
                },
              },
              x: {
                adapters: {
                  // date: { locale: enGB },
                  type: "time",
                  distribution: "linear",
                  time: {
                    parser: "yyyy-MM-dd",
                    unit: "month",
                  },
                  title: {
                    display: true,
                    text: "Date",
                  },
                },
              },
            },
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                  handleClick(data, item);
                }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "top",
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 2;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = total;
                // textX = Math.round(
                //   (width - ctx.measureText(text).width) / 2.9
                // ),
                // textY = height / 1.9;
                // ctx.fillText(text, textX, textY);
                // ctx.save();
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItems, data) {
                    return "$" + tooltipItems.yLabel.toString();
                  },
                },
              },
            },
          ]}

          /*  style={{ height: '50%', width: '50%' }} */
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        <Line
          options={{
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                  handleClick(data, item);
                }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "right",
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
              tooltip: {
                usePointStyle: true,
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || "";

                    if (label) {
                      label += ": ";
                    }
                    if (context.parsed.y !== null) {
                      label +=
                        "$" +
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(context.parsed.y);
                    }
                    return label;
                  },
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 2;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                // let text = total,
                //   textX = Math.round(
                //     (width - ctx.measureText(text).width) / numCenter
                //   ),
                //   textY = height / 1.9;
                // ctx.fillText(text, textX, textY);
                // ctx.save();
              },
            },
          ]}
        />
      </div>
    );
  }
}
export default ChartLineMarket;
