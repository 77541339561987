import React, { useEffect, useState } from "react";
import ProductCard from "./productCard";
import "../../styles/ProductResults/style.scss";

export default function ProductResults() {
  return (
    <React.Fragment>
      <div className="fullContainer">
        <div className="d-flex headerContainer">
          <label>Aceites</label>
          <div className="counter" >
            <button id="leftBtn" style={{background:'white',border:'none',boxShadow: '0px 4px 5px 0px rgba(161,161,161,0.68)'}}>
              <svg
                width="9"
                height="14"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.44311 1.46909L7.44311 11.9411C7.44311 12.8827 6.28911 13.3535 5.61631 12.6891L0.314121 7.4531C-0.104707 7.0395 -0.104707 6.3707 0.314121 5.9615L5.61631 0.725489C6.28911 0.0566877 7.44311 0.527488 7.44311 1.46909Z"
                  fill="black"
                />
              </svg>
            </button>
            <label className="counterNums">1</label>
            <label className="counterNums">2</label>
            <label className="counterNums">3</label>
            <button id="rightBtn" style={{background:'white',border:'none',boxShadow: '0px 4px 5px 0px rgba(161,161,161,0.68)'}}>
              <svg
                width="9"
                height="14"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.556885 11.5309L0.556885 1.05889C0.556885 0.11729 1.71089 -0.353511 2.38369 0.31089L7.68588 5.5469C8.10471 5.9605 8.10471 6.6293 7.68588 7.0385L2.38369 12.2745C1.71089 12.9433 0.556885 12.4725 0.556885 11.5309Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>

          {/* <div className="d-flex counter">
            <button className="d-flex ">
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.44311 1.46909L7.44311 11.9411C7.44311 12.8827 6.28911 13.3535 5.61631 12.6891L0.314121 7.4531C-0.104707 7.0395 -0.104707 6.3707 0.314121 5.9615L5.61631 0.725489C6.28911 0.0566877 7.44311 0.527488 7.44311 1.46909Z"
                  fill="black"
                />
              </svg>
            </button>
            <label className="counterNum">1</label>
            <label className="counterNum">2</label>
            <label className="counterNum">3</label>
            <button className="d-flex ">
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.556885 11.5309L0.556885 1.05889C0.556885 0.11729 1.71089 -0.353511 2.38369 0.31089L7.68588 5.5469C8.10471 5.9605 8.10471 6.6293 7.68588 7.0385L2.38369 12.2745C1.71089 12.9433 0.556885 12.4725 0.556885 11.5309Z"
                  fill="black"
                />
              </svg>
            </button>
          </div> */}

          {/* <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
        <div className="d-flex imgRow">
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>
        <div className="d-flex imgRow">
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>
        <div className="d-flex imgRow">
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>
      </div>
    </React.Fragment>
  );
}
