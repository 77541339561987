import React from "react";
import { useSelector } from "react-redux";
import "../../styles/Modals/modalCart.scss";
import { useHistory } from "react-router-dom";
import RenderProducts from "./RenderProducts";
import withAmount from "./conditionalRender";
import { IoCloseOutline } from "react-icons/io5";

const ModalCart = ({ setShoppingModal }) => {
  const shoppingCart = useSelector((state) => state.rootReducers.shoop);
  const navigate = useHistory();

  const handlePayment = async () => {
    navigate.push("/payment");
  };

	const getTotalAmount = () => {
		let money = 0;
		shoppingCart.forEach((element) => {
			money +=
				Number(element.Precio_de_Venta) * element.cuantity +
				10 * element.cuantity;
		});

    const parsedMoney = money.toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
    });

    return parsedMoney;
  };

  const dropable = (condition) => {
    if (condition) return "dropable";
  };

  const ConditionalRender = withAmount(RenderProducts);

  return (
    <div className="modalCart">
      <header className="header">
        <IoCloseOutline onClick={() => setShoppingModal((state) => !state)} />
      </header>
      <div className="mainContainer">
        <ConditionalRender
          shoppingCart={shoppingCart}
          condition={shoppingCart.length > 0}
        />
      </div>
      <div className={`buySection ${dropable(shoppingCart.length <= 0)}`}>
        <span>Total:</span> {getTotalAmount()}
      </div>
      <div className="lastSection">
        <button
          className="btn cancelBtn"
          onClick={() => setShoppingModal((state) => !state)}
        >
          Cancelar
        </button>
        <button
          className="btn payButton"
          onClick={handlePayment}
          disabled={!(shoppingCart.length > 0)}
        >
          Realizar pago
        </button>
      </div>
    </div>
  );
};

export default ModalCart;
