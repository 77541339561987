import { configureStore } from "@reduxjs/toolkit";
import shoppingReducer, {
  loadState,
  saveState,
} from "./reducers/shopping.reducer";
import filtersReducer, {
  searchWithFilter,
  saveStateFilter,
} from "./reducers/FiltersProducts.reducer";
import tbReducer, {
  saveStateTB,
  changeTasa,
} from "./reducers/TasaBeneficio.reducer";
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  shoop: shoppingReducer,
  filters: filtersReducer,
  tasa: tbReducer,
});

const persistentDate = loadState();
const storeShop = configureStore({
  reducer: { rootReducers },
  preloadedState: persistentDate,
});

storeShop.subscribe(() => {
  saveState(storeShop.getState().rootReducers.shoop);
  saveStateFilter(storeShop.getState().rootReducers.filters);
  saveStateTB(storeShop.getState().rootReducers.tasa);
});

export default storeShop;
