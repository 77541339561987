import React, { useEffect, useState } from "react";
import "../styles/InsideProduct/style.scss";

export default function SelectMenu() {
  const [autoparte, setAutoparte] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [motor, setMotor] = useState("");
  const [año, setAño] = useState("");

  return (
    <React.Fragment>
      <div className=" d-flex selectItemContainer" style={{color:'#75787B'}}>
        <select
          className="selectItem"
          onChange={(e) => {
            const selectedAutoparte = e.target.value;
            setAutoparte(selectedAutoparte);
          }}
        >
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
        </select>

        <select
          className="custom-select"
          onChange={(e) => {
            const selectedMarca = e.target.value;
            setMarca(selectedMarca);
          }}
        >
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
        </select>

        <select
          className="custom-select"
          onChange={(e) => {
            const selectedModelo = e.target.value;
            setModelo(selectedModelo);
          }}
        >
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
        </select>

        <select
          className="custom-select"
          onChange={(e) => {
            const selectedMotor = e.target.value;
            setMotor(selectedMotor);
          }}
        >
          <option value="10CL">10CL</option>
          <option value="8CL">8CL</option>
          <option value="6CL">6CL</option>
          <option value="4CL">4CL</option>
        </select>

        <select
          className="custom-select"
          onChange={(e) => {
            const selectedAño = e.target.value;
            setAño(selectedAño);
          }}
        >
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
        </select>
        <button className="btn  filterButton" style={{marginRight:'196px'}}>Buscar</button>
      </div>
    </React.Fragment>
  );
}
