import React from "react";
import Image from "../assets/rightImage.jpg";

export default function rightImage() {
  return (
    <div className="containerImg">
      <img src={Image} />
    </div>
  );
}
