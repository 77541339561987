import styled from 'styled-components';

export const Boton = styled.div`
  width: 152px;
  height: 41px;
  border: ${(props) => props.border || '1px solid #027cbb'};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${(props) => props.color || '#027cbb'};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background || '#fff'};
  cursor: ${(props) => props.cursor || 'pointer'};

  &:hover {
    background-color: ${(props) => props.backgroundHover || '#fff'};
  }
`;

export const Sections = styled.div`
  padding: 10px;
  background: white;
  border-radius: 2px;
  width: ${(props) => props.width || '45%'};
`;

export const Inline = styled.div`
  display: flex;
`;
