import React, { Fragment } from "react";
import LoginV2 from "../loginV2/LoginV2";
import Footer from "../Footer";
import "../../styles/Login/style.scss";

const Login = () => {
  return (  <Fragment>
    <div className="main">
      <LoginV2/>
      <Footer />
    </div>
  </Fragment>  );
}
 
export default Login;

