import React from "react";
import { Link, useHistory} from "react-router-dom";
import dashboardIcono from "../../assets/dashboard.svg";
import inventarioIcono from "../../assets/coche.svg";
import clientesIcono from "../../assets/usuarios.svg";
import ofertasIcono from "../../assets/dolar.svg";
import bhphIcono from "../../assets/bhph.png";
import marketingIcono from "../../assets/tag.svg";
import contabilidadIcono from "../../assets/balanza.svg";
import cuentaIcono from "../../assets/usuario.svg";
import icoMarketPlace from "../../assets/dashboard/Marketplace_icon.svg"



export default function LeftAside() {
  const history = useHistory();
  const signOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("refreshToken");
    history.push("/distributor");
  };
  return (
    <div className="leftAside d-flex">
      <div className="listContainer">
        <div className="list" style={{marginTop:'7%'}}>
          <Link to={'/dashboardDis'} className="linkStyle">
            {/* <img src={dashboardIcono} /> */}
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.2849 12.9375H12.8162L19.7881 20.0365C20.0546 20.3079 20.4931 20.3299 20.7671 20.0671C22.4744 18.4292 23.6488 16.2213 23.9934 13.7393C24.0525 13.3144 23.7062 12.9375 23.2849 12.9375ZM22.5865 10.0265C22.223 4.66007 18.0115 0.371785 12.7412 0.0016244C12.3389 -0.0266767 12.0001 0.318776 12.0001 0.729367V10.7812H21.8722C22.2755 10.7812 22.6143 10.4362 22.5865 10.0265ZM9.88242 12.9375V2.27784C9.88242 1.84883 9.51227 1.49619 9.09536 1.55639C3.83788 2.31288 -0.180785 6.98975 0.00627287 12.5947C0.198625 18.3511 5.06611 23.0715 10.722 22.9992C12.9455 22.9709 15 22.2413 16.6893 21.0212C17.0378 20.7697 17.0608 20.2472 16.7586 19.9395L9.88242 12.9375Z"
                fill="white"
              />
            </svg>
            Dashboard
          </Link>
        </div>
        {/* <div className="list">
          <Link className="linkStyle">
           
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.6667 2.3H14.79C14.3 0.966 13.0167 0 11.5 0C9.98333 0 8.7 0.966 8.21 2.3H3.33333C2.05 2.3 1 3.335 1 4.6V20.7C1 21.965 2.05 23 3.33333 23H19.6667C20.95 23 22 21.965 22 20.7V4.6C22 3.335 20.95 2.3 19.6667 2.3ZM11.5 2.3C12.1417 2.3 12.6667 2.8175 12.6667 3.45C12.6667 4.0825 12.1417 4.6 11.5 4.6C10.8583 4.6 10.3333 4.0825 10.3333 3.45C10.3333 2.8175 10.8583 2.3 11.5 2.3ZM12.6667 18.4H6.83333C6.19167 18.4 5.66667 17.8825 5.66667 17.25C5.66667 16.6175 6.19167 16.1 6.83333 16.1H12.6667C13.3083 16.1 13.8333 16.6175 13.8333 17.25C13.8333 17.8825 13.3083 18.4 12.6667 18.4ZM16.1667 13.8H6.83333C6.19167 13.8 5.66667 13.2825 5.66667 12.65C5.66667 12.0175 6.19167 11.5 6.83333 11.5H16.1667C16.8083 11.5 17.3333 12.0175 17.3333 12.65C17.3333 13.2825 16.8083 13.8 16.1667 13.8ZM16.1667 9.2H6.83333C6.19167 9.2 5.66667 8.6825 5.66667 8.05C5.66667 7.4175 6.19167 6.9 6.83333 6.9H16.1667C16.8083 6.9 17.3333 7.4175 17.3333 8.05C17.3333 8.6825 16.8083 9.2 16.1667 9.2Z"
                fill="#022741"
              />
            </svg>
            Inventario
          </Link>
        </div> */}
        {/* <div className="list">
          <Link className="linkStyle">
           
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="users"
              class="svg-inline--fa fa-users fa-w-20"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="White"
                d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
              ></path>
            </svg>
            Clientes
          </Link>
        </div> */}
        {/* <div className="list">
          <Link className="linkStyle">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_534:1626)">
                  <path
                    d="M15.6018 1.16426C14.9168 0.848011 14.0894 0.865186 13.3882 1.27006L1.88108 7.92763C0.729765 8.59234 0.325593 10.0677 0.990304 11.219L7.03313 21.6855C7.69784 22.8368 9.17764 23.2245 10.329 22.5598L21.8481 15.9231C22.5494 15.5183 22.978 14.8103 23.0466 14.059L23.8194 5.82571C23.8696 5.30831 23.5916 4.82685 23.1184 4.6116L15.6018 1.16426Z"
                    fill="white"
                  />
                  <path
                    d="M11.3394 7.58228L8.83696 16.9214"
                    stroke="#022741"
                    stroke-linecap="round"
                    opacity=".6"
                  />
                  <circle
                    cx="6.58612"
                    cy="11.3134"
                    r="1.91713"
                    transform="rotate(15 6.58612 11.3134)"
                    stroke="#022741"
                    opacity=".6"
                  />
                  <circle
                    cx="13.59"
                    cy="13.1901"
                    r="1.91713"
                    transform="rotate(15 13.59 13.1901)"
                    stroke="#022741"
                    opacity=".6"
                  />
                  <circle
                    cx="19.7569"
                    cy="6.20857"
                    r="1.20857"
                    fill="#022741"
                    opacity=".6"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_534:1626">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            Ofertas
          </Link>
        </div> */}
        {/* <div className="list">
          <Link className="linkStyle">
            <svg
              
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 11V4.26C12.35 4.09 11.68 4 11 4C6.8 4 3 7.22 3 12.2C3 15.38 5.45 19.12 10.34 23.43C10.72 23.76 11.29 23.76 11.67 23.43C16.55 19.12 19 15.38 19 12.2C19 11.79 18.96 11.39 18.91 11H13ZM11 14C9.9 14 9 13.1 9 12C9 10.9 9.9 10 11 10C12.1 10 13 10.9 13 12C13 13.1 12.1 14 11 14Z"
                fill="white"
                
              />
              <path
                d="M17.3577 4.72792L15.624 4.22319C15.4233 4.16569 15.2836 3.97882 15.2836 3.77118C15.2836 3.51083 15.4955 3.3 15.7572 3.3H16.8215C17.0173 3.3 17.21 3.3591 17.3705 3.46771C17.4684 3.53319 17.6001 3.51722 17.6835 3.43576L18.2422 2.89271C18.3562 2.7825 18.3401 2.59882 18.2133 2.50139C17.82 2.19472 17.3288 2.02382 16.8247 2.02222V1.25556C16.8247 1.115 16.7091 1 16.5679 1H16.0542C15.9129 1 15.7973 1.115 15.7973 1.25556V2.02222H15.7572C14.7346 2.02222 13.9127 2.8959 14.0074 3.9325C14.0749 4.66882 14.6399 5.26778 15.3527 5.47542L16.9981 5.95458C17.1987 6.01368 17.3384 6.19896 17.3384 6.4066C17.3384 6.66694 17.1265 6.87778 16.8648 6.87778H15.8005C15.6047 6.87778 15.4121 6.81868 15.2515 6.71007C15.1536 6.64458 15.022 6.66056 14.9385 6.74201L14.3799 7.28507C14.2659 7.39528 14.2819 7.57896 14.4088 7.67639C14.8021 7.98306 15.2933 8.15396 15.7973 8.15556V8.92222C15.7973 9.06278 15.9129 9.17778 16.0542 9.17778H16.5679C16.7091 9.17778 16.8247 9.06278 16.8247 8.92222V8.15236C17.5728 8.13799 18.2743 7.69556 18.5215 6.99118C18.8666 6.00729 18.2871 4.99785 17.3577 4.72792V4.72792Z"
                fill="white"
                
              />
            </svg>
            BHPH
          </Link>
        </div> */}
        {/* <div className="list">
          <Link className="linkStyle">
           
            <svg
              
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0586 11.4707C19.0586 12.053 19.5351 12.5295 20.1174 12.5295H22.2351C22.8174 12.5295 23.2939 12.053 23.2939 11.4707C23.2939 10.8883 22.8174 10.4119 22.2351 10.4119H20.1174C19.5351 10.4119 19.0586 10.8883 19.0586 11.4707Z"
                fill="white"
              />
              <path
                d="M17.1749 17.3269C16.7875 17.9084 16.8932 18.7147 17.4097 19.1377C18.0319 19.6532 18.6893 20.2083 19.3114 20.737C19.828 21.1732 20.5558 21.0542 20.9314 20.4727C20.9314 20.4594 20.9432 20.4594 20.9432 20.4462C21.3306 19.8646 21.2249 19.0452 20.7084 18.6222C20.0862 18.0935 19.4288 17.5384 18.8184 17.0229C18.3019 16.5867 17.5741 16.7189 17.1867 17.3004C17.1867 17.3137 17.1749 17.3269 17.1749 17.3269Z"
                fill="white"
              />
              <path
                d="M20.9468 3.41354C20.9468 3.40367 20.935 3.40367 20.935 3.3938C20.5465 2.95946 19.8167 2.87062 19.3105 3.19637C18.6866 3.59123 18.0157 4.00582 17.4035 4.40068C16.8856 4.72643 16.7914 5.33846 17.1799 5.76293C17.1799 5.7728 17.1917 5.7728 17.1917 5.78267C17.5801 6.21701 18.2982 6.30585 18.8161 5.9801C19.44 5.59511 20.0992 5.17065 20.7231 4.77579C21.2293 4.45991 21.3235 3.84788 20.9468 3.41354Z"
                fill="white"
              />
              <path
                d="M6.93048 8.53849H2.31016C1.03957 8.53849 0 9.56473 0 10.819V13.0996C0 14.3539 1.03957 15.3801 2.31016 15.3801H3.46524V18.8009C3.46524 19.4281 3.98503 19.9412 4.62032 19.9412C5.25561 19.9412 5.7754 19.4281 5.7754 18.8009V15.3801H6.93048L12.7059 18.8009V5.11768L6.93048 8.53849Z"
                fill="white"
              />
              <path
                d="M15.8823 12.0056C15.8823 10.5321 15.0635 9.20266 13.7646 8.29419V15.706C15.0635 14.8086 15.8823 13.4791 15.8823 12.0056Z"
                fill="white"
              />
            </svg>
            Marketing
          </Link>
        </div>
        <div className="list">
          <Link className="linkStyle">
           
            <svg
              
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                
                d="M22 16.875V1.125C22 0.501562 21.4984 0 20.875 0H5.5C3.01562 0 1 2.01562 1 4.5V19.5C1 21.9844 3.01562 24 5.5 24H20.875C21.4984 24 22 23.4984 22 22.875V22.125C22 21.7734 21.8359 21.4547 21.5828 21.2484C21.3859 20.5266 21.3859 18.4688 21.5828 17.7469C21.8359 17.5453 22 17.2266 22 16.875ZM7 6.28125C7 6.12656 7.12656 6 7.28125 6H17.2188C17.3734 6 17.5 6.12656 17.5 6.28125V7.21875C17.5 7.37344 17.3734 7.5 17.2188 7.5H7.28125C7.12656 7.5 7 7.37344 7 7.21875V6.28125ZM7 9.28125C7 9.12656 7.12656 9 7.28125 9H17.2188C17.3734 9 17.5 9.12656 17.5 9.28125V10.2188C17.5 10.3734 17.3734 10.5 17.2188 10.5H7.28125C7.12656 10.5 7 10.3734 7 10.2188V9.28125ZM18.8781 21H5.5C4.67031 21 4 20.3297 4 19.5C4 18.675 4.675 18 5.5 18H18.8781C18.7891 18.8016 18.7891 20.1984 18.8781 21Z"
                fill="white"
              />
            </svg>
            Contabilidad
          </Link>
        </div> */}
        <div className="list">
          <Link className="linkStyle"  to='/dashboardDis/ClientStore'>
          {/* <Link className="linkStyle"  to='/ClientStore'> */}
            <img src={icoMarketPlace}/>
            MarketPlace
          </Link>
        </div>
        <div className="list">
          <Link className="linkStyle">
            {/* <img src={cuentaIcono} /> */}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-alt"
              class="svg-inline--fa fa-user-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="White"
                d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
              ></path>
            </svg>
            Mi cuenta
          </Link>
        </div>
        <hr style={{
            height: 2,
            width: '100%',
            backgroundColor: 'white'
          }}></hr>
        <div className="list">
          <button className="d-flex buttonCerrar" onClick={signOut}>
            <svg
              
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1053 21.7222C10.1053 21.0165 9.53973 20.4444 8.8421 20.4444H2.52632V2.55556H8.8421C9.53973 2.55556 10.1053 1.98347 10.1053 1.27778C10.1053 0.572079 9.53973 0 8.8421 0H2.52632C1.13107 0 0 1.14416 0 2.55556V20.4444C0 21.8558 1.13107 23 2.52632 23H8.8421C9.53973 23 10.1053 22.4279 10.1053 21.7222Z"
                fill="white"
              />
              <path
                d="M23.6382 12.3951C23.7553 12.2746 23.8439 12.1364 23.9042 11.9891C23.9655 11.8396 23.9995 11.6757 24 11.5038L24 11.5L24 11.4962C23.999 11.1704 23.8757 10.845 23.63 10.5965L18.5774 5.48536C18.0841 4.98636 17.2843 4.98636 16.791 5.48536C16.2977 5.98437 16.2977 6.79341 16.791 7.29241L19.6873 10.2222H7.57895C6.88132 10.2222 6.31579 10.7943 6.31579 11.5C6.31579 12.2057 6.88132 12.7778 7.57895 12.7778H19.6873L16.791 15.7076C16.2977 16.2066 16.2977 17.0156 16.791 17.5146C17.2843 18.0136 18.0841 18.0136 18.5774 17.5146L23.6291 12.4044L23.6382 12.3951Z"
                fill="white"
              />
            </svg>
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  );
}
