import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import tagNuevo from "../../assets/marketplace/tagNuevo.svg";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import classes from "../../styles/Start/GenericCategoriesCard.module.scss";
import ModalRequestPart from "../requestPart";
import { useDispatch, useSelector } from "react-redux";
import { addShopElement } from "../../redux/reducers/shopping.reducer";
import { searchWithFilter } from "../../redux/reducers/FiltersProducts.reducer.js";
import { GET_TB } from "../../graphql/Query";
import { useLazyQuery } from "@apollo/client";
import numeral from "numeral";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
    price: {
      fontSize: "25px",
    },
    description: {},
  },
});
theme.typography.price = {
  "@media (max-width:375px)": {
    fontSize: "14px",
  },
};
theme.typography.description = {
  "@media (max-width:375px)": {
    fontSize: "16px",
  },
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GenericCardProduct({
  arrayCards = [],
  limit = 0,
  marginTop = "0%",
  flexWrap = "",
  padding = 0,
  categories,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  arrayCards.map((current) => {
    const categoria = arrayCards.filter((c) => c.Name == current.Name);
  });
  const imagenes = [
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06084_2_1024x1024@2x.jpg?v=1626215933",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06084_1_1024x1024@2x.jpg?v=1626215933",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06085_2_1024x1024@2x.jpg?v=1626216117",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
  ];
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const dispatch = useDispatch();

  const addToCartClick = (value, image) => {
    dispatch(addShopElement({ ...value, Image: value.Image || image }));
  };

  const [tasaB, setTasaB] = React.useState(0);

  const [getTB] = useLazyQuery(GET_TB, {
    fetchPolicy: "no-cache",
    onCompleted({ getTB }) {
      console.log(getTB[0].tasa);
      setTasaB(getTB[0].tasa);
    },
    onError(e) {
      console.log("Error", e);
    },
  });

  React.useEffect(() => {
    getTB({
      variables: {
        a: 0,
      },
    });
  }, [tasaB]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };
  const getFinalPrice = (pv) => {
    const price = parseInt(pv, 10) + getIva(parseInt(pv, 10));
    const precioVentaFormated = formatter.format(filterFloat(price));
    return precioVentaFormated;
  };
  const getIva = (pv) => {
    return pv * (tasaB * 0.01);
  };

  const filtrados = [];
  let categoria = [];
  let count = 0;
  console.log(categories);
  console.log(arrayCards);
  return categories.map((current, index2) => {
    categoria = arrayCards.filter(
      (c) => c.id_Categoria == current.id_Categoria
    );

    // filtrados.push(current.Name);
    if (count > 5) return;

    return (
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {categoria.map((cat, index) => {
            if (index > 0) {
              return;
            }
            if (!filtrados.includes(current.Categorias)) {
              return (
                <h3
                  id={`titleMtk${index2}`}
                  key={index}
                  style={{ fontWeight: 700 }}
                >
                  {current.Categorias}
                </h3>
              );
              filtrados.push(current.Categorias);
              //   count += 1;
              const tempdiv = document.getElementById(`titleMtk${index2}`);
              //console.log(tempdiv);
              tempdiv &&
                (tempdiv.innerHTML = `<h3  style={{ fontWeight: 700 }}>${current.Categorias}</h3>`);
            }
          })}
          {/* <h3 id={`titleMtk${index2}`} style={{ fontWeight: 700 }}>
            TEST
          </h3> */}
          {/* <div id={`titleMtk${index2}`}>{`titleMtk${index2}`}</div> */}
          <Grid
            container
            spacing={{ xs: 1, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            display="flex"
            alignItems="center"
          >
            {categoria?.map(
              (
                {
                  Image,
                  Name,
                  Description,
                  Precio_de_Venta,
                  precio_plataforma,
                  id_parte,
                  id_distribuidor,
                  id_grupo,
                  statusMarket,
                  SKU,
                },
                index,
                array
              ) => {
                // filtrados.push(Name);

                return (
                  <>
                    <ThemeProvider theme={theme}>
                      <Grid item xs={2} sm={4} md={3} key={index}>
                        <div>
                          <Card
                            className={classes.Card_grid}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            sx={{
                              height: "auto",
                            }}
                            xs={2}
                            sm={4}
                            md={3}
                            key={index}
                          >
                            {/* <img
                src={tagNuevo}
                style={{
                  width: "auto",
                  height: "10%",
                  marginTop: "5%",
                  marginLeft: "16px",
                }}
              /> */}
                            <CardMedia
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              flexDirection="column"
                              style={{
                                height: "200px",
                                width: "100%",
                                marginTop: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                              className={statusMarket == 0 && classes.opacity}
                              component="img"
                              //   image={Image}
                              image={imagenes[getRandomInt(0, 5)]}
                              alt="Paella dish"
                            />
                            <CardContent
                              className={statusMarket == 0 && classes.opacity}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                fontWeight="500"
                                fontSize={"14px"}
                                fontStyle={"roboto"}
                                marginTop="5px"
                              >
                                {Name}
                              </Typography>
                              <div
                                style={{
                                  boxSizing: "border-box",
                                  width: "350px",
                                  height: "40px",
                                }}
                              >
                                <Typography
                                  variant="description"
                                  color="black"
                                  fontWeight="700"
                                  fontSize={"16px"}
                                  fontStyle={"roboto"}
                                  marginTop="5px"
                                >
                                  {Description ? Description : "Descripcion"}
                                </Typography>
                              </div>

                              <Typography
                                variant="price"
                                color="#027CBB"
                                fontWeight=" 750"
                                fontSize={"25px"}
                                style={{ display: statusMarket == 0 && "none" }}
                              >
                                {statusMarket != 0 &&
                                  getFinalPrice(
                                    precio_plataforma
                                      ? precio_plataforma
                                      : Precio_de_Venta
                                  )}
                              </Typography>
                            </CardContent>
                            <div className={classes.container_pay_and_detail}>
                              {statusMarket == 1 ? (
                                <button
                                  type="button"
                                  className={classes.add_button}
                                  onClick={(e) =>
                                    addToCartClick(
                                      array[index],
                                      imagenes[getRandomInt(0, 5)]
                                    )
                                  }
                                >
                                  Agregar al carrito
                                </button>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className={classes.add_button}
                                    onClick={() => {
                                      setModalOpen(true);
                                    }}
                                  >
                                    Solicitar Pieza
                                  </button>
                                  {modalOpen && (
                                    <ModalRequestPart
                                      setOpenModal={setModalOpen}
                                      productInfo={{
                                        id_parte,
                                        Name,
                                        Description,
                                        SKU,
                                      }}
                                    />
                                  )}
                                </>
                              )}

                              <div
                                className={classes.show_detail}
                                style={{
                                  marginLeft: "5%",
                                  color: "#027CBB",
                                  fontWeight: 400,
                                  fontSize: 14,
                                  marginTop: "10px",
                                  marginRight: "50px",
                                }}
                              >
                                <Link
                                  className={
                                    statusMarket == 0 && classes.displayNone
                                  }
                                  /* onClick={() => {
                                alert(
                                  "parte :" +
                                    id_parte +
                                    "distribuidor: " +
                                    id_distribuidor +
                                    "grupo:" +
                                    id_grupo
                                );
                              }} */
                                  //esta es la forma en la que se estaban pasando los parametros
                                  // className={classes.show_detail}
                                  to={`/insideProduct?id_parte=${id_parte}&id_distribuidor=${id_distribuidor}`}
                                  onClick={() => {
                                    dispatch(
                                      searchWithFilter(JSON.stringify("empty"))
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Ver más detalle
                                </Link>
                              </div>
                            </div>
                            <ExpandMore
                              expand={expanded}
                              onClick={handleExpandClick}
                              aria-expanded={expanded}
                              aria-label="show more"
                            ></ExpandMore>
                          </Card>
                        </div>
                      </Grid>
                    </ThemeProvider>
                  </>
                );
              }
            )}
          </Grid>
        </div>
        {/* <br /> */}
      </>
    );
  });
}
