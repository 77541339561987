import React, { useState, useEffect } from 'react'
import NavComp from "../Navbar/index";
import { GET_ALL_PRODUCTS } from '../../graphql/Query';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GenericTable } from './GenericTable';
import LeftAside from './leftAside';


const ClientStore = () => {
    const [dataTable, setDataTable] = useState([])
    const [dataColumns, setDataColumns] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const emailLocal = sessionStorage.getItem('auxEmail')
    const [showWindow, setShowWindow] = useState(false);

    useEffect(() => {
        const sizeWindow = window.screen.width;
        if (sizeWindow >= 768) {
            setShowWindow(true);
        }
    }, []);

    useQuery(GET_ALL_PRODUCTS, {
        fetchPolicy: 'no-cache',
        onCompleted({ getAllProducts }) {
            let disFilter = []
            let filterByDis = getAllProducts.filter(({ email }) => email === emailLocal)
            filterByDis.map((obj) => {
                disFilter.push(obj.email)
            })
            if (disFilter[0] == emailLocal) {
                setDataTable(filterByDis)
                setDataColumns([
                    { title: 'ID Parte', field: 'id_parte' },
                    { title: 'Parte', field: 'Name' },
                    { title: 'Descripción', field: 'Name' },
                    { title: 'Precio de venta', field: 'Precio_de_Venta' },
                    { title: 'ID Distribuidor', field: 'id_distribuidor' },
                ])
                setIsLoading(false)
            } else {
                setDataTable(getAllProducts)
                setDataColumns([
                    { title: 'ID Parte', field: 'id_parte' },
                    { title: 'Parte', field: 'Name' },
                    { title: 'Descripción', field: 'Name' },
                    { title: 'Precio de venta', field: 'Precio_de_Venta' },
                    { title: 'ID Distribuidor', field: 'id_distribuidor' },
                ])
                setIsLoading(false)
            }

        }

    })
    //console.log('datos market', dataTable)
    if (!isLoading) {
        return (
            <>
                <NavComp />
                <div className="mainDashboard d-flex">
                    {showWindow ?
                        <>
                            <LeftAside />
                            <div style={{ width: '100%', padding: 100 }}>
                                <GenericTable
                                    columns={dataColumns}
                                    body={dataTable} />
                            </div>
                        </>
                        :
                        <>
                            <div style={{ width: '100%', padding: '48px 40px 0px 40px' }}>
                                <GenericTable
                                    columns={dataColumns}
                                    body={dataTable} />
                            </div>
                        </>
                    }




                </div>
            </>
        )
    } else {
        return null
    }
}

export default ClientStore
