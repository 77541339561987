import React, { useState } from 'react';
import DropProducts from "./DropProducts";
import imageDefenses from '../../assets/image/imageProducts/defensas.svg';
import imageFaros from '../../assets/image/imageProducts/faros.svg';
import imageCalaveras from '../../assets/image/imageProducts/calaveras.svg';
import limpiadores from '../../assets/image/imageProducts/limpiadores.svg';


function ContainerDrop({ text, handleChange, closeDrop, name, disabled = false }) {
    const [showDrop, setShowDrop] = useState(false);
    const styleDisabled = disabled ? { pointerEvents: "none", filter:'brightness(0.4)'  } : {}
    return (
        <>
            <input
                style={styleDisabled}
                type='button'
                className={`${closeDrop ? `btn-drop btn-drop-new` : "btn-drop"}`}
                onClick={() => {
                    handleChange(name);
                }}
                value={text}
            />
            {closeDrop && <div
                style={styleDisabled}
                onMouseLeave={() => {
                    handleChange('');
                }}
            >
                <DropProducts text={text} showDrop={closeDrop} arrayImage={[imageFaros, imageCalaveras, imageDefenses, limpiadores]} />
            </div>
            }

        </>

    )
}
export default ContainerDrop;