import React from 'react';
import { Bar } from 'react-chartjs-2';


function PieChartAbC({ dataBarDis, title = "Distribuidores con mayores ganancias" }) {

    const data = {
        labels: ['Año uno'],
        datasets: [...dataBarDis],
    }

    const legendMargin = {
        id:'legendMargin',
        beforeInit ( chart,legend, options ) {
            //console.log(chart.legend.fit);debugger;
            const fitValue=chart.legend.fit;
           chart.legend.fit= function fit() {
            fitValue.bind(chart.legend)();
            return this.height += 20 ;
           }
         }
    }
  
    return (
        <div style={{ height: 120, marginTop: 20, marginBottom:240 }}>            
            <Bar
                id="myChart"
                data={data}
                plugins={[legendMargin]}
                style={{ maxHeight: '280%', boxShadow: '0px 6px 7px 1px rgba(0,0,0,0.39)', background: 'white', padding: '10px' }}
                options={{
                    onHover: (event) => {
                        event.native.target.style.cursor = 'pointer'
                    },
                    plugins: {
                        legend:{
                            display:true,
                            labels:{
                                padding:1,  
                            },
                            align:'start',
                            
                        },
                        title: {
                            display: true,
                            text: title,
                            align: 'start',
                            color: "#022741",
                            font: {
                                size: 18,
                                weight: "700"
                            }
                        }
                    },scales:{
                        y:{
                            beginAtZero:true
                        }
                    },
                }
            }
            />
        </div>
    );
}
export default PieChartAbC;