import React, { useState, useEffect } from "react";
import NavComp from "../Navbar/index";
import {
  GET_ALL_PRODUCTS,
  GET_MOV_DISTRIBUTOR,
  GET_POT_OBSOLETOS_ADMIN,
  GET_POT_OBSOLETOS_ADMIN_TABLE,
  GET_SALES_MARKET,
  GET_SALES_MARKET_GROUP,
  GET_SALES_MARKET_GROUP_MARCA,
  GET_SALES_MARKET_LINEAL,
} from "../../graphql/Query";
import { useQuery, useLazyQuery } from "@apollo/client";
import { TableMarketplace } from "../Dashboard/TableMarketplace";
import LeftAside from "./LeftAside";
import downloadExcel from "../../helpers/DownloadXLSX";
import ChartLine from "../ChartDahsboard/ChartLine";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import ChartLineMarket from "../ChartDahsboard/ChartLineMarket";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "../../styles/filterAdmin/filterAdmin.module.scss";
import Pagination from "@material-ui/lab/Pagination";

const ClientStoreAdmin = () => {
  let history = useHistory();
  const [dataTable, setDataTable] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [dataTableExport, setDataTableExport] = useState([]);
  const [countLoader, setCountLoader] = useState(0);
  var e = sessionStorage.getItem("auxEmail");
  //console.log("EEEEMAIL", e);
  const [datavaluesAcual, setDatavaluesActual] = useState([]);
  const [datavaluesAnterior, setDatavaluesAnterior] = useState([]);
  const [label, setLabel] = useState("Un mes");
  const [datavaluesAcualGruop, setDatavaluesActualGroup] = useState([]);
  const [datavaluesAntesGruop, setDatavaluesAntesGroup] = useState([]);
  const [datalabelsGroup, setDatalabelsGroup] = useState([]);
  const [datavaluesAcualMarca, setDatavaluesActualMarca] = useState([]);
  const [datavaluesAntesMarca, setDatavaluesAntesMarca] = useState([]);
  const [datalabelsMarca, setDatalabelsMarca] = useState([]);
  const [datavaluesAcualLineal, setDatavaluesActualLineal] = useState([]);
  const [datavaluesAntesLineal, setDatavaluesAntesLineal] = useState([]);
  const [datalabelsLineal, setDatalabelsLineal] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [endDate1, setEndDate1] = useState(null);
  const [startDate1a, setStartDate1a] = useState(new Date());
  const [endDate1a, setEndDate1a] = useState(null);
  const [startDate2, setStartDate2] = useState(new Date());
  const [endDate2, setEndDate2] = useState(null);
  const [startDate2a, setStartDate2a] = useState(new Date());
  const [endDate2a, setEndDate2a] = useState(null);
  const [startDate3, setStartDate3] = useState(new Date());
  const [endDate3, setEndDate3] = useState(null);
  const [startDate3a, setStartDate3a] = useState(new Date());
  const [endDate3a, setEndDate3a] = useState(null);
  const [startDate4, setStartDate4] = useState(new Date());
  const [endDate4, setEndDate4] = useState(null);
  const [startDate4a, setStartDate4a] = useState(new Date());
  const [endDate4a, setEndDate4a] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const tipo = localStorage.getItem("typeUser");

  const [loadingPublicados, setLoadingPublicados] = useState(true);
  const [totalesPublicados, setTotalesPublicados] = useState([
    0, 0, 0, 0, 0, 0,
  ]);

  /**Filters from DashboardAdmin */
  const marcasFiltradas = sessionStorage.getItem("marcasFiltradas");
  const idDistiltrado = sessionStorage.getItem("idDistiltrado");
  const idFather = sessionStorage.getItem("idFather");
  //console.log("-------========");
  //console.log(marcasFiltradas, idDistiltrado, idFather);

  const [getPotObsoletosAdmincount] = useLazyQuery(
    GET_POT_OBSOLETOS_ADMIN_TABLE,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getPotObsoletoAdminTabl }) {
        console.log("getPotObsoletoAdminTabl");
        console.log(getPotObsoletoAdminTabl);
        const obsoletospotenciales = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "Potencial obsoleto"
        );
        const obsoletospublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == false
        );

        const obsoletos1 = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "obsoleto"
        );
        const obsoletospublicados1 = obsoletos1.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados2 = obsoletos1.filter(
          (pot) => pot.statusMarket == false
        );
        setLoadingPublicados(false);
        console.log(getPotObsoletoAdminTabl.length);
        console.log(obsoletospublicados.length);
        console.log(obsoletosnopublicados.length);
        console.log(obsoletospublicados1.length);
        console.log(obsoletosnopublicados2.length);
      },
      onError(err) {
        console.log(err);
      },
    }
  );

  const formatDate = (date) => {
    if (date) {
      const nuevo = date;
      let formatted_date =
        nuevo.getFullYear() +
        "-" +
        (nuevo.getMonth() + 1) +
        "-" +
        nuevo.getDate();
      return formatted_date;
    }
  };
  const formatDateLast = (date, days) => {
    const temp = new Date();
    console.log("llega -->" + temp);
    temp.setDate(temp.getDate() - days);
    console.log(
      "return --->" +
        temp.getDate() +
        "/" +
        (temp.getMonth() + 1) +
        "/" +
        temp.getFullYear()
    );
    const res =
      temp.getFullYear() + "-" + (temp.getMonth() + 1) + "-" + temp.getDate();
    return res;
  };

  const onChange1 = (dates) => {
    const [start, end] = dates;
    setStartDate1(start);
    setEndDate1(end);
    getSalesMonthlyLinealActual({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };

  const onChange1a = (dates) => {
    const [start, end] = dates;
    setStartDate1a(start);
    setEndDate1a(end);
    getSalesMonthlyLinealAnterior({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };
  const onChange2 = (dates) => {
    const [start, end] = dates;
    setStartDate2(start);
    setEndDate2(end);
    getSalesMonthlyMarcaActual({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };
  const onChange2a = (dates) => {
    const [start, end] = dates;
    setStartDate2a(start);
    setEndDate2a(end);
    getSalesMonthlyMarcaAnterior({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };
  /**____ */
  const onChange3 = (dates) => {
    const [start, end] = dates;
    setStartDate3(start);
    setEndDate3(end);
    getSalesMonthlyGroupActual({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };

  const onChange3a = (dates) => {
    const [start, end] = dates;
    setStartDate3a(start);
    setEndDate3a(end);
    getSalesMonthlyGroupAnterior({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };
  const onChange4 = (dates) => {
    const [start, end] = dates;
    setStartDate4(start);
    setEndDate4(end);
    getSalesMonthlActual({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };
  const onChange4a = (dates) => {
    const [start, end] = dates;
    setStartDate4a(start);
    setEndDate4a(end);
    getSalesMonthlAnterior({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(start),
        fin: formatDate(end),
      },
    });
  };
  /**_________________ */

  // filtros ABC admin
  const [getSalesMonthlActual] = useLazyQuery(GET_SALES_MARKET, {
    fetchPolicy: "no-cache",
    onCompleted({ getSalesMonthly }) {
      // debugger
      //console.log("step----------------1");
      //console.log(getSalesMonthly);
      const disFilter = [];
      const labelsFilter = [];

      getSalesMonthly.map((obj, i) => {
        if (obj !== "__typename") {
          disFilter.push(obj.total);
          obj.uno == 1
            ? labelsFilter.push("Grupo bours automotriz")
            : labelsFilter.push(obj.uno);
        }
      });
      setDatavaluesActual(disFilter);
      setLabel(labelsFilter);

      //console.log("getSalesMonthly");
    },
    onError(err) {
      //console.log(err);
    },
  });
  // filtros ABC admin
  const [getSalesMonthlAnterior] = useLazyQuery(GET_SALES_MARKET, {
    fetchPolicy: "no-cache",
    onCompleted({ getSalesMonthly }) {
      // debugger
      //console.log("step----------------1");
      //console.log(getSalesMonthly);
      const disFilter = [];

      getSalesMonthly.map((obj, i) => {
        if (obj !== "__typename") {
          disFilter.push(obj.total);
        }
      });
      setDatavaluesAnterior(disFilter);

      //console.log("getSalesMonthly");
    },
    onError(err) {
      //console.log(err);
    },
  });

  // filtros ABC admin group
  const [getSalesMonthlyGroupActual] = useLazyQuery(GET_SALES_MARKET_GROUP, {
    fetchPolicy: "no-cache",
    onCompleted({ getSalesMonthlyGroup }) {
      // debugger
      //console.log("step----------------a");
      //console.log(getSalesMonthlyGroup);
      const disFilter = [];
      const disFilter2 = [];

      getSalesMonthlyGroup?.forEach((obj) => {
        return disFilter.push(obj.total);
      });
      getSalesMonthlyGroup?.forEach((obj) => {
        return disFilter2.push(obj.uno);
      });
      //console.log(disFilter);
      //console.log(disFilter2);
      setDatavaluesActualGroup(disFilter);
      setDatalabelsGroup(disFilter2);

      //console.log("getSalesMonthlyGroup");
    },
    onError(err) {
      //console.log(err);
    },
  });

  // filtros ABC admin group
  const [getSalesMonthlyGroupAnterior] = useLazyQuery(GET_SALES_MARKET_GROUP, {
    fetchPolicy: "no-cache",
    onCompleted({ getSalesMonthlyGroup }) {
      // debugger
      //console.log("step----------------a");
      //console.log(getSalesMonthlyGroup);
      const disFilter = [];
      const disFilter2 = [];

      getSalesMonthlyGroup?.forEach((obj) => {
        return disFilter.push(obj.total);
      });

      setDatavaluesAntesGroup(disFilter);

      //console.log("getSalesMonthlyGroup");
    },
    onError(err) {
      //console.log(err);
    },
  });

  /***by marca */
  const [getSalesMonthlyMarcaActual] = useLazyQuery(
    GET_SALES_MARKET_GROUP_MARCA,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getSalesMonthlyGroupMarca }) {
        // debugger
        //console.log("step----------------a");
        //console.log(getSalesMonthlyGroupMarca);
        const disFilter = [];
        const disFilter2 = [];

        getSalesMonthlyGroupMarca?.forEach((obj) => {
          return disFilter.push(obj.total);
        });
        getSalesMonthlyGroupMarca?.forEach((obj) => {
          return disFilter2.push(obj.uno);
        });

        setDatavaluesActualMarca(disFilter);
        setDatalabelsMarca(disFilter2);

        //console.log("getSalesMonthlyGroupMarca");
      },
      onError(err) {
        //console.log(err);
      },
    }
  );

  /***by marca */
  const [getSalesMonthlyMarcaAnterior] = useLazyQuery(
    GET_SALES_MARKET_GROUP_MARCA,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getSalesMonthlyGroupMarca }) {
        // debugger
        //console.log("step----------------a");
        //console.log(getSalesMonthlyGroupMarca);
        const disFilter = [];
        const disFilter2 = [];

        getSalesMonthlyGroupMarca?.forEach((obj) => {
          return disFilter.push(obj.total);
        });

        setDatavaluesAntesMarca(disFilter);

        //console.log("getSalesMonthlyGroupMarca");
      },
      onError(err) {
        //console.log(err);
      },
    }
  );

  /***lineal no filters */
  const [getSalesMonthlyLinealActual] = useLazyQuery(GET_SALES_MARKET_LINEAL, {
    fetchPolicy: "no-cache",
    onCompleted({ getSalesMonthlyGroupLineal }) {
      // debugger
      //console.log("step----------------a");
      //console.log(getSalesMonthlyGroupLineal);
      const disFilter = [];
      const disFilter2 = [];

      getSalesMonthlyGroupLineal?.forEach((obj) => {
        return disFilter.push(obj.total);
      });
      getSalesMonthlyGroupLineal?.forEach((obj) => {
        return disFilter2.push(obj.uno);
      });

      setDatavaluesActualLineal(disFilter);
      setDatalabelsLineal(disFilter2);

      //console.log("getSalesMonthlyGroupMarca");
    },
    onError(err) {
      //console.log(err);
    },
  });

  /***lineal no filters */
  const [getSalesMonthlyLinealAnterior] = useLazyQuery(
    GET_SALES_MARKET_LINEAL,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getSalesMonthlyGroupLineal }) {
        // debugger
        //console.log("step----------------lineal");
        //console.log(getSalesMonthlyGroupLineal);
        const disFilter = [];
        const disFilter2 = [];

        getSalesMonthlyGroupLineal?.forEach((obj) => {
          return disFilter.push(obj.total);
        });
        setDatavaluesAntesLineal(disFilter);
        //console.log("getSalesMonthlyGroupMarca");
      },
      onError(err) {
        //console.log(err);
      },
    }
  );

  const [showWindow, setShowWindow] = useState(false);
  /*   useEffect(() => {
        //console.log('counter', countLoader)
        if (countLoader >= 1) {
            setIsLoading(false)
        }
    }, [countLoader]) */
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  useEffect(() => {
    setLoadingPublicados(true);

    tipo == 10 &&
      getSalesMonthlActual({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          init: "2021-01-08",
          fin: "2021-01-14",
        },
      });
    tipo == 10 &&
      getSalesMonthlAnterior({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          init: "2021-01-08",
          fin: "2021-01-14",
        },
      });
    tipo == 10 &&
      getSalesMonthlyGroupActual({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          init: "2021-01-08",
          fin: "2021-01-14",
        },
      });
    tipo == 10 &&
      getSalesMonthlyGroupAnterior({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          init: "2021-01-08",
          fin: "2021-01-14",
        },
      });
    const date = new Date();

    getSalesMonthlyMarcaActual({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(date),
        fin: formatDateLast(date, 6),
      },
    });
    getSalesMonthlyMarcaAnterior({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDateLast(date, 7),
        fin: formatDateLast(date, 13),
      },
    });

    getSalesMonthlyLinealActual({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDate(date),
        fin: formatDateLast(date, 6),
      },
    });
    getSalesMonthlyLinealAnterior({
      variables: {
        a: marcasFiltradas,
        b: idDistiltrado,
        c: idFather,
        init: formatDateLast(date, 7),
        fin: formatDateLast(date, 13),
      },
    });
  }, []);

  const [getPotObsoletosAdminTable] = useLazyQuery(
    GET_POT_OBSOLETOS_ADMIN_TABLE,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getPotObsoletoAdminTabl }) {
        console.log("getPotObsoletoAdminTabl");
        console.log(getPotObsoletoAdminTabl);
        const totalnop = getPotObsoletoAdminTabl.filter(
          (pot) => pot.statusMarket == false
        );
        const totalsip = getPotObsoletoAdminTabl.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletospotenciales = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "Potencial obsoleto"
        );
        const obsoletospublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == false
        );

        const obsoletos1 = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "obsoleto"
        );
        const obsoletospublicados1 = obsoletos1.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados2 = obsoletos1.filter(
          (pot) => pot.statusMarket == false
        );
        console.log(getPotObsoletoAdminTabl.length);
        console.log(obsoletospublicados.length);
        console.log(obsoletosnopublicados.length);
        console.log(obsoletospublicados1.length);
        console.log(obsoletosnopublicados2.length);
        const temp2 = [
          getPotObsoletoAdminTabl.length,
          totalnop.length,
          totalsip.length,
          obsoletospublicados.length,
          obsoletosnopublicados.length,
          obsoletospublicados1.length,
          obsoletosnopublicados2.length,
        ];
        let temp = [...temp2];
        setLoadingPublicados(false);
        setTotalesPublicados(temp);
        setTotalPages(getPotObsoletoAdminTabl.length / 200);
        console.log(getPotObsoletoAdminTabl.length / 200);
        setDataTableExport(
          getPotObsoletoAdminTabl?.map((obj) => {
            const statusMarket =
              obj.statusMarket == false
                ? "No disponible en MP"
                : "Disponible en MP";
            return {
              id_parte: obj.id_parte,
              Name: obj.Name,
              tipo_movimiento: obj.tipo_movimiento,
              distribuidor: obj.distribuidor,
              PrecioVenta: obj.PrecioVenta,
              Costo_pieza: obj.Costo_pieza,
              statusMarket,
            };
          })
        );
        //console.log("getPotObsoletoAdminTabl");
        //console.log(getPotObsoletoAdminTabl);
      },
      onError(err) {
        //console.log(err);
      },
    }
  );

  const [getPotObsoletosAdmin] = useLazyQuery(GET_POT_OBSOLETOS_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getPotObsoletoAdmin }) {
      console.log("¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡");
      console.log(getPotObsoletoAdmin);

      if (getPotObsoletoAdmin && getPotObsoletoAdmin.length > 0)
        setDataTable(getPotObsoletoAdmin);
      if (tipo == 2 || tipo == 3 || tipo == 4) {
        setDataColumns([
          { title: "ID Parte", field: "id_parte" },
          { title: "Parte", field: "Name" },
          { title: "Descripción", field: "Name" },
          { title: "Precio de venta", field: "PrecioVenta" },
          { title: "ID Distribuidor", field: "id_distribuidor" },
          { title: "Imagen", field: "Image" },
          { title: "Marketplace", field: "IconUpload" },
          { title: "Precio Plataforma", field: "Precio_Plataforma" },
        ]);
      } else {
        setDataColumns([
          { title: "ID Parte", field: "id_parte" },
          { title: "Parte", field: "Name" },
          { title: "Descripción", field: "Name" },
          { title: "Precio de venta", field: "PrecioVenta" },
          { title: "ID Distribuidor", field: "id_distribuidor" },
        ]);
      }

      setDataTableExport(
        getPotObsoletoAdmin?.map((obj) => {
          const Image = obj.image == null && "Sin imagen";
          return {
            id_parte: obj.id_parte,
            Name: obj.Name,
            Descripcion: obj.Name,
            Precio_de_Venta: obj.Precio_de_Venta,
            id_distribuidor: obj.id_distribuidor,
            Image,
          };
        })
      );
    },
    onError(err) {
      //console.log(err);
    },
  });

  useEffect(() => {
    getPotObsoletosAdminTable({
      variables: {
        // a: marcasFiltradas.toString(),
        // b: idDistiltrado.toString(),
        // c: idFather.toString(),
        a: "",
        b: "",
        c: "",
        tipMov: "",
        email: null,
      },
    });
  }, []);

  useEffect(() => {
    console.log("currentPage", currentPage);
    setDataTable([]);
    getPotObsoletosAdmin({
      variables: {
        // a: marcasFiltradas.toString(),
        // b: idDistiltrado.toString(),
        // c: idFather.toString(),
        a: "",
        b: "",
        c: "",
        tipMov: "",
        email: null,
        limit: currentPage,
      },
    });
  }, [currentPage]);

  /*   useQuery(GET_ALL_PRODUCTS, {
    variables: {
      e: "admin@ballena.com",
    },
    fetchPolicy: "no-cache",
    onCompleted({ getAllProducts }) {
      // debugger;
      setDataTable(getAllProducts);
      if (tipo == 2 || tipo == 3 || tipo == 4) {
        setDataColumns([
          { title: "ID Parte", field: "id_parte" },
          { title: "Parte", field: "Name" },
          { title: "Descripción", field: "Name" },
          { title: "Precio de venta", field: "Precio_de_Venta" },
          { title: "ID Distribuidor", field: "id_distribuidor" },
          { title: "Imagen", field: "Image" },
          { title: "Marketplace", field: "IconUpload" },
          { title: "Precio Plataforma", field: "Precio_Plataforma" },
        ]);
      } else {
        setDataColumns([
          { title: "ID Parte", field: "id_parte" },
          { title: "Parte", field: "Name" },
          { title: "Descripción", field: "Name" },
          { title: "Precio de venta", field: "Precio_de_Venta" },
          { title: "ID Distribuidor", field: "id_distribuidor" },
        ]);
      }

      setDataTableExport(
        getAllProducts?.map((obj) => {
          const Image = obj.image == null && "Sin imagen";
          return {
            id_parte: obj.id_parte,
            Name: obj.Name,
            Descripcion: obj.Name,
            Precio_de_Venta: obj.Precio_de_Venta,
            id_distribuidor: obj.id_distribuidor,
            Image,
          };
        })
      );
    },
  }); */

  //console.log("datos", dataTable);

  const handlePagination = (_, page) => {
    console.log("change");
    setLoadingPublicados(true);
    // page--;
    // if (!(page === currentPage)) {
    setCurrentPage(page);
    getPotObsoletosAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: "",
        email: null,
        limit: page,
      },
    });
    getPotObsoletosAdminTable({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: "",
        email: null,
      },
    });
    // }
  };

  const dataDonut = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const numeroTotal = 12;

  return (
    <>
      <NavComp />
      <div className="mainDashboard d-flex">
        <>
          <LeftAside />
          <div className={classes.containerGraphs}>
            <p className={classes.Styetitle}>MARKETPLACE</p>
            {/* <br /> */}
            <div className={classes.subcontainergraphicsfour}>
              <div className={classes.subcontainergraphicstwo}>
                <div style={{ marginRight: "3%" }}>
                  <h5>GVP</h5>
                </div>
                <div
                  style={{
                    marginRight: "3%",
                  }}
                >
                  <div>Actual</div>
                  <div>
                    <DatePicker
                      className={classes.inputCalendariorango}
                      selected={startDate}
                      onChange={onChange1}
                      startDate={startDate1}
                      endDate={endDate1}
                      selectsRange
                    />
                  </div>
                </div>
                <div>
                  <div>Anterior</div>
                  <div>
                    <DatePicker
                      className={classes.inputCalendariorango}
                      selected={startDate}
                      onChange={onChange1a}
                      startDate={startDate1a}
                      endDate={endDate1a}
                      selectsRange
                    />
                  </div>
                </div>
              </div>
              <div className={classes.subcontainergraphicsfive}>
                <div style={{ marginRight: "3%" }}>
                  <h5>GVM</h5>
                </div>
                <div
                  style={{
                    marginRight: "3%",
                  }}
                >
                  <div>Actual</div>
                  <div>
                    <DatePicker
                      className={classes.inputCalendariorango}
                      selected={startDate}
                      onChange={onChange2}
                      startDate={startDate2}
                      endDate={endDate2}
                      selectsRange
                    />
                  </div>
                </div>
                <div>
                  <div>Anterior</div>
                  <div>
                    <DatePicker
                      className={classes.inputCalendariorango}
                      selected={startDate}
                      onChange={onChange2a}
                      startDate={startDate2a}
                      endDate={endDate2a}
                      selectsRange
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.subcontainergraphicsthree}>
              <div className={classes.subcontainergraphics}>
                <ChartLineMarket
                  title="Gráfica Ventas Piezas"
                  height="100%"
                  labels={datalabelsLineal}
                  dataActual={datavaluesAcualLineal}
                  datanterior={datavaluesAntesLineal}
                  numeroToTal={numeroTotal}
                />
              </div>
              <div className={classes.subcontainergraphicssix}>
                <div style={{ marginRight: "3%" }}>
                  <h5>GVM</h5>
                </div>
                <div
                  style={{
                    marginRight: "3%",
                  }}
                >
                  <div>Actual</div>
                  <div>
                    <DatePicker
                      className={classes.inputCalendariorango}
                      selected={startDate}
                      onChange={onChange2}
                      startDate={startDate2}
                      endDate={endDate2}
                      selectsRange
                    />
                  </div>
                </div>
                <div>
                  <div>Anterior</div>
                  <div>
                    <DatePicker
                      className={classes.inputCalendariorango}
                      selected={startDate}
                      onChange={onChange2a}
                      startDate={startDate2a}
                      endDate={endDate2a}
                      selectsRange
                    />
                  </div>
                </div>
              </div>
              <div className={classes.subcontainergraphics}>
                <ChartLine
                  title="Gráfica Ventas Marcas"
                  height="100%"
                  labels={datalabelsMarca}
                  dataActual={datavaluesAcualMarca}
                  datanterior={datavaluesAntesMarca}
                  numeroToTal={numeroTotal}
                />
              </div>
            </div>

            {tipo == 10 && (
              <>
                <div className={classes.subcontainergraphicsfour}>
                  <div className={classes.subcontainergraphicstwo}>
                    <div style={{ marginRight: "3%" }}>
                      <h5>GVPL</h5>
                    </div>
                    <div
                      style={{
                        marginRight: "3%",
                      }}
                    >
                      <div>Actual</div>
                      <div>
                        <DatePicker
                          className={classes.inputCalendariorango}
                          selected={startDate}
                          onChange={onChange3}
                          startDate={startDate3}
                          endDate={endDate3}
                          selectsRange
                        />
                      </div>
                    </div>
                    <div>
                      <div>Anterior</div>
                      <div>
                        <DatePicker
                          className={classes.inputCalendariorango}
                          selected={startDate}
                          onChange={onChange3a}
                          startDate={startDate3a}
                          endDate={endDate3a}
                          selectsRange
                        />
                      </div>
                    </div>
                  </div>

                  <div className={classes.subcontainergraphicsfive}>
                    <div style={{ marginRight: "3%" }}>
                      <h5>GVD</h5>
                    </div>
                    <div
                      style={{
                        marginRight: "3%",
                      }}
                    >
                      <div>Actual</div>
                      <div>
                        <DatePicker
                          className={classes.inputCalendariorango}
                          selected={startDate}
                          onChange={onChange4}
                          startDate={startDate4}
                          endDate={endDate4}
                          selectsRange
                        />
                      </div>
                    </div>
                    <div>
                      <div>Anterior</div>
                      <div>
                        <DatePicker
                          className={classes.inputCalendariorango}
                          selected={startDate}
                          onChange={onChange4a}
                          startDate={startDate4a}
                          endDate={endDate4a}
                          selectsRange
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.subcontainergraphicsthree}>
                  <div className={classes.subcontainergraphics}>
                    <ChartLine
                      title="Gráfica Ventas Plantas"
                      height="100%"
                      labels={datalabelsGroup}
                      dataActual={datavaluesAcualGruop}
                      datanterior={datavaluesAntesGruop}
                      numeroToTal={numeroTotal}
                    />
                  </div>
                  <div className={classes.subcontainergraphicssix}>
                    <div style={{ marginRight: "3%" }}>
                      <h5>GVD</h5>
                    </div>
                    <div
                      style={{
                        marginRight: "3%",
                      }}
                    >
                      <div>Actual</div>
                      <div>
                        <DatePicker
                          className={classes.inputCalendariorango}
                          selected={startDate}
                          onChange={onChange4}
                          startDate={startDate4}
                          endDate={endDate4}
                          selectsRange
                        />
                      </div>
                    </div>
                    <div>
                      <div>Anterior</div>
                      <div>
                        <DatePicker
                          className={classes.inputCalendariorango}
                          selected={startDate}
                          onChange={onChange4a}
                          startDate={startDate4a}
                          endDate={endDate4a}
                          selectsRange
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.subcontainergraphics}>
                    <ChartLine
                      title="Gráfica Ventas Distribuidores"
                      labels={label}
                      height="100%"
                      dataActual={datavaluesAcual}
                      datanterior={datavaluesAnterior}
                      numeroToTal={numeroTotal}
                    />
                  </div>
                </div>
              </>
            )}
            {tipo != 5 && (
              <>
                <div className="col-12" style={{ padding: "0 30px" }}>
                  {!loadingPublicados ? (
                    <div>
                      Total del listado: {totalesPublicados[0]} <br />
                      Total de piezas no publicadas en el Marketplace:{" "}
                      {totalesPublicados[1]} <br />
                      Obsoleto No publicado: {totalesPublicados[6]} <br />
                      Potencialmente obsoleto no Publicado:{" "}
                      {totalesPublicados[4]} <br />
                      Total de piezas publicadas en el Marketplace:{" "}
                      {totalesPublicados[2]} <br />
                      Obsoleto Publicado: {totalesPublicados[5]} <br />
                      Potencialmente obsoleto Publicado: {
                        totalesPublicados[3]
                      }{" "}
                      <br />
                    </div>
                  ) : (
                    <div>loading ...</div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      overflow: "auto",
                    }}
                  >
                    <TableMarketplace columns={dataColumns} body={dataTable} />
                  </div>
                  {totalPages !== 0 && (
                    <div className="buttons-paginacion-obsoleto">
                      <div className="root">
                        <Pagination
                          count={Math.ceil(totalPages)}
                          page={currentPage}
                          onChange={handlePagination}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "92%",
                    justifyContent: "end",
                  }}
                >
                  <input
                    type="button"
                    value="Exportar tabla"
                    className="btn btn-primary"
                    onClick={() =>
                      downloadExcel(dataTableExport, "tabla-partes")
                    }
                  />
                </div>
              </>
            )}
          </div>
        </>

        {/* <>
            <div style={{ width: "100%", padding: "48px 40px 0px 40px" }}>
              <GenericTable columns={dataColumns} body={dataTable} />
            </div>
            <br />
            <input
              type="button"
              value="Exportar tabla"
              className="btn btn-primary"
              onClick={() => downloadExcel(dataTableExport, "tabla-partes")}
            />
          </> */}
      </div>
    </>
  );
};

export default ClientStoreAdmin;
