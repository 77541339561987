import React, { useState, useEffect } from "react";

import S3FileUpload from "react-s3";
import "../../styles/marketplaceDis/style.scss";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Modal } from "@material-ui/core";
import { IconButton } from "@mui/material";
import Dropzone from "react-dropzone";
import { UPLOAD_IMAGE } from "../../graphql/Mutation";
import { useMutation } from "@apollo/client";
import Form from "react-bootstrap/Form";
import { SiCheckmarx } from "react-icons/si";
import ReactDOMServer from "react-dom/server";
import  "../../styles/styleDashBoard.css";
import "../../styles/TooTip/style.css";

const config = {
  bucketName: "ballena-website",
  region: "us-east-2",
  accessKeyId: "AKIA2KOCQD3QDDFMB2ZW",
  secretAccessKey: "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",
};

export const GenericTable = ({ columns = [], body = [] }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [parte, setParte] = useState();
  const [idDis, setIdDis] = useState("");
  const [ifImg, setIfImg] = useState("");
  const tipo = localStorage.getItem("typeUser");

  var e = localStorage.getItem("e");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };

  const changeStatusSwich = (e) => {
    // if ("checked" in e.target) {
    //   console.log("oks");
    //   document.getElementById(e.target.id).checked = false;
    // }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files[0]);
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const upload = () => {
    console.log(picture);

    S3FileUpload.uploadFile(picture, config)
      .then((data) => {
        console.log(data);
        if (data) {
          console.log("si Hay Data");
          console.log(data.location);
          console.log(parte);
          uploadImage({
            variables: {
              imgUrl: data.location,
              email: e,
              id_parte: parte,
            },
          });
        }
      })
      .catch((err) => console.error(err));
  };

  // const update = () =>{
  //   forceUpdate()
  // }

  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    // fetchPolicy: 'no-cache',
    onCompleted({ uploadImage }) {
      console.log("correcto");

      setParte();
      // update()
      handleClose();
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleTool = (item) => {
    console.log(item);
    console.log(item.id_parte);
    setParte(item.id_parte);
    setIdDis(item.id_distribuidor);
    setIfImg(item.Image);
    handleOpen();
    // console.log(parte);
    // console.log(idDis);
  };

  const handleToolClose = () => {
    handleClose();
    setImgData(null);
    setPicture(null);
  };

  const renderTitle = () => {
    if (ifImg == "") {
      return (
        <h2
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          Añadir imagen
        </h2>
      );
    } else {
      return (
        <h2
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          Cambiar imagen
        </h2>
      );
    }
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 600,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 5,
  };
  return (
    <div className="modalSize">
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className="closeModal">
            <button onClick={() => handleToolClose()}>X</button>
          </div>
          <div className="title">
            {/* <h2
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              
              Añadir imagen
            </h2> */}
            {renderTitle()}
          </div>
          <div
            className="uploadImage d-flex"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <div className="imgContainer">
              <img src={imgData} />
            </div>

            <div className="buttonsUploadContainer d-flex">
              <input type="file" onChange={onChangePicture} />
              <button className="btn btn-primary" onClick={() => upload()}>
                Subir
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <table
        className="table table tableContainer"
        style={{ height: "auto" }}
      >
        <thead
          className="sticky"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            top: 0,
            zIndex: 1,
            background: "white",
          }}
        >
          <tr className="sticky titeColumns">
            {columns.map(({ title }, index) => (
              <th key={`${title}_${index}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody className="textContainTable bodyTable">
          {body.map((item, index) => (
            <tr key={`tr_${index}`} style={{ backgroundColor: "#EAF0F6" }}>
              {columns.map(({ field }) => {
                if (field === "Image") {
                  if (tipo == 2 || tipo == 3 || tipo == 4) {
                    return (
                      <td key={`${field}_${index}`}>
                        <IconButton
                          className="uploadIconButton"
                          aria-label="upload picture"
                          component="span"
                          value={item}
                          onClick={() => handleTool(item)}
                        >
                          <FileUploadIcon />
                        </IconButton>
                      </td>
                    );
                  }
                } else if (field === "IconUpload") {
                  return (
                    <td key={`${index}`}>
                      {tipo == 2 || tipo == 3 || tipo == 4 ? (
                        <Form>
                          <Form.Check
                            type="switch"
                            id={`custom-switch${index}`}
                            key={index}
                            checked="false"
                            onClick={changeStatusSwich}
                          //disabled=${disabled}
                          />
                        </Form>
                      ) : item["statusMarket"] ? (
                        <SiCheckmarx style={{ color: "green" }} />
                      ) : (
                        <SiCheckmarx />
                      )}
                    </td>
                  );
                } else if (field === "Costo_pieza") {
                  return (<td key={`${field}_${index}`}> {formatter.format(filterFloat(item["Costo_pieza"]))}</td>)
                } else if (field === "Precio_de_Venta") {
                  return (<td key={`${field}_${index}`}> {formatter.format(filterFloat(item["Precio_de_Venta"]))}</td>)
                } else if (field === "Utilidad") {
                  return(  <td key={`${field}_${index}`}> {formatter.format(filterFloat(item["Utilidad"]))}</td>)          
                }else if (field === "Costo_total_almacen") {
                  return(  <td key={`${field}_${index}`}> {formatter.format(filterFloat(item["Costo_total_almacen"]))}</td>)          
                } else if (field === "Utilidad_Real") {
                  return(  <td key={`${field}_${index}`}> {formatter.format(filterFloat(item["Utilidad_Real"]))}</td>)          
                }else if (field === "Costo_de_almacenaje") {
                  return(  <td key={`${field}_${index}`}> {formatter.format(filterFloat(item["Costo_de_almacenaje"]))}</td>)          
                } 
                 
                else return <td key={`${field}_${index}`}>{item[field]}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
