import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import tagNuevo from "../../assets/marketplace/tagNuevo.svg";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { addShopElement } from "../../redux/reducers/shopping.reducer.js";
import { useSelector } from "react-redux";
import "../../styles/TooTip/style.css";
import classes from "../../styles/Start/GenericCategoriesCard.module.scss";
import ModalRequestPart from "../requestPart";
import numeral from "numeral";
import { searchWithFilter } from "../../redux/reducers/FiltersProducts.reducer.js";
import { Snackbar, Alert } from "@mui/material";
import { FaCartPlus } from "react-icons/fa";
import { GET_TB } from "../../graphql/Query";
import { useLazyQuery } from "@apollo/client";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GenericCard({
  arrayCards = [],
  limit = 0,
  marginTop = "0%",
  flexWrap = "",
  padding = 0,
  categories,
  changePaginationCat,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [inCart, setInCart] = React.useState(false);
  const dispatch = useDispatch();
  const stateCart = useSelector((state) => state.rootReducers.shoop);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //console.log(arrayCards);
  arrayCards.map((current) => {
    const categoria = arrayCards.filter((c) => c.Name == current.Name);
    //console.log(categoria);
  });
  const imagenes = [
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06084_2_1024x1024@2x.jpg?v=1626215933",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06084_1_1024x1024@2x.jpg?v=1626215933",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06085_2_1024x1024@2x.jpg?v=1626216117",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
  ];
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const filtrados = [];
  let categoria = [];
  let count = 0;
  //console.log(categories);

  const addToCartClick = async (value, image) => {
    let repeat = false;
    dispatch(addShopElement({ ...value, Image: value.Image || image }));

    stateCart.forEach((item) => {
      item.ID !== value.ID ? (repeat = false) : (repeat = true);
    });
    if (!repeat) setInCart(true);
  };

  const [tasaB, setTasaB] = React.useState(0);

  const [getTB] = useLazyQuery(GET_TB, {
    fetchPolicy: "no-cache",
    onCompleted({ getTB }) {
      console.log(getTB[0].tasa);
      setTasaB(getTB[0].tasa);
    },
    onError(e) {
      console.log("Error", e);
    },
  });

  React.useEffect(() => {
    getTB({
      variables: {
        a: 0,
      },
    });
  }, [tasaB]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };
  const getFinalPrice = (pv) => {
    //debugger;
    const price = parseInt(pv, 10) + getIva(parseInt(pv, 10));
    const precioVentaFormated = formatter.format(filterFloat(price));
    // const precioVentaFormated = numeral(price.format("$0,0"));
    //return precioVentaFormated;
    return precioVentaFormated;
  };
  const getIva = (pv) => {
    return pv * (tasaB * 0.01);
  };
  return categories.map((current, index2) => {
    categoria = arrayCards;
    // filtrados.push(current.Name);
    if (count > 5) return;
    console.log("categoria--->", categoria);

    return (
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {/* <h3 id={`title${index2}`} style={{ fontWeight: 700 }}>
            {filtrados.includes(current.Categorias) && current.Categorias}
          </h3> */}
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {categoria?.map(
              (
                {
                  Image,
                  Name,
                  Description,
                  Precio_de_Venta,
                  precio_plataforma,
                  id_parte,
                  id_distribuidor,
                  statusMarket,
                  SKU,
                },
                index,
                array
              ) => {
                // filtrados.push(Name);
                // if (!filtrados.includes(current.Categorias)) {
                //   filtrados.push(current.Categorias);
                //   count += 1;
                //   const title = document.getElementById(`title${index2}`);
                //   title && (title.innerHTML = `${current.Categorias}`);
                // }

                return (
                  <>
                    <Grid item xs={2} sm={4} md={3} key={index}>
                      <Card
                        sx={{
                          maxWidth: 355,
                          boxShadow: " -2px 2px 14px -2px rgba(0,0,0,0.58)",
                          height: "auto",
                        }}
                        xs={2}
                        sm={4}
                        md={3}
                        key={index}
                      >
                        {/* <img
                src={tagNuevo}
                style={{
                  width: "auto",
                  height: "10%",
                  marginTop: "5%",
                  marginLeft: "16px",
                }}
              /> */}
                        <CardMedia
                          style={{
                            height: "200px",
                            width: "100%",
                            marginTop: "15px",
                          }}
                          component="img"
                          //   image={Image}
                          className={statusMarket == 0 && classes.opacity}
                          image={imagenes[getRandomInt(0, 5)]}
                          alt="Paella dish"
                        />
                        <CardContent
                          className={statusMarket == 0 && classes.opacity}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            fontWeight="500"
                            fontSize={"14px"}
                            fontStyle={"roboto"}
                            marginTop="5px"
                          >
                            {Name}
                          </Typography>
                          <div
                            style={{
                              boxSizing: "border-box",
                              width: "350px",
                              height: "40px",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="black"
                              fontWeight="700"
                              fontSize={"16px"}
                              fontStyle={"roboto"}
                              marginTop="5px"
                            >
                              {Description ? Description : "Descripcion"}
                            </Typography>
                          </div>

                          <Typography
                            variant="body2"
                            color="#027CBB"
                            fontWeight=" 750"
                            fontSize={"25px"}
                          >
                            {statusMarket &&
                              getFinalPrice(
                                precio_plataforma
                                  ? precio_plataforma
                                  : Precio_de_Venta
                              )}
                          </Typography>
                        </CardContent>
                        <div className="showMobile ">
                          {statusMarket == 1 ? (
                            <button
                              type="button"
                              className={classes.add_button}
                              onClick={(e) =>
                                addToCartClick(
                                  array[index],
                                  imagenes[getRandomInt(0, 5)]
                                )
                              }
                            >
                              Agregar al carrito
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className={classes.add_button}
                                onClick={() => {
                                  setModalOpen(true);
                                }}
                              >
                                Solicitar Pieza
                              </button>
                              {modalOpen && (
                                <ModalRequestPart
                                  setOpenModal={setModalOpen}
                                  productInfo={{
                                    id_parte,
                                    Name,
                                    Description,
                                    SKU,
                                  }}
                                />
                              )}
                            </>
                          )}
                          <div
                            style={{
                              marginLeft: "5%",
                              color: "#027CBB",
                              fontWeight: 400,
                              fontSize: 14,

                              alignSelf: "center",
                              cursor: "pointer"
                            }}
                          >
                            <p
                              className={
                                statusMarket == 0 && classes.displayNone
                              }
                              style={{ color: "#027CBB" }}
                              // to={`/insideProduct?id_parte=${id_parte}&id_distribuidor=${id_distribuidor}`}
                              onClick={() => {
                                dispatch(
                                  searchWithFilter(JSON.stringify("empty"))
                                );
                                window.open(
                                  `/insideProduct?id_parte=${id_parte}&id_distribuidor=${id_distribuidor}`,
                                  "_blank"
                                );
                              }}
                            >
                              Ver más detalle
                            </p>
                          </div>
                        </div>
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        ></ExpandMore>
                      </Card>
                    </Grid>
                  </>
                );
              }
            )}
          </Grid>
        </div>
        <br />
        <Snackbar
          open={inCart}
          autoHideDuration={2000}
          onClose={() => setInCart(false)}
          message={<p>Agregado al carrito</p>}
        >
          <Alert
            severity="info"
            sx={{ width: "100%" }}
            onClose={() => setInCart(false)}
          >
            <FaCartPlus /> Agregado al carrito
          </Alert>
        </Snackbar>
      </>
    );
  });
}
