import React, { Fragment } from "react";

import NavBarShop from "../NavBarShop/index";
import Footer from "../Footer";
import SecondNav from "../Ecommerce/secondNav";
import ShoppingDetail from "./shoppingDetail";

import "../../styles/Login/style.scss";
import "../../styles/Ecommerce/style.scss";

class Shopping extends React.Component {
  render() {
    return (
      <Fragment>
        <NavBarShop />
        {/* <MercadoPagoPayment /> */}
        <div className="mainHomeTop d-flex flex-column">
          <SecondNav />
          <ShoppingDetail />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default Shopping;
