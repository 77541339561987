import React, { useState, useEffect } from "react";
import Test1 from "../../assets/imgHome/Test1.png";
import Test2 from "../../assets/imgHome/Test2.png";
import Test3 from "../../assets/imgHome/Test3.png";

import TopImg from "../../assets/topImage.jpg";

import Skeleton from "react-loading-skeleton";

import "../../styles/ClientMenu/style.scss";
import "../../styles/Loaders/style.scss";

export default function ContentMenu() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const loader = () => {
    return (
      <div id="mainContainer">
        <div id="Container">
          <div className="d-flex" id="headerPagination">
            <label>Novedades</label>
            <div id="counter">
              <button id="leftBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.44311 1.46909L7.44311 11.9411C7.44311 12.8827 6.28911 13.3535 5.61631 12.6891L0.314121 7.4531C-0.104707 7.0395 -0.104707 6.3707 0.314121 5.9615L5.61631 0.725489C6.28911 0.0566877 7.44311 0.527488 7.44311 1.46909Z"
                    fill="black"
                  />
                </svg>
              </button>
              <button id="rightBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.556885 11.5309L0.556885 1.05889C0.556885 0.11729 1.71089 -0.353511 2.38369 0.31089L7.68588 5.5469C8.10471 5.9605 8.10471 6.6293 7.68588 7.0385L2.38369 12.2745C1.71089 12.9433 0.556885 12.4725 0.556885 11.5309Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="d-flex" id="imgSlider">
            <div className="d-flex" id="leftImg">
              <div id="longBar"></div>
              <div id="shortBar"></div>
              <div id="longBar"></div>
            </div>
            <div className="d-flex" id="rightImg">
              <div id="longBar"></div>
              <div id="shortBar"></div>
              <div id="longBar"></div>
            </div>
          </div>
        </div>
        <div id="Container2">
          <div className="d-flex" id="headerPagination">
            <label>Categorías Top</label>
            <div id="counter">
              <button id="leftBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.44311 1.46909L7.44311 11.9411C7.44311 12.8827 6.28911 13.3535 5.61631 12.6891L0.314121 7.4531C-0.104707 7.0395 -0.104707 6.3707 0.314121 5.9615L5.61631 0.725489C6.28911 0.0566877 7.44311 0.527488 7.44311 1.46909Z"
                    fill="black"
                  />
                </svg>
              </button>
              <button id="rightBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.556885 11.5309L0.556885 1.05889C0.556885 0.11729 1.71089 -0.353511 2.38369 0.31089L7.68588 5.5469C8.10471 5.9605 8.10471 6.6293 7.68588 7.0385L2.38369 12.2745C1.71089 12.9433 0.556885 12.4725 0.556885 11.5309Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="d-flex" id="imgSlider">
            <div className="d-flex" id="leftImg">
              <div id="longBar"></div>
              <div id="shortBar"></div>
              <div id="longBar"></div>
            </div>
            <div className="d-flex" id="midImg">
              <div id="longBar"></div>
              <div id="shortBar"></div>
              <div id="longBar"></div>
            </div>
            <div className="d-flex" id="rightImg">
              <div id="longBar"></div>
              <div id="shortBar"></div>
              <div id="longBar"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return loader();
  } else {
    return (
      <div className="mainContainer">
        <div className="Container">
          <div className="d-flex headerPagination">
            <label>Novedades</label>
            <div className="counter">
              <button id="leftBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.44311 1.46909L7.44311 11.9411C7.44311 12.8827 6.28911 13.3535 5.61631 12.6891L0.314121 7.4531C-0.104707 7.0395 -0.104707 6.3707 0.314121 5.9615L5.61631 0.725489C6.28911 0.0566877 7.44311 0.527488 7.44311 1.46909Z"
                    fill="black"
                  />
                </svg>
              </button>
              <button id="rightBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.556885 11.5309L0.556885 1.05889C0.556885 0.11729 1.71089 -0.353511 2.38369 0.31089L7.68588 5.5469C8.10471 5.9605 8.10471 6.6293 7.68588 7.0385L2.38369 12.2745C1.71089 12.9433 0.556885 12.4725 0.556885 11.5309Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="d-flex imgSlider">
            <div className=" d-flex leftImg">
              <img src={TopImg} />
            </div>
            <div className=" d-flex rightImg">
              <img src={TopImg} />
            </div>
          </div>
        </div>
        <div className="Container">
          <div className="d-flex headerPagination">
            <label>Categorías Top</label>
            <div className="counter">
              <button id="leftBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.44311 1.46909L7.44311 11.9411C7.44311 12.8827 6.28911 13.3535 5.61631 12.6891L0.314121 7.4531C-0.104707 7.0395 -0.104707 6.3707 0.314121 5.9615L5.61631 0.725489C6.28911 0.0566877 7.44311 0.527488 7.44311 1.46909Z"
                    fill="black"
                  />
                </svg>
              </button>
              <button id="rightBtn">
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.556885 11.5309L0.556885 1.05889C0.556885 0.11729 1.71089 -0.353511 2.38369 0.31089L7.68588 5.5469C8.10471 5.9605 8.10471 6.6293 7.68588 7.0385L2.38369 12.2745C1.71089 12.9433 0.556885 12.4725 0.556885 11.5309Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="d-flex imgSlider">
            <div className=" d-flex leftImg">
              <img src={Test1} />
            </div>
            <div className=" d-flex midImg">
              <img src={Test2} />
            </div>
            <div className=" d-flex rightImg">
              <img src={Test3} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
