import React from 'react';

const withAmount = (Component) => {
	return function RenderAmount({ condition, shoppingCart }) {
		if (!condition)
			return (
				<h3 className='nocontent'>No hay productos en el carrito de compras</h3>
			);
		return <Component shoppingCart={shoppingCart} />;
	};
};

export default withAmount;
