import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { LOGIN } from "../../graphql/Mutation";
import axios from "axios";
import CustomInput from "../CustomInput";
import Logo from "../../assets/LOGO.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
// import eye from "../../assets/eye.svg";
import EyeOff from "../../assets/eyeOff.svg";
import EyeOn from "../../assets/eyeOn.svg";
import Image from "../../assets/rightImage.jpg";

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#027cba",
    },
  },
});
const LoginV2 = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongEmail, setWrongEmail] = useState("");
  const [wrongPassword, setWrongPassword] = useState("");
  const [isPasswordShown, setisPasswordShown] = useState(false);
  const [showWindow, setShowWindow] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [login] = useMutation(LOGIN, {
    onCompleted: (data) => {
      //console.log(data);
      if (data.loginV2.ok == true) {
        const typeUser = data.loginV2.typeUser;

        localStorage.setItem("token", data.loginV2.token);
        localStorage.setItem("refreshToken", data.loginV2.refreshToken);
        localStorage.setItem("typeUser", typeUser);

        if (typeUser == 1) {
          history.push("/dashboardAdmin");
          sessionStorage.setItem("idFather2", "no");
          sessionStorage.setItem("idMarcaAut2", "no");
        }
        if (typeUser == 2) {
          sessionStorage.setItem("idFather2", data.loginV2.father);
          sessionStorage.setItem("idMarcaAut2", data.loginV2.marca);
          sessionStorage.setItem("marcasFiltradas", data.loginV2.marca);
          sessionStorage.setItem("idFather", data.loginV2.father);
          history.push("/dashboardAdmin");
        }
        if (typeUser == 3) {
          sessionStorage.setItem("idFather2", data.loginV2.father);
          sessionStorage.setItem("idMarcaAut2", data.loginV2.marca);
          sessionStorage.setItem("marcasFiltradas", data.loginV2.marca);
          sessionStorage.setItem("idFather", data.loginV2.father);
          history.push("/dashboardAdmin");
        }
        if (typeUser == 4) {
          sessionStorage.setItem("idFather2", data.loginV2.father);
          sessionStorage.setItem("idMarcaAut2", data.loginV2.marca);
          sessionStorage.setItem("marcasFiltradas", data.loginV2.marca);
          sessionStorage.setItem("idFather", data.loginV2.father);
          history.push("/dashboardAdmin");
        }
        if (typeUser == 5) {
          sessionStorage.setItem("idFather2", data.loginV2.father);
          sessionStorage.setItem("idDist2", data.loginV2.distrib);
          sessionStorage.setItem("idMarcaAut2", data.loginV2.marca);
          sessionStorage.setItem("idDistiltrado", data.loginV2.distrib);
          sessionStorage.setItem("marcasFiltradas", data.loginV2.marca);
          sessionStorage.setItem("idFather", data.loginV2.father);
          //console.log(data.loginV2.distrib);
          history.push("/dashboardAdmin");
        }
      } else if (data.loginV2.errors[0].path == "email") {
        setWrongEmail(data.loginV2.errors[0].message);
        setWrongPassword("");
      } else if (data.loginV2.errors[0].path == "password") {
        setWrongPassword(data.loginV2.errors[0].message);
        setWrongEmail("");
      }

      /*algoritm*/
    },
    onError: (error) => {
      //console.log(error);
      alert(`Error en peticion: ${error.message.split("GraphQL error: ")[1]}`);
    },
  });

  axios.defaults.withCredentials = true;

  const togglePasswordVisiblity = () => {
    setisPasswordShown((isPasswordShown) => !isPasswordShown);
  };

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  return (
    <Fragment>
      <div className="containerScroll d-flex">
        <div className="containerForm">
          <form>
            <div className="logoContainer d-flex flex-column">
              <div>
                <img className="logo" src={Logo} alt="Logo" />
              </div>
              <label className="headerlbl text-center">Iniciar sesión</label>
            </div>
            <div className="inputsContainer d-flex">
              <ThemeProvider theme={outerTheme}>
                <TextField
                  id="outlined-basic"
                  label="Correo electrónico"
                  multiline
                  maxRows={4}
                  value={email}
                  onChange={handleEmailChange}
                  variant="outlined"
                />
              </ThemeProvider>

              <ThemeProvider theme={outerTheme}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Ingresa tu contraseña
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={isPasswordShown ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisiblity}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {isPasswordShown ? (
                            <img src={EyeOn} alt="EyeOn" />
                          ) : (
                            <img src={EyeOff} alt="EyeOff" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Ingresa tu contraseña"
                    variant="outlined"
                  />
                </FormControl>
              </ThemeProvider>
            </div>
            <div className="lastContainer d-flex">
              <button
                className="btn btn-primary btn-lg btn-block buttonStyle"
                type="button"
                onClick={() => {
                  login({
                    variables: {
                      email: email,
                      password: password,
                    },
                  });
                }}
              >
                Iniciar Sesión
              </button>

              <Link
                className="linkStyle d-flex justify-content-center"
                to="/recover"
              >
                <label className="lblForgPass">¿Olvidaste tu contraseña?</label>
              </Link>
            </div>
          </form>
        </div>
        {showWindow ? (
          <div className="containerImg">
            <img src={Image} />
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default LoginV2;
