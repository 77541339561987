import styled from 'styled-components';

export const DropDawnContent = styled.div`
 width: 84%; 
 height: 26.9em;
 padding:10px;
 margin-left: 11.7%;
 margin-right: 2.5%;
 margin-top: 71px;
 position: absolute;
//  margin-inline: 159px;
 left: 2em;
 background-color: #FFFFFF;
 box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 z-index: 2000;
`;

export const Gallery = styled.div`
display: flex;
align-items: center;
flex-direction: row;
`;
export const StyledImage = styled.div`
margin: auto;
`;
export const Linea = styled.div`
    border-top: 1px  solid black;
    height: 3px;
    max-width: 94%;
    padding: 0;
    margin: 20px auto 0 auto;

  `;
export const MoreOptions = styled.div`
  display: flex;
  `;
export const Column = styled.div`
  align-items: center;
  font:18px;
  margin: auto;
  flex-direction: columns;
  `;
export const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  color: black;
  font-style: normal;
  font:24px;
  font-size: larger;
  `;
export const ScrollContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: ${props => props.justifyContent || ''};

  `;
