import React, { useState, useEffect } from "react";
import "../../styles/InsideProduct/style.scss";
import classes from "../../styles/InsideProduct/piezas.module.scss";
import Modal from "../addToCartModal";
import ModalRequestPart from "../requestPart";
import {
  GET_ALL_PRODUCTS,
  GET_RANDOM_SUGGESTION,
  GET_TB,
} from "../../graphql/Query";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { addShopElement } from "../../redux/reducers/shopping.reducer";
import { Link } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { FaCartPlus } from "react-icons/fa";

export default function ProductDetail(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [detailProduct, setDetailProduct] = useState([]);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [contador, setContador] = useState(1);
  const [id_parte, setIdParte] = useState("");
  const [id_distribuidor, setiIdDistribuidor] = useState("");
  const [inCart, setInCart] = useState(false);
  const [modalOpenImage, setModalOpenImage] = useState(false);
  const [tasaB, setTasaB] = React.useState(0);

  const [getTB] = useLazyQuery(GET_TB, {
    fetchPolicy: "no-cache",
    onCompleted({ getTB }) {
      console.log(getTB[0].tasa);
      setTasaB(getTB[0].tasa);
    },
    onError(e) {
      console.log("Error", e);
    },
  });
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };
  const getFinalPrice = (pv) => {
    console.log(pv);
    const price = parseInt(pv, 10) + getTasaBeneficio(parseInt(pv, 10));
    const precioVentaFormated = formatter.format(filterFloat(price));
    return precioVentaFormated;
  };
  const getTasaBeneficio = (pv) => {
    console.log(tasaB);
    return pv * (tasaB * 0.01);
  };

  const stateCart = useSelector((state) => state.rootReducers.shoop);

  const [getAllProduct] = useLazyQuery(GET_ALL_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted({ getAllProducts }) {
      console.log("getAllProducts", getAllProducts);
      setDetailProduct(getAllProducts);
    },
    onError(err) {
      console.log(err);
    },
  });

  const [getRandomSuggestion] = useLazyQuery(GET_RANDOM_SUGGESTION, {
    fetchPolicy: "no-cache",
    onCompleted({ getRandomSuggestion }) {
      setSuggestedProducts(getRandomSuggestion);
    },
    onError(err) {
      console.log(err);
    },
  });

  const dispatch = useDispatch();

  const addToCartClick = (value) => {
    let repeat = false;
    dispatch(
      addShopElement({
        ...value,
        Image:
          value.Image ||
          "https://res.cloudinary.com/walmart-labs/image/upload/w_960,dpr_auto,f_auto,q_auto:best/mg/gm/1p/images/product-images/img_large/00750117122670l.jpg",
      })
    );

    stateCart.forEach((item) => {
      item.ID !== value.ID ? (repeat = false) : (repeat = true);
    });
    if (!repeat) setInCart(true);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setIdParte(queryParams.get("id_parte"));
    setiIdDistribuidor(+queryParams.get("id_distribuidor"));

    if (id_parte !== "") {
      getAllProduct({
        variables: {
          idParte: id_parte,
          idDis: id_distribuidor,
        },
      });
    }

    getTB({
      variables: {
        a: 0,
      },
    });
  }, [id_parte]);

  useEffect(() => {
    if (detailProduct[0]) {
      getRandomSuggestion({
        variables: {
          id_Categorias: detailProduct[0].id_Categorias,
          currentID: detailProduct[0].id_parte,
        },
      });
    }
  }, [detailProduct]);

  if (detailProduct.length === 0)
    return (
      <div className="productContainer">
        <h2>Producto no disponible</h2>
      </div>
    );
  if (detailProduct.length > 0) {
    const {
      id_parte,
      statusMarket,
      Name,
      SKU,
      notas,
      Dimensiones,
      Peso,
      Proveedor,
      Description,
      Precio_de_Venta,
      Image,
      TermsAndConditions,
      nombre_categoria,
      existencias,
    } = detailProduct[0];
    const arrayImg = ["", "", "", ""];
    return (
      <React.Fragment>
        <div className="productContainer">
          <div className="d-flex upDataContainer">
            {/* <div className="d-flex imgIzqCont">
              {arrayImg.map(() => {
                return <img src={Image} />;
              })}
            </div> */}
            <div className=" d-flex productDetailContainer  ">
              <div className="mainImgContainer">
                {Image != "" ? (
                  <img src={Image} />
                ) : (
                  <>
                    <button
                      type="button"
                      className={classes.solicitar}
                      onClick={() => {
                        setModalOpenImage(true);
                      }}
                    >
                      Solicitar imagen al distribuidor
                    </button>
                    {modalOpenImage && (
                      <ModalRequestPart
                        setOpenModalImage={setModalOpenImage}
                        setOpenModal={setModalOpen}
                        byImage={true}
                        productInfo={{
                          id_parte,
                          Name,
                          Description,
                          SKU,
                          id_distribuidor: id_distribuidor,
                        }}
                      />
                    )}
                  </>
                )}
              </div>

              <div className="dataContainer">
                <div className="availableProductCont">
                  <div className="labelNameContainer">
                    <label className="title">
                      {/* {Name} <span>${getFinalPrice(Precio_de_Venta)} </span> */}
                      {Name}
                    </label>
                    {statusMarket ? (
                      <label className="Cost">
                        {getFinalPrice(Precio_de_Venta)} MXN
                      </label>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <label>N# {id_parte}</label>
                    {/* <label style={{ marginLeft: "100px" }}>SKU {id_parte}</label> */}
                  </div>
                  {statusMarket ? (
                    <label className="availableStatus"> DISPONIBLE</label>
                  ) : (
                    <label className="notAvailableStatus">No disponible</label>
                  )}

                  <label className="labelNotes">
                    <span className="bold">Notas:</span> {notas ? notas : "N/A"}
                  </label>
                </div>
                <div className="buyContainer">
                  <label className="carDetail">
                    *Ver detalles en el carrito
                  </label>
                  {statusMarket ? (
                    <>
                      <div className="d-flex">
                        <div className="counter">
                          <button
                            onClick={() =>
                              setContador(contador === 1 ? 1 : contador - 1)
                            }
                            style={{
                              background: "white",
                              border: "none",
                              boxShadow:
                                "0px 4px 5px 0px rgba(161,161,161,0.68)",
                            }}
                          >
                            -
                          </button>
                          <label>{contador}</label>
                          <button
                            onClick={() =>
                              setContador(
                                contador === existencias
                                  ? existencias
                                  : contador + 1
                              )
                            }
                            style={{
                              background: "white",
                              border: "none",
                              boxShadow:
                                "0px 4px 5px 0px rgba(161,161,161,0.68)",
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="buttonCont">
                        <label className="labelEnvio">
                          Envio Mercado Envíos
                        </label>
                        <button
                          className="btn btnAgregarCarrito"
                          onClick={() => {
                            // setModalOpen(true);
                            addToCartClick(detailProduct[0]);
                          }}
                        >
                          Agregar al carrito
                        </button>
                      </div>

                      {modalOpen && <Modal setOpenModal={setModalOpen} />}
                    </>
                  ) : (
                    <div className="buttonCont">
                      <button
                        className="btn btnSolicitarPieza"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        Solicitar Pieza
                      </button>
                      {modalOpen && (
                        <ModalRequestPart
                          setOpenModal={setModalOpen}
                          productInfo={{ id_parte, Name, Description, SKU }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex specProductContainer">
            <div className=" specDataProduct">
              <h4>Especificaciones del producto</h4>
              <div className="d-flex">
                <div className="d-flex variableContainer">
                  <label>No. Parte</label>
                  {/* <label>SKU</label> */}
                  <label>Dimensiones</label>
                  <label>Proveedor</label>
                  <label>Peso</label>
                </div>
                <div className="d-flex valueContainer">
                  <label className="specDataVal">
                    {id_parte ? id_parte : "N/A"}
                  </label>
                  {/* <label className="specDataVal">{SKU ? SKU : "N/A"}</label> */}
                  <label className="specDataVal">
                    {Dimensiones ? Dimensiones : "N/A"}
                  </label>
                  <label className="specDataVal">
                    {Proveedor ? Proveedor : "N/A"}
                  </label>
                  <label className="specDataVal">{Peso ? Peso : "N/A"}</label>
                </div>
              </div>
            </div>
            <div className="detailProductContainer">
              <h4>Detalles del producto</h4>
              <label>{Description ? Description : "N/A"}</label>
            </div>
          </div>
          <div className=" d-flex labelAccesoriosContainer">
            <div className=" specDataProduct">
              <h4>Tambien en {nombre_categoria}</h4>
              <div className="d-flex container-labelAccesorios">
                <div className="d-flex labelAccesorios">
                  {suggestedProducts.map((suggProd) => (
                    <label>
                      <Link
                        style={{ color: "#027CBB" }}
                        to={`/insideProduct?id_parte=${suggProd.id_parte}&id_distribuidor=${suggProd.id_distribuidor}`}
                        onClick={() => {
                          setIdParte(suggProd.id_parte);
                          setiIdDistribuidor(+suggProd.id_distribuidor);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {suggProd.Name}
                      </Link>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={inCart}
          autoHideDuration={2000}
          onClose={() => setInCart(false)}
          message={<p>Agregado al carrito</p>}
        >
          <Alert
            severity="info"
            sx={{ width: "100%" }}
            onClose={() => setInCart(false)}
          >
            <FaCartPlus /> Agregado al carrito
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}
