import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
// import { LOGIN } from "../../graphql/Query";
import { LOGIN_DIS } from "../../graphql/Mutation";

import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CustomInput from "../CustomInput";
import Logo from "../../assets/LOGO.png";
// import eye from "../../assets/eye.svg";
import EyeOff from '../../assets/eyeOff.svg';
import EyeOn from '../../assets/eyeOn.svg';
import Image from "../../assets/distributor.jpg";

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#027cba',
    },
  },
}); 

export default function LoginForm() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongEmail, setWrongEmail] = useState("");
  const [wrongPassword, setWrongPassword] = useState("");
  const [isPasswordShown, setisPasswordShown] = useState(false);
  const [showWindow, setShowWindow] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [loginDis] = useMutation(LOGIN_DIS, {
    onCompleted: (data) => {
      //console.log(data);
      sessionStorage.setItem('auxEmail', email)
      if (data.loginDis.ok == true) {
        localStorage.setItem("token", data.loginDis.token);
        localStorage.setItem("refreshToken", data.loginDis.refreshToken);
        localStorage.setItem("e", data.loginDis.user)
        history.push("/dashboardDis");
      }
      else if (data.loginDis.errors[0].path == "email") {
        setWrongEmail(data.loginDis.errors[0].message);
        setWrongPassword("");
      } else if (data.loginDis.errors[0].path == "password") {
        setWrongPassword(data.loginDis.errors[0].message);
        setWrongEmail("");
      }
    },
    onError: (error) =>
      alert(
        `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
      ),
  });

  axios.defaults.withCredentials = true;

  const togglePasswordVisiblity = () => {
    setisPasswordShown((isPasswordShown) => !isPasswordShown);
  };

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768 ) {
      setShowWindow(true);
    }
  }, []);


  return (
    <React.Fragment>
      <div className="containerScroll d-flex">
        <div className="containerForm">
          {showWindow ? <div className="loginMode">
            <Link className="linkBtn" to="/">
              <button disabled className="btn active">
                <label>Cliente</label>
              </button>
            </Link>
            <Link className="linkBtn" to="/distributor">
              <button className=" btn  active">
                <label className="lblForgPass">Distribuidor</label>
              </button>
            </Link>
          </div> : null}
          <form>
            <div className="logoContainer d-flex flex-column">
              <div>
              <img
                className="logo"
                src={Logo}
                alt="Logo"
              />

              </div>

              
              {showWindow ?
                null :
                <div className="loginMode">
                  <Link className="linkBtn" to="/">
                    <button disabled  className="btn active">
                      <label className="lblForgPass">Cliente</label>
                    </button>
                  </Link>
                  <Link className="linkBtn" to="/distributor">
                    <button  className=" btn  active">
                      <label  >Distribuidor</label>
                    </button>
                  </Link>
                </div>}
              <label className="headerlbl text-center">Iniciar sesión</label>
            </div>
            <div className="inputsContainer d-flex">
            <ThemeProvider theme={outerTheme}>
                <TextField
                  id="outlined-basic"
                  label='Correo electrónico'
                  // placeholder='Correo electrónico'
                  multiline
                  maxRows={4}
                  // sx = { {m: 1,
                  //           width: '515px',
                  //           border: 1,
                  //           borderColor: '#07598c'}
                  //         }
                  // sx={{ m: 1, width: '515px' }}
                  // sx={
                  //   statusCorrect
                  //     ? {
                  //         m: 1,
                  //         width: '515px',
                  //         border: 1,
                  //         borderColor: '#7fe390',
                  //       }
                  //     : statusWrong
                  //     ? {
                  //         m: 1,
                  //         width: '515px',
                  //         border: 1,
                  //         borderColor: '#FC796E',
                  //       }
                  //     : { m: 1, width: '515px' }
                  // }
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position='start'>
                  //       <img src={Mail} alt='Mail' />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  value={email}
                  onChange={handleEmailChange}
                  variant="outlined"
                  // value={value}
                  // onChange={handleChange}
                />
                {/* <span className='warningSpan'>{eremail}</span> */}
              </ThemeProvider>

              <ThemeProvider theme={outerTheme}>
                <FormControl
                 
                  variant='outlined'
                >
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Ingresa tu contraseña
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={isPasswordShown ? 'text' : 'password'}
                    value={password}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={togglePasswordVisiblity}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {isPasswordShown ? (
                            <img src={EyeOn} alt='EyeOn' />
                          ) : (
                            <img src={EyeOff} alt='EyeOff' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Ingresa tu contraseña'
                    variant="outlined" 
                    
                  />
                </FormControl>
                
              </ThemeProvider>
            </div>
            
            {/* <div className="form-group formDiv">
              <CustomInput
                id="Correo"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                callback={setEmail}
              />
              <div className={wrongEmail ? "d-flex  wrongLabelContainer" : "d-flex  wrongLabelContainer d-none"}>
                <label className="mt-2 wrongLabel">{wrongEmail}</label>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 12C9.44771 12 9 11.5523 9 11L9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5L11 11C11 11.5523 10.5523 12 10 12Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M8.5 14.5C8.5 13.6716 9.17157 13 10 13C10.8284 13 11.5 13.6716 11.5 14.5C11.5 15.3284 10.8284 16 10 16C9.17157 16 8.5 15.3284 8.5 14.5Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                </svg>
              </div>
            </div>

            <div className="form-group formDiv  secretPass">
              <div style={{ display: 'flex' }}>
                <CustomInput
                  id="Contraseña"
                  type={isPasswordShown ? "text" : "password"}
                  placeholder="Ingresa tu contraseña"
                  value={password}
                  callback={setPassword}
                />
                <img
                  className="password-icon"
                  src={eye}
                  onClick={togglePasswordVisiblity}
                />
              </div>

              <div className={wrongPassword ? "d-flex  wrongLabelContainer " : "d-flex  wrongLabelContainer d-none"}>
                <label className="mt-2 wrongLabel">{wrongPassword}</label>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 12C9.44771 12 9 11.5523 9 11L9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5L11 11C11 11.5523 10.5523 12 10 12Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M8.5 14.5C8.5 13.6716 9.17157 13 10 13C10.8284 13 11.5 13.6716 11.5 14.5C11.5 15.3284 10.8284 16 10 16C9.17157 16 8.5 15.3284 8.5 14.5Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                </svg>
              </div>
            </div> */}
            <div className="lastContainer d-flex">
            <button
              className="btn btn-primary btn-lg btn-block buttonStyle"
              type="button"
              onClick={() => {
                loginDis({
                  variables: {
                    email: email,
                    password: password,
                  },
                });
                // handleSubmit();
              }}
            >
              Iniciar Sesión
            </button>

            <Link
              className="linkStyle d-flex justify-content-center"
              to="/recoverdis"
            >
              <label className="lblForgPass">¿Olvidaste tu contraseña?</label>
            </Link>

            </div>

            
            {/* <div className="d-flex justify-content-center">
              <h4 className="mt-2">{Status}</h4>
            </div> */}
          </form>
          {/* <form>
            <div className=" d-flex flex-column align-items-center logoContainer">
              <img
                className="logo"
                src={Logo}  
                alt="Logo"
              />
              {showWindow ?
               null : 
              <div className="loginMode">
                <Link className="linkBtn" to="/">
                  <button disabled className="btn active">
                    <label>Cliente</label>
                  </button>
                </Link>
                <Link className="linkBtn" to="/distributor">
                  <button className=" btn  active">
                    <label className="lblForgPass">Distribuidor</label>
                  </button>
                </Link>
              </div>}

              <label className="headerlbl text-center">Iniciar sesión</label>
            </div>

            <div className="form-group formDiv">
              <CustomInput
                id="Correo"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                callback={setEmail}
              />

              <div className={wrongEmail ? "d-flex  wrongLabelContainer" : "d-flex  wrongLabelContainer d-none"}>
                <label className="mt-2 wrongLabel">{wrongEmail}</label>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 12C9.44771 12 9 11.5523 9 11L9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5L11 11C11 11.5523 10.5523 12 10 12Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M8.5 14.5C8.5 13.6716 9.17157 13 10 13C10.8284 13 11.5 13.6716 11.5 14.5C11.5 15.3284 10.8284 16 10 16C9.17157 16 8.5 15.3284 8.5 14.5Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                </svg>
              </div>
            </div>
            <div className="form-group formDiv secretPass">
              <CustomInput
                id="Contraseña"
                type={isPasswordShown ? "text" : "password"}
                placeholder="Ingresa tu contraseña"
                value={password}
                callback={setPassword}
                
              />
              <img
                className="password-icon "
                src={eye}
                onClick={togglePasswordVisiblity}
              />
              <div className={wrongPassword ? "d-flex  wrongLabelContainer" : "d-flex  wrongLabelContainer d-none"}>
                <label className="mt-2 wrongLabel">{wrongPassword}</label>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 12C9.44771 12 9 11.5523 9 11L9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5L11 11C11 11.5523 10.5523 12 10 12Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                  <path
                    d="M8.5 14.5C8.5 13.6716 9.17157 13 10 13C10.8284 13 11.5 13.6716 11.5 14.5C11.5 15.3284 10.8284 16 10 16C9.17157 16 8.5 15.3284 8.5 14.5Z"
                    fill="#FF0000"
                    fill-opacity="0.7"
                  />
                </svg>
              </div>
            </div>
            <button
              className="btn btn-primary btn-lg btn-block buttonStyle"
              type="button"
              onClick={() => {
                loginDis({
                  variables: {
                    email: email,
                    password: password,
                  },
                });
                // handleSubmit();
              }}
            >
              Iniciar Sesión
            </button>

            <Link
              className="linkStyle d-flex justify-content-center"
              to="/recoverdis"
            >
              <label className="lblForgPass">¿Olvidaste tu contraseña?</label>
            </Link>
          </form> */}

        </div>
        {showWindow ?
          <div className="containerImg">
            <img src={Image} />
          </div> : null}

      </div>
    </React.Fragment>
  );
}
