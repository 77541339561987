import React, { useEffect, useState } from 'react';
import '../../styles/Shopping/style.scss';
import Test1 from '../../assets/imgHome/Test1.png';
import { useDispatch, useSelector } from 'react-redux';
import { cuantityModify } from '../../redux/actions/shopping.actions';
import {
	cuantityModifyReducer,
	deleteShopElement,
} from '../../redux/reducers/shopping.reducer';

export default function ProductsCart({
	index,
	id_parte,
	SKU,
	Dimensiones,
	Proveedor,
	Peso,
	existencias,
	Image,
	Precio_de_Venta,
	cuantity,
	name,
}) {
	const dispatch = useDispatch();
	const getAmount = (cuantity = 1) => {
		const parsedMoney = (Number(Precio_de_Venta) * cuantity).toLocaleString(
			'es-MX',
			{
				style: 'currency',
				currency: 'MXN',
			}
		);

		return parsedMoney;
	};

	const handleChange = (value) => {
		dispatch(cuantityModifyReducer({ value, index }));
	};

	const handleDelete = () => {
		dispatch(deleteShopElement(index));
	};

	return (
		<React.Fragment>
			<div className=' d-flex containerProducts'>
				<div id='containers' className='imageCheck'>
					<div className='imgContainer'>
						<img src={Image} />
					</div>
				</div>
				<div className='secondContainer'>
					<div className='specProduct' id='containers'>
						<h4>{name}</h4>
						<div className='d-flex infoSection'>
							<div className='d-flex valueContainer'>
								<label className='specDataVal'>No. parte: {id_parte}</label>
								<label className='specDataVal drop'>SKU: {SKU}</label>
								<label className='specDataVal drop'>
									Dimensiones: {Dimensiones}
								</label>
								<label className='specDataVal drop'>
									Proveedor: {Proveedor}
								</label>
								<label className='specDataVal drop'>Peso: {Peso}</label>
							</div>
							<label className='delete' onClick={handleDelete}>
								Eliminar
							</label>
						</div>
					</div>
					<div className='d-flex unitPrice drop' id='containers'>
						<label className='Cost'>{getAmount()}</label>
					</div>
					<div className="quantityContainer">
						<div className=' d-flex countContainer' id='containers'>
							<div className='d-flex counter'>
								<button
									onClick={(e) => handleChange(-1)}
									className='d-flex '
									style={{
										background: 'white',
										border: 'none',
										boxShadow: '0px 4px 5px 0px rgba(161,161,161,0.68)',
									}}
								>
									<svg
										width='16'
										height='2'
										viewBox='0 0 16 2'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1Z'
											fill='black'
										/>
									</svg>
								</button>

								<label className='counterNum'>{cuantity}</label>
								<button
									onClick={(e) => handleChange(1)}
									className='d-flex '
									style={{
										background: 'white',
										border: 'none',
										boxShadow: '0px 4px 5px 0px rgba(161,161,161,0.68)',
									}}
								>
									<svg
										width='16'
										height='16'
										viewBox='0 0 16 16'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fill-rule='evenodd'
											clip-rule='evenodd'
											d='M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z'
											fill='black'
										/>
									</svg>
								</button>
							</div>
							<div className='exists'>
								<label>Existencias: {existencias}</label>
							</div>
						</div>
						<div className='d-flex totalPrice' id='containers'>
							<label className='Cost'>{getAmount(cuantity)}</label>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
