import React, { useState,useEffect } from 'react'
import NavComp from "../Navbar/index";
import { GET_ALL_PRODUCTS } from '../../graphql/Query';
import { useQuery } from '@apollo/client';
import { GenericTable } from '../Dashboard/GenericTable';
import LeftAside from './leftAside';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ClientStoreDis = () => {
    const [dataTable, setDataTable] = useState([])
    const [dataColumns, setDataColumns] = useState([])
    var e = localStorage.getItem("e");

    const [showWindow, setShowWindow] = useState(false);

    useEffect(() => {
        const sizeWindow = window.screen.width;
        if (sizeWindow >= 768) {
            setShowWindow(true);
        }
    }, []);

    useQuery(GET_ALL_PRODUCTS, {
        variables: {
            e: e
        },
        fetchPolicy: 'no-cache',
        onCompleted({ getAllProducts }) {
            debugger;
            setDataTable(getAllProducts);
            setDataColumns([
                { title: 'ID Parte', field: 'id_parte' },
                { title: 'Parte', field: 'Name' },
                { title: 'Descripción', field: 'Name' },
                { title: 'Precio de venta', field: 'Precio_de_Venta' },
                { title: 'ID Distribuidor', field: 'id_distribuidor' },
                { title: 'Imagen', field: 'Image' },
                { title: 'Upload', field: 'IconUpload' },
            ])

        }

    })
    //console.log('datos', dataTable)


    return (
        <>
            <NavComp />
            <div className="mainDashboard d-flex">

                {showWindow ?
                    <>
                        <LeftAside />
                        <div style={{ width: '100%', padding: 100 }}>
                            <GenericTable
                                columns={dataColumns}
                                body={dataTable} />
                        </div>
                    </>
                    :
                    <>
                        <div style={{ width: '100%', padding: '48px 40px 0px 40px' }}>
                            <GenericTable
                                columns={dataColumns}
                                body={dataTable} />
                        </div>
                    </>
                }

            </div>
        </>
    )
}

export default ClientStoreDis
