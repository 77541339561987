import React, { useState } from "react";

import intervalToDuration from "date-fns/intervalToDuration";
import parseISO from "date-fns/parseISO";
import isAfter from "date-fns/isAfter";
import { GrClose } from "react-icons/gr";

import {
  REQUEST_PART,
  SEND_EMAIL_REQUEST_AUTOPART,
  UPDATE_USER_REQUESTPART,
} from "../graphql/Mutation";
import { GET_REQUEST_USER } from "../graphql/Query";
import { useLazyQuery } from "@apollo/client";
import "../styles/Modals/style.scss";
import { useMutation } from "@apollo/client";

function ModalRequestPart({
  setOpenModal,
  setOpenModalImage,
  productInfo: { id_parte, Name, Description, SKU, id_distribuidor },
  byImage,
}) {
  const [inputs, setInputs] = useState({
    firtsName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [errors, setErrors] = useState({
    firtsName: "Este campo es requerido",
    lastName: "Este campo es requerido",
    email: "Este campo es requerido",
    phone: "Este campo es requerido",
  });
  const [handleButtonSend, setHandleButtonSend] = useState({
    disabled: false,
    text: "Enviar Solicitud",
    message: "",
  });

  const [requestAutopart] = useMutation(SEND_EMAIL_REQUEST_AUTOPART, {
    fetchPolicy: "no-cache",
    onCompleted({ requestAutopart }) {
      console.log("requestAutopart");
      if (requestAutopart) {
        setInputs({
          firtsName: "",
          lastName: "",
          email: "",
          phone: "",
        });
        setHandleShow("displayNone");
        setHandleButtonSend({
          disabled: true,
          text: "Solicitud enviada",
          message:
            "Gracias por contactarnos, en breve nos pondremos en contacto contigo.",
        });
      }
    },
    onError(err) {},
  });
  const [requestPart] = useMutation(REQUEST_PART, {
    fetchPolicy: "no-cache",
    onCompleted({ requestPart }) {
      if (requestPart) {
        requestAutopart({
          variables: {
            autopart: {
              idParte: id_parte,
              nombre: Name,
              descripcion: Description,
              SKU: SKU,
              tipoMovimiento: "obsoleto",
              linkback: `https://dev.ballena.therocketcode.com/insideProduct?id_parte=${id_parte}&id_distribuidor=${id_distribuidor}`,
            },
            customerData: {
              nombre: inputs.firtsName,
              apellido: inputs.firtsName,
              correo: inputs.email,
              telefono: inputs.phone,
            },
          },
        });
      }
    },
    onError(err) {
      console.log("requestPart ERROR", err.networkError.result.errors);
    },
  });
  const [upDateUserRequestPart] = useMutation(UPDATE_USER_REQUESTPART, {
    fetchPolicy: "no-cache",
    onCompleted({ upDateUserRequestPart }) {
      console.log("upDateUserRequestPart", upDateUserRequestPart);
    },
    onError(err) {
      console.log(
        "upDateUserRequestPart error",
        err.networkError.result.errors
      );
    },
  });
  const [getUserRequestingPartbyId] = useLazyQuery(GET_REQUEST_USER, {
    fetchPolicy: "no-cache",
    onCompleted({ getUserRequestingPartbyId }) {
      let intervalDuration;
      if (!getUserRequestingPartbyId) {
        requestPart({
          variables: {
            lastName: inputs.lastName,
            firstName: inputs.firtsName,
            email: inputs.email,
            phone: inputs.phone,
            id_parte: id_parte,
          },
        });
        return;
      }

      if (
        isAfter(
          parseISO(getUserRequestingPartbyId.updatedAt),
          parseISO(getUserRequestingPartbyId.createdAt)
        )
      ) {
        intervalDuration = intervalToDuration({
          start: parseISO(getUserRequestingPartbyId.updatedAt),
          end: new Date(),
        });
      } else {
        intervalDuration = intervalToDuration({
          start: parseISO(getUserRequestingPartbyId.createdAt),
          end: new Date(),
        });
      }
      console.log("inter", intervalDuration);
      handleDaysCounter({
        intervalDuration,
        request_part_users_id: getUserRequestingPartbyId.request_part_users_id,
      });
    },
    onError(err) {
      console.log("getUserRequestingPartbyId error", err);
      console.log("getUserRequestingPartbyId", err.networkError.result.errors);
    },
  });

  const handleDaysCounter = ({
    intervalDuration: { years, months, days },
    request_part_users_id,
  }) => {
    console.log("handleDaysCounter", request_part_users_id);
    if (!years && !months && days < 5) {
      setHandleButtonSend({
        disabled: true,
        text: "Error al enviar",
        message: `Ya has solicitado esta pieza. 
        Puedes volver a solicitarla en ${
          5 - days
        } días si aun no has recibido respuesta`,
      });

      return;
    }

    requestAutopart({
      variables: {
        autopart: {
          idParte: id_parte,
          nombre: Name,
          descripcion: Description,
          SKU: SKU,
          tipoMovimiento: "obsoleto",
        },
        customerData: {
          nombre: inputs.firtsName,
          apellido: inputs.firtsName,
          correo: inputs.email,
          telefono: inputs.phone,
        },
      },
    });
    upDateUserRequestPart({
      variables: {
        request_part_users_id,
        status_request: "solicitado otra vez",
      },
    });
  };
  const handleInputError = (event) => {
    const inputErrors = {
      firtsName: (value) => {
        /\d/g.test(value)
          ? setErrors({
              ...errors,

              firtsName: "Este campo no puede contener números",
            })
          : setErrors({
              ...errors,

              firtsName: "",
            });
      },
      lastName: (value) => {
        /\d/g.test(value)
          ? setErrors({
              ...errors,

              lastName: "Este campo no puede contener números",
            })
          : setErrors({
              ...errors,

              lastName: "",
            });
      },
      email: (value) => {
        !/\S+@\S+\.\S+/.test(value)
          ? setErrors({
              ...errors,

              email: "Este campo debe ser un correo válido",
            })
          : setErrors({
              ...errors,

              email: "",
            });
      },
      phone: (value) => {
        !/^\d+$/.test(value)
          ? setErrors({
              ...errors,

              phone: "Este campo debe ser un número válido",
            })
          : setErrors({
              ...errors,

              phone: "",
            });
      },
    };
    if (event.target.value === "") {
      setErrors({
        ...errors,

        [event.target.id]: "Este campo es requerido",
      });
    } else {
      inputErrors[event.target.id](event.target.value);
    }
  };
  const handleInputChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.id]: event.target.value,
    });
    handleInputError(event);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    errors.firtsName === "" &&
    errors.lastName === "" &&
    errors.email === "" &&
    errors.phone === ""
      ? await getUserRequestingPartbyId({
          variables: {
            email: inputs.email,
            id_parte: id_parte,
          },
        })
      : console.log("ERROR");
  };
  const [handleShow, setHandleShow] = useState("");
  return (
    <div className="modalBackground">
      <div className="modalContainer ">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              byImage ? setOpenModalImage(false) : setOpenModal(false);
            }}
          >
            <GrClose />
          </button>
        </div>
        <div className="title formTitle ">
          <label className={handleShow}>
            Por favor ayudanos con la siguiente información:{" "}
          </label>
        </div>
        <form className={`body fromMobile requestPart ${handleShow}`}>
          <span className="bold">Datos personales:</span>
          <div className="from-group row d-flex justify-content-center ">
            <label className="col-sm-1 col-form-label col-auto ">Nombre:</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                id="firtsName"
                onChange={handleInputChange}
                value={inputs.firtsName}
                placeholder="John"
              />

              <div className="error">{errors.firtsName}</div>
            </div>
            <label className="col-sm-1 col-auto col-form-label">
              Apellido:
            </label>

            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Doe"
                value={inputs.lastName}
                onChange={handleInputChange}
              />
              <div className="error">{errors.lastName}</div>
            </div>
          </div>
          <div className="from-group row d-flex justify-content-center ">
            <label className="col-md-1 col-auto col-form-label">Email:</label>

            <div className="col-sm-4">
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={handleInputChange}
                value={inputs.email}
                placeholder="johndoe@email.com"
              />
              <div className="error">{errors.email}</div>
            </div>
            <label className="col-sm-1 col-auto col-form-label">
              Teléfono:
            </label>

            <div className="col-sm-4 ">
              <input
                required
                type="tel"
                className="form-control"
                id="phone"
                value={inputs.phone}
                onChange={handleInputChange}
                placeholder="934185858"
              />
              <div className="error">{errors.phone}</div>
            </div>
          </div>
          {byImage && (
            <div className="col-sm-4">
              <label>
                <input
                  type="checkbox"
                  checked={true}
                  // onChange={handleChange}
                />
                Solicitar imagen
              </label>
            </div>
          )}
        </form>
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <button
            onClick={handleSubmit}
            disabled={handleButtonSend.disabled}
            className="btn sendButtonBottom p-2 "
          >
            {handleButtonSend.text}
          </button>
          <div className="messageButtonSend p-2 ">
            {handleButtonSend.message}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalRequestPart;
