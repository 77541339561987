import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    loginV2(email: $email, password: $password) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
      typeUser
      father
      distrib
      marca
    }
  }
`;

export const LOGIN_DIS = gql`
  mutation login($email: String!, $password: String!) {
    loginDis(email: $email, password: $password) {
      ok
      token
      refreshToken
      user
      errors {
        path
        message
      }
    }
  }
`;

export const GET_DATA_DISTRIBUTOR = gql`
  mutation getDistributor($e: String!) {
    getDistributor(e: $e) {
      id
      gato
      id_parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Costo_de_almacenaje
      Precio_de_Venta
      Fecha_orden_almacen
      Fecha_de_entrega_almacen
      Fecha_orden_cliente
      Fecha_de_venta
      celda_L
      celda_M
      celda_N
      celda_O
      celda_P
      celda_Q
      celda_R
      id_distribuidor
    }
  }
`;

export const MOVE_TO_MARKETPLACE = gql`
  mutation moveToMarketplace($e: String, $id_parte: String) {
    moveToMarketplace(e: $e, id_parte: $id_parte) {
      ok
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($imgUrl: String, $email: String, $id_parte: Int) {
    uploadImage(imgUrl: $imgUrl, email: $email, id_parte: $id_parte) {
      ok
    }
  }
`;

export const REQUEST_PART = gql`
  mutation requestPart(
    $lastName: String!
    $firstName: String!
    $email: String!
    $phone: String!
    $id_parte: String!
  ) {
    requestPart(
      lastName: $lastName
      firstName: $firstName
      email: $email
      phone: $phone
      id_parte: $id_parte
    )
  }
`;

export const SEND_EMAIL_REQUEST_AUTOPART = gql`
  mutation requestAutopart($autopart: Autopart!, $customerData: CustomerData!) {
    requestAutopart(autopart: $autopart, customerData: $customerData)
  }
`;
export const UPDATE_USER_REQUESTPART = gql`
  mutation upDateUserRequestPart(
    $request_part_users_id: Int!
    $status_request: String!
  ) {
    upDateUserRequestPart(
      request_part_users_id: $request_part_users_id
      status_request: $status_request
    )
  }
`;

export const SELL_SHOPPING_CART = gql`
  mutation setSellShoppingCart(
    $items: [SellInfo]
    $userToken: String
    $token: String
    $issuer_id: String
    $payment_method_id: String
    $installments: Int
    $description: String
    $email: String
    $amount: String
    $identification: Identification
  ) {
    setSellShoppingCart(
      selling: {
        items: $items
        userToken: $userToken
        token: $token
        issuer_id: $issuer_id
        payment_method_id: $payment_method_id
        installments: $installments
        description: $description
        payer: { email: $email, identification: $identification }
        amount: $amount
      }
    ) {
      message
      success
      status
      sellId
    }
  }
`;

export const POST_PRODUCT_TO_MARKETPLACE = gql`
  mutation (
    $id_CatProduct_group: Int!
    $id_parte: String!
    $id_grupo: Int!
    $id_distribuidor: Int!
    $descripcion: String
    $notas: String
    $peso: String
    $dimensiones: String
    $Image: Upload
    $precio_plataforma: Float!
    $existencias: Int
    $id_Categorias: Int!
    $nueva_categoria: String
  ) {
    postingProductMarketplace(
      productData: {
        id_CatProduct_group: $id_CatProduct_group
        id_parte: $id_parte
        id_grupo: $id_grupo
        id_distribuidor: $id_distribuidor
        precio_plataforma: $precio_plataforma
        descripcion: $descripcion
        notas: $notas
        peso: $peso
        dimensiones: $dimensiones
        Image: $Image
        existencias: $existencias
        id_Categorias: $id_Categorias
        nueva_categoria: $nueva_categoria
      }
    )
  }
`;

export const REMOVE_PRODUCT_FROM_MARKETPLACE = gql`
  mutation ($ID: Int!) {
    removeProductFromMarketplace(ID: $ID)
  }
`;
