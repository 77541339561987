// import React, { useState, useEffect } from 'react'
// import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
// // import SearchBar from "./searchBar";
// // import MidVideoSection from "./midVideoSection";
// // import MidBlueRect from "./midBlueRect";
// import StatsTable from "./statsTable";
// import { useQuery, useLazyQuery } from '@apollo/client'
// import {
//     GET_ABC_NUMBER,
//     GET_POT_OBSOLETOS,
//     GET_DATA_CLIENTE_TABLE,
//     GET_BEST_SELL_PART,
//     GET_BEST_DIS_PRO_FITS,
//     GET_ABCD_DISTRIBUIDOR,
//     GET_ALL_DISTRIBUTORS,
//     GET_MOV_NUMBER,
//     GET_MOV_DISTRIBUTOR,

//     GET_DISTRIBUTOR_BEST_SELL_PART,
//     GET_DISTRIBUTOR_BEST_PART_PROFITS,
//     GET_DISTRIBUTOR_WORSE_SELL_PART
// } from '../../graphql/Query';
// import PieChartAbC from "../ChartDahsboard/PieChartAbC";
// import ChartBestSellinngsPieces from "../ChartDahsboard/ChartBestSellinngsPieces";
// import ChartDealers from "../ChartDahsboard/ChartDealers";
// import FilterByDistributor from '../Dashboard/FilterByDistributor';

// const Distributor = () => {
//     let history = useHistory();
//     const [dataAux, setDataAux] = useState([])

//     const [dataTable, setDataTable] = useState([])
//     const [dataDonut, setDataDonut] = useState([])
//     const [dataBarPieces, setDataBarPieces] = useState([])
//     const [dataBarDis, setDataBarDis] = useState([])

//     const [dataBarPiecesAux, setDataBarPiecesAux] = useState([])
//     const [dataBarDisAux, setDataBarDisAux] = useState([])
//     const [optionSelect, setOptionSelect] = useState([])
//     const [dataDonutTypeMov, setDataDonutTypeMov] = useState([])
//     const [dataLabelDonutMov, setDataLabelDonutMov] = useState([])
//     const [dataDonutRegABC, setDataDonutRegABC] = useState([])
//     const [dataBarBestPieces, setDataBarBestPieces] = useState([])
//     const [labelsBestPieces, setLabelsBestPieces] = useState([])
//     const [bestPartProfits, setBestPartProfits] = useState([])
//     const [labelsBestPartProfits, setLabelsBestPartProfits] = useState([])
//     // queries iniales para llenar graficas y tablas
//     const [getDistributorBestSell] = useLazyQuery(GET_DISTRIBUTOR_BEST_SELL_PART, {
//         fetchPolicy: 'no-cache',
//         onCompleted({ getDistributorBestSellPart }) {
//             let result = {}; debugger
//             getDistributorBestSellPart.forEach(({ id_parte, celda_M }) => {
//                 result[id_parte] = 0
//                 result[id_parte] += celda_M
//             });
//             //console.log(result)
//             setDataBarBestPieces(Object.values(result))
//             setLabelsBestPieces(Object.keys(result))
//         }, onError(err) {
//             //console.log(err)
//         }
//     })

//     const [getABCDistribuidor] = useLazyQuery(GET_ABCD_DISTRIBUIDOR, {
//         fetchPolicy: 'no-cache',
//         onCompleted({ getABCDistributor }) {
//             const letterA = getABCDistributor[0]?.count
//             const letterB = getABCDistributor[1]?.count
//             const letterC = getABCDistributor[2]?.count
//             setDataDonutRegABC([letterA, letterB, letterC])

//         }, onError(err) {
//             //console.log(err)
//         }

//     })
//     const [getDistributorBestPartProfits] = useLazyQuery(GET_DISTRIBUTOR_BEST_PART_PROFITS, {
//         fetchPolicy: 'no-cache',
//         onCompleted({ getDistributorBestPartProfits }) {
//             let result = {}; debugger
//             getDistributorBestPartProfits.forEach(({ id_parte, celda_O }) => {
//                 result[id_parte] = 0
//                 result[id_parte] += celda_O
//             });
//             //console.log(result)
//             setBestPartProfits(Object.values(result))
//             setLabelsBestPartProfits(Object.keys(result))
//         }, onError(err) {
//             //console.log(err)
//         }
//     })
//     const [getDistributorWorseSellPart] = useLazyQuery(GET_DISTRIBUTOR_WORSE_SELL_PART, {
//         fetchPolicy: 'no-cache',
//         onCompleted({ getDistributorWorseSellPart }) {
//             debugger
//             const dataDona = getDistributorWorseSellPart.map(({ celda_O }) => celda_O)

//             setDataDonut(dataDona)
//         }, onError(err) {
//             //console.log(err)
//         }
//     })

//     useQuery(GET_BEST_DIS_PRO_FITS, {
//         fetchPolicy: 'no-cache',
//         onCompleted({ getBestDisProfits }) {
//             debugger
//             const bestDisProfits = getBestDisProfits.map(({ total_amount, distribuidor }) => {
//                 return {
//                     label: distribuidor,
//                     backgroundColor: '#80BDDD',
//                     borderColor: '#026ba1',
//                     borderRadius: 5,
//                     borderWidth: 1,
//                     hoverBackgroundColor: '#026ba1',
//                     /*  hoverBorderColor: 'rgba(255,99,132,1)', */
//                     data: [total_amount]
//                 }
//             })
//             setDataBarDis(bestDisProfits)
//         }
//     })

//     useQuery(GET_DATA_CLIENTE_TABLE, {
//         fetchPolicy: 'no-cache',
//         onCompleted({ getDataClient }) {
//             setDataTable(getDataClient);
//         }
//     })

//     useEffect(() => {
//         getDistributorBestSell({
//             variables: {
//                 e: localStorage.getItem("e")
//             }
//         })
//         getDistributorBestPartProfits({
//             variables: {
//                 e: localStorage.getItem("e")
//             }
//         })
//         getDistributorWorseSellPart({
//             variables: {
//                 e: localStorage.getItem("e")
//             }
//         })
//         getABCDistribuidor({
//             variables: {
//                 e: localStorage.getItem("e")
//             }
//         })
//     }, [])

//     return (
//         <div className="chartContainer" >
//             <div
//                 style={{
//                     display: 'grid',
//                     justifyItems: 'center',
//                     gridTemplateColumns: '1fr 1fr',
//                     gridColumnGap: '3%',
//                     marginBottom: '6%'
//                 }}
//             >
// {
//                     dataDonut.length > 0 && <ChartDealers dataDonut={dataDonutRegABC} height={300} title="Registro ABC " />
//                 }

//                 <ChartBestSellinngsPieces
//                 title="Piezas que generan más ganancia"
//                     dataBarPieces={bestPartProfits}
//                     width={'90%'}
//                     labels={labelsBestPartProfits}
//                 />

//             </div>

//             <div
//                 style={{
//                     display: 'grid',
//                     justifyItems: 'center',
//                     gridTemplateColumns: '1fr 1fr',
//                     gridColumnGap: '3%',
//                     marginBottom: '6%'
//                 }}
//             >

//                 <ChartBestSellinngsPieces
//                     dataBarPieces={dataBarBestPieces}
//                     width={'90%'}
//                     labels={labelsBestPieces}
//                 />

//                 {
//                     dataDonut.length > 0 && <ChartDealers dataDonut={dataDonut} height={300} title="Piezas menos vendidas" />
//                 }

//             </div>

//             <div
//                 style={{
//                     display: 'flex',
//                     marginBottom: 10
//                 }}
//             >
//                 <StatsTable
//                     dataTable={dataTable}
//                 />

//             </div>

//         </div>
//     )
// }
// export default Distributor;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import StatsTable from "./statsTable";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ABC_NUMBER,
  GET_POT_OBSOLETOS,
  GET_BEST_SELL_PART,
  GET_BEST_DIS_PRO_FITS,
  GET_ABCD_DISTRIBUIDOR,
  GET_ALL_DISTRIBUTORS,
  GET_MOV_NUMBER,
  GET_MOV_DISTRIBUTOR,
  GET_TYPE_SALE_DISTRIBUTOR,
  GET_COMPARE_MOV_TYPE,
} from "../../graphql/Query";
import { MOVE_TO_MARKETPLACE } from "../../graphql/Mutation";
import { Box, Modal, LinearProgress } from "@material-ui/core";
import Title from "antd/lib/typography/Title";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PieChartAbC from "../ChartDahsboard/PieChartAbC";
import ChartBestSellinngsPieces from "../ChartDahsboard/ChartBestSellinngsPieces";
import GenericModalCompareDis from "../Dashboard/GenericModalCompareDis";
import ModalSwitch from "./ModalSwitch";
import ChartDealers from "../ChartDahsboard/ChartDealers";
import FilterByDistributor from "../Dashboard/FilterByDistributor";
import Form from "react-bootstrap/Form";
import { HiDotsVertical } from "react-icons/hi";
import Tooltip from "@mui/material/Tooltip";
import "../../styles/TooTip/style.css";
import CircularProgress from '@mui/material/CircularProgress';




const Distribuidor = () => {
  const history = useHistory();
  const [dataAux, setDataAux] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [dataDonut, setDataDonut] = useState([]);
  const [dataBarPieces, setDataBarPieces] = useState([]);
  const [dataBarDis, setDataBarDis] = useState([]);

  const [dataBarPiecesAux, setDataBarPiecesAux] = useState([]);
  const [dataBarDisAux, setDataBarDisAux] = useState([]);
  const [optionSelect, setOptionSelect] = useState([]);
  const [dataDonutTypeMov, setDataDonutTypeMov] = useState([]);
  const [dataLabelDonutMov, setDataLabelDonutMov] = useState([]);
  const [dataDonutTypeVenta, setDataDonutTypeVenta] = useState([]);
  const [dataLabelDonutVenta, setDataLabelDonutVenta] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");
  const [optionTypeMov, setOptionTypeMov] = useState("Obsoleto");
  const [parteSwitch, setParteSwitch] = useState();

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dataChartHerDistri, setDataChartHerDistri] = useState([]);
  const [dataLabelsChartDis, setDataLabelsChartDis] = useState([]);
  const [leyend, setLeyend] = useState([]);
  const [parte, setParte] = useState(0);
  const [numeroTotal, setNumeroTotal] = useState('')
  const [numTotal, setNumTotal] = useState('')
  const [numTotalTypMov, setNumTotalTypMov] = useState('')
  const [labelsBarPieces, setLabelsBarPieces] = useState([])

  // const [dataCompare, setDataCompare] = useState([]);

  const [dataCompareModal, setDataCompareModal] = useState([]);
  const [columnsTableModal, setColumnsTableModal] = useState([]);

  const [styleProgBar, setStyleProgBar] = React.useState({
    opacity: 1,
    width: `20%`
  });
  const [title, setTitle] = useState("Cargando elemento. \n Por favor espera");
  const [progress, setProgress] = useState("20");
  const [level, setLevel] = useState(0)
  const [showWindow, setShowWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countLoader, setCountLoader] = useState(0);

  // const initialState = {
  //   styleProgBar: {
  //     opacity: 1,
  //     width: `20%`
  //   },
  //   title: "Cargando elemento. \n Por favor espera",
  //   progress: "20",
  // };

  // const [
  //   { styleProgBar, title, progress },
  //   setState
  // ] = useState(initialState);

  // const clearState = () => {
  //   setState({ ...initialState });
  // };


  var e = localStorage.getItem("e");


  // //console.log(e);

  // var email = localStorage.getItem("e");
  // //console.log(email);
  // queries iniales para llenar graficas y tablas
  useQuery(GET_BEST_DIS_PRO_FITS, {
    fetchPolicy: "no-cache",
    onCompleted({ getBestDisProfits }) {
      const bestDisProfits = getBestDisProfits.map(
        ({ total_amount, distribuidor }) => {
          return {
            label: distribuidor,
            backgroundColor: "#80BDDD",
            borderColor: "#026ba1",
            borderRadius: 5,
            borderWidth: 1,
            hoverBackgroundColor: "#026ba1",
            /*  hoverBorderColor: 'rgba(255,99,132,1)', */
            data: [total_amount],
          };
        }
      );

      // setDataBarDis([totalGanancias3, totalGanancias1, totalGanancias2])
      setDataBarDis(bestDisProfits);
      setDataBarDisAux(bestDisProfits);
      setCountLoader((count) => count + 1)
    },
  });
  useQuery(GET_BEST_SELL_PART, {
    fetchPolicy: "no-cache",
    onCompleted({ getBestSellPart }) {
      let response = JSON.parse(getBestSellPart);
      let arrayPiezas = [];            
      let labels = [];    
      let disFilter = []        
      response.forEach(obj => {
          arrayPiezas.push(obj.celda_M)
          labels.push(obj.parte)         
      });
      disFilter=[...labels]
      disFilter.map((obj,i) => {
          if (obj == '<NULL>'){
              disFilter[i]='OTROS'
          }
              disFilter.push()
          
              
      })
      setDataBarPieces(arrayPiezas)
      setLabelsBarPieces(disFilter)
      setDataBarPiecesAux(response)
      setCountLoader((count) => count + 1)
    },
  });
  /* useQuery(GET_ABC_NUMBER, {
    variables: {
      e: e,
    },
    fetchPolicy: "no-cache",
    onCompleted({ getABCNumber }) {
      debugger
      let count = []
      getABCNumber?.forEach((obj) => {
          return (
              count.push(obj.count)
          )
      })
      const suma = count.reduce((prev, next) => prev + next)
      setNumeroTotal(parseInt(suma))  
      setDataDonut(count)
  }
  }); */

  const [getPotObsoletos] = useLazyQuery(GET_POT_OBSOLETOS, {
    variables: {
      tipMov: "Potencial obsoleto",
      email: e,
    },
    fetchPolicy: "no-cache",
    onCompleted({ getPotObsoletos }) {
      setDataAux(getPotObsoletos);
      setDataTable(getPotObsoletos);
      setCountLoader((count) => count + 1)
    },
    onError(err) {
      //console.log(err);
    },
  });
  // querie para llenar filtro por distribuidor
  useQuery(GET_ALL_DISTRIBUTORS, {
    fetchPolicy: "no-cache",
    onCompleted({ getAllDistributors }) {
      setOptionSelect(getAllDistributors);
    },
  });
  // querie para filtrar grafica de dona
  const [getABCDistribuidor] = useLazyQuery(GET_ABCD_DISTRIBUIDOR, {
    variables: {
      e: e,
    },
    fetchPolicy: "no-cache",
    onCompleted({ getABCDistributor }) {
      let count = []
      getABCDistributor?.forEach((obj) => {
        return (
          count.push(obj.count)
        )
      })
      setDataDonut(count)
      const suma = count.reduce((prev, next) => prev + next,0)
      setNumeroTotal(parseInt(suma))
      setCountLoader((count) => count + 1)
    }, onError(err) {
      //console.log(err)
    }
  });
  //QUERY PARA GRAFICA DE TIPO DE MOVIENTO
  // useQuery(GET_MOV_NUMBER, {
  //     variables: {
  //         e: e
  //     },

  //     fetchPolicy: 'no-cache',
  //     onCompleted({ getMovNumber }) {
  //         //let labels = []
  //         const estancado = getMovNumber[0]?.count || 0
  //         const inactivo = getMovNumber[1]?.count || 0
  //         const lento = getMovNumber[2]?.count || 0
  //         //const obsoleto = getMovNumber[3]?.count || 0
  //         const potencial_obsoleto = getMovNumber[3]?.count || 0
  //         const rapido = getMovNumber[4]?.count || 0

  //         setDataLabelDonutMov(['Estancado', 'Inactivo', 'Lento', 'Potencial obsoleto', 'Rápido']);
  //         setDataDonutTypeMov([estancado, inactivo, lento, potencial_obsoleto, rapido])
  //     }
  // })
  // const [getMovDistributor] = useLazyQuery(GET_MOV_DISTRIBUTOR, {
  //   variables: {
  //     e: e,
  //   },

  //   fetchPolicy: "no-cache",
  //   onCompleted({ getMovDistributor }) {
  //     // let labels = []
  //     // let datCount = []
  //     // getMovDistributor.forEach((obj) => {
  //     //     return (
  //     //         datCount.push(obj.count),
  //     //         labels.push(obj.tipo_movimiento)
  //     //     )

  //     // })
  //     debugger;
  //     //console.log("claves", Object.keys(getMovDistributor));
  //     let labels = Object.keys(getMovDistributor[0]);
  //     let datCount = Object.values(getMovDistributor[0]);
  //     setDataLabelDonutMov(labels);
  //     setDataDonutTypeMov(datCount);
  //     // //console.log('datCount', datCount)
  //     // setDataLabelDonutMov(labels);
  //     // setDataDonutTypeMov(datCount)
  //     // //console.log('dataDonutTypeMov', dataDonutTypeMov)
  //   },
  //   onError(err) {
  //     //console.log(err);
  //   },
  // });

  // const [getTypeSaleDistributor] = useLazyQuery(GET_TYPE_SALE_DISTRIBUTOR, {
  //   variables: {
  //     e: e,
  //   },
  //   fetchPolicy: "no-cache",
  //   onCompleted({ getTypeSaleDistributor }) {
  //     let labels = [];
  //     const hojalateriaPintura = getTypeSaleDistributor[0]?.count || 0;
  //     const taller = getTypeSaleDistributor[1]?.count || 0;
  //     const ventanilla = getTypeSaleDistributor[2]?.count || 0;
  //     getTypeSaleDistributor.forEach((obj) => {
  //       return labels.push(obj.tipo_venta);
  //     });
  //     setDataLabelDonutVenta(labels);
  //     setDataDonutTypeVenta([hojalateriaPintura, taller, ventanilla]);
  //   },
  //   onError(err) {
  //     //console.log(err);
  //   },
  // });

  const [moveToMarketplace] = useMutation(MOVE_TO_MARKETPLACE, {
    refetchQueries: [
      GET_POT_OBSOLETOS, // DocumentNode object parsed with gql
      "getPotObsoletos", // Query name
    ],

    // fetchPolicy: 'no-cache',
    onCompleted({ moveToMarketplace }) {
      //console.log("correcto");
      // progressfx()
      //console.log(level);
      // setLevel(10)
      // const timer = setInterval(() =>{
      //   setLevel((newLevel) => newLevel >= 100 ? 0 : newLevel + 10)
      //   //console.log(level);

      // }, 500);
      // const [title, setTitle] = useState("Cargando elemento. \n Por favor espera");
      // const [progress, setProgress] = useState("20");
      const newStyle = {
        opacity: 1,
        width: `100%`
      }
      // setState( { [styleProgBar]: newStyle });
      // setState( { [progress]: "100" });
      // setState( { [title]: "Carga exitosa" });

      // setState((prevState) => ({ ...prevState, [styleProgBar]: newStyle }));
      // setState((prevState) => ({ ...prevState, [progress]: "100" }));
      // setState((prevState) => ({ ...prevState, [title]: "Carga exitosa" }));


      setStyleProgBar(newStyle);
      setProgress("100")
      setTitle("Carga exitosa")      
      // handleCloses()
      // setTimeout(() => {
      //   handleCloses() 

      // }, 2000);

      // clearState();



    },
    onError(err) {
      //console.log(err);
    },
    // onError: (error) =>
    //   alert(
    //     `Resource Not Updated: ${error.message.split("GraphQL error: ")[1]}`
    //   ),
  });

  const [getCompareMovType] = useLazyQuery(GET_COMPARE_MOV_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted({ getCompareMovType }) {
      //debugger;
      ////console.log(getCompareMovType, 'getCompareMovType')
      //   setDataCompare(getCompareMovType);
      setDataCompareModal(getCompareMovType);
      setColumnsTableModal([
        { title: "Parte", field: "parte" },
        { title: "Distribuidor", field: "distribuidor" },
        { title: "Tipo de movimento", field: "tipo_movimiento" },
        { title: "Piezas disponibles", field: "numPartesDisponibles" },
      ]);
      let labels = [];
      let leyendTypeMov = [];
      const piezasDis1 = getCompareMovType[0]?.numPartesDisponibles || 0;
      const piezasDis2 = getCompareMovType[1]?.numPartesDisponibles || 0;
      const piezasDis3 = getCompareMovType[2]?.numPartesDisponibles || 0;
      getCompareMovType.forEach((obj) => {
        return (
          labels.push(obj.distribuidor), leyendTypeMov.push(obj.tipo_movimiento)
        );
      });
      setLeyend(leyendTypeMov);
      setDataLabelsChartDis(labels);
      setDataChartHerDistri([piezasDis1, piezasDis2, piezasDis3]);
      setCountLoader((count) => count + 1)
    },
    onError(err) {
      //console.log(err);
    },
  });

  // const filterByDistribuidor = ({ target }) => {
  //     const { value } = target;

  //     //console.log(value)
  //     if (value === "") {
  //         setDataTable(dataAux)
  //         let sum4 = 0
  //         let sum5 = 0
  //         dataBarPiecesAux.forEach(obj => {
  //             if (obj.id_parte === 4) {
  //                 sum4 += obj.celda_M
  //             } else if (obj.id_parte === 5) {
  //                 sum5 += obj.celda_M
  //             }
  //         });
  //         setDataBarPieces([sum4, sum5])
  //         setDataBarDis(dataBarDisAux)
  //     } else {
  //         const typeDisSplit = String(value).split('_');
  //         sessionStorage.setItem('auxEmail', typeDisSplit[1])
  //         const byDistribuidor =
  //             dataAux.filter(item => item.distribuidor === typeDisSplit[0])
  //         const byDisBarPieces =
  //             dataBarPiecesAux.filter(item => item.distribuidor === typeDisSplit[0])
  //         const byDataBarDis =
  //             dataBarDisAux.filter(item => item.label === typeDisSplit[0])

  //         setDataTable(byDistribuidor)
  //         let sum4 = 0
  //         let sum5 = 0
  //         byDisBarPieces.forEach(obj => {
  //             if (obj.id_parte === 4) {
  //                 sum4 += obj.celda_M
  //             } else if (obj.id_parte === 5) {
  //                 sum5 += obj.celda_M
  //             }
  //         });
  //         setDataBarPieces([sum4, sum5])

  //         setDataBarDis(byDataBarDis)
  //         getABCDistribuidor({
  //             variables: {
  //                 e: typeDisSplit[1]
  //             }
  //         })
  //         getMovDistributor({
  //             variables: {
  //                 e: typeDisSplit[1]
  //             }
  //         })
  //         getPotObsoletos({
  //             variables: {
  //                 email: typeDisSplit[1]

  //             }
  //         })

  //     }

  // }

  const options = [
    { value: "Obsoleto", label: "Obsoletos" },
    { value: "Potencial obsoleto", label: "Potencialmente obsoletos" },
  ];

  const filterByTypMove = ({ target }) => {
    setOptionTypeMov(target.value);
    // const email = sessionStorage.getItem('auxEmail')
    getPotObsoletos({
      variables: {
        tipMov: target.value,
        email: e,
      },
    });
  };

  // const [optionSelectedByPotObs, setOptionSelectedByPotObs] = useState('')

  // function handleAddrTypeChange(e) {
  //     setAddrType(e.target.value);
  //     //console.log(addrtype)
  // }


  //QUERY PARA GRAFICA DE TIPO DE MOVIENTO
  // useQuery(GET_MOV_NUMBER, {
  //     variables: {
  //         e: e
  //     },

  //     fetchPolicy: 'no-cache',
  //     onCompleted({ getMovNumber }) {
  //         //let labels = []   
  //         const estancado = getMovNumber[0]?.count || 0
  //         const inactivo = getMovNumber[1]?.count || 0
  //         const lento = getMovNumber[2]?.count || 0
  //         //const obsoleto = getMovNumber[3]?.count || 0
  //         const potencial_obsoleto = getMovNumber[3]?.count || 0
  //         const rapido = getMovNumber[4]?.count || 0

  //         setDataLabelDonutMov(['Estancado', 'Inactivo', 'Lento', 'Potencial obsoleto', 'Rápido']);
  //         setDataDonutTypeMov([estancado, inactivo, lento, potencial_obsoleto, rapido])
  //     }
  // })
  const [getMovDistributor] = useLazyQuery(GET_MOV_DISTRIBUTOR, {
    variables: {
      e: e
    },

    fetchPolicy: 'no-cache',
    onCompleted({ getMovDistributor }) {
      let disFilter = []
      let filterCount = []
      //console.log('claves', Object.keys(getMovDistributor))
      let labels = Object.keys(getMovDistributor[0])
      let datCount = Object.values(getMovDistributor[0])
      labels.map((obj) => {
        if (obj !== '__typename')
          disFilter.push(obj)
      })
      filterCount = datCount.filter(obj => obj !== 'MovData')

      setDataLabelDonutMov(disFilter);
      setDataDonutTypeMov(datCount);
      const suma = filterCount.reduce((prev, next) => prev + next,0)
      setNumTotalTypMov(parseInt(suma))
      setCountLoader((count) => count + 1)
    }, onError(err) {
      //console.log(err)
    }
  })

  const [getTypeSaleDistributor] = useLazyQuery(GET_TYPE_SALE_DISTRIBUTOR, {
    variables: {
      e: e
    },
    fetchPolicy: 'no-cache',
    onCompleted({ getTypeSaleDistributor }) {
      let labels = []
      let count = []
      getTypeSaleDistributor?.forEach((obj) => {
        return (
          labels.push(obj.tipo_venta),
          count.push(obj.count)
        )

      })
      const suma = count.reduce((prev, next) => prev + next)
      setNumTotal(parseInt(suma))
      setDataLabelDonutVenta(labels);
      setDataDonutTypeVenta(count);
      setCountLoader((count) => count + 1)
    }, onError(err) {
      //console.log(err)
    }

  })

  const changeStatusSwich = (event) => {
    setParteSwitch(event.target.value);
    //console.log(event.target.value);

    // const idparte = event.target.value
    // //console.log(id_parte);
    handleOpens();
    // progressfx()


    moveToMarketplace({
      variables: {
        e: e,
        id_parte: event.target.value,
      },
    });

    getPotObsoletos({
      variables: {
        tipMov: optionTypeMov,
        email: e,
      },
    });

    setTimeout(() => {
      handleCloses()

    }, 1500);

    setStyleProgBar({
      opacity: 1,
      width: `20%`
    });
    setProgress("20")
    setTitle("Cargando elemento. \n Por favor espera")


  };

  const handleTool = (id_parte) => {
    setParte(id_parte);
    getCompareMovType({
      variables: {
        idParte: parseInt(id_parte),
        group: 1,
      },
    });
    handleOpen();
  };

  // const styleFuncion = () =>{
  //   const newStyle = {
  //     		opacity: 1,
  //     		width: `${progress}%`
  //     	}

  //     	setStyleProgBar(newStyle);

  // }

  // setTimeout(() => {
  // 	const newStyle = {
  // 		opacity: 1,
  // 		width: `${progress}%`
  // 	}

  // 	setStyleProgBar(newStyle);
  // }, 200);


  useEffect(() => {
    //console.log('counter', countLoader)
    if (countLoader >= 5) {
      setIsLoading(false)
    }
  }, [countLoader])
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }

    getABCDistribuidor({
      variables: {
        e: e,
      },
    });
    getMovDistributor({
      variables: {
        e: e,
      },
    });
    getTypeSaleDistributor({
      variables: {
        e: e,
      },
    });
    getPotObsoletos({
      variables: {
        tipMov: "Obsoleto",
        email: e,
      },
    });
    // moveToMarketplace({
    //   variables: {
    //     e: e,
    //   },
    // });
  }, []);


  // const [styleProgBar, setStyleProgBar] = React.useState({});
  // const [title, setTitle] = useState("Cargando elemento. \n Por favor espera");
  // const [progress, setProgress] = useState("20");

  // useEffect(() => {
  //       setProgress("20")
  // }, []);

  // useEffect(() => {
  //       setTitle("Cargando elemento. \n Por favor espera")

  // }, []);

  // useEffect(() => {
  //     const newStyle = {
  //       opacity: 1,
  //       width: `20%`
  //     }
  //       setStyleProgBar(newStyle);

  // }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",


    boxShadow: 24,
    p: 5,
  };

  // function progressfx(){
  //   const timer = setInterval(() =>{
  //     setLevel((newLevel) => newLevel >= 100 ? 0 : newLevel + 25)
  //     //console.log(level);

  //   }, 500);

  // }
  if (isLoading) {
    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress disableShrink size={100} />
        </div>
    )
}

  return (
    <div className="chartContainer">
      <p
        style={{ color: '#02274190', fontWeight: 'bold', fontSize: 25, fontStyle: 'normal', font: 'Roboto', lineHeight: '140%', top: 118 }}
      >DASHBOARD DISTRIBUIDOR
      </p>
      {/* <br />
            <FilterByDistributor
                filterByDistribuidor={filterByDistribuidor}
                optionSelect={optionSelect}
            /> */}
      <div>
        <Modal
          open={opens}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleCloses();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles}>
            <div >
              <h2 style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>{title}</h2>
            </div>
            <div style={{ marginLeft: "auto", marginRight: "auto" }} className="progress">
              <div className="progress-done" style={styleProgBar}>
                {progress}%
              </div>
            </div>
            {/* <div>
              <LinearProgress variant='determinate' value={level} style={{width:200}} />
            </div> */}
          </Box>
        </Modal>
      </div>
      {showWindow ?
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            marginBottom: "4%",
            justifyItems: "start",
            marginTop: "2%",
            height: "50%",
          }}
        >
          {dataDonut.length > 0 && (
            <ChartDealers
              height="100%"
              dataDonut={dataDonut}
              numeroToTal={numeroTotal}
              handleClick={(data, item) => {
                sessionStorage.setItem("chart", "Porcentaje ABC");
                const index = item[0]?.index;
                const value = data.datasets[0].data[index];
                const label = data.labels[index];
                if (label === "Elemento A")
                  history.push(`/dashboardMovimientosDis?mov=A&&chart=AnalisisABC`);
                else if (label === "Elemento B")
                  history.push(`/dashboardMovimientosDis?mov=B&&chart=AnalisisABC`);
                else if (label === "Elemento C")
                  history.push(`/dashboardMovimientosDis?mov=C&&chart=AnalisisABC`);
              }}
            />
          )}
          {dataDonutTypeMov.length > 0 && (
            <ChartDealers
              title="Tipo de movimiento"
              height="100%"
              backgroundColor={[
                "#03a9f4",
                "#cddc39",
                "#ff5722",
                "#e91e63",
                "#009688",
                "#6394b8",
              ]}
              dataDonut={dataDonutTypeMov}
              labels={dataLabelDonutMov}
              numeroToTal={numTotalTypMov}
              handleClick={(data, item) => {
                sessionStorage.setItem("chart", "Tipo de Movimiento");
                const index = item[0]?.index;
                const value = data.datasets[0].data[index];
                const label = data.labels[index];
                history.push(`/dashboardMovimientosDis?mov=${label}&&chart=TypeMov`);
              }}
            />
          )}
        </div>
        :
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            marginBottom: '4%',
            justifyItems: 'start',
            marginTop: '2%',
            height: '50%',
          }}
        >
          {dataDonut.length > 0 && (
            <ChartDealers
              height='262px'
              weight='335px'
              num={3.8}
              dataDonut={dataDonut}
              numeroToTal={numeroTotal}
              handleClick={(data, item) => {
                sessionStorage.setItem("chart", "Porcentaje ABC");
                const index = item[0]?.index;
                const value = data.datasets[0].data[index];
                const label = data.labels[index];
                if (label === "Elemento A")
                  history.push(`/dashboardMovimientosDis?mov=A&&chart=AnalisisABC`);
                else if (label === "Elemento B")
                  history.push(`/dashboardMovimientosDis?mov=B&&chart=AnalisisABC`);
                else if (label === "Elemento C")
                  history.push(`/dashboardMovimientosDis?mov=C&&chart=AnalisisABC`);
              }}
            />
          )
          }
          <div style={{ height: '5%', padding: '4%' }}> </div>

          {dataDonutTypeMov.length > 0 && (
            <ChartDealers
              title="Tipo de movimiento"
              height='262px'
              weight='335px'
              backgroundColor={[
                "#03a9f4",
                "#cddc39",
                "#ff5722",
                "#e91e63",
                "#009688",
                "#6394b8",
              ]}
              num={4.5}
              dataDonut={dataDonutTypeMov}
              labels={dataLabelDonutMov}
              numeroToTal={numTotalTypMov}
              handleClick={(data, item) => {
                sessionStorage.setItem("chart", "Tipo de Movimiento");
                const index = item[0]?.index;
                const value = data.datasets[0].data[index];
                const label = data.labels[index];
                history.push(`/dashboardMovimientosDis?mov=${label}&&chart=TypeMov`);
              }}
            />
          )}
        </div>
      }
      {showWindow ?
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr ",
            marginBottom: "4%",
            justifyItems: "start",
            marginTop: "2%",
            height: "50%",
          }}
        >
          {dataDonutTypeMov.length > 0 && (
            <ChartDealers
              title="Tipo de venta"
              height="100%"
              backgroundColor={["#e75226", "#39dcc5", "#46aaf2"]}
              dataDonut={dataDonutTypeVenta}
              labels={dataLabelDonutVenta}
              numeroToTal={numTotal}
              handleClick={(data, item) => {
                //console.log("data", data);
                sessionStorage.setItem("chart", "Tipo de Venta");
                const index = item[0]?.index;
                const value = data.datasets[0].data[index];
                const label = data.labels[index];
                {
                  label === "Hojalatería y pintura"
                    ? history.push(`/dashboardMovimientosDis?mov=HyP&&chart=TypeVenta`)
                    : history.push(`/dashboardMovimientosDis?mov=${label}&&chart=TypeVenta`);
                }
              }}
            />
          )}
          <ChartBestSellinngsPieces height={'100%'} width="90%" dataBarPieces={dataBarPieces} labels={labelsBarPieces}/>
        </div>
        :
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            marginBottom: '4%',
            justifyItems: 'start',
            marginTop: '67%',
            height: '50%',
          }}
        >
          {dataDonutTypeMov.length > 0 && (
            <ChartDealers
              title="Tipo de venta"
              height='265px'
              weight='335px'
              num={4.5}
              backgroundColor={["#e75226", "#39dcc5", "#46aaf2"]}
              dataDonut={dataDonutTypeVenta}
              labels={dataLabelDonutVenta}
              numeroToTal={numTotal}
              handleClick={(data, item) => {
                //console.log("data", data);
                sessionStorage.setItem("chart", "Tipo de Venta");
                const index = item[0]?.index;
                const value = data.datasets[0].data[index];
                const label = data.labels[index];
                {
                  label === "Hojalatería y pintura"
                    ? history.push(`/dashboardMovimientosDis?mov=HyP&&chart=TypeVenta`)
                    : history.push(`/dashboardMovimientosDis?mov=${label}&&chart=TypeVenta`);
                }
              }}
            />
          )
          }
          <div style={{ height: '5%', padding: '4%' }}> </div>
          <ChartBestSellinngsPieces height={'265px'} width={'335px'} dataBarPieces={dataBarPieces} labels={labelsBarPieces} />
        </div>
      }
      {/* <PieChartAbC dataBarDis={dataBarDis} /> */}
      {showWindow ?
        <div
          style={{ display: "flex", justifyContent: "left", marginBottom: "2%" }}
        >
          <select
            style={{ width: 300, borderRadius: 5, padding: 5 }}
            value={optionTypeMov}
            onChange={(e) => filterByTypMove(e)}
          >
            {/* <option selected value=''>Elige un tipo de movimiento</option> */}
            {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        :
        <>
          <div style={{ height: '5%', padding: '4%' }}> </div>
          <div className='filter'>
            <select
              style={{ width: 300, borderRadius: 5, padding: 5 }}
              value={optionTypeMov}
              onChange={(e) => filterByTypMove(e)}
            >
              {/* <option selected value=''>Elige un tipo de movimiento</option> */}
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </>

      }

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <div style={{ display: 'Flex' }}>
            <div style={{ width: '70%' }}>
              <Title style={{ fontSize: 18, fontWeight: 700, color: "#022741", }}>Comparar con distribuidor hermano</Title>
            </div>
          </div>
          <ChartBestSellinngsPieces 
          width='90%' 
          title={`No.Parte: ${parte}`}
          dataBarPieces={dataChartHerDistri} 
          labels={dataLabelsChartDis} 
          indexAxis="y"
          boxShadow="0"
          borderRadius={50}
          label={'piezas disponibles'}/>
          <div className="tContainer" style={{ width: '100%', marginTop:185 }}>
          <table className="table table-striped tableContainer">
            <thead className="sticky">
              <tr className="sticky">
                <th>Parte</th>
                <th>Distribuidor</th>
                <th>Tipo de movimento</th>
                <th>Piezas disponibles</th>
              </tr>
            </thead>
            <tbody>
              {dataCompare.map(({
                parte,
                distribuidor,
                tipo_movimiento,
                numPartesDisponibles
              }) => (
                <tr style={{ background: '#EAF0F6' }} >
                  <td>{parte}</td>
                  <td>{distribuidor}</td>
                  <td>{tipo_movimiento}</td>
                  <td>{numPartesDisponibles}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </Box>
      </Modal> */}

      <GenericModalCompareDis
        dataChartHerDistri={dataChartHerDistri}
        dataLabelsChartDis={dataLabelsChartDis}
        open={open}
        handleClose={handleClose}
        dataCompareModal={dataCompareModal}
        columnsTableModal={columnsTableModal}
        parte={parte}
      />
      <div className="d-flex bottomContainerClient">
        <div className="tContainer" style={{ width: "100%" }}>
          <table className="table table-striped tableContainer">
            <thead className="sticky">
              <tr className="sticky">
                <th>No. parte</th>
                <th>Descripción</th>
                <th>Tipo de movimiento</th>
                <th>Distribuidor</th>
                <th style={{ textAlign: "center" }}>Enviar a Market Place</th>
                <th>Acción</th>)
              </tr>
            </thead>
            <tbody>
              {dataTable?.map(
                ({
                  id_parte,
                  Name,
                  tipo_movimiento,
                  distribuidor,
                  statusMarket,
                }) => (
                  <tr style={{ background: "#EAF0F6" }}>
                    <td>{id_parte}</td>
                    <td>{Name}</td>
                    <td>{tipo_movimiento}</td>
                    <td>{distribuidor}</td>
                    <td style={{ textAlign: "center" }}>
                      <Form>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          key={id_parte}
                          value={id_parte}
                          onChange={(event) => changeStatusSwich(event)}
                          checked={statusMarket}
                        />
                      </Form>
                    </td>
                    <td>
                      <Tooltip
                        title="Comparar con otro distribuidor"
                        style={{ cursor: "pointer" }}
                      >
                        <HiDotsVertical onClick={() => handleTool(id_parte)} />
                      </Tooltip>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <StatsTable
                dataTable={dataTable}
            /> */}
    </div>
  );
};

export default Distribuidor;
