import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    }
  },
};

const names = [
  'Utilidad',
  'Número de días para la venta',
  'Número de días de surtido  ',
  'Costo de almacenaje',
];

const PartFilter = ({ handleChange, filterOption }) => {



  return (
    <div style={{marginLeft:'7%'}}>
      <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-multiple-checkbox-label">...</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={filterOption}
          onChange={handleChange}
          input={<OutlinedInput label="filtroModal" />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={filterOption.includes(name)} style={{  marginInline: '-30px',pointerEvents: 'none'}} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default PartFilter;