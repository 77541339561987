import React, { Fragment } from "react";

import LoginForm from "./Form";
import Footer from "../Footer";
import "../../styles/Login/style.scss";

class LoginDistributor extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="main">
          <LoginForm />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default LoginDistributor;
