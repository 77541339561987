/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import '../Modals/styles.css';

import { Boton, Sections, Inline } from '../Modals/styles';
import TableResults from "../Ecommerce/tableResults"
import Button from '@material-ui/core/Button';

export default function SimpleModal(props) {
  const [backgroundBtn, setBackgroundBtn] = useState('#56A8D2');
  const [stateBtn, setStateBtn] = useState(false);
  const [stateBtnOrder, setStateBtnOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [description, setDescription] = useState('');
  const handleClose = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  const fileUpload = () => {
    const file = document.getElementsByName('fileUpload')[0].files[0];
    if (file !== undefined && description !== '') {
      setBackgroundBtn('#027CBB');
      setStateBtn(true);
      setShowTable(false);
    }
  }

  const updateDescription = (e) => {
    setDescription(e.target.value);
  }

  const hiddenExamples = () => {
    setLoading(false);
    setShowTable(true);
    setStateBtn(false);
    setStateBtnOrder(true);
  }

  const searchData = () => {
    setLoading(true);
    setTimeout(() => {
      hiddenExamples()
    }, 3000);
  }

  return (
    <Modal
      show={props.open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={props.class} style={{ width: '105%' }}>
        <div className="titleModal">Buscar sin vehículo</div>
        <div className="containerSections">
          <Sections >
            <div className='titleSections'>Para dar de alta una pedido necesitarás cargar un archivo en formato de Excel y anotar su descripción. </div>
            <Form.Group controlId="formFile" className="mb-3" style={{ marginBottom: 15 }}>
              {/*  <Form.Control type="file" name='fileUpload' onChange={fileUpload} /> */}
              <Inline
              >
                <div
                  fullWidth
                  style={{
                    borderColor: '#E0E0E7',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: 2,
                    width:'-webkit-fill-available',
                    color:'#00000050'
                  }}
                //onClick={() => upload()}
                >
                  No se ha elegido un archivo
                </div>
                <button
                  style={{
                    height: '41px',
                    color: "#fff",
                    background: "#56A7D2 ",
                    borderRadius: 2,
                    cursor: 'pointer',
                    backgroundHover: "#56A8D2",
                    fontWeight: 500,
                    fontSize: '14px',
                    border:'none',
                    width: '74%',
                    
                  }}
                  id="uploadImages"
                  variant="contained"
                  component="label"
                >
                  Seleccionar archivo
                  <input
                    type="file"
                    name="name"
                    // ref={fileInput}
                    style={{ display: 'none' }}
                    onChange={fileUpload}
                  />
                </button>


              </Inline>
            </Form.Group>
            <Inline>
              <Form.Group className="mb-3" controlId="descriptionFile">
                <Form.Control type="text" placeholder="Descripción" style={{ height: 41 }} onChange={fileUpload} value={description} onInput={updateDescription} />
              </Form.Group>
              <div>
                {stateBtn ? <Boton color="#fff" background={backgroundBtn} cursor='pointer' backgroundHover="#03487d" border="none" onClick={searchData}>Cargar archivo</Boton> : <Boton color="#fff" background="#56A7D2 " cursor='text' backgroundHover="#56A8D2" border="none" >Cargar archivo</Boton>}
              </div>
            </Inline>
          </Sections>
          <Sections style={{ height: 'fit-content' }}>
            <div className='titleSections' style={{ height: '2px' }}>Descarga el formato para realizar el alta de los pedidos.</div>
            <div
              className="containerBtnDowload"
              style={{
                color: '#027CBB',
                marginTop: 70,
                marginBottom: 5,
                width: '152px',
                height: '42px',
                boxSizing: 'border-box',
                borderRadius: '2px',
                fontWeight: 700,
                fontSize: '14px',
                marginLeft: 172
              }}>
              <Boton >Descargar formato</Boton>
            </div>
          </Sections>
        </div>
        <div className="containerBtnDowload" style={{ marginBottom: 30 }}>
          {stateBtnOrder ? <Boton color="#fff" background={'#56A7D2'} cursor='pointer' backgroundHover="#03487d" border="none">Realizar pedido</Boton> : <Boton color="#fff" background="#56A8D2" cursor='text' backgroundHover="#56A8D2" border="none" >Realizar pedido</Boton>}
        </div>
        <div className="containerSections">
          <Sections width="100%">
            <div className="titleSections" style={{ fontWeight: "bold", height: 'auto' }}>Productos encontrados</div>
            {loading ? <div style={{ textAlign: "center" }}><Spinner animation="border" variant="primary" /></div> : null}
            {showTable ? <TableResults /> : null}

          </Sections>
        </div>
      </Modal.Body>
    </Modal>
  );
}
