import React, { useState, useEffect } from 'react';
import NavComp from "../Navbar/index";
import {
    GET_MOV_ABCT_YPE_SALE,
    GET_DAY_DIFF,
    GET_UTILIDAD,
    GET_DAY_DIFF_SURTIDO,
    GET_COSTO_ALMACENAJE,
    GET_ALL_PRODUCTS,
    GET_COMPARE_MOV_TYPE

} from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
// import Modal from '@material-ui/core/Modal'
import { Box, Modal, Typography } from '@material-ui/core';
import Title from 'antd/lib/typography/Title';
import LeftAside from './leftAside';
import PartFilter from '../Dashboard/PartFilter';
import GenericModalCompareDis from '../Dashboard/GenericModalCompareDis';
import { HiDotsVertical } from "react-icons/hi";
import { GenericTable } from '../Dashboard/GenericTable';
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
    height: 500
};
const TableMov = () => {
    const [dataTable, setDataTable] = useState([])
    const [columnsTableModal, setColumnsTableModal] = useState([])
    const [dataTableModal, setDataTableModal] = useState([])
    const [partName, setPartName] = useState('')
    const [IdParte, setIdParte] = useState(0)
    const [labelMov, setLabelMov] = useState('')
    const [optionToll, setOptionToll] = useState('')
    const [idGroup, setIdGroup] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [openCompare, setOpenCompare] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setFilterOption("");


    }
    const handleOpenCompareDis = () => setOpenCompare(true);
    const handleCloseCompareDis = () => setOpenCompare(false);



    const [filterOption, setFilterOption] = useState('');
    const [dataCompareModal, setDataCompareModal] = useState([]);

    const [leyend, setLeyend] = useState([])
    const [dataChartHerDistri, setDataChartHerDistri] = useState([])
    const [dataLabelsChartDis, setDataLabelsChartDis] = useState([])
    const [showWindow, setShowWindow] = useState(false);

    var e = localStorage.getItem("e");

    //Grafica comparar con hermano distribuidor
    const [getCompareMovType] = useLazyQuery(GET_COMPARE_MOV_TYPE, {
        fetchPolicy: 'no-cache',
        onCompleted({ getCompareMovType }) {
            //debugger;
            ////console.log(getCompareMovType, 'getCompareMovType')
            setDataCompareModal(getCompareMovType);
            setColumnsTableModal([
                { title: 'PARTE', field: 'parte' },
                { title: 'DISTRIBUIDOR', field: 'distribuidor' },
                { title: 'TIPO DE MOVIMIENTO', field: 'tipo_movimiento' },
                { title: 'PIEZAS DISPONIBLES', field: 'numPartesDisponibles' },
            ])
            let labels = []
            let leyendTypeMov = []
            const piezasDis1 = getCompareMovType[0]?.numPartesDisponibles || 0
            const piezasDis2 = getCompareMovType[1]?.numPartesDisponibles || 0
            const piezasDis3 = getCompareMovType[2]?.numPartesDisponibles || 0
            getCompareMovType.forEach((obj) => {
                return (
                    labels.push(obj.distribuidor),
                    leyendTypeMov.push(obj.tipo_movimiento)
                )
            })
            setLeyend(leyendTypeMov)
            setDataLabelsChartDis(labels);
            setDataChartHerDistri([piezasDis1, piezasDis2, piezasDis3])

        },
        onError(err) {
            //console.log(err);
        }
    })

    const [getMovABCTypeSale] = useLazyQuery(GET_MOV_ABCT_YPE_SALE, {
        fetchPolicy: 'no-cache',
        onCompleted({ getMovABCTypeSale }) {
            //console.log(getMovABCTypeSale);
            setDataTable(getMovABCTypeSale);
            setIsLoading(false);
            setLoading(false);
        },
        onError(err) {
            //console.log(err);
        }
    })



    const [getDayDiff] = useLazyQuery(GET_DAY_DIFF, {
        fetchPolicy: 'no-cache',
        onCompleted({ getDayDiff }) {
            setDataTableModal(getDayDiff)
            setColumnsTableModal([
                { title: 'ID PARTE', field: 'id_parte' },
                { title: 'PARTE', field: 'parte' },
                { title: 'FECHA ENTREGA ALMACÉN', field: 'Fecha_de_entrega_almacen' },
                { title: 'FECHA DE VENTA', field: 'Fecha_de_venta' },
                { title: 'DÍAS DE VENTA', field: 'dayDiff' },
            ])
            handleOpen()
        },
        onError(err) {
            //console.log(err);
        }
    })

    const [getUtilidad] = useLazyQuery(GET_UTILIDAD, {
        fetchPolicy: 'no-cache',
        onCompleted({ getUtilidad }) {
            setDataTableModal(getUtilidad)
            setColumnsTableModal([
                { title: 'ID PARTE', field: 'id_parte' },
                { title: 'PARTE', field: 'parte' },
                { title: 'COSTO DE PIEZA', field: 'Costo_pieza' },
                { title: 'PRECIO DE VENTA', field: 'Precio_de_Venta' },
                { title: 'UTILIDAD', field: 'Utilidad' },
                { title: 'COSTO TOTAL DE ALMACÉN', field: 'Costo_total_almacen' },
                { title: 'UTILIDAD REAL', field: 'Utilidad_Real' }
            ])

        }, onError(err) {
            //console.log(err);
        }
    })

    const [getDayDiffSurtido] = useLazyQuery(GET_DAY_DIFF_SURTIDO, {
        fetchPolicy: 'no-cache',
        onCompleted({ getDayDiffSurtido }) {
            setDataTableModal(getDayDiffSurtido)
            setColumnsTableModal([
                { title: 'ID PARTE', field: 'id_parte' },
                { title: 'PARTE', field: 'parte' },
                { title: 'FECHA ORDEN ALMACÉN', field: 'Fecha_orden_almacen' },
                { title: 'FECHA ENTREGA ALMACÉN', field: 'Fecha_de_entrega_almacen' },
                { title: 'DÍA DE SURTIDO', field: 'dayDiffSurtido' }
            ])

        }, onError(err) {
            //console.log(err);
        }
    })
    const [getCostoAlmacenaje] = useLazyQuery(GET_COSTO_ALMACENAJE, {
        fetchPolicy: 'no-cache',
        onCompleted({ getCostoAlmacenaje }) {
            setDataTableModal(getCostoAlmacenaje)
            setColumnsTableModal([
                { title: 'ID PARTE', field: 'id_parte' },
                { title: 'PARTE', field: 'parte' },
                { title: 'DÍAS EN ALMACÉN', field: 'dayDiff' },
                { title: 'COSTO DE ALMACENAJE POR SEMANA', field: 'Costo_de_almacenaje' },
                { title: 'COSTO TOTAL ALMACENAJE', field: 'Costo_total_almacen' }
            ])

        }, onError(err) {
            //console.log(err);
        }
    })

    const handleAdvanceTable = (acction) => {
        debugger
        setLoading(true);
        let numPage = 1
        if (acction == 'next') {
            numPage = page + 1

        } else {
            numPage = page - 1

        }
        setPage(numPage)
        const queryParams = new URLSearchParams(window.location.search);
        const chartType = queryParams.get("chart") != null ? queryParams.get("chart") : "";
        const mov = queryParams.get("mov") != null ? queryParams.get("mov") : "";
        if (chartType == 'TypeMov') {
            getMovABCTypeSale({
                variables: {
                    email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
                    MovABCTypeSale: mov,
                    page: page
                },
            });


        } else if (chartType == 'AnalisisABC') {
            getMovABCTypeSale({
                variables: {
                    email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
                    MovABCTypeSale: mov,
                    page: page
                },
            });
        } else if (chartType == 'TypeVenta') {
            getMovABCTypeSale({
                variables: {
                    email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
                    MovABCTypeSale: mov,
                    page: page
                },
            });
        }

    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value === 'Utilidad') {
            getUtilidad({
                variables: {
                    email: e,
                    idParte: IdParte
                }

            })
        } else if (value === 'Número de días de surtido  ') {
            getDayDiffSurtido({
                variables: {
                    email: e,
                    idParte: IdParte
                }
            })
        } else if (value === 'Costo de almacenaje') {
            getCostoAlmacenaje({
                variables: {
                    email: e,
                    idParte: IdParte
                }
            })
        } else if (value === 'Número de días para la venta') {
            getDayDiff({
                variables: {
                    email: e,
                    idParte: IdParte
                }
            })

        }

        setFilterOption(value);
    };



    const getData = (idParte, parte) => {
        setPartName(parte)
        setIdParte(idParte)
        ////console.log('parte',parte)
        getDayDiff({
            variables: {
                email: e,
                idParte: idParte
            }
        })
    }

    const handleTool = (id_parte) => {
        setIdParte(id_parte)
        getCompareMovType({
            variables: {
                idParte: parseInt(id_parte),
                group: parseInt(idGroup),
            }
        })
        handleOpenCompareDis()
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const option = queryParams.get('option') != null ? queryParams.get('option') : ''
        const mov = queryParams.get('mov') != null ? queryParams.get('mov') : ''
        const idGroup = queryParams.get('idGroup') != null ? queryParams.get('idGroup') : ''
        const sizeWindow = window.screen.width;
        if (sizeWindow >= 768) {
            setShowWindow(true);
        }
        if (mov !== '') {
            getMovABCTypeSale({
                variables: {
                    email: e,
                    MovABCTypeSale: mov
                }

            })
            setLabelMov(mov)
            ////console.log('labelMov', labelMov)
        }
        setOptionToll(option)
        setIdGroup(idGroup)
    }, [])
    if (isLoading) {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                <CircularProgress disableShrink size={100} />
            </div>
        )
    }
    return (
        <>
            <GenericModalCompareDis
                dataChartHerDistri={dataChartHerDistri}
                dataLabelsChartDis={dataLabelsChartDis}
                open={openCompare}
                handleClose={handleCloseCompareDis}
                dataCompareModal={dataCompareModal}
                columnsTableModal={columnsTableModal}
                parte={IdParte}

            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {showWindow ? <Box sx={style} style={{ height: '80%' }}>
                    <div style={{ display: 'Flex' }}>
                        <div style={{ width: '70%', marginTop: '2%' }}>
                            <Title style={{ fontSize: 18, fontWeight: 700, color: "#022741", }}>Detalle de {partName}</Title>
                        </div>
                        <PartFilter handleChange={handleChange} filterOption={filterOption} />
                    </div>
                    <GenericTable
                        columns={columnsTableModal}
                        body={dataTableModal}
                    />
                </Box>
                    :
                    <Box sx={style} style={{ width: '95%', paddingLeft: '2%', paddingRight: '2%' }}>
                        <div style={{ display: 'Flex' }}>
                            <div style={{ width: '70%', marginTop: '2%' }}>
                                <Title style={{ fontSize: 18, fontWeight: 700, color: "#022741", }}>Detalle de {partName}</Title>
                            </div>
                            <PartFilter handleChange={handleChange} filterOption={filterOption} />
                        </div>
                        <GenericTable
                            columns={columnsTableModal}
                            body={dataTableModal}
                        />
                    </Box>
                }
            </Modal>

            <NavComp />
            <div className="mainDashboard d-flex">
                {showWindow ? <LeftAside /> : null}
                <div className='mainDashboard tableMobile'>
                    <Link to={window.location.pathname === "/dashboardMovimientosDis" ? "/dashboardDis" : "/dashboard"}>
                        <Title
                            style={{
                                // padding: 5,
                                fontSize: 14,
                                fontWeight: 400,
                                marginBottom: 26,
                                color: "#027CBB",
                                height: 15
                            }}
                        > <span style={{ textTransform: 'uppercase', marginLeft: '3%', padding: '0' }}> {'DASHBOARD DISTRIBUIDOR > '} {sessionStorage.getItem('chart')}</span></Title>
                    </Link>

                    <div style={{ overflowY: 'auto', height: '80%', width: '95%', marginLeft: '3%' }}>
                       
                        <table className="table table-striped tableContainer" style={{ background: 'white', height: 'auto', width: '100%' }}>
                        <thead className="sticky">
                            <Title style={{
                                padding: 5,
                                fontSize: 18,
                                fontWeight: 700,
                                marginTop: 16,
                                color: "#022741",
                                width: 'max-content',
                            }}>{sessionStorage.getItem('chart')}</Title>
                            <Title
                                style={{
                                    padding: 5,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginBottom: 26,
                                    color: "#C4C4C4",
                                    height: 15,

                                }}
                            >  <span style={{ textTransform: 'uppercase' }}>{labelMov}</span></Title>
                            <tr className="sticky">
                                <th style={{ width: '10%' }}>ID parte</th>
                                <th>Parte</th>
                                <th>Costo de pieza</th>
                                <th>Costo fijo</th>
                                <th>Costo de almacenaje</th>
                                <th>Precio de venta</th>
                                <th>Tipo de venta</th>
                                <th>Distribuidor</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        {loading?
                        <div style={{ width: '100%', display: 'flex',marginLeft:'350%', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                        <CircularProgress disableShrink size={100} />
                      </div> 
                        :
                        <tbody>
                        {dataTable?.map(({
                            id_parte,
                            parte,
                            Costo_pieza,
                            costo_fijo_orden_de_compra,
                            Costo_de_almacenaje,
                            Precio_de_Venta,
                            tipo_venta,
                            distribuidor
                        }) => (
                            <tr style={{ cursor: 'pointer', backgroundColor: '#EAF0F6' }}>
                                <td onClick={() => getData(id_parte, parte)} >{id_parte}</td>
                                <td>{parte}</td>
                                <td>{Costo_pieza}</td>
                                <td>{costo_fijo_orden_de_compra}</td>
                                <td>{Costo_de_almacenaje}</td>
                                <td>{Precio_de_Venta}</td>
                                <td>{tipo_venta}</td>
                                <td>{distribuidor}</td>
                                <td>

                                    <tooltip style={{ cursor: 'pointer' }} title="Comparar con otro distribuidor" values={{ color: 'red' }}>
                                        <HiDotsVertical
                                            onClick={() => handleTool(id_parte)}
                                        />
                                    </tooltip>


                                </td>
                            </tr>
                        ))}
                    </tbody>
                        }
                    </table>
                    </div>
                    <div style={{ display: 'flex', marginTop: '2%', justifyContent: 'flex-end' }}>
                        <input
                            type='button'
                            value="<"
                            className='btn btn-primary'
                            style={{ marginRight: '2%' }}
                            onClick={() => handleAdvanceTable('atras')}
                        ></input>
                        <input
                            type='button'
                            value=">"
                            className='btn btn-primary'
                            style={{ marginRight: '2%' }}
                            onClick={() => handleAdvanceTable('next')}></input>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TableMov;
