import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import {
  cuantityModifyReducer,
  deleteShopElement,
} from "../../redux/reducers/shopping.reducer";

const RenderProducts = ({ shoppingCart }) => {
  const dispatch = useDispatch();

  const handleChange = (value, index) => {
    dispatch(cuantityModifyReducer({ value, index }));
  };

  const handleDelete = (index) => {
    dispatch(deleteShopElement(index));
  };

  const getAmount = (item) => {
    const parsedMoney = (
      Number(item.Precio_de_Venta) * item.cuantity
    ).toLocaleString("es-MX", {
      style: "currency",
      currency: "MXN",
    });

    return parsedMoney;
  };

  return (
    <Fragment>
      {shoppingCart.map((element, index) => (
        <div className="articles">
          <div className="imgContainer">
            <img src={element.Image} />
          </div>

          <hr />

          <div className="secondContainer">
            <div className="infoContainer">
              <h6>{element.Name}</h6>
              <p className="existencias">
                <span className="boldFont">Existencias:</span>
                {element.existencias}
              </p>
              <p id="parte">
                {" "}
                <span className="boldFont">No. parte: </span>
                {element.id_parte}
              </p>
              <button className="delBtn" onClick={() => handleDelete(index)}>
                Eliminar
              </button>
            </div>
            <div className="quantityContainer">
              <div className="amountContainer">
                <label className="boldFont">QTY: </label>
                <div className="qtyElements">
                  <button
                    onClick={() => handleChange(-1, index)}
                    className="countButton"
                  >
                    <svg
                      width="16"
                      height="2"
                      viewBox="0 0 16 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1Z"
                        fill="black"
                      />
                    </svg>
                  </button>

                  <span className="counterNum">
                    {" "}
                    {element.cuantity}
                    <span className="red"> </span>{" "}
                  </span>
                  <button
                    onClick={() => handleChange(1, index)}
                    className="countButton"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <span id="precio">
                <span className="boldFont" id="delPrecio">
                  Precio:
                </span>
                {getAmount(element)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};
export default RenderProducts;
