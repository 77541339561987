import React, { useState, useEffect } from "react";

import NavComp from "../Navbar/index";
import LeftAside from "./leftAside";
import "../../styles/LeftAside/style.scss";
import "../../styles/mainContainer/style.scss";
import Cliente from "./Cliente";


const Dashboard = () => {
  sessionStorage.setItem('auxEmail', 'contacto@ballena.com')
  var token = localStorage.getItem("token");
  //console.log(token);
  const [showWindow, setShowWindow] = useState(false);

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  return (
    <React.Fragment>
      { }
      <NavComp />
      {token ? (
        <div className="mainDashboard d-flex">
          {showWindow ? <LeftAside /> : null}
          <Cliente />
        </div>
      ) : (
        <div className="d-flex noAuth"><label>Sin Autorización</label></div>
      )
      }

    </React.Fragment>
  );

}






export default Dashboard;
