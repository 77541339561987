import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { AiFillCaretDown } from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";

import { ImSearch } from "react-icons/im";
import { MdClose } from "react-icons/md";
import {
  GET_MARKET_CATEGORIES,
  GET_MARKET,
  GET_ALL_TRADE,
  GET_NUMBER_PAGES,
  GET_NUM_PRODUCTS,
} from "../../graphql/Query";
import classes from "../../styles/Start/Start.module.scss";
import { searchWithFilter } from "../../redux/reducers/FiltersProducts.reducer.js";
import { resultsFilters } from "../../redux/selectors/filters.selector.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";

export default function FilersComponent(props) {
  const dispatch = useDispatch();

  const shoppingValues = useSelector((state) => state.rootReducers.filters);

  const [categorias, setCategorias] = useState([]);
  const [marcas2, setMarcas2] = useState([]);
  const [id_parteFilter, setIdParteFilter] = useState([]);
  const [marcaFilter, setMarcaFilter] = useState([]);
  const [categoriaFilter, setCategoriaFilter] = useState([]);
  const [categoriaFilterId, setCategoriaFilterId] = useState([]);
  const [id_parte, setIdParte] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [categoriaId, setCategoriaId] = useState("");
  const [page, setPage] = React.useState(1);
  const [allCategorias2, setAllCategorias] = useState([]);
  const [paginationProduct, setPaginationProduct] = useState(false);
  const [likecategoria, setLikeCategoria] = useState("");
  const [numberPagesProducts, setNumberPagesProducts] = useState(0);

  const handleChange = (event, value) => {
    dispatch(searchWithFilter(JSON.stringify(false)));
    setPage(value);
    get_All_Market({
      variables: {
        pag: value,
        id: id_parteFilter.toString(),
        marca: marcaFilter.toString(),
        categoria: categoriaFilterId.toString(),
        likecats: likecategoria.toString(),
      },
    });
    get_pages_All_Market({
      variables: {
        pag: value,
        id: id_parteFilter.toString(),
        marca: marcaFilter.toString(),
        categoria: categoriaFilterId.toString(),
        likecats: likecategoria.toString(),
      },
    });
  };

  const add_Filter = (type, filter, filterId) => {
    switch (type) {
      case "by_id":
        const value = document.getElementById("id_parte");
        setIdParte(value && value.value);
        ////console.log(value && value.value);
        break;
      case "by_marca":
        setMarca(filter);
        break;
      case "by_category":
        setCategoria(filter);
        setCategoriaId(filterId);

        break;
    }
  };

  const delete_Filter = (type, filter) => {
    let temp = [];
    switch (type) {
      case "by_id":
        temp = id_parteFilter.filter((item) => item !== filter);
        setIdParteFilter([...temp]);
        if (
          temp.toString() == "" &&
          marcaFilter.toString() == "" &&
          categoriaFilter.toString() == ""
        ) {
          dispatch(searchWithFilter(JSON.stringify("empty")));
          setPaginationProduct(false);
        } else {
          get_All_Market({
            variables: {
              pag: page,
              id: temp.toString(),
              marca: marcaFilter.toString(),
              categoria: categoriaFilterId.toString(),
              likecats: likecategoria.toString(),
            },
          });
          get_pages_All_Market({
            variables: {
              pag: page,
              id: temp.toString(),
              marca: marcaFilter.toString(),
              categoria: categoriaFilter.toString(),
              likecats: likecategoria.toString(),
            },
          });
        }

        break;
      case "by_marca":
        temp = marcaFilter.filter((item) => item !== filter);
        setMarcaFilter([...temp]);
        if (
          id_parteFilter.toString() == "" &&
          temp.toString() == "" &&
          categoriaFilter.toString() == ""
        ) {
          dispatch(searchWithFilter(JSON.stringify("empty")));
          setPaginationProduct(false);
        } else {
          get_All_Market({
            variables: {
              pag: page,
              id: id_parteFilter.toString(),
              marca: temp.toString(),
              categoria: categoriaFilterId.toString(),
              likecats: likecategoria.toString(),
            },
          });
          get_pages_All_Market({
            variables: {
              pag: page,
              id: id_parteFilter.toString(),
              marca: temp.toString(),
              categoria: categoriaFilter.toString(),
              likecats: likecategoria.toString(),
            },
          });
        }

        break;
      case "by_category":
        temp = categoriaFilterId.filter((item) => item !== filter);
        setCategoriaFilter([...temp]);
        if (
          id_parteFilter.toString() == "" &&
          marcaFilter.toString() == "" &&
          temp.toString() == ""
        ) {
          dispatch(searchWithFilter(JSON.stringify("empty")));
          setPaginationProduct(false);
        } else {
          get_All_Market({
            variables: {
              pag: page,
              id: id_parteFilter.toString(),
              marca: marcaFilter.toString(),
              categoria: temp.toString(),
              likecats: likecategoria.toString(),
            },
          });
          get_pages_All_Market({
            variables: {
              pag: page,
              id: id_parteFilter.toString(),
              marca: marcaFilter.toString(),
              categoria: temp.toString(),
              likecats: likecategoria.toString(),
            },
          });
        }

        break;
    }
  };

  const search_With_Filters = () => {
    console.log("esteee");
    setPaginationProduct(true);
    dispatch(searchWithFilter(JSON.stringify(false)));
    let temp = id_parteFilter;
    let temp2 = marcaFilter;
    let temp3 = categoriaFilter;
    let temp4 = categoriaFilterId;
    id_parte.length > 0 &&
      !id_parteFilter.includes(id_parte) &&
      temp.push(id_parte);
    marca.length > 0 && !marcaFilter.includes(marca) && temp2.push(marca);

    categoria.length > 0 &&
      !categoriaFilter.includes(categoria) &&
      temp3.push(categoria);

    console.log("***********");
    console.log(categoriaId.toString());
    console.log(temp4);

    categoriaId == "0" && !categoriaFilterId.includes("0") && temp4.push("0");
    categoriaId &&
      !categoriaFilterId.includes(categoriaId.toString()) &&
      temp4.push(categoriaId.toString());
    console.log(temp4);
    setIdParteFilter([...temp]);
    setMarcaFilter([...temp2]);
    setCategoriaFilter([...temp3]);
    get_All_Market({
      variables: {
        pag: page,
        id: temp.toString(),
        marca: temp2.toString(),
        categoria: temp4.toString(),
        likecats: likecategoria.toString(),
      },
    });
    get_pages_All_Market({
      variables: {
        pag: page,
        id: temp.toString(),
        marca: temp2.toString(),
        categoria: temp4.toString(),
        likecats: likecategoria.toString(),
      },
    });
  };

  const [get_All_Market] = useLazyQuery(GET_MARKET, {
    fetchPolicy: "no-cache",
    onCompleted({ getMarketProducts }) {
      dispatch(searchWithFilter(JSON.stringify(getMarketProducts)));
      //console.log("+++++++++++++++++++++++++++++");
      //console.log(getMarketProducts);
    },
  });

  const [get_pages_All_Market] = useLazyQuery(GET_NUM_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted({ getNumProducts }) {
      //console.log("????????????????????????");
      //console.log(getNumProducts);
      setNumberPagesProducts(Math.ceil(getNumProducts.count / 40));
    },
  });

  const [get_All_Market_Category] = useLazyQuery(GET_MARKET_CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted({ getCategories }) {
      setCategorias(getCategories);
      ////console.log("_______________________________");
      ////console.log(getCategories);
    },
  });

  const [getmarcaslastFilter2] = useLazyQuery(GET_ALL_TRADE, {
    fetchPolicy: "no-cache",
    onCompleted({ getDistinctMarca }) {
      ////console.log("¡¡??¡¡??¡¡??");
      ////console.log(getDistinctMarca);
      setMarcas2(getDistinctMarca);
    },
    onError(e) {
      ////console.log("Error", e);
      console.table(e);
    },
  });

  useQuery(GET_NUMBER_PAGES, {
    fetchPolicy: "no-cache",
    onCompleted({ getNumCategories }) {
      //console.log(getNumCategories.length);
      //console.log(getNumCategories);
      setAllCategorias(getNumCategories);
      setCategorias(getNumCategories);
      let temp = [];
      getNumCategories.map((category) => {
        //console.log(category);
        temp.push(category.Categorias);
      });
      setLikeCategoria(temp);
    },
  });
  useEffect(() => {
    ////console.log("state");
    ////console.log(resultsFilters);
    ////console.log(shoppingValues);

    // get_All_Market_Category({
    //   variables: {
    //     a: "1",
    //   },
    // });

    getmarcaslastFilter2({
      variables: {
        e: "no",
      },
    });
  }, []);

  const [handleShowMobileMenu, setHandleShowMobileMenu] = useState("");

  return (
    <>
      <div className={classes.categories_sub_nav_busqueda}>
        <div className={classes.handleCont}>
          <span
            onClick={() => setHandleShowMobileMenu("show")}
            className={`${classes.handleShowMobileMenu}`}
          >
            <BiMenuAltLeft style={{ fontSize: "24px" }} /> Filtros
          </span>
        </div>

        <div
          className={`${classes.categories_sub_nav_busqueda_center} ${
            handleShowMobileMenu === "show" && classes.displayShow
          }`}
        >
          <div className={classes.categories_sub_nav_busqueda_single}>
            <a
              className={
                props.theme !== "white"
                  ? `${classes.categories_sub_nav_busqueda_href}`
                  : `${classes.categories_sub_nav_busqueda_href} ${classes.white}`
              }
              href=""
            >
              Buscar sin vehículo
            </a>
          </div>
          <div className={classes.categories_sub_nav_busqueda_row}>
            <div className={classes.container_dropdowns_vehiculo}>
              <div className={classes.container_dropdowns_vehiculo_border}>
                <div className={classes.dropdown_Row}>
                  <input
                    id="id_parte"
                    className={classes.dropbtn_input}
                    type="text"
                    placeholder=" Buscar por id"
                    onChange={() => {
                      add_Filter("by_id", "none", "none");
                    }}
                  />
                  <ImSearch className={classes.searchIcon} />

                  {/* <AiFillCaretDown className={classes.arrow_Down} /> */}
                </div>
              </div>
              <div
                className={classes.container_dropdowns_vehiculo_border_small}
              >
                <div className={classes.dropdown}>
                  <button className={classes.dropbtn}>
                    {marca != "" ? marca : "Marcas"}{" "}
                    <AiFillCaretDown className={classes.arrow_Down} />
                  </button>
                  <div className={classes.dropdown_content}>
                    {marcas2.map((marca) => (
                      <a
                        onClick={() => {
                          add_Filter("by_marca", marca.desc_Marca, "none");
                        }}
                        className={classes.arrow_item}
                      >
                        {marca.desc_Marca}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={classes.container_dropdowns_vehiculo_border_small}
              >
                <div className={classes.dropdown}>
                  <button className={classes.dropbtn}>
                    {categoria != "" ? categoria : "Categoria"}{" "}
                    <AiFillCaretDown className={classes.arrow_Down} />
                  </button>
                  <div className={classes.dropdown_content}>
                    {categorias.map((cat) => (
                      <a
                        onClick={() => {
                          add_Filter(
                            "by_category",
                            cat.Categorias,
                            cat.id_Categoria
                          );
                        }}
                        className={classes.arrow_item}
                      >
                        {cat.Categorias}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.btnCont}>
              <div
                className={classes.btn_Share}
                role="presentation"
                onClick={() => {
                  search_With_Filters();
                }}
              >
                Buscar
              </div>
              <div
                onClick={() => setHandleShowMobileMenu("")}
                className={classes.btn_cancel}
              >
                Cancelar
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.container_applied_filters_a}>
        <div className={classes.container_applied_filters}>
          {id_parteFilter.length > 0 &&
            id_parteFilter.map((id) => (
              <div className={classes.container_filter_name}>
                {id}
                <MdClose
                  onClick={() => {
                    delete_Filter("by_id", id);
                  }}
                  className={classes.delete_applied_filter}
                />
              </div>
            ))}

          {marcaFilter.length > 0 &&
            marcaFilter.map((marca) => (
              <div className={classes.container_filter_name}>
                {marca}
                <MdClose
                  onClick={() => {
                    delete_Filter("by_marca", marca);
                  }}
                  className={classes.delete_applied_filter}
                />
              </div>
            ))}

          {categoriaFilter.length > 0 &&
            categoriaFilter.map((categoria) => (
              <div className={classes.container_filter_name}>
                {categoria}
                <MdClose
                  onClick={() => {
                    delete_Filter("by_category", categoria);
                  }}
                  className={classes.delete_applied_filter}
                />
              </div>
            ))}
        </div>
      </div>
      {paginationProduct && (
        <div className={classes.container_pagination}>
          <div className={classes.root}>
            <Pagination
              count={numberPagesProducts}
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </>
  );
}
