import React from "react";

import NavComp from "../Navbar/index";
import LeftAside from "../Dashboard/leftAside";
import MainContainer from "./mainContainer";
import "../../styles/LeftAside/style.scss";
import "../../styles/mainContainer/style.scss";

class Dashboard extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavComp />
        <div className="mainDashboard d-flex">
          <LeftAside />
          <MainContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
