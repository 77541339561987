import React, { Fragment } from "react";

import RecoverPassForm from "./recoverPassForm";
import RightImg from "../rightImage";
import Footer from "../Footer";
import "../../styles/Login/style.scss";
import "../../styles/RecoverPass/style.scss";

class RecoverPassDistributor extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="main">
          <RecoverPassForm />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default RecoverPassDistributor;
