import React from "react";
import { Link } from "react-router-dom";
import Test1 from "../../assets/imgHome/Test1.png";

export default function UnitPiece() {
  return (
    <React.Fragment>
      <div className="d-flex itemCard">
        <div className="d-flex imgCol">
          <div className="imgContainer">
            <img src={Test1} />
          </div>
          <div className="d-flex flex-column dataContainer">
            <label>Aceite (Lorem ipsum)</label>
            <Link>
              <label className="detailLabl"> Detalle</label>
            </Link>
            {/* <Link className="detailLab">Detalle</Link> */}
          </div>
        </div>
        <div className="d-flex numbCol">
          <label>123456</label>
        </div>
        <div className="d-flex numbCol">
          <label>123456</label>
        </div>
        <div className="d-flex numbCol" id="red">
          <label>123456</label>
        </div>
        <div className="d-flex numbCol" id="yellow">
          <label>123456</label>
        </div>
        <div className="d-flex numbCol" id="lastC">
          <label>123456</label>
        </div>
      </div>
    </React.Fragment>
  );
}




