import React, { useState } from "react";
import { Link } from "react-router-dom";

import CustomInput from "../CustomInput";
import Logo from "../../assets/LOGO.png";
import RightImg from "../rightImage";

export default function RecoverPassForm() {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  return (
    <React.Fragment>
      <div className="containerScroll d-flex">
        <div className="containerForm">
          <div className="loginMode">
            <Link className="linkBtn" to="/recover">
              <button className="btn active">
                <label className="lblForgPass">Cliente</label>
              </button>
            </Link>
            <Link className="linkBtn" to="/recoverdis">
              <button disabled className=" btn  active">
                <label>Distribuidor</label>
              </button>
            </Link>
          </div>
          <form onSubmit={handleSubmit} autocomplete="off">
            <div className=" d-flex flex-column align-items-center logoContainer">
              <img
                className="logo"
                src={Logo}
                width="250"
                height="90"
                alt="Logo"
              />
              <label className="headerlbl text-center">
                Recuperar tu contraseña
              </label>
              <span className=" text-center">
                Te enviaremos un correo electrónico para restablecer su
                contraseña
              </span>
            </div>
            <div className="form-group formDiv">
              <CustomInput
                id="Correo"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                callback={setEmail}
              />
            </div>
            <button
              className="btn btn-primary btn-lg btn-block buttonStyle"
              onClick={handleSubmit}
              type="submit"
            >
              Enviar
            </button>
            <Link className="linkStyle d-flex justify-content-center" to="/">
              <label className="lblBack">Regresar</label>
            </Link>
          </form>
        </div>
        <RightImg />
      </div>
    </React.Fragment>
  );
}
