import { gql } from "@apollo/client";

export const GET_AUTH = gql`
  query getAuth {
    getAuth {
      statusCode
      message
      error
      response {
        idCont
      }
    }
  }
`;
export const GET_DATA_CLIENTE_TABLE = gql`
  query getDataClient {
    getDataClient {
      parte
      distribuidor
      Fecha_de_entrega_cliente
      Costo_pieza
      celda_R
      id_distribuidor
    }
  }
`;

export const GET_DATA_WEEK_TABLE = gql`
  query getDataWeek {
    getDataWeek {
      id_parte
      Costo_pieza
      Precio_de_Venta
      Fecha_de_venta
      Fecha_de_entrega_almacen
      celda_O
      celda_R
      id_distribuidor
      distribuidor
    }
  }
`;
export const GET_DATA_SALE_DAY = gql`
  query getDataToday {
    getDataToday {
      celda_O
      id_distribuidor
      distribuidor
    }
  }
`;

export const GET_DISTRIBUTOR = gql`
  query getDistributor($e: String!) {
    getDistributor(e: $e) {
      id
      gato
      id_parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Costo_de_almacenaje
      Precio_de_Venta
      Fecha_orden_almacen
      Fecha_de_entrega_almacen
      Fecha_orden_cliente
      Fecha_de_venta
      celda_L
      celda_M
      celda_N
      celda_O
      celda_P
      celda_Q
      celda_R
      id_distribuidor
    }
  }
`;

export const GET_DISTRIBUTOR_DAY = gql`
  query getDistributorToday($e: String!) {
    getDistributorToday(e: $e) {
      id
      gato
      id_parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Costo_de_almacenaje
      Precio_de_Venta
      Fecha_orden_almacen
      Fecha_de_entrega_almacen
      Fecha_orden_cliente
      Fecha_de_venta
      celda_L
      celda_M
      celda_N
      celda_O
      celda_P
      celda_Q
      celda_R
      id_distribuidor
    }
  }
`;

export const GET_DISTRIBUTOR_WEEK = gql`
  query getDistributorWeek($e: String!) {
    getDistributorWeek(e: $e) {
      id
      gato
      id_parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Costo_de_almacenaje
      Precio_de_Venta
      Fecha_orden_almacen
      Fecha_de_entrega_almacen
      Fecha_orden_cliente
      Fecha_de_venta
      celda_L
      celda_M
      celda_N
      celda_O
      celda_P
      celda_Q
      celda_R
      id_distribuidor
    }
  }
`;
export const GET_ABC_NUMBER = gql`
  query getABCNumber {
    getABCNumber {
      celda_R
      count
    }
  }
`;
export const GET_ABCD_DISTRIBUIDOR = gql`
  query getABCDistributor($e: String!) {
    getABCDistributor(e: $e) {
      celda_R
      count
    }
  }
`;

export const GET_BEST_SELL_PART = gql`
  query getBestSellPart {
    getBestSellPart
  }
`;

export const GET_BEST_DIS_PRO_FITS = gql`
  query getBestDisProfits {
    getBestDisProfits {
      distribuidor
      total_amount
    }
  }
`;
export const GET_ALL_DISTRIBUTORS = gql`
  query getAllDistributors {
    getAllDistributors {
      id_group
      type_user
      name
      email
    }
  }
`;
// QUERYS DASHBOARD DISTRIBUTOR
export const GET_DISTRIBUTOR_BEST_SELL_PART = gql`
  query getDistributorBestSellPart($e: String!) {
    getDistributorBestSellPart(e: $e) {
      id_parte
      celda_M
      celda_O
      id_distribuidor
    }
  }
`;
export const GET_DISTRIBUTOR_BEST_PART_PROFITS = gql`
  query getDistributorBestPartProfits($e: String!) {
    getDistributorBestPartProfits(e: $e) {
      id_parte
      celda_M
      celda_O
      id_distribuidor
    }
  }
`;
export const GET_DISTRIBUTOR_WORSE_SELL_PART = gql`
  query getDistributorWorseSellPart($e: String!) {
    getDistributorWorseSellPart(e: $e) {
      id_parte
      celda_M
      celda_O
      id_distribuidor
    }
  }
`;
//QUERYS DASHcLIENT, GRAFICA tipo de movimiento

export const GET_MOV_NUMBER = gql`
  query getMovNumber {
    getMovNumber {
      tipo_movimiento
      count
    }
  }
`;
// table de detalles de movimientos
export const GET_MOV_ABCT_YPE_SALE = gql`
  query getMovABCTypeSale($email: String, $MovABCTypeSale: String, $page: Int) {
    getMovABCTypeSale(
      email: $email
      MovABCTypeSale: $MovABCTypeSale
      page: $page
    ) {
      id_parte
      parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Precio_de_Venta
      tipo_venta
      tipo_movimiento
      distribuidor
    }
  }
`;
// modal de detalles de piezas
export const GET_RAW_DATA = gql`
  query getRawData($idParte: Int) {
    getRawData(idParte: $idParte) {
      id_parte
      Fecha_de_entrega_almacen
      Fecha_de_venta
      Fecha_de_entrega_cligetUtilidadente
      dayDiff
    }
  }
`;
//filtro modal
export const GET_UTILIDAD = gql`
  query getUtilidad($email: String!, $idParte: String) {
    getUtilidad(email: $email, idParte: $idParte) {
      id_parte
      parte
      Costo_pieza
      Precio_de_Venta
      Utilidad
      Costo_total_almacen
      Utilidad_Real
    }
  }
`;
export const GET_DAY_DIFF = gql`
  query getDayDiff($email: String!, $idParte: String) {
    getDayDiff(email: $email, idParte: $idParte) {
      id_parte
      parte
      Fecha_de_entrega_almacen
      Fecha_de_venta
      dayDiff
    }
  }
`;
export const GET_DAY_DIFF_SURTIDO = gql`
  query getDayDiffSurtido($email: String!, $idParte: String) {
    getDayDiffSurtido(email: $email, idParte: $idParte) {
      id_parte
      parte
      Fecha_orden_almacen
      Fecha_de_entrega_almacen
      dayDiffSurtido
    }
  }
`;
export const GET_COSTO_ALMACENAJE = gql`
  query getCostoAlmacenaje($email: String!, $idParte: String) {
    getCostoAlmacenaje(email: $email, idParte: $idParte) {
      id_parte
      parte
      dayDiff
      Costo_de_almacenaje
      Costo_total_almacen
    }
  }
`;
export const GET_MOV_DISTRIBUTOR = gql`
  query getMovDistributor($e: String) {
    getMovDistributor(e: $e) {
      Rapido
      Lento
      Estancado
      Inactivo
      Potencial_obsoleto
      Obsoleto
    }
  }
`;
export const GET_POT_OBSOLETOS = gql`
  query getPotObsoletos($tipMov: String, $email: String) {
    getPotObsoletos(tipMov: $tipMov, email: $email) {
      id_parte
      Name
      tipo_movimiento
      distribuidor
      id_distribuidor
      statusMarket
      PrecioVenta
      Costo_pieza
    }
  }
`;
export const GET_NUM_ALL_PRODUCTS = gql`
  query getNumAllProducts {
    getNumAllProducts {
      count
    }
  }
`;
export const GET_ALL_PRODUCTS = gql`
  query getAllProducts($idParte: String, $e: String, $idDis: Int, $pag: Int) {
    getAllProducts(idParte: $idParte, e: $e, idDis: $idDis, pag: $pag) {
      ID
      id_parte
      id_grupo
      id_distribuidor
      email
      statusMarket
      Name
      SKU
      Dimensiones
      Peso
      Proveedor
      Description
      Precio_de_Venta
      Image
      statusImage
      TermsAndConditions
      existencias
      notas
      id_Categorias
      nombre_categoria
    }
  }
`;
//Nueva grafica dashClienet Tipo de venta
export const GET_TYPE_SALE_DISTRIBUTOR = gql`
  query getTypeSaleDistributor($e: String) {
    getTypeSaleDistributor(e: $e) {
      tipo_venta
      count
    }
  }
`;
//Grafica del modal comparar con hermano distribuidor
export const GET_COMPARE_MOV_TYPE = gql`
  query getCompareMovType($idParte: Int, $group: Int) {
    getCompareMovType(idParte: $idParte, group: $group) {
      id_parte
      parte
      tipo_movimiento
      distribuidor
      numPartesDisponibles
    }
  }
`;

//Grafica del modal comparar con hermano distribuidor
export const GET_COMPARE_MOV_TYPE2 = gql`
  query getCompareMovType2($idParte: String, $group: Int) {
    getCompareMovType2(idParte: $idParte, group: $group) {
      parte
      tipo_movimiento
      distribuidor
      tipo_venta
      celda_R
    }
  }
`;

export const GET_ALL_TRADE = gql`
  query getDistinctMarca($e: String!) {
    getDistinctMarca(e: $e) {
      id_Marca
      desc_Marca
    }
  }
`;

export const GET_ALL_DIST = gql`
  query getDistinctDist($a: String!, $b: String!) {
    getDistinctDist(a: $a, b: $b) {
      id_distribuidor
      name
    }
  }
`;

export const GET_ALL_FATHERS = gql`
  query getDistinctFathers {
    getDistinctFathers {
      id_fathers
      name
    }
  }
`;

export const GET_ABCD_DISTRIBUIDOR_ADMIN = gql`
  query getABCDistributorbyAdmin(
    $a: String!
    $b: String!
    $c: String!
    $d: String
    $e: String
  ) {
    getABCDistributorbyAdmin(a: $a, b: $b, c: $c, d: $d, e: $e) {
      total
      name
      costoTotal
      amount
    }
  }
`;

export const GET_MOV_DISTRIBUIDOR_ADMIN = gql`
  query getMovDistributorAdmin(
    $a: String!
    $b: String!
    $c: String!
    $d: String
    $e: String
  ) {
    getMovDistributorAdmin(a: $a, b: $b, c: $c, d: $d, e: $e) {
      Rapido
      Lento
      Estancado
      Inactivo
      Potencial_obsoleto
      Obsoleto
      costoTotal
    }
  }
`;

export const GET_TYPE_SALE_ADMIN = gql`
  query getTypeSaleAdmin(
    $a: String!
    $b: String!
    $c: String!
    $d: String
    $e: String
  ) {
    getTypeSaleAdmin(a: $a, b: $b, c: $c, d: $d, e: $e) {
      tipo_venta
      count
      costoTotal
    }
  }
`;

export const GET_BEST_SELL_PART_ADMIN = gql`
  query getBestSellPartAdmin($a: String!, $b: String!, $c: String!) {
    getBestSellPartAdmin(a: $a, b: $b, c: $c)
  }
`;

export const GET_POT_OBSOLETOS_ADMIN = gql`
  query getPotObsoletoAdmin(
    $a: String!
    $b: String!
    $c: String!
    $tipMov: String
    $email: String
    $limit: Int
    $orderby: String
  ) {
    getPotObsoletoAdmin(
      a: $a
      b: $b
      c: $c
      tipMov: $tipMov
      email: $email
      limit: $limit
      orderby: $orderby
    ) {
      ID
      id_grupo
      id_parte
      Name
      tipo_movimiento
      distribuidor
      id_distribuidor
      statusMarket
      PrecioVenta
      Costo_pieza
      precio_plataforma
      id_Categorias
    }
  }
`;

export const GET_POT_OBSOLETOS_ADMIN_TABLE = gql`
  query getPotObsoletoAdminTabl(
    $a: String!
    $b: String!
    $c: String!
    $tipMov: String
    $email: String
  ) {
    getPotObsoletoAdminTabl(
      a: $a
      b: $b
      c: $c
      tipMov: $tipMov
      email: $email
    ) {
      id_parte
      Name
      tipo_movimiento
      distribuidor
      id_distribuidor
      statusMarket
      PrecioVenta
      Costo_pieza
    }
  }
`;
// table de detalles de movimientos con filtros
export const GET_MOV_ABCT_YPE_SALE_ADMIN = gql`
  query getMovABCTypeSaleAdmin(
    $a: String!
    $b: String!
    $c: String!
    $email: String
    $MovABCTypeSale: String
    $page: Int
    $orderby: String
  ) {
    getMovABCTypeSaleAdmin(
      a: $a
      b: $b
      c: $c
      email: $email
      MovABCTypeSale: $MovABCTypeSale
      page: $page
      orderby: $orderby
    ) {
      id_parte
      parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Precio_de_Venta
      tipo_venta
      tipo_movimiento
      distribuidor
      almacenaje
      pvpiva
    }
  }
`;

export const GET_MOV_ABCT_YPE_SALE_ADMIN_NUMBER = gql`
  query getMovABCTypeSaleAdminNumber(
    $a: String!
    $b: String!
    $c: String!
    $email: String
    $MovABCTypeSale: String
    $page: Int
  ) {
    getMovABCTypeSaleAdminNumber(
      a: $a
      b: $b
      c: $c
      email: $email
      MovABCTypeSale: $MovABCTypeSale
      page: $page
    ) {
      paginas
    }
  }
`;

export const GET_MARCAS = gql`
  query getMarcas {
    getMarcas {
      id_Marca
      desc_Marca
    }
  }
`;

export const GET_SALES_MARKET = gql`
  query getSalesMonthly(
    $a: String!
    $b: String!
    $c: String!
    $init: String
    $fin: String
  ) {
    getSalesMonthly(a: $a, b: $b, c: $c, init: $init, fin: $fin) {
      total
      uno
    }
  }
`;

export const GET_SALES_MARKET_GROUP = gql`
  query getSalesMonthlyGroup(
    $a: String!
    $b: String!
    $c: String!
    $init: String
    $fin: String
  ) {
    getSalesMonthlyGroup(a: $a, b: $b, c: $c, init: $init, fin: $fin) {
      total
      uno
    }
  }
`;

export const GET_SALES_MARKET_GROUP_MARCA = gql`
  query getSalesMonthlyGroupMarca(
    $a: String!
    $b: String!
    $c: String!
    $init: String
    $fin: String
  ) {
    getSalesMonthlyGroupMarca(a: $a, b: $b, c: $c, init: $init, fin: $fin) {
      total
      uno
    }
  }
`;

export const GET_SALES_MARKET_LINEAL = gql`
  query getSalesMonthlyGroupLineal(
    $a: String!
    $b: String!
    $c: String!
    $init: String
    $fin: String
  ) {
    getSalesMonthlyGroupLineal(a: $a, b: $b, c: $c, init: $init, fin: $fin) {
      total
      uno
    }
  }
`;

export const GET_ALL_TRADE_LAST = gql`
  query getDistinctMarca2($a: String, $b: String, $c: String) {
    getDistinctMarca2(a: $a, b: $b, c: $c) {
      id_Marca
      desc_Marca
    }
  }
`;

export const GET_MARKET = gql`
  query getMarketProducts(
    $pag: Int
    $id: String
    $marca: String
    $categoria: String
    $likecats: String
  ) {
    getMarketProducts(
      pag: $pag
      id: $id
      marca: $marca
      categoria: $categoria
      likecats: $likecats
    ) {
      ID
      id_parte
      id_grupo
      id_distribuidor
      email
      statusMarket
      Name
      SKU
      Dimensiones
      Peso
      Proveedor
      Description
      Precio_de_Venta
      precio_plataforma
      Image
      statusImage
      TermsAndConditions
      existencias
      id_Categoria
    }
  }
`;

export const GET_MARKET2 = gql`
  query getMarketProducts2(
    $pag: Int
    $id: String
    $marca: String
    $categoria: String
    $likecats: String
  ) {
    getMarketProducts2(
      pag: $pag
      id: $id
      marca: $marca
      categoria: $categoria
      likecats: $likecats
    ) {
      ID
      id_parte
      id_grupo
      id_distribuidor
      email
      statusMarket
      Name
      SKU
      Dimensiones
      Peso
      Proveedor
      Description
      Precio_de_Venta
      precio_plataforma
      Image
      statusImage
      TermsAndConditions
      existencias
    }
  }
`;

export const GET_RANDOM_SUGGESTION = gql`
  query getRandomSuggestion($id_Categorias: Int!, $currentID: String!) {
    getRandomSuggestion(id_Categorias: $id_Categorias, currentID: $currentID) {
      id_parte
      id_grupo
      id_distribuidor
      Name
    }
  }
`;

export const GET_MARKET_CATEGORIES = gql`
  query getCategories($a: String) {
    getCategories(a: $a) {
      id_Categoria
      Categorias
      image
    }
  }
`;

export const GET_THREE_IN_ONE = gql`
  query getThreeInOne($a: String!, $b: String!, $c: String!, $d: String) {
    getThreeInOne(a: $a, b: $b, c: $c, d: $d) {
      res1 {
        total
        name
      }
      res2 {
        Lento
        Rapido
        Estancado
        Inactivo
        Potencial_obsoleto
        Obsoleto
        costoTotal
      }
      res3 {
        tipo_venta
        count
        costoTotal
      }
    }
  }
`;

export const CONSULTA_HISTORICO = gql`
  query consultaHistorial($a: String) {
    consultaHistorial(a: $a) {
      ok
    }
  }
`;

export const SAVE_HISTORICO_ABC = gql`
  query saveHistoricABC($a: String) {
    saveHistoricABC(a: $a) {
      ok
    }
  }
`;
export const SAVE_CONSULTA_HISTORICO_MOV = gql`
  query saveHistoricMOV($a: String) {
    saveHistoricMOV(a: $a) {
      ok
    }
  }
`;
export const SAVE_CONSULTA_HISTORICO_VENTA = gql`
  query saveHistoricVENTA($a: String) {
    saveHistoricVENTA(a: $a) {
      ok
    }
  }
`;

export const RUN_ALGORITHM = gql`
  query runAlgorithm($celdaL: String) {
    runAlgorithm(celdaL: $celdaL) {
      ok
    }
  }
`;

export const GET_REQUEST_USER = gql`
  query getUserRequestingPartbyId($email: String!, $id_parte: String!) {
    getUserRequestingPartbyId(email: $email, id_parte: $id_parte) {
      request_part_users_id
      lastName
      firstName
      email
      phone
      id_parte
      status_request
      createdAt
      updatedAt
    }
  }
`;

export const GET_NUMBER_PAGES = gql`
  query getNumCategories($a: String) {
    getNumCategories(a: $a) {
      id_Categoria
      Categorias
      image
    }
  }
`;

export const GET_NUM_PRODUCTS = gql`
  query getNumProducts(
    $pag: Int
    $id: String
    $marca: String
    $categoria: String
    $likecats: String
  ) {
    getNumProducts(
      pag: $pag
      id: $id
      marca: $marca
      categoria: $categoria
      likecats: $likecats
    ) {
      count
    }
  }
`;

export const GET_NAME_FILTERS = gql`
  query getNameFilters($a: String, $b: String, $c: String) {
    getNameFilters(a: $a, b: $b, c: $c) {
      grupo
      marca
      distribuidor
    }
  }
`;

export const GET_TB = gql`
  query getTB($a: Int, $b: Int) {
    getTB(a: $a, b: $b) {
      tasa
    }
  }
`;

export const GET_PAY_DATA = gql`
  query getPaymentInformation($id: ID!) {
    getPaymentInformation(id: $id) {
      status
      installments
      totalAmount
      items {
        parte
        Precio_de_Venta
        cantidad
      }
    }
  }
`