import React, { useState, useEffect, useRef } from "react";

import S3FileUpload from "react-s3";
import "../../styles/marketplaceDis/style.scss";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box, Modal } from "@material-ui/core";
import { IconButton } from "@mui/material";
import Dropzone from "react-dropzone";
import {
  POST_PRODUCT_TO_MARKETPLACE,
  REMOVE_PRODUCT_FROM_MARKETPLACE,
  UPLOAD_IMAGE,
} from "../../graphql/Mutation";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Form from "react-bootstrap/Form";
import { SiCheckmarx } from "react-icons/si";
import { BsCheckLg } from "react-icons/bs";
import ReactDOMServer from "react-dom/server";
import ImageDefaultWhite from "../../assets/dashboard/Marketplace/image-default-white.svg";
import { Spinner } from "react-bootstrap";
import { GET_MARKET_CATEGORIES } from "../../graphql/Query";

const config = {
  bucketName: "ballena-website",
  region: "us-east-2",
  accessKeyId: "AKIA2KOCQD3QDDFMB2ZW",
  secretAccessKey: "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",
};

const DEFAULT_PRODUCT = {
  Name: "",
  id_parte: "",
  PrecioVenta: "",
  descripcion: "",
  peso: "",
  largo: "",
  ancho: "",
  id_Categorias: 0,
};

export const TableMarketplace = ({ columns = [], body = [] }) => {
  const inputRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [picture, setPicture] = useState(null);
  const [postingProduct, setPostingProduct] = useState(DEFAULT_PRODUCT);
  const [current, setCurrent] = useState(null);
  const [parte, setParte] = useState();
  const [counter, setCounter] = useState(1);
  const [ifImg, setIfImg] = useState("");
  const [imgTemp, setImgTemp] = useState(null);
  const tipo = localStorage.getItem("typeUser");

  const [uploadProductData] = useMutation(POST_PRODUCT_TO_MARKETPLACE);
  const [removeProduct] = useMutation(REMOVE_PRODUCT_FROM_MARKETPLACE);

  const [dataProductCompleted, setDataProductCompleted] = React.useState(false);
  const [addNewCat, setAddNewCat] = React.useState(false);
  const [newCat, setNewCat] = React.useState("");
  const [categorias, setCategorias] = React.useState([]);

  const [get_All_Market_Category] = useLazyQuery(GET_MARKET_CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted({ getCategories }) {
      setCategorias(getCategories);
      console.log("_______________________________");
      console.log(getCategories);
    },
  });

  useEffect(() => {
    get_All_Market_Category({
      variables: {
        a: "",
      },
    });
  }, []);

  useEffect(() => {
    if (
      !postingProduct.descripcion ||
      (!+postingProduct.id_Categorias && !newCat) ||
      !postingProduct.peso ||
      !postingProduct.largo ||
      !postingProduct.ancho
    ) {
      setDataProductCompleted(false);
    } else {
      setDataProductCompleted(true);
    }
  }, [postingProduct, newCat]);

  var e = localStorage.getItem("e");

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };

  const changeStatusSwich = async (e, item) => {
    if (!item.statusMarket) {
      e.preventDefault();
      setNewCat("");
      setAddNewCat(false);
      setCurrent(e.currentTarget);
      setPostingProduct(item);
      if (item.id_Categorias === 0) {
        setAddNewCat(true);
      }
      handleOpen();
    } else {
      const { data } = await removeProduct({
        variables: { ID: item.ID },
      });
      console.log(data);
      item.statusMarket = 0;
      item.precio_plataforma = 0;
      console.log(item);
      alert("Se ha dado de baja este producto en el Marketplace");
    }
  };

  const upload = () => {
    console.log(picture);

    S3FileUpload.uploadFile(picture, config)
      .then((data) => {
        console.log(data);
        if (data) {
          console.log("si Hay Data");
          console.log(data.location);
          console.log(parte);
          uploadImage({
            variables: {
              imgUrl: data.location,
              email: e,
              id_parte: parte,
            },
          });
        }
      })
      .catch((err) => console.error(err));
  };

  // const update = () =>{
  //   forceUpdate()
  // }

  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    // fetchPolicy: 'no-cache',
    onCompleted({ uploadImage }) {
      console.log("correcto");

      setParte();
      // update()
      handleClose();
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleTool = (item) => {
    console.log(item);
    console.log(item.id_parte);
    setParte(item.id_parte);
    // setIdDis(item.id_distribuidor);
    setIfImg(item.Image);
    handleOpen();
  };

  const handleToolClose = () => {
    setPostingProduct(DEFAULT_PRODUCT);
    setCounter(1);
    setImgTemp(null);
    handleClose();
  };

  const handleConfirmPosting = async (e) => {
    e.preventDefault();
    const idCatN = +postingProduct.id_Categorias;
    if (idCatN === 0) {
      alert("Es necesario que selecciones o agreges una Categoría");
      return;
    }
    if (postingProduct.id_Categorias === "nueva_categoria") {
      if (!newCat) {
        alert("Es necesario que escribas el nombre de la nueva Categoría");
        return;
      }
      const regexSpecialChars = /^[A-Za-z ]+$/;
      if (!regexSpecialChars.test(newCat)) {
        alert(
          "El nombre de la Categoría no debe contener números ni caracteres especiales"
        );
        return;
      }
    }
    if (!postingProduct.descripcion) {
      alert("Es necesario que agreges una descripción");
      return;
    }
    if (!postingProduct.PrecioVenta) {
      alert("Es necesario que agreges un precio de plataforma");
      return;
    }
    const plataformData = {
      id_CatProduct_group: postingProduct.ID,
      id_parte: postingProduct.id_parte,
      id_grupo: postingProduct.id_grupo,
      id_distribuidor: postingProduct.id_distribuidor,
      descripcion: postingProduct.descripcion,
      notas: postingProduct.notas,
      peso: postingProduct.peso,
      dimensiones: `Largo: ${postingProduct.largo}. Ancho: ${postingProduct.ancho}`,
      Image: postingProduct.Image,
      precio_plataforma: +postingProduct.PrecioVenta,
      existencias: counter,
      id_Categorias: idCatN ? idCatN : 0,
      nueva_categoria: newCat,
    };

    const {
      data: { postingProductMarketplace },
    } = await uploadProductData({
      variables: plataformData,
    });
    console.log(postingProductMarketplace);
    handleToolClose();
    if (postingProductMarketplace.success) {
      body.map((item) => {
        if (item.ID === postingProduct.ID) {
          item.statusMarket = 1;
          item.precio_plataforma =
            postingProductMarketplace.productData.precio_plataforma;
          item.id_Categorias = plataformData.id_Categorias;
        }
      });
      setOpenSuccess(true);
      current.checked = true;
    }
  };

  const handleChangeInput = (e) => {
    setPostingProduct({
      ...postingProduct,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const triggerInputFile = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const imgSelected = e.currentTarget.files[0];
    if (imgSelected) {
      const imagePrev = URL.createObjectURL(imgSelected);
      setPostingProduct({ ...postingProduct, Image: imgSelected });
      setImgTemp(imagePrev);
    } else {
      setImgTemp(null);
    }
  };

  const handleCat = (e) => {
    const catVal = e.currentTarget.value;
    if (catVal === "nueva_categoria" || catVal === 0 || catVal === "0") {
      setAddNewCat(true);
    } else {
      setAddNewCat(false);
    }
    setPostingProduct({ ...postingProduct, id_Categorias: catVal });
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "100%",
      lg: "700px",
    },
    // height: "85%",
    height: "auto",
    maxHeight: "100%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 5,
  };
  return (
    <div className="modalSize">
      <Modal
        className="modalMrkt"
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className="closeModal">
            <button onClick={handleToolClose}>X</button>
          </div>
          <div className="row h-100">
            <div className="col-lg-6">
              <input
                type="file"
                hidden
                ref={inputRef}
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />
              <div className="img-rendered" onClick={triggerInputFile}>
                {imgTemp ? (
                  <img src={imgTemp} alt="imgTemp" className="img-temp" />
                ) : (
                  <>
                    <img
                      src={ImageDefaultWhite}
                      alt="ImageDefaultWhite"
                      className="img-default"
                    />
                    <h2>Subir Imagen</h2>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="title">
                <h2>{postingProduct.Name}</h2>
              </div>
              <div className="row mb-2">
                <div className="col-7 text-black">
                  N# {postingProduct.id_parte}
                </div>
                {/* <div className="col-5">SKU</div> */}
              </div>
              <p
                style={{ color: "#00AB59", fontSize: "20px" }}
                className="fw-bold"
              >
                DISPONIBLE
              </p>
              <strong>Notas:</strong>
              <textarea
                class="form-control"
                aria-label="With textarea"
                rows={1}
                name="notas"
                onChange={handleChangeInput}
              />
              <div className="row mt-3 numering">
                <div className="col-7">
                  <span className="dolar-char">$</span>
                  <input
                    type="number"
                    min={1}
                    placeholder="Precio"
                    class="form-control py-1"
                    value={parseInt(postingProduct.PrecioVenta)}
                    name="PrecioVenta"
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="col-5">
                  <div className="row buttons-counter">
                    <div className="col-4 p-0">
                      <button
                        className="boton"
                        onClick={() => {
                          if (counter !== 1) setCounter(counter - 1);
                        }}
                      >
                        -
                      </button>
                    </div>
                    <div className="col-4 p-0">{counter}</div>
                    <div className="col-4 p-0">
                      <button
                        className="boton"
                        onClick={() => setCounter(counter + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <textarea
                class="form-control mt-3"
                rows={1}
                placeholder="Descripción"
                name="descripcion"
                onChange={handleChangeInput}
              />
              <textarea
                class="form-control mt-3"
                rows={1}
                placeholder="Peso"
                name="peso"
                onChange={handleChangeInput}
              />
              <div className="row mt-3">
                <strong className="pb-2">Dimensiones:</strong>
                <div className="col-6 pe-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Largo"
                    name="largo"
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="col-6 ps-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Ancho"
                    name="ancho"
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <select
                name="category"
                className="form-control mt-3"
                onChange={handleCat}
              >
                <option value={0}>Sin Categoría</option>
                {categorias.map((cat) => (
                  <option
                    value={cat.id_Categoria}
                    selected={
                      cat.id_Categoria === postingProduct.id_Categorias
                        ? true
                        : false
                    }
                  >
                    {cat.Categorias}
                  </option>
                ))}
                <option value="nueva_categoria">Nueva Categoría</option>
              </select>
              {addNewCat && (
                <input
                  type="text"
                  class="form-control mt-3"
                  placeholder="Escriba su nueva categoría"
                  name="nueva_categoria"
                  onChange={(e) => setNewCat(e.currentTarget.value)}
                />
              )}

              {dataProductCompleted ? (
                <button
                  className="mt-3 boton-confirmar"
                  onClick={handleConfirmPosting}
                >
                  Confirmar
                </button>
              ) : (
                <button className="mt-3 boton-confirmar disabled" disabled>
                  Confirmar
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styles, height: "40%", padding: "15px" }}>
          <div className="closeModal">
            <button onClick={() => setOpenSuccess(false)}>X</button>
          </div>
          <div className="text-center">
            <h2>¡Carga exitosa!</h2>
            <BsCheckLg color="3AC267" size={50} />
            <h4 className="text-secondary">
              Se ha cargado con exito el producto al MarketPlace
            </h4>
          </div>
        </Box>
      </Modal>
      {body.length < 1 ? (
        <div className="text-center w-100">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <table
          className="table table-striped tableContainer tableMrkt"
          style={{ height: "auto" }}
        >
          <thead
            className="sticky"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "white",
            }}
          >
            <tr className="sticky">
              {columns.map(({ title }, index) => (
                <th key={`${title}_${index}`}>{title}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {body.map((item, index) => (
              <tr key={`tr_${index}`} style={{ backgroundColor: "#EAF0F6" }}>
                {columns.map(({ field }, j) => {
                  if (field === "Image") {
                    if (tipo == 2 || tipo == 3 || tipo == 4) {
                      return (
                        <td key={`${field}_${index}_Image`}>
                          <IconButton
                            className="uploadIconButton"
                            aria-label="upload picture"
                            component="span"
                            value={item}
                          >
                            <FileUploadIcon />
                          </IconButton>
                        </td>
                      );
                    }
                  } else if (field === "IconUpload") {
                    return (
                      <td key={`${index}`}>
                        {tipo == 2 || tipo == 3 || tipo == 4 ? (
                          <Form>
                            <Form.Check
                              type="switch"
                              id={`custom-switch${index}`}
                              key={index}
                              onClick={(e) => changeStatusSwich(e, item)}
                              defaultChecked={item.statusMarket ? true : false}
                              //disabled=${disabled}
                            />
                          </Form>
                        ) : item["statusMarket"] ? (
                          <SiCheckmarx style={{ color: "green" }} />
                        ) : (
                          <SiCheckmarx />
                        )}
                      </td>
                    );
                  } else if (field === "Costo_pieza") {
                    return (
                      <td key={`${field}_${index}_Costo_pieza`}>
                        {" "}
                        {formatter.format(filterFloat(item["Costo_pieza"]))}
                      </td>
                    );
                  } else if (field === "PrecioVenta") {
                    return (
                      <td key={`${field}_${index}PrecioVenta`}>
                        {formatter.format(item["PrecioVenta"])}
                      </td>
                    );
                  } else if (field === "Utilidad") {
                    return (
                      <td key={`${field}_${index}_Utilidad`}>
                        {" "}
                        {formatter.format(filterFloat(item["Utilidad"]))}
                      </td>
                    );
                  } else if (field === "Costo_total_almacen") {
                    return (
                      <td key={`${field}_${index}_Costo_total_almacen`}>
                        {formatter.format(
                          filterFloat(item["Costo_total_almacen"])
                        )}
                      </td>
                    );
                  } else if (field === "Utilidad_Real") {
                    return (
                      <td key={`${field}_${index}_Utilidad_Real`}>
                        {" "}
                        {formatter.format(filterFloat(item["Utilidad_Real"]))}
                      </td>
                    );
                  } else if (field === "Costo_de_almacenaje") {
                    return (
                      <td key={`${field}_${index}_Costo_de_almacenaje`}>
                        {" "}
                        {formatter.format(
                          filterFloat(item["Costo_de_almacenaje"])
                        )}
                      </td>
                    );
                  } else if (field === "Precio_Plataforma") {
                    return (
                      <td key={`${field}_${index}_Precio_Plataforma`}>
                        {formatter.format(item["precio_plataforma"])}
                      </td>
                    );
                  } else
                    return (
                      <td key={`${field}_${index}_${j}`}>{item[field]}</td>
                    );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
