import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import useMercadoPago from '../../hooks/useMercadoPago';
import NavBarShop from '../NavBarShop';
import { useHistory } from 'react-router-dom';
import '../../styles/MercadoPago/style.scss';
import { Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAllShopElements } from '../../redux/reducers/shopping.reducer';
import withLoading from './conditionalRender';

const INITIAL_STATE = {
	cvc: '',
	cardExpirationMonth: '',
	cardExpirationYear: '',
	focus: 'cardNumber',
	cardholderName: '',
	cardNumber: '',
	issuer: '',
};

const MercadoPagoPayment = () => {
	const [resultPayment, loading] = useMercadoPago();
	const LoadingRender = withLoading(MercadoPagoForm);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (resultPayment) {
			if (resultPayment.status === 'approved')
				dispatch(deleteAllShopElements());
			history.push(`/payment-result/${resultPayment.sellId}`);
		}
	}, [resultPayment]);

	return (
		<Fragment>
			<NavBarShop />
			<LoadingRender loading={loading} condition={loading} />
		</Fragment>
	);
};

const MercadoPagoForm = ({ loading }) => {
	const shoppingValues = useSelector((state) => state.rootReducers.shoop);
	const [state, setState] = useState(INITIAL_STATE);

	const handleInputChange = (e) => {
		setState({
			...state,
			[e.target.dataset.name || e.target.name]: e.target.value,
		});
	};

	const handleInputFocus = (e) => {
		setState({ ...state, focus: e.target.dataset.name || e.target.name });
	};

	return (
		<div className='container'>
			<form id='form-checkout'>
				<div className='form-control'>
					<input
						type='tel'
						name='cardNumber'
						id='form-checkout__cardNumber'
						onChange={handleInputChange}
						onFocus={handleInputFocus}
					/>
				</div>
				<div className='form-control'>
					<input
						type='tel'
						name='cardExpirationMonth'
						id='form-checkout__cardExpirationMonth'
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						maxLength={2}
					/>
					<input
						type='tel'
						name='cardExpirationYear'
						id='form-checkout__cardExpirationYear'
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						maxLength={2}
					/>
					<input
						type='tel'
						name='cvc'
						id='form-checkout__securityCode'
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						maxLength={3}
					/>
				</div>
				<div className='form-control'>
					<input
						type='text'
						name='cardholderName'
						id='form-checkout__cardholderName'
						onChange={handleInputChange}
						onFocus={handleInputFocus}
					/>
					<input
						type='email'
						name='cardholderEmail'
						id='form-checkout__cardholderEmail'
						onFocus={handleInputFocus}
					/>
				</div>
				<div className='form-control'>
					<select name='issuer' id='form-checkout__issuer' on></select>
					<select
						name='identificationType'
						id='form-checkout__identificationType'
					></select>
				</div>
				<div className='form-control'>
					<input
						type='text'
						name='identificationNumber'
						id='form-checkout__identificationNumber'
					/>
				</div>
				<div className='form-control'>
					<select name='installments' id='form-checkout__installments'></select>
				</div>
				<div className='form-control'>
					<button
						type='submit'
						id='form-checkout__submit'
						disabled={shoppingValues.length < 1 || loading}
					>
						Pagar
					</button>
				</div>
			</form>
		</div>
	);
};

export default MercadoPagoPayment;
