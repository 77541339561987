import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import StatsTable from "./statsTable";
import { useQuery, useLazyQuery } from '@apollo/client'
import {
    GET_ABC_NUMBER,
    GET_POT_OBSOLETOS,
    GET_BEST_SELL_PART,
    GET_BEST_DIS_PRO_FITS,
    GET_ABCD_DISTRIBUIDOR,
    GET_ALL_DISTRIBUTORS,
    GET_MOV_DISTRIBUTOR,
    GET_TYPE_SALE_DISTRIBUTOR
} from '../../graphql/Query';
import ChartBestSellinngsPieces from "../ChartDahsboard/ChartBestSellinngsPieces";
import ChartDealers from "../ChartDahsboard/ChartDealers";
import FilterByDistributor from './FilterByDistributor';
import { padding } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import downloadExcel from '../../helpers/DownloadXLSX'

const Cliente = () => {
    let history = useHistory();
    const [dataAux, setDataAux] = useState([])

    const [dataTable, setDataTable] = useState([])
    const [dataTableExport, setDataTableExport] = useState([])
    const [dataDonut, setDataDonut] = useState([])
    const [dataBarPieces, setDataBarPieces] = useState([])
    const [labelsBarPieces, setLabelsBarPieces] = useState([])
    const [dataBarDis, setDataBarDis] = useState([])


    const [dataBarPiecesAux, setDataBarPiecesAux] = useState([])
    const [dataBarDisAux, setDataBarDisAux] = useState([])
    const [optionSelect, setOptionSelect] = useState([])
    const [dataDonutTypeMov, setDataDonutTypeMov] = useState([])
    const [dataLabelDonutMov, setDataLabelDonutMov] = useState([])
    const [dataDonutTypeVenta, setDataDonutTypeVenta] = useState([])
    const [dataLabelDonutVenta, setDataLabelDonutVenta] = useState([])
    const [optionSelected, setOptionSelected] = useState('')
    const [idGroup, setIdGroup] = useState(0)
    const [optionTypeMov, setOptionTypeMov] = useState('')
    const [numeroTotal, setNumeroTotal] = useState('')
    const [numTotal, setNumTotal] = useState('')
    const [numTotalTypMov, setNumTotalTypMov] = useState('')
    const [showWindow, setShowWindow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [countLoader, setCountLoader] = useState(0);
    // querie para llenar filtro por distribuidor
    useQuery(GET_ALL_DISTRIBUTORS, {
        fetchPolicy: 'no-cache',

        onCompleted({ getAllDistributors }) {
            /*   const reponse=JSON.parse(getAllDistributors); */
            setOptionSelect(getAllDistributors);
            setCountLoader((count) => count + 1)
            //console.log("GET_ALL_DISTRIBUTORS")
        },
        onError(e) {
            //console.log("Error", e)
        }
    })
    // queries iniales para llenar graficas y tablas 
    useQuery(GET_BEST_DIS_PRO_FITS, {
        fetchPolicy: 'no-cache',
        onCompleted({ getBestDisProfits }) {
            const bestDisProfits = getBestDisProfits.map(({ total_amount, distribuidor }) => {
                return {
                    label: distribuidor,
                    backgroundColor: '#80BDDD',
                    borderColor: '#026ba1',
                    borderRadius: 5,
                    borderWidth: 1,
                    hoverBackgroundColor: '#026ba1',
                    data: [total_amount]
                }
            })
            setDataBarDis(bestDisProfits)
            setDataBarDisAux(bestDisProfits)
            setCountLoader((count) => count + 1)
            //console.log("GET_BEST_DIS_PRO_FITS")
        }
    })
    useQuery(GET_BEST_SELL_PART, {
        fetchPolicy: 'no-cache',
        onCompleted({ getBestSellPart }) {
            let response = JSON.parse(getBestSellPart);
            let arrayPiezas = [];
            let labels = []
            let disFilter = []
            response.forEach(obj => {
                arrayPiezas.push(obj.celda_M)
                labels.push(obj.parte)
            });
            disFilter = [...labels]
            disFilter.map((obj, i) => {
                if (obj == '<NULL>') {
                    disFilter[i] = 'OTROS'
                }
                disFilter.push()


            })
            setDataBarPieces(arrayPiezas)
            setLabelsBarPieces(disFilter)
            //console.log(labels)
            //console.log(disFilter)

            setDataBarPiecesAux(response)
            setCountLoader((count) => count + 1)
            //console.log("GET_BEST_SELL_PART")
        },
        onError(err) {
            //console.log('ERROR: getBestSellPart', err)
        }
    })
    useQuery(GET_ABC_NUMBER, {
        fetchPolicy: 'no-cache',
        onCompleted({ getABCNumber }) {
            let count = []
            getABCNumber?.forEach((obj) => {
                return (
                    count.push(obj.count)
                )
            })
            const suma = count.reduce((prev, next) => prev + next)
            setNumeroTotal(parseInt(suma))
            setDataDonut(count)
            setCountLoader((count) => count + 1)
            //console.log("GET_ABC_NUMBER")
        }
    })
    const [getPotObsoletos] = useLazyQuery(GET_POT_OBSOLETOS, {
        fetchPolicy: 'no-cache',
        onCompleted({ getPotObsoletos }) {
            debugger
            setDataAux(getPotObsoletos);
            setDataTable(getPotObsoletos);
            let listLabelsExcel = []
            setDataTableExport(getPotObsoletos?.map((obj) => {
                const statusMarket = obj.statusMarket == false ? 'No disponible en MP' : 'Disponible en MP'
                return {
                    id_parte: obj.id_parte,
                    Name: obj.Name,
                    tipo_movimiento: obj.tipo_movimiento,
                    distribuidor: obj.distribuidor,
                    PrecioVenta: obj.PrecioVenta,
                    Costo_pieza: obj.Costo_pieza,
                    statusMarket
                }
            }))
            //console.log("listLabelsExcel", listLabelsExcel)
            setCountLoader((count) => count + 1)
            //console.log("getPotObsoletos")
        }, onError(err) {
            //console.log(err)
        }

    })

    // querie para filtrar grafica de dona
    const [getABCDistribuidor] = useLazyQuery(GET_ABCD_DISTRIBUIDOR, {
        fetchPolicy: 'no-cache',
        onCompleted({ getABCDistributor }) {
            let count = []
            getABCDistributor?.forEach((obj) => {
                return (
                    count.push(obj.count)
                )
            })
            setDataDonut(count)
            if (count.length >= 1) {
                const suma = count?.reduce((prev, next) => prev + next)
                setNumeroTotal(parseInt(suma) || 0)
                // setCountLoader((count) => count + 1)
            }
            //console.log("getABCDistribuidor")

        }, onError(err) {
            //console.log(err)
        }

    })
    //QUERY PARA GRAFICA DE TIPO DE MOVIENTO 
    const [getMovDistributor] = useLazyQuery(GET_MOV_DISTRIBUTOR, {
        fetchPolicy: 'no-cache',
        onCompleted({ getMovDistributor }) {
            debugger
            let disFilter = []
            let filterCount = []
            let labels = Object?.keys(getMovDistributor[0])
            let datCount = Object?.values(getMovDistributor[0])
            labels.map((obj, i) => {
                if (obj !== '__typename') {
                    disFilter.push(obj)
                }

            })


            filterCount = datCount.filter(obj => obj !== 'MovData')
            setDataLabelDonutMov(disFilter);
            setDataDonutTypeMov(datCount);
            const suma = filterCount.reduce((prev, next) => prev + next)
            setNumTotalTypMov(parseInt(suma))
            setCountLoader((count) => count + 1)
            //console.log("getMovDistributor")
        }, onError(err) {
            //console.log(err)
        }
    })
    const [getTypeSaleDistributor] = useLazyQuery(GET_TYPE_SALE_DISTRIBUTOR, {
        fetchPolicy: 'no-cache',
        onCompleted({ getTypeSaleDistributor }) {
            let labels = []
            let count = []
            getTypeSaleDistributor?.forEach((obj) => {
                return (
                    labels.push(obj.tipo_venta),
                    count.push(obj.count)
                )
            })
            labels.map((obj, i) => {
                if (obj == null) {
                    labels[i] = 'OTROS'
                }

            })

            const suma = count.reduce((prev, next) => prev + next)
            setNumTotal(parseInt(suma))
            setDataLabelDonutVenta(labels);
            setDataDonutTypeVenta(count);
            setCountLoader((count) => count + 1)
            //console.log("getTypeSaleDistributor")
        }, onError(err) {
            //console.log(err)
        }
    })

    const options = [
        { value: 'Potencial obsoleto', label: 'Potencialmente obsoletos' },
        { value: 'Obsoleto', label: 'Obsoletos' }
    ]
    const filterByTypMove = ({ target }) => {

        setOptionTypeMov(target.value)
        const email = sessionStorage.getItem('auxEmail')
        getPotObsoletos({
            variables: {
                tipMov: target.value,
                email: email
            }
        })
        if (target.value == '') {
            const email = sessionStorage.getItem('auxEmail')
            getPotObsoletos({
                variables: {
                    tipMov: '',
                    email: email
                }
            })
        }


    }


    const filterByDistribuidor = ({ target }) => {
        const { value } = target;
        //console.log('grupo', value)
        if (value === "") {
            setNumeroTotal('')
            setNumTotal('')
            setNumTotalTypMov('')
            setOptionSelected('')
            let arrayPiezas = [];
            let labels = [];

            dataBarPiecesAux.forEach(obj => {
                arrayPiezas.push(obj.celda_M)
                labels.push(obj.parte)
            });

            setDataBarPieces(arrayPiezas)
            setLabelsBarPieces(labels)
            setDataBarDis(dataBarDisAux)

            getABCDistribuidor({
                variables: {
                    e: sessionStorage.getItem('emailGeneral')
                }
            })
            getPotObsoletos({
                variables: {
                    tipMov: "",
                    email: sessionStorage.getItem('emailGeneral')
                }
            })
            getTypeSaleDistributor({
                variables: {
                    e: sessionStorage.getItem('emailGeneral')

                }
            })
            getMovDistributor({
                variables: {
                    e: sessionStorage.getItem('emailGeneral')
                }
            })



        } else {
            setNumeroTotal('')
            setNumTotal('')
            setNumTotalTypMov('')
            const typeDisSplit = String(value).split('_');
            setOptionSelected(value)
            sessionStorage.setItem('auxEmail', typeDisSplit[1])
            const byDistribuidor =
                dataAux?.filter(item => item.distribuidor === typeDisSplit[0])
            const byDisBarPieces =
                dataBarPiecesAux.filter(item => item.distribuidor === typeDisSplit[0])
            const byDataBarDis =
                dataBarDisAux.filter(item => item.label === typeDisSplit[0])
            const group = typeDisSplit[2]
            setIdGroup(group)


            setDataTable(byDistribuidor)
            let sum4 = 0
            let sum5 = 0
            byDisBarPieces.forEach(obj => {
                if (obj.id_parte === 4) {
                    sum4 += obj.celda_M
                } else if (obj.id_parte === 5) {
                    sum5 += obj.celda_M
                }
            });
            setDataBarPieces([sum4, sum5])

            setDataBarDis(byDataBarDis)

            getABCDistribuidor({
                variables: {
                    e: typeDisSplit[1]
                }
            })
            getMovDistributor({
                variables: {
                    e: typeDisSplit[1]
                }
            })
            getPotObsoletos({
                variables: {
                    tipMov: "Potencial obsoleto",
                    email: typeDisSplit[1]

                }
            })
            getTypeSaleDistributor({
                variables: {
                    e: typeDisSplit[1]

                }
            })


        }


    }

    useEffect(() => {
        //console.log('counter', countLoader)
        if (countLoader >= 1) {
            setIsLoading(false)
        }
    }, [countLoader])
    useEffect(() => {

        const sizeWindow = window.screen.width;
        if (sizeWindow >= 768) {
            setShowWindow(true);
        }
        const email = sessionStorage.getItem('auxEmail')
        sessionStorage.setItem('emailGeneral', email)
        //console.log('emailCiente', email);
        getABCDistribuidor({
            variables: {
                e: email
            }
        })
        getPotObsoletos({
            variables: {
                tipMov: "",
                email: email

            }
        })
        getTypeSaleDistributor({
            variables: {
                e: email

            }
        })
        getMovDistributor({
            variables: {
                e: email
            }
        })

    }, [])

    if (isLoading) {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress disableShrink size={100} />
            </div>
        )
    }
    return (
        <div className="chartContainer">
            <p
                style={{ color: '#02274190', fontWeight: 'bold', fontSize: 25, fontStyle: 'normal', top: 118, font: 'Roboto', lineHeight: '140%' }}
            >DASHBOARD
            </p>
            <br />
            <FilterByDistributor
                filterByDistribuidor={filterByDistribuidor}
                optionSelect={optionSelect}
                value={optionSelected}
            />
            {showWindow ? <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    marginBottom: '4%',
                    justifyItems: 'start',
                    marginTop: '2%',
                    height: '50%',

                }}

            >
                {
                    dataDonut.length > 0 &&
                    <ChartDealers
                        height='100%'
                        dataDonut={dataDonut}
                        numeroToTal={numeroTotal}
                        handleClick={
                            (data, item) => {
                                sessionStorage.setItem('chart', 'Porcentaje ABC')
                                const index = item[0]?.index
                                const value = data.datasets[0].data[index]
                                const label = data.labels[index]
                                if (label === 'Elemento A')
                                    history.push(`/dashboardMovimientos?mov=A&&chart=AnalisisABC`)
                                else if (label === 'Elemento B')
                                    history.push(`/dashboardMovimientos?mov=B&&chart=AnalisisABC`)
                                else if (label === 'Elemento C')
                                    history.push(`/dashboardMovimientos?mov=C&&chart=AnalisisABC`)

                            }}
                    />
                }
                {
                    dataDonutTypeMov.length > 0 && <ChartDealers
                        title='Tipo de movimiento'
                        height='100%'
                        backgroundColor={['#03a9f4', '#cddc39', '#ff5722', '#e91e63', '#009688', '#6394b8']}
                        dataDonut={dataDonutTypeMov}
                        labels={dataLabelDonutMov}
                        numeroToTal={numTotalTypMov}
                        handleClick={(data, item) => {
                            sessionStorage.setItem('chart', 'Tipo de Movimiento')
                            const index = item[0]?.index
                            const value = data.datasets[0].data[index]
                            const label = data.labels[index]
                            history.push(`/dashboardMovimientos?mov=${label}&&option=${optionSelected}&&idGroup=${idGroup}&&chart=TypeMov`)

                        }}
                    />
                }
            </div>
                :
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        marginBottom: '4%',
                        justifyItems: 'start',
                        marginTop: '2%',
                        height: '50%',

                    }}

                >
                    {
                        dataDonut.length > 0 &&
                        <ChartDealers
                            height='262px'
                            weight='335px'
                            num={3.5}
                            dataDonut={dataDonut}
                            numeroToTal={numeroTotal}
                            handleClick={
                                (data, item) => {
                                    sessionStorage.setItem('chart', 'Porcentaje ABC')
                                    const index = item[0]?.index
                                    const value = data.datasets[0].data[index]
                                    const label = data.labels[index]
                                    if (label === 'Elemento A')
                                        history.push(`/dashboardMovimientos?mov=A&&chart=AnalisisABC`)
                                    else if (label === 'Elemento B')
                                        history.push(`/dashboardMovimientos?mov=B&&chart=AnalisisABC`)
                                    else if (label === 'Elemento C')
                                        history.push(`/dashboardMovimientos?mov=C&&chart=AnalisisABC`)

                                }}
                        />
                    }
                    <div style={{ height: '5%', padding: '4%' }}>

                    </div>
                    {
                        dataDonutTypeMov.length > 0 && <ChartDealers
                            title='Tipo de movimiento'
                            height='262px'
                            weight='335px'
                            num={4.3}
                            backgroundColor={['#03a9f4', '#cddc39', '#ff5722', '#e91e63', '#009688', '#6394b8']}
                            dataDonut={dataDonutTypeMov}
                            labels={dataLabelDonutMov}
                            numeroToTal={numTotalTypMov}
                            handleClick={(data, item) => {
                                sessionStorage.setItem('chart', 'Tipo de Movimiento')
                                const index = item[0]?.index
                                const value = data.datasets[0].data[index]
                                const label = data.labels[index]
                                history.push(`/dashboardMovimientos?mov=${label}&&option=${optionSelected}&&idGroup=${idGroup}&&chart=TypeMov`)

                            }}
                        />
                    }
                </div>

            }
            {showWindow ?
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr ',
                        marginBottom: '4%',
                        justifyItems: 'start',
                        marginTop: '2%',
                        height: '50%',
                    }}
                >
                    {
                        dataDonutTypeVenta.length > 0 && <ChartDealers
                            title='Tipo de venta'
                            height='100%'
                            backgroundColor={['#e75226', '#39dcc5', '#46aaf2']}
                            dataDonut={dataDonutTypeVenta}
                            labels={dataLabelDonutVenta}
                            numeroToTal={numTotal}
                            handleClick={(data, item) => {
                                //console.log('data', data)

                                sessionStorage.setItem('chart', 'Tipo de Venta')
                                const index = item[0]?.index
                                const value = data.datasets[0].data[index]
                                const label = data.labels[index]
                                {
                                    label === 'Hojalatería y pintura' ?
                                        history.push(`/dashboardMovimientos?mov=HyP&&chart=TypeVenta`) :
                                        history.push(`/dashboardMovimientos?mov=${label}&&chart=TypeVenta`)
                                }
                            }
                            }
                        />
                    }
                    <ChartBestSellinngsPieces height={'100%'} width={'90%'} dataBarPieces={dataBarPieces} labels={labelsBarPieces} />
                </div>
                :
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        marginBottom: '4%',
                        justifyItems: 'start',
                        marginTop: '67%',
                        height: '50%',
                    }}
                >
                    {
                        dataDonutTypeVenta.length > 0 && <ChartDealers
                            title='Tipo de venta'
                            height='265px'
                            weight='335px'
                            num={4.3}
                            backgroundColor={['#e75226', '#39dcc5', '#46aaf2']}
                            dataDonut={dataDonutTypeVenta}
                            labels={dataLabelDonutVenta}
                            numeroToTal={numTotal}
                            handleClick={(data, item) => {
                                //console.log('data', data)

                                sessionStorage.setItem('chart', 'Tipo de Venta')
                                const index = item[0]?.index
                                const value = data.datasets[0].data[index]
                                const label = data.labels[index]
                                {
                                    label === 'Hojalatería y pintura' ?
                                        history.push(`/dashboardMovimientos?mov=HyP&&chart=TypeVenta`) :
                                        history.push(`/dashboardMovimientos?mov=${label}&&chart=TypeVenta`)
                                }
                            }
                            }
                        />
                    }
                    <div style={{ height: '5%', padding: '4%' }}>
                    </div>
                    <ChartBestSellinngsPieces height={'265px'} width={'335px'} dataBarPieces={dataBarPieces} labels={labelsBarPieces} />
                </div>
            }


            {/* <PieChartAbC dataBarDis={dataBarDis} /> */}
            {showWindow ?
                <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '2%' }}>
                    <select
                        style={{ width: 300, borderRadius: 5, padding: 5 }}
                        value={optionTypeMov}
                        onChange={(e) => filterByTypMove(e)}
                    >
                        <option selected value=''>Elige un tipo de movimiento</option>
                        {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}

                    </select>
                </div>
                :
                <div className='filter'>
                    <select
                        style={{ width: 300, borderRadius: 5, padding: 5 }}
                        value={optionTypeMov}
                        onChange={(e) => filterByTypMove(e)}
                    >
                        <option selected value=''>Elige un tipo de movimiento</option>
                        {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}

                    </select>
                </div>
            }
            <StatsTable
                id="tableDashboard"
                dataTable={dataTable}
                optionSelect={optionSelected}
                idGroup={idGroup}
            />
            <br />
            <input type='button' value="Exportar tabla" className='btn btn-primary' onClick={() => downloadExcel(dataTableExport, 'tabla-partes')} />
            <div
                style={{ align: "right", width: "200px" }}
            >
            </div>

        </div>
    )
}
export default Cliente;