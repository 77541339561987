import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import tagNuevo from "../../assets/marketplace/tagNuevo.svg";
import { Grid } from "@mui/material";

import classes from "../../styles/Start/GenericCategoriesCard.module.scss";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GenericCardCategory({
  arrayCards = [],
  limit = 0,
  marginTop = "0%",
  flexWrap = "",
  padding = 0,
  categories,
  handler,
  categoriaActual,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // //console.log(arrayCards);
  arrayCards.map((current) => {
    const categoria = arrayCards.filter((c) => c.Name == current.Name);
    // //console.log(categoria);
  });

  const filtrados = [];
  let categoria = [];
  let count = 0;
  // //console.log(categories);

  let history = useHistory();
  return (
    <>
      <div className={classes.General_Container_Cards}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 10, sm: 12, md: 12 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {categories.map((current, index) => (
            <>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                item
                xs={10}
                sm={6}
                md={3}
                lg={4}
                xl={4}
                key={index}
              >
                <div className={classes.Container_Card}>
                  <Card
                    className={classes.Card}
                    sx={[
                      {
                        // height: 300,
                        // maxWidth: 300,
                        boxShadow: " -2px 2px 14px -2px rgba(0,0,0,0.58)",
                      },
                      {
                        "&:hover": {
                          boxShadow: " 1px 1px 1px 1px rgba(0,0,0,0.58)",
                          cursor: "pointer",
                        },
                      },
                    ]}
                    xs={10}
                    sm={4}
                    md={3}
                    lg={4}
                    xl={4}
                    key={index}
                    onClick={() => {
                      history.push(
                        `/categorias/${current.Categorias}/${current.id_Categoria}`
                      );
                      // handler(true);
                      // categoriaActual(current.Categorias);
                    }}
                  >
                    <CardMedia
                      className={classes.card_Image}
                      component="img"
                      image={current.image}
                      alt="Paella dish"
                    />
                  </Card>
                  <div className={classes.Name_Category}>
                    {current.Categorias}
                  </div>
                </div>
              </Grid>
            </>
          ))}{" "}
        </Grid>
      </div>

      <br />
    </>
  );
}
