import React from "react";

import FooterComp from "./footer";
import "../../styles/Footer/style.scss";

class Footer extends React.Component {
  render() {
    return (
      <footer className="d-flex">
        <FooterComp />
      </footer>
    );
  }
}

export default Footer;
