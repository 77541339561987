import React, { useState, useEffect } from "react";
import DropIcon from "../../assets/sortUp.svg";
import TopImg from "../../assets/topImage.jpg";
import Skeleton from "react-loading-skeleton";
import "../../styles/Loaders/style.scss";
import tiImageMarket from "../../assets/marketplace/top-image-Market.jpg";

import Modal from "../Modals/search";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function TopImage() {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchField, setSearchField] = useState("");
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const loader = () => {
    return (
      <div className="image-area2 ">
        <div className="filterImg d-flex">
          <Dropdown isOpen={firstDropdownOpen} toggle={firstToggle}>
            <DropdownToggle caret>Seleccionar auto parte</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                toggle={false}
                className="searchbarDropdownContainer"
              >
                <input
                  className="searchbarDropdown"
                  type="search"
                  placeholder="Buscar vehículo ó no. parte"
                  onChange={handleChange}
                />
              </DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
              <DropdownItem divider color="#027cba" />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
              <DropdownItem>Foo Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {/* </div> */}
          <Dropdown isOpen={secondDropdownOpen} toggle={secondToggle}>
            <DropdownToggle caret>Marca</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                toggle={false}
                className="searchbarDropdownContainer"
              >
                <input
                  className="searchbarDropdown"
                  type="search"
                  placeholder="Buscar vehículo ó no. parte"
                  onChange={handleChange}
                />
              </DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={thirdDropdownOpen} toggle={thirdToggle}>
            <DropdownToggle caret>Modelo</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                toggle={false}
                className="searchbarDropdownContainer"
              >
                <input
                  className="searchbarDropdown"
                  type="search"
                  placeholder="Buscar vehículo ó no. parte"
                  onChange={handleChange}
                />
              </DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={fourthDropdownOpen} toggle={fourthToggle}>
            <DropdownToggle caret>Motor</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                toggle={false}
                className="searchbarDropdownContainer"
              >
                <input
                  className="searchbarDropdown"
                  type="search"
                  placeholder="Buscar vehículo ó no. parte"
                  onChange={handleChange}
                />
              </DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={fifthDropdownOpen} toggle={fifthToggle}>
            <DropdownToggle caret>Año</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                toggle={false}
                className="searchbarDropdownContainer"
              >
                <input
                  className="searchbarDropdown"
                  type="search"
                  placeholder="Buscar vehículo ó no. parte"
                  onChange={handleChange}
                />
              </DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <button className="btn  filterButton">Buscar</button>
        </div>
      </div>
    );
  };
  //
  const [firstDropdownOpen, setFirstDropdownOpen] = useState(false);
  const firstToggle = () => {
    setFirstDropdownOpen((prevState) => !prevState);
  };
  //
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const secondToggle = () => {
    setSecondDropdownOpen((prevState) => !prevState);
  };
  //
  const [thirdDropdownOpen, setThirdDropdownOpen] = useState(false);
  const thirdToggle = () => {
    setThirdDropdownOpen((prevState) => !prevState);
  };
  //
  const [fourthDropdownOpen, setFourthDropdownOpen] = useState(false);
  const fourthToggle = () => {
    setFourthDropdownOpen((prevState) => !prevState);
  };
  //
  const [fifthDropdownOpen, setFifthDropdownOpen] = useState(false);
  const fifthToggle = () => {
    setFifthDropdownOpen((prevState) => !prevState);
  };

  if (loading) {
    return loader();
  } else {
    return (
      <>
        <div className=" image-area ">
          <img src={tiImageMarket} />
          {/* <div className="textImg d-flex">
            {// <div className={firstDropdownOpen ? "modalBackground1" : "hide"}> }
            <Dropdown isOpen={firstDropdownOpen} toggle={firstToggle}>
              <DropdownToggle caret>Seleccionar auto parte</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  toggle={false}
                  className="searchbarDropdownContainer"
                >
                  <input
                    className="searchbarDropdown"
                    type="search"
                    placeholder="Buscar"
                    onChange={handleChange}
                  />
                </DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem divider color="#027cba" />
                <DropdownItem>Foo Action</DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem>Foo Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/* </div> }
            <Dropdown isOpen={secondDropdownOpen} toggle={secondToggle}>
              <DropdownToggle caret>Marca</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  toggle={false}
                  className="searchbarDropdownContainer"
                >
                  <input
                    className="searchbarDropdown"
                    type="search"
                    placeholder="Buscar"
                    onChange={handleChange}
                  />
                </DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Foo Action</DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem>Foo Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown isOpen={thirdDropdownOpen} toggle={thirdToggle}>
              <DropdownToggle caret>Modelo</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  toggle={false}
                  className="searchbarDropdownContainer"
                >
                  <input
                    className="searchbarDropdown"
                    type="search"
                    placeholder="Buscar"
                    onChange={handleChange}
                  />
                </DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem divider/>
                <DropdownItem>Foo Action</DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem>Foo Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown isOpen={fourthDropdownOpen} toggle={fourthToggle}>
              <DropdownToggle caret>Motor</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  toggle={false}
                  className="searchbarDropdownContainer"
                >
                  <input
                    className="searchbarDropdown"
                    type="search"
                    placeholder="Buscar"
                    onChange={handleChange}
                  />
                </DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Foo Action</DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem>Foo Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown isOpen={fifthDropdownOpen} toggle={fifthToggle}>
              <DropdownToggle caret>Año</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  toggle={false}
                  className="searchbarDropdownContainer"
                >
                  <input
                    className="searchbarDropdown"
                    type="search"
                    placeholder="Buscar"
                    onChange={handleChange}
                  />
                </DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Foo Action</DropdownItem>
                <DropdownItem>Bar Action</DropdownItem>
                <DropdownItem>Quo Action</DropdownItem>
                <DropdownItem>Foo Action</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <button className="btn  filterButton">Buscar</button>
            <div className="searchWihtOutVehicle" onClick={handleShow}>
              Buscar sin vehículo
            </div>
          </div> */}
        </div>
        <Modal open={show} onHide={handleClose} />
      </>
    );
  }
}

//   return (
//     <>
//     <div className=" image-area ">
//       <img src={TopImg} />
//       <div className="textImg d-flex">
//         {/* <div className={firstDropdownOpen ? "modalBackground1" : "hide"}> */}
//         <Dropdown isOpen={firstDropdownOpen} toggle={firstToggle}>
//           <DropdownToggle caret>Seleccionar auto parte</DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem toggle={false} className="searchbarDropdownContainer">
//               <input
//                 className="searchbarDropdown"
//                 type="search"
//                 placeholder="Buscar vehículo ó no. parte"
//                 onChange={handleChange}
//               />
//             </DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//             <DropdownItem divider color="#027cba" />
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//             <DropdownItem>Foo Action</DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//         {/* </div> */}
//         <Dropdown isOpen={secondDropdownOpen} toggle={secondToggle}>
//           <DropdownToggle caret>Marca</DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//             <DropdownItem divider />
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//         <Dropdown isOpen={thirdDropdownOpen} toggle={thirdToggle}>
//           <DropdownToggle caret>Modelo</DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//             <DropdownItem divider />
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//         <Dropdown isOpen={fourthDropdownOpen} toggle={fourthToggle}>
//           <DropdownToggle caret>Motor</DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//             <DropdownItem divider />
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//         <Dropdown isOpen={fifthDropdownOpen} toggle={fifthToggle}>
//           <DropdownToggle caret>Año</DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//             <DropdownItem divider />
//             <DropdownItem>Foo Action</DropdownItem>
//             <DropdownItem>Bar Action</DropdownItem>
//             <DropdownItem>Quo Action</DropdownItem>
//           </DropdownMenu>
//         </Dropdown>
//         <button className="btn  filterButton">Buscar</button>
//         <div className="searchWihtOutVehicle" onClick={handleShow}>
//           Buscar sin vehiculo
//         </div>
//       </div>

//     </div>
//     <Modal open={show} onHide={handleClose} />
//     </>
//   );
// }

// /* <ul id="ulTop">
//           <li className="menuItem">
//             Seleccionar vehículo
//             <img src={DropIcon} className="dropIcon" />
//             <ul className="dropdown">
//               <li className="dropItem">Test1</li>
//               <li className="dropItem">Test2</li>
//               <li className="dropItem">Test3</li>
//               <li className="dropItem">Test4</li>
//             </ul>
//           </li>
//           <li className="menuItem">
//             Marca
//             <img src={DropIcon} className="dropIcon" />
//             <ul className="dropdown">
//               <li className="dropItem">Test1</li>
//               <li className="dropItem">Test2</li>
//               <li className="dropItem">Test3</li>
//               <li className="dropItem">Test4</li>
//             </ul>
//           </li>
//           <li className="menuItem">
//             Modelo
//             <img src={DropIcon} className="dropIcon" />
//             <ul className="dropdown">
//               <li className="dropItem">Test1</li>
//               <li className="dropItem">Test2</li>
//               <li className="dropItem">Test3</li>
//               <li className="dropItem">Test4</li>
//             </ul>
//           </li>
//           <li className="menuItem">
//             Año
//             <img src={DropIcon} className="dropIcon" />
//             <ul className="dropdown">
//               <li className="dropItem">Test1</li>
//               <li className="dropItem">Test2</li>
//               <li className="dropItem">Test3</li>
//               <li className="dropItem">Test4</li>
//             </ul>
//           </li>

//           <button className="btn  filterButton">Buscar</button>
//         </ul> */
