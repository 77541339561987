import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/LOGO.png";
import { useQuery } from '@apollo/client';
import { GET_ALL_DISTRIBUTORS } from '../../graphql/Query'


function LogoCom() {
  const [compareEmail, setCompareEmail] = useState(true)
  const [returnLog, setReturnLog] = useState(true)

  let correo = sessionStorage.getItem('auxEmail')

  useQuery(GET_ALL_DISTRIBUTORS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getAllDistributors }) {
      // debugger
      let emaill = []
      let dis = []
      getAllDistributors?.forEach((obj) => {
        return (
          dis.push(obj.email)
        )
      })
      emaill = dis.filter(obj => obj === correo)
      //console.log('email',emaill)

      if (correo === null || correo === 0) {
        setReturnLog(false)
      } else if (correo !== null || correo !== 0) {
        if (emaill == correo) {
          setCompareEmail(true)
        } else if (emaill !== correo) {
          setCompareEmail(false)
        }
      }
    }

  })

  if (!returnLog) {
    return (
      <>
        <Link
          to={window.location.pathname === "/store" ||
            window.location.pathname === "/AllProducts" ||
            window.location.pathname === "/insideProduct" ?
            "/" : null}
        >
          <img className="Logo" src={Logo} />
        </Link>
      </>
    );
  } if (compareEmail) {
    return (
      <>
        <Link
          to={window.location.pathname === "/dashboardMovimientosDis" ||
            window.location.pathname === "/store" ||
            window.location.pathname === "/AllProducts" ||
            window.location.pathname === "/insideProduct" ? "/dashboardDis" : null}
        >
          <img className="Logo" src={Logo} />
        </Link>
      </>
    );
  } else {
    return (
      <>
        <Link
          to={
              window.location.pathname === "/dashboardMovimientos" ||
              window.location.pathname === "/store" ||
              window.location.pathname === "/AllProducts" ||
              window.location.pathname === "/insideProduct" ? "/dashboard" : null}
        >
          <img className="Logo" src={Logo} />
        </Link>
      </>
    );
  }
}

export default LogoCom;
