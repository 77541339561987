import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
const initialState = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      /* hoverBackgroundColor: [], */
      cutout: "75%",
      spacing: 5,
      borderRadius: 50,
    },
  ],
};
function ChartDealers({
  dataDonut,
  height = 0,
  weight = "90%",
  title = "Análisis ABC",
  labels = ["Elemento A", "Elemento B", "Elemento C"],
  backgroundColor = ["#027CBB", "#027CBB80", "#C4C4C480"],
  numeroToTal = "12",
  numeroPrecio = "12",
  handleClick,
  num = 2.9,
}) {
  const validateHeight = height === 0 ? null : height;
  const validateWeight = weight === 0 ? null : weight;
  const numCenter = num === 0 ? null : num;
  const [total, setTotal] = useState(numeroToTal);
  const [totalPrecio, setTotalPrecio] = useState(numeroPrecio);
  //console.log("dataDonut", dataDonut);
  const [data, setData] = useState(initialState);
  const [showWindow, setShowWindow] = useState(false);
  const [showWindowLaptop, setShowWindowLaptop] = useState(false);
  const [showWindowLarge, setShowWindowLarge] = useState(false);
  const [showWindowFourK, setShowWindowFourK] = useState(false);


  const [legendPosition, setLegendPosition] = useState("right");
  const [minHeight, setMinHeight] = useState(0);
  const [sizeInnerWindow, setSizeInnerWindow] = useState(window.innerWidth);
  useEffect(() => {
    setData({
      ...data,
      labels: [...labels],
      datasets: [
        {
          ...data.datasets[0],
          data: [...dataDonut],
          backgroundColor: [...backgroundColor],
          /*  hoverBackgroundColor: [...hoverBackgroundColor] */
        },
      ],
    });
    setTotal(numeroToTal);
    setTotalPrecio(numeroPrecio);
  }, [dataDonut, total, totalPrecio, numeroToTal, numeroPrecio]);

  useEffect(() => {

    const sizeWindow = window.screen.width;
    if (sizeWindow <= 319 && sizeWindow <= 768) {
      setShowWindow(true);
    }
    if (sizeWindow >= 1023 && sizeWindow <= 1439) {
      setShowWindowLaptop(true);
    }
    if (sizeWindow >= 1440 && sizeWindow <= 2559) {
      setShowWindowLarge(true);
    }
    if (sizeWindow >= 2560) {
      setShowWindowFourK(true);
    }
  }, []);

  useEffect(() => {
    if (sizeInnerWindow < 768) {
      setMinHeight(450);
    }

    if (sizeInnerWindow < 1500) {
      setLegendPosition("bottom");
    } else {
      setLegendPosition("right");
    }
  }, [sizeInnerWindow]);

  window.addEventListener("resize", () => {
    setSizeInnerWindow(window.innerWidth);
  });

  if (!total || !numeroToTal) return null;
  if (showWindow) {
    console.log('PHONE')

    return (
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        <Doughnut
          options={{
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                handleClick(data, item);
              }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: legendPosition,
                color: "#C4C4C4",
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 2;
                let weight = "bold";
                ctx.font = weight + fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = total,
                  textX = Math.round(
                    (width - ctx.measureText(text).width) / 2.9
                  ),
                  textY = height / 1.9;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 2;
                let weight = "bold";
                ctx.font = weight + fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = totalPrecio,
                  textX = Math.round(
                    (width - ctx.measureText(text).width) / 2.9
                  ),
                  textY = height / 1.9;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
          ]}
          style={{ minHeight }}
        /*  style={{ height: '50%', width: '50%' }} */
        />
      </div>
    );
  }if (showWindowLaptop) {
    console.log('Laptop')
    return (
      
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        <Doughnut
          options={{
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                handleClick(data, item);
              }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: legendPosition,
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = total,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 1.8;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = totalPrecio,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.2;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                // let text = 'No asignadas',
                //   textX = Math.round((width - ctx.measureText(text).width) / 2.6),
                //   textY = height / 2.4;
                // ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
          ]}

        /*  style={{ height: '50%', width: '50%' }} */
        />
      </div>
    );
  }
  
  
  if (showWindowLarge) {
    console.log('LARGE')
    return (
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        
        <Doughnut
          options={{
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                handleClick(data, item);
              }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: legendPosition,
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = total,
                  textX = Math.round((width - ctx.measureText(text).width) / 2.5),
                  textY = height / 1.8;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = totalPrecio,
                  textX = Math.round((width - ctx.measureText(text).width) / 2.5),
                  textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.2;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                // let text = 'No asignadas',
                //   textX = Math.round((width - ctx.measureText(text).width) / 2.6),
                //   textY = height / 2.4;
                // ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
          ]}

        /*  style={{ height: '50%', width: '50%' }} */
        />
      </div>
    )
  }
  
  if (showWindowFourK) {
    console.log('4K')
    return (
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        
        <Doughnut
          options={{
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                handleClick(data, item);
              }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: legendPosition,
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = total,
                  textX = Math.round((width - ctx.measureText(text).width) / 2.5),
                  textY = height / 1.8;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = totalPrecio,
                  textX = Math.round((width - ctx.measureText(text).width) / 2.5),
                  textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.2;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                // let text = 'No asignadas',
                //   textX = Math.round((width - ctx.measureText(text).width) / 2.6),
                //   textY = height / 2.4;
                // ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
          ]}

        /*  style={{ height: '50%', width: '50%' }} */
        />
      </div>
    )
  }
  
  else {
    console.log('DEFAULT')
    return (
      
      <div
        style={{
          boxShadow: "0px 6px 7px 1px rgba(0,0,0,0.39)",
          padding: "10px",
          height: validateHeight,
          width: validateWeight,
          background: "white",
        }}
      >
        <Doughnut
          options={{
            onHover: (event) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: handleClick
              ? (evt, item) => {
                handleClick(data, item);
              }
              : null,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: legendPosition,
              },
              title: {
                display: true,
                text: title,
                align: "start",
                color: "#022741",
                font: {
                  size: 18,
                  weight: "700",
                },
              },
            },
          }}
          data={data}
          plugins={[
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = total,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 1.8;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.6;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                let text = totalPrecio,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              beforeDraw: function (chart) {
                let width = chart.width,
                  height = chart.height,
                  ctx = chart.ctx;
                ctx.restore();
                let fontSize = 1.2;
                ctx.font = fontSize + "em sans-serif";
                ctx.textBaseline = "top";
                // let text = 'No asignadas',
                //   textX = Math.round((width - ctx.measureText(text).width) / 2.6),
                //   textY = height / 2.4;
                // ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
          ]}

        /*  style={{ height: '50%', width: '50%' }} */
        />
      </div>
    );
  }
}
export default ChartDealers;
