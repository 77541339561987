import React, { useState, useEffect } from "react";
import NavComp from "../Navbar/index";
import {
  GET_MOV_ABCT_YPE_SALE,
  GET_DAY_DIFF,
  GET_UTILIDAD,
  GET_DAY_DIFF_SURTIDO,
  GET_COSTO_ALMACENAJE,
  GET_ALL_PRODUCTS,
  GET_COMPARE_MOV_TYPE,
  GET_MOV_ABCT_YPE_SALE_ADMIN,
  GET_COMPARE_MOV_TYPE2,
  GET_MOV_ABCT_YPE_SALE_ADMIN_NUMBER,
  GET_NAME_FILTERS,
} from "../../graphql/Query";
import { useLazyQuery } from "@apollo/client";
// import Modal from '@material-ui/core/Modal'
import { Box, Modal, Typography } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Title from "antd/lib/typography/Title";
import LeftAside from "../DashboardAdmin/LeftAside";
// import PartFilter from './PartFilter';
import { GenericTable } from "./GenericTable";
import { Link } from "react-router-dom";
import { HiDotsHorizontal, HiDotsVertical } from "react-icons/hi";
import GenericModalCompareDis from "./GenericModalCompareDis";
import CircularProgress from "@mui/material/CircularProgress";
import { Next } from "react-bootstrap/esm/PageItem";
import downloadExcel from "../../helpers/DownloadXLSX";

import Pagination from "@material-ui/lab/Pagination";
import  "../../styles/styleDashBoard.css";
import "../../styles/TooTip/style.css";
import {
  FcAlphabeticalSortingAz,
  FcAlphabeticalSortingZa,
} from "react-icons/fc";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const names = [
  "Utilidad",
  "Tiempos de venta",
  "Días de surtido  ",
  "Almacenaje",
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 5,
  height: 500,
};

const TableMov = () => {
  const email = sessionStorage.getItem("auxEmail");
  const [dataTable, setDataTable] = useState([]);
  const [columnsTableModal, setColumnsTableModal] = useState([]);
  const [dataTableModal, setDataTableModal] = useState([]);
  const [partName, setPartName] = useState("");
  const [IdParte, setIdParte] = useState(0);
  const [labelMov, setLabelMov] = useState("");
  const [optionToll, setOptionToll] = useState("");
  const [idGroup, setIdGroup] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [openCompare, setOpenCompare] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [infoFilters, setInfoFilters] = useState([]);

  const tipo = localStorage.getItem("typeUser");

  const handleClose = () => {
    setOpen(false);
    setFilterOption("");
  };
  const handleOpenCompareDis = () => setOpenCompare(true);
  const handleCloseCompareDis = () => setOpenCompare(false);

  const [filterOption, setFilterOption] = useState("");
  const [dataCompareModal, setDataCompareModal] = useState([]);

  const [leyend, setLeyend] = useState([]);
  const [dataChartHerDistri, setDataChartHerDistri] = useState([]);
  const [dataLabelsChartDis, setDataLabelsChartDis] = useState([]);
  const [showWindow, setShowWindow] = useState(false);
  const [page, setPage] = useState(1);
  const [numberPaginations, setnumberPaginations] = useState(0);
  //Grafica comparar con hermano distribuidor
  const [getCompareMovType] = useLazyQuery(GET_COMPARE_MOV_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted({ getCompareMovType }) {
      //debugger;
      ////console.log(getCompareMovType, 'getCompareMovType')
      setDataCompareModal(getCompareMovType);
      setColumnsTableModal([
        { title: "PARTE", field: "parte" },
        { title: "DISTRIBUIDOR", field: "distribuidor" },
        { title: "TIPO DE MOVIMIENTO", field: "tipo_movimiento" },
        { title: "PIEZAS DISPONIBLES", field: "numPartesDisponibles" },
      ]);
      let labels = [];
      let leyendTypeMov = [];
      const piezasDis1 = getCompareMovType[0]?.numPartesDisponibles || 0;
      const piezasDis2 = getCompareMovType[1]?.numPartesDisponibles || 0;
      const piezasDis3 = getCompareMovType[2]?.numPartesDisponibles || 0;
      getCompareMovType.forEach((obj) => {
        return (
          labels.push(obj.distribuidor), leyendTypeMov.push(obj.tipo_movimiento)
        );
      });
      setLeyend(leyendTypeMov);
      setDataLabelsChartDis(labels);
      setDataChartHerDistri([piezasDis1, piezasDis2, piezasDis3]);
    },
    onError(err) {
      //console.log(err);
    },
  });

  /*********************Comparar con hermanos  **************************************** */
  const [getCompareMovType2] = useLazyQuery(GET_COMPARE_MOV_TYPE2, {
    fetchPolicy: "no-cache",
    onCompleted({ getCompareMovType2 }) {
      //debugger;
      const actual = sessionStorage.getItem("chart");
      console.log(actual);
      console.log(getCompareMovType2, "getCompareMovType");
      setDataCompareModal(getCompareMovType2);

      //console.log(actual);
      actual == "Tipo de Venta" &&
        setColumnsTableModal([
          { title: "PARTE", field: "parte" },
          { title: "DISTRIBUIDOR", field: "distribuidor" },
          { title: "TIPO DE VENTA", field: "tipo_venta" },
          // { title: "PIEZAS DISPONIBLES", field: "numPartesDisponibles" },
        ]);
      actual == "Análisis ABC" &&
        setColumnsTableModal([
          { title: "PARTE", field: "parte" },
          { title: "DISTRIBUIDOR", field: "distribuidor" },
          { title: "ANALISIS ABC", field: "celda_R" },
          // { title: "PIEZAS DISPONIBLES", field: "numPartesDisponibles" },
        ]);
      actual == "Tipo de Movimiento" &&
        setColumnsTableModal([
          { title: "PARTE", field: "parte" },
          { title: "DISTRIBUIDOR", field: "distribuidor" },
          { title: "TIPO DE MOVIMIENTO", field: "tipo_movimiento" },
          // { title: "PIEZAS DISPONIBLES", field: "numPartesDisponibles" },
        ]);
    },
    onError(err) {
      //console.log(err);
    },
  });
  /************************************************************************** */
  const [getMovABCTypeSale] = useLazyQuery(GET_MOV_ABCT_YPE_SALE, {
    fetchPolicy: "no-cache",
    onCompleted({ getMovABCTypeSale }) {
      // debugger
      //console.log(getMovABCTypeSale);
      setDataTable(getMovABCTypeSale);
      setIsLoading(false);
      setLoading(false);
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getMovABCTypeSaleAdmin] = useLazyQuery(GET_MOV_ABCT_YPE_SALE_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getMovABCTypeSaleAdmin }) {
      // debugger
      //console.log(getMovABCTypeSaleAdmin);
      setDataTable(getMovABCTypeSaleAdmin);
      setIsLoading(false);
      setLoading(false);
      //console.log("complete getMovABCTypeSaleAdmin");
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getNameFilters] = useLazyQuery(GET_NAME_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted({ getNameFilters }) {
      console.log("*************");
      console.log(getNameFilters);
      setInfoFilters(getNameFilters);
    },
    onError(err) {
      console.log("*************");
      console.log(err);
    },
  });

  const [getMovABCTypeSaleAdminNumber] = useLazyQuery(
    GET_MOV_ABCT_YPE_SALE_ADMIN_NUMBER,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getMovABCTypeSaleAdminNumber }) {
        // debugger
        console.log(getMovABCTypeSaleAdminNumber);
        setnumberPaginations(
          Math.round(getMovABCTypeSaleAdminNumber[0].paginas / 1000)
        );
        //console.log("complete getMovABCTypeSaleAdmin");
      },
      onError(err) {
        //console.log(err);
      },
    }
  );
  const [getDayDiff] = useLazyQuery(GET_DAY_DIFF, {
    fetchPolicy: "no-cache",
    onCompleted({ getDayDiff }) {
      debugger;
      setDataTableModal(getDayDiff);
      setColumnsTableModal([
        { title: "ID PARTE", field: "id_parte" },
        { title: "PARTE", field: "parte" },
        { title: "FECHA ENTREGA ALMACÉN", field: "Fecha_de_entrega_almacen" },
        { title: "FECHA DE VENTA", field: "Fecha_de_venta" },
        { title: "DÍAS DE VENTA", field: "dayDiff" },
      ]);
      handleOpen();
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getUtilidad] = useLazyQuery(GET_UTILIDAD, {
    fetchPolicy: "no-cache",
    onCompleted({ getUtilidad }) {
      setDataTableModal(getUtilidad);
      setColumnsTableModal([
        { title: "ID PARTE", field: "id_parte" },
        { title: "PARTE", field: "parte" },
        { title: "COSTO DE PIEZA", field: "Costo_pieza" },
        { title: "PRECIO DE VENTA", field: "Precio_de_Venta" },
        { title: "UTILIDAD", field: "Utilidad" },
        { title: "COSTO TOTAL DE ALMACÉN", field: "Costo_total_almacen" },
        { title: "UTILIDAD REAL", field: "Utilidad_Real" },
      ]);
    },

    onError(err) {
      //console.log(err);
    },
  });

  const [getDayDiffSurtido] = useLazyQuery(GET_DAY_DIFF_SURTIDO, {
    fetchPolicy: "no-cache",
    onCompleted({ getDayDiffSurtido }) {
      debugger;
      let data = [];

      getDayDiffSurtido.map(function (dato) {
        if (dato.dayDiffSurtido == null) {
          dato.dayDiffSurtido = 0;
        }

        return data.push(dato);
      });

      setDataTableModal(data);
      setColumnsTableModal([
        { title: "ID PARTE", field: "id_parte" },
        { title: "PARTE", field: "parte" },
        { title: "FECHA ORDEN ALMACÉN", field: "Fecha_orden_almacen" },
        { title: "FECHA ENTREGA ALMACÉN", field: "Fecha_de_entrega_almacen" },
        { title: "DÍA DE SURTIDO", field: "dayDiffSurtido" },
      ]);
    },
    onError(err) {
      //console.log(err);
    },
  });
  const [getCostoAlmacenaje] = useLazyQuery(GET_COSTO_ALMACENAJE, {
    fetchPolicy: "no-cache",
    onCompleted({ getCostoAlmacenaje }) {
      setDataTableModal(getCostoAlmacenaje);
      setColumnsTableModal([
        { title: "ID PARTE", field: "id_parte" },
        { title: "PARTE", field: "parte" },
        { title: "DÍAS EN ALMACÉN", field: "dayDiff" },
        {
          title: "COSTO DE ALMACENAJE POR SEMANA",
          field: "Costo_de_almacenaje",
        },
        { title: "COSTO TOTAL ALMACENAJE", field: "Costo_total_almacen" },
      ]);
    },
    onError(err) {
      //console.log(err);
    },
  });

  const handleChangePage = (event, value) => {
    setLoading(true);
    let numPage = value;
    console.log("try-----");
    console.log(value);
    setPage(numPage);
    const queryParams = new URLSearchParams(window.location.search);
    const chartType =
      queryParams.get("chart") != null ? queryParams.get("chart") : "";
    const mov = queryParams.get("mov") != null ? queryParams.get("mov") : "";
    if (chartType == "TypeMov") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //     page: page
      //   },
      // });

      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: parseInt(value, 10),
          orderby: orderBy,
        },
      });
    } else if (chartType == "AnalisisABC") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //     page: page
      //   },
      // });

      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: parseInt(value, 10),
          orderby: orderBy,
        },
      });
    } else if (chartType == "TypeVenta") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //     page: page
      //   },
      // });
      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: parseInt(value, 10),
          orderby: orderBy,
        },
      });
    }
  };
  //   const handleChangeClose = () =>{
  //     setFilterOption("");
  //
  const handleAdvanceTable = (acction) => {
    debugger;
    setLoading(true);
    let numPage = 1;
    if (acction == "next") {
      numPage = page + 1;
    } else {
      numPage = page - 1;
    }
    setPage(numPage);
    const queryParams = new URLSearchParams(window.location.search);
    const chartType =
      queryParams.get("chart") != null ? queryParams.get("chart") : "";
    const mov = queryParams.get("mov") != null ? queryParams.get("mov") : "";
    if (chartType == "TypeMov") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //     page: page
      //   },
      // });

      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: page,
        },
      });
    } else if (chartType == "AnalisisABC") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //     page: page
      //   },
      // });

      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: page,
        },
      });
    } else if (chartType == "TypeVenta") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //     page: page
      //   },
      // });
      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: page,
        },
      });
    }
  };
  const handleChange = (event) => {
    // setTimeout(() => {
    //     setFilterOption("");
    //   }, 5000);
    const {
      target: { value },
    } = event;

    if (value.trim() === "Utilidad") {
      getUtilidad({
        variables: {
          // email: sessionStorage.getItem("auxEmail"),
          email: "admin@ballena.com",
          idParte: IdParte,
        },
      });
    } else if (value.trim() === "Días de surtido") {
      getDayDiffSurtido({
        variables: {
          email: "admin@ballena.com",
          idParte: IdParte,
        },
      });
    } else if (value.trim() === "Almacenaje") {
      getCostoAlmacenaje({
        variables: {
          email: "admin@ballena.com",
          idParte: IdParte,
        },
      });
    } else if (value.trim() === "Tiempos de venta") {
      getDayDiff({
        variables: {
          // email: sessionStorage.getItem("auxEmail"),
          email: "admin@ballena.com",
          idParte: IdParte,
        },
      });
    }
    setFilterOption(value);
  };

  const getData = (idParte, parte) => {
    setPartName(parte);
    setIdParte(idParte);
    ////console.log('parte',parte)
    getDayDiff({
      variables: {
        // email: sessionStorage.getItem("auxEmail"),
        email: "admin@ballena.com",
        idParte: idParte,
      },
    });
  };
  const handleTool = (id_parte) => {
    setIdParte(id_parte);
    console.log(id_parte);
    getCompareMovType2({
      variables: {
        idParte: id_parte,
        group: parseInt(idGroup),
      },
    });
    handleOpenCompareDis();
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };

  const marcasFiltradas = sessionStorage.getItem("marcasFiltradas");
  const idDistiltrado = sessionStorage.getItem("idDistiltrado");
  const idFather = sessionStorage.getItem("idFather");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const option =
      queryParams.get("option") != null ? queryParams.get("option") : "";
    const mov = queryParams.get("mov") != null ? queryParams.get("mov") : "";
    const idGroup =
      queryParams.get("idGroup") != null ? queryParams.get("idGroup") : "";
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }

    if (
      (marcasFiltradas && marcasFiltradas != "") ||
      (idDistiltrado && idDistiltrado != "") ||
      (idFather && idFather != "")
    ) {
      console.log(marcasFiltradas, idDistiltrado, idFather);
      getNameFilters({
        variables: {
          a: idDistiltrado,
          b: marcasFiltradas,
          c: idFather,
        },
      });
    }

    if (mov !== "") {
      // getMovABCTypeSale({
      //   variables: {
      //     email: sessionStorage.getItem('auxEmail'),//'contacto@ballena.com',
      //     MovABCTypeSale: mov,
      //   },
      // });
      getMovABCTypeSaleAdmin({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: page,
        },
      });
      setLabelMov(mov);

      getMovABCTypeSaleAdminNumber({
        variables: {
          a: marcasFiltradas,
          b: idDistiltrado,
          c: idFather,
          email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
          MovABCTypeSale: mov,
          page: page,
        },
      });
      ////console.log('labelMov', labelMov)
    }
    setOptionToll(option);
    setIdGroup(idGroup);
  }, []);

  function cmpEdad(a, b) {
    return a.Costo_pieza - b.Costo_pieza;
  }
  function cmpEdadmayor(a, b) {
    return b.Costo_pieza - a.Costo_pieza;
  }

  function cmpEdad2(a, b) {
    return a.PrecioVenta - b.PrecioVenta;
  }
  function cmpEdadmayor2(a, b) {
    return b.PrecioVenta - a.PrecioVenta;
  }
  function cmpEdad3(a, b) {
    return a.statusMarket - b.statusMarket;
  }
  function cmpEdadmayor3(a, b) {
    return b.statusMarket - a.statusMarket;
  }
  const ordenarId = (target) => {
    const queryParams = new URLSearchParams(window.location.search);
    const option =
      queryParams.get("option") != null ? queryParams.get("option") : "";
    const mov = queryParams.get("mov") != null ? queryParams.get("mov") : "";
    const idGroup =
      queryParams.get("idGroup") != null ? queryParams.get("idGroup") : "";

    let temporal = [];
    switch (target) {
      case "parte_des":
        document.getElementById("parte_des").style.display = "none";
        document.getElementById("parte_asc").style.display = "initial";
        setOrderBy("costoAlmacenaje asc");
        if (mov !== "") {
          getMovABCTypeSaleAdmin({
            variables: {
              a: marcasFiltradas,
              b: idDistiltrado,
              c: idFather,
              email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
              MovABCTypeSale: mov,
              page: page,
              orderby: "costoAlmacenaje asc",
            },
          });
          setLabelMov(mov);
        }
        break;
      case "parte_asc":
        document.getElementById("parte_des").style.display = "initial";
        document.getElementById("parte_asc").style.display = "none";
        setOrderBy("costoAlmacenaje desc");
        if (mov !== "") {
          getMovABCTypeSaleAdmin({
            variables: {
              a: marcasFiltradas,
              b: idDistiltrado,
              c: idFather,
              email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
              MovABCTypeSale: mov,
              page: page,
              orderby: "costoAlmacenaje desc",
            },
          });
          setLabelMov(mov);
        }
        break;
      case "description_asc":
        document.getElementById("description_des").style.display = "initial";
        document.getElementById("description_asc").style.display = "none";
        console.log("go");
        setOrderBy("parte asc");
        if (mov !== "") {
          getMovABCTypeSaleAdmin({
            variables: {
              a: marcasFiltradas,
              b: idDistiltrado,
              c: idFather,
              email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
              MovABCTypeSale: mov,
              page: page,
              orderby: "parte asc",
            },
          });
          setLabelMov(mov);
        }

        break;
      case "description_des":
        document.getElementById("description_des").style.display = "none";
        document.getElementById("description_asc").style.display = "initial";
        console.log("og");
        setOrderBy("parte desc");
        if (mov !== "") {
          getMovABCTypeSaleAdmin({
            variables: {
              a: marcasFiltradas,
              b: idDistiltrado,
              c: idFather,
              email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
              MovABCTypeSale: mov,
              page: page,
              orderby: "parte desc",
            },
          });
          setLabelMov(mov);
        }
        break;

      case "venta_asc":
        document.getElementById("venta_des").style.display = "initial";
        document.getElementById("venta_asc").style.display = "none";
        setOrderBy("tipo_venta asc");
        if (mov !== "") {
          getMovABCTypeSaleAdmin({
            variables: {
              a: marcasFiltradas,
              b: idDistiltrado,
              c: idFather,
              email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
              MovABCTypeSale: mov,
              page: page,
              orderby: "tipo_venta asc",
            },
          });
          setLabelMov(mov);
        }
        break;
      case "venta_des":
        document.getElementById("venta_des").style.display = "none";
        document.getElementById("venta_asc").style.display = "initial";
        setOrderBy("tipo_venta desc");
        if (mov !== "") {
          getMovABCTypeSaleAdmin({
            variables: {
              a: marcasFiltradas,
              b: idDistiltrado,
              c: idFather,
              email: sessionStorage.getItem("auxEmail"), //'contacto@ballena.com',
              MovABCTypeSale: mov,
              page: page,
              orderby: "tipo_venta desc",
            },
          });
          setLabelMov(mov);
        }
        break;
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress disableShrink size={100} />
      </div>
    );
  }

  return (
    <>
      <GenericModalCompareDis
        dataChartHerDistri={dataChartHerDistri}
        dataLabelsChartDis={dataLabelsChartDis}
        open={openCompare}
        handleClose={handleCloseCompareDis}
        dataCompareModal={dataCompareModal}
        columnsTableModal={columnsTableModal}
        parte={IdParte}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {showWindow ? (
          <Box sx={style} style={{ height: "80%" }}>
            <div style={{ display: "Flex" }}>
              <div style={{ width: "70%", marginTop: "2%" }}>
                <Title
                  style={{ fontSize: 18, fontWeight: 700, color: "#022741" }}
                >
                  Detalle de {partName}
                </Title>
              </div>
              {/* <PartFilter handleChange={handleChange} filterOption={filterOption} /> */}
              <div style={{ marginLeft: "7%" }}>
                <FormControl sx={{ m: 1, width: 200 }}>
                  <InputLabel id="demo-multiple-checkbox-label">...</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterOption}
                    onChange={handleChange}
                    // onOpen={handleChangeClose}
                    input={<OutlinedInput label="filtroModal" />}
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={filterOption.includes(name)}
                          style={{
                            marginInline: "-30px",
                            pointerEvents: "none",
                          }}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <GenericTable columns={columnsTableModal} body={dataTableModal} />
          </Box>
        ) : (
          <Box
            sx={style}
            style={{ width: "95%", paddingLeft: "2%", paddingRight: "2%" }}
          >
            <div style={{ display: "Flex" }}>
              <div style={{ width: "70%", marginTop: "2%" }}>
                <Title
                  style={{ fontSize: 18, fontWeight: 700, color: "#022741" }}
                >
                  Detalle de {partName}
                </Title>
              </div>
              {/* <PartFilter handleChange={handleChange} filterOption={filterOption} /> */}
              <div style={{ marginLeft: "7%" }}>
                <FormControl sx={{ m: 1, width: 200 }}>
                  <InputLabel id="demo-multiple-checkbox-label">...</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={filterOption}
                    onChange={handleChange}
                    // onOpen={handleChangeClose}
                    input={<OutlinedInput label="filtroModal" />}
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={filterOption.includes(name)}
                          style={{
                            marginInline: "-30px",
                            pointerEvents: "none",
                          }}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <GenericTable columns={columnsTableModal} body={dataTableModal} />
          </Box>
        )}
      </Modal>

      <NavComp />
      <div className="mainDashboard d-flex">
        {showWindow ? <LeftAside /> : null}
        <div className="mainDashboard tableMobile">
          <Link
            to={
              window.location.pathname === "/dashboardMovimientos"
                ? "/dashboard"
                : "/dashboardDis"
            }
          >
            <Title
              style={{
                // padding: 5,
                fontSize: 14,
                fontWeight: 400,
                marginBottom: 26,

                color: "#027CBB",
                height: 15,
              }}
            >
              {" "}
              <Link to={"/dashboardAdmin"}>
                <span
                  style={{
                    textTransform: "uppercase",
                    marginLeft: "3%",
                    padding: "0",
                  }}
                >
                  {"DASHBOARD >"} {sessionStorage.getItem("chart")}
                </span>
              </Link>
            </Title>
          </Link>

          <div
            style={{
              overflowY: "auto",
              height: "80%",
              width: "95%",
              marginLeft: "3%",
              boxShadow: '-1px 8px 12px -1px rgba(145,143,145,1)'

            }}
          >
            <table
              className="table table tableContainer tableContainerMobile "
              style={{
                background: "white",
                height: "auto",
                width: "100%",
              }}
            >
              <thead
                className="sticky"
                style={{
                  position: "relative",
                }}
              >
                <div>
                  <div>
                    {" "}
                    <Title
                      style={{
                        display: "flex",
                        padding: 5,
                        fontSize: 18,
                        fontWeight: 700,
                        marginTop: 16,
                        color: "#022741",
                        width: "max-content",
                      }}
                    >
                      {sessionStorage.getItem("chart")}
                    </Title>
                    <Title
                      style={{
                        padding: 5,
                        fontSize: 14,
                        fontWeight: 400,
                        marginBottom: 26,
                        color: "#C4C4C4",
                        textAlign: "justify",
                        height: "auto",
                        wordWrap: "break-word",
                        width: "600%",
                      }}
                    >
                      {" "}
                      {labelMov == "A" ? (
                        <span>
                          Tipo de artículos
                          <span style={{ textTransform: "uppercase" }}>
                            {" "}
                            {labelMov}
                          </span>
                          <br />
                          <span style={{ marginLeft: "1%" }}>
                            Son los más importantes, usados o vendidos.
                            Asimismo, son los que más ingresos generan.
                          </span>
                        </span>
                      ) : labelMov == "B" ? (
                        <span>
                          Tipo de artículos
                          <span style={{ textTransform: "uppercase" }}>
                            {" "}
                            {labelMov}
                          </span>
                          <br />
                          <span style={{ marginLeft: "1%" }}>
                            Tienen una importancia secundaria y los ingresos
                            generados son menores en relación con los artículos
                            A.
                          </span>
                        </span>
                      ) : labelMov == "C" ? (
                        <span>
                          Tipo de artículos{" "}
                          <span style={{ textTransform: "uppercase" }}>
                            {" "}
                            {labelMov}
                          </span>
                          <br />
                          <span style={{ marginLeft: "1%" }}>
                            Son de menor importancia y reportan poco beneficio.
                          </span>
                        </span>
                      ) : (
                        <span style={{ textTransform: "uppercase" }}>
                          {labelMov}{" "}
                        </span>
                      )}
                    </Title>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      padding: "60px",
                    }}
                  >
                    {infoFilters.length > 0 && (
                      <>
                        <span>{infoFilters[0].distribuidor}</span>
                        <br />
                        <span>{infoFilters[0].marca}</span>
                        <br />
                        <span>{infoFilters[0].grupo}</span>
                      </>
                    )}
                  </div>
                </div>

                <tr className="sticky titeColumns">
                  <th style={{ width: "10%" }}>ID Parte</th>
                  <th>
                    Parte
                    <FcAlphabeticalSortingAz
                      style={{ cursor: "pointer" }}
                      id="description_asc"
                      onClick={() => {
                        ordenarId("description_asc");
                      }}
                    />
                    <FcAlphabeticalSortingZa
                      style={{ cursor: "pointer", display: "none" }}
                      id="description_des"
                      onClick={() => {
                        ordenarId("description_des");
                      }}
                    />
                  </th>
                  <th>Costo de pieza</th>
                  <th>Costo fijo</th>
                  <th>
                    Costo de almacenaje
                    <FcAlphabeticalSortingAz
                      style={{ cursor: "pointer" }}
                      id="parte_asc"
                      onClick={() => {
                        ordenarId("parte_asc");
                      }}
                    />
                    <FcAlphabeticalSortingZa
                      style={{ cursor: "pointer", display: "none" }}
                      id="parte_des"
                      onClick={() => {
                        ordenarId("parte_des");
                      }}
                    />
                  </th>
                  <th>Precio de venta</th>
                  <th>
                    Tipo de venta
                    <FcAlphabeticalSortingAz
                      style={{ cursor: "pointer" }}
                      id="venta_asc"
                      onClick={() => {
                        ordenarId("venta_asc");
                      }}
                    />
                    <FcAlphabeticalSortingZa
                      style={{ cursor: "pointer", display: "none" }}
                      id="venta_des"
                      onClick={() => {
                        ordenarId("venta_des");
                      }}
                    />
                  </th>
                  {sessionStorage.getItem("chart") === "Análisis ABC" && (
                    <th>Tipo de movimiento</th>
                  )}

                  <th>Distribuidor</th>
                  {optionToll !== "" ? <th>Acción</th> : null}
                </tr>
              </thead>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginLeft: "350%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 500,
                  }}
                >
                  <CircularProgress disableShrink size={100} />
                </div>
              ) : (
                <tbody className="bodyTable textContainTable">
                  {sessionStorage.getItem("chart") === "Análisis ABC"
                    ? dataTable.length > 0 &&
                      dataTable?.map(
                        ({
                          id_parte,
                          parte,
                          costo_fijo_orden_de_compra,
                          Precio_de_Venta,
                          almacenaje,
                          pvpiva,
                          tipo_venta,
                          tipo_movimiento,
                          distribuidor,
                        }) => (
                          <tr
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#EAF0F6",
                            }}
                          >
                            <td
                              label="ID Parte"
                              onClick={() => getData(id_parte, parte)}
                            >
                              {id_parte}
                            </td>
                            <td label="Parte">{parte}</td>
                            <td label="Costo de pieza">
                              {formatter.format(
                                filterFloat(costo_fijo_orden_de_compra)
                              )}
                            </td>
                            <td label="Costo fijo">
                              {formatter.format(filterFloat(Precio_de_Venta))}
                            </td>
                            <td label="Costo de almacenaje">
                              {formatter.format(filterFloat(almacenaje))}
                            </td>
                            <td label="Precio de venta">
                              {formatter.format(
                                filterFloat(pvpiva?.toFixed(2))
                              )}
                            </td>
                            <td label="Tipo de venta">{tipo_venta}</td>
                            <td label="Tipo de movimiento">
                              {tipo_movimiento}
                            </td>
                            <td
                              label="Distribuidor"
                              style={{ position: "relative" }}
                            >
                              {distribuidor}{" "}
                              <HiDotsHorizontal
                                style={{
                                  position: "absolute",
                                  right: "5",
                                  top: "0",
                                }}
                                onClick={() => handleTool(id_parte)}
                              />
                            </td>
                            {optionToll !== "" ? (
                              <td>
                                <tooltip
                                  style={{ cursor: "pointer" }}
                                  title="Comparar con otro distribuidor"
                                  values={{ color: "red" }}
                                >
                                  <HiDotsVertical
                                    onClick={() => handleTool(id_parte)}
                                  />
                                </tooltip>
                              </td>
                            ) : null}
                          </tr>
                        )
                      )
                    : dataTable?.map(
                        ({
                          id_parte,
                          parte,
                          costo_fijo_orden_de_compra,
                          Precio_de_Venta,
                          almacenaje,
                          pvpiva,
                          tipo_venta,
                          distribuidor,
                        }) => (
                          <tr
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#EAF0F6",
                            }}
                          >
                            <td onClick={() => getData(id_parte, parte)}>
                              {id_parte}
                            </td>
                            <td>{parte}</td>
                            <td>
                              {" "}
                              {formatter.format(
                                filterFloat(costo_fijo_orden_de_compra)
                              )}
                            </td>
                            <td>
                              {formatter.format(filterFloat(Precio_de_Venta))}
                            </td>
                            <td>{formatter.format(filterFloat(almacenaje))}</td>
                            <td>{pvpiva?.toFixed(2)}</td>
                            <td>{tipo_venta}</td>
                            <td style={{ position: "relative" }}>
                              {distribuidor}{" "}
                              <HiDotsHorizontal
                                style={{
                                  position: "absolute",
                                  right: "5",
                                  top: "0",
                                }}
                                onClick={() => handleTool(id_parte)}
                              />
                            </td>
                            {optionToll !== "" ? (
                              <td>
                                <tooltip
                                  style={{ cursor: "pointer" }}
                                  title="Comparar con otro distribuidor"
                                  values={{ color: "red" }}
                                >
                                  <HiDotsVertical
                                    onClick={() => handleTool(id_parte)}
                                  />
                                </tooltip>
                              </td>
                            ) : null}
                          </tr>
                        )
                      )}
                </tbody>
              )}
            </table>
          </div>

          <div
            className="bottonMobile"
            style={{
              width: "100%",
              display: "flex",
              marginTop: "2%",
              justifyContent: "flex-end",
            }}
          >
            {tipo != 5 && (
              <div
                className="col-lg-2 "
                style={{ display: "flex", justifyContent: "end" }}
              >
                <input
                  style={{ marginTop: "1%", marginRight: "4%" }}
                  type="button"
                  value="Descargar"
                  className="btn btn-dark "
                  onClick={() => downloadExcel(dataTable, "Grafica-ABC")}
                />
              </div>
            )}

            <Pagination
              count={numberPaginations}
              page={page}
              onChange={handleChangePage}
            />
            {/* <input
              type="button"
              value="<"
              className="btn btn-primary "
              style={{ marginRight: "2%" }}
              onClick={() => handleAdvanceTable("atras")}
            ></input>
            <input
              type="button"
              value=">"
              className="btn btn-primary "
              style={{ marginRight: "2%" }}
              onClick={() => handleAdvanceTable("next")}
            ></input> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableMov;
