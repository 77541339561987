import React from "react";
import { Link } from "react-router-dom";
import Test1 from "../../assets/imgHome/Test1.png";

import UnitPrice from "./unitPiece"

import "../../styles/Notifications/style.scss";

export default function PorVencer() {
  return (
    <div className=" porVencerContainer">
      <div className=" d-flex header">
        <div className="table">Piezas por vencer </div>
        <div className="table">No. Parte ▼</div>
        <div className="table">No. Pieza ▼</div>
        <div className="table">Días Ven ▼</div>
        <div className="table">Días C ▼</div>
        <div className="table">Área h ▼</div>
      </div>
      <div className="d-flex flex-column content">
        <UnitPrice/>  
        <UnitPrice/>
        <UnitPrice/>      
      </div>
    </div>
  );
}


 