import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropProducts from "./DropProducts";
import ContainerDrop from "./ContainerDrop";
import { ScrollContainer } from "./styles.styled";
import cerrar from '../../assets/image/imageProducts/cerrar.svg';

export default function SecondNav({ setHasOpacity, setisMenuShown, isMenuShown, disabled, setDisabled }) {
  const [disabledCat, setDisabledCat] = useState(false);
  const styleDisabled = disabled
    ? { pointerEvents: "none", filter: 'brightness(0.4)' }
    : { background: 'white', width: '34%', height: 'auto' }
  const [closeDropDown, setCloseDropdown] = useState({
    auto: false,
    accesorios: false,
    accesoriosExteriores: false,
    camiónRemolque: false,
    quimicos: false,
    performance: false,
  });

  const toggleMenuVisiblity = () => {
    if (!disabledCat) {
      setisMenuShown(true);
      setHasOpacity(true)
      setDisabled(true)
    }

  };
  const handleChange = (name) => {

    if (name) {
      setCloseDropdown({
        auto: false,
        accesorios: false,
        accesoriosExteriores: false,
        camiónRemolque: false,
        quimicos: false,
        performance: false,
        [name]: !closeDropDown[name],
      });
      setHasOpacity(!closeDropDown[name])
      setDisabled(false)
      setDisabledCat(!disabledCat)
    }

    else {
      setCloseDropdown({
        auto: false,
        accesorios: false,
        accesoriosExteriores: false,
        camiónRemolque: false,
        quimicos: false,
        performance: false,
      });
      setHasOpacity('')
      setDisabled(false)
      setDisabledCat(false)
    }
  };
  return (
    <>
      <div className="d-flex menu-area " >
        <button
          id="styleButton"
          onClick={toggleMenuVisiblity}
          style={{ width: 'inherit', background: '#027CBA', color: 'white', fontWeight: `${isMenuShown ? 'bold' : "normal"}` }}

        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            class="svg-inline--fa fa-bars fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            height="20px"
            width="20px"
            style={{ marginBottom: "5px", marginRight: "10px" }}
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
          Categorías
        </button>

        <ContainerDrop
          disabled={disabled}
          name="auto"
          closeDrop={closeDropDown.auto}
          handleChange={handleChange}
          text="Auto"
        />
        <ContainerDrop
          disabled={disabled}
          name="accesorios"
          closeDrop={closeDropDown.accesorios}
          handleChange={handleChange}
          text="Accesorios"
        />
        <ContainerDrop
          disabled={disabled}
          name="accesoriosExteriores"
          closeDrop={closeDropDown.accesoriosExteriores}
          handleChange={handleChange}
          text="Accesorios Exteriores"
        />
        <ContainerDrop
          disabled={disabled}
          name="camiónRemolque"
          closeDrop={closeDropDown.camiónRemolque}
          handleChange={handleChange}
          text="Camión y Remolque"
        />
        <ContainerDrop
          disabled={disabled}
          name="quimicos"
          closeDrop={closeDropDown.quimicos}
          handleChange={handleChange}
          text="Químicos"
        />
        <ContainerDrop
          disabled={disabled}
          name="performance"
          closeDrop={closeDropDown.performance}
          handleChange={handleChange}
          text="Performance"
        />
        <div
          style={styleDisabled}>
        </div>
      </div>
      <ScrollContainer>

        <div
          className={isMenuShown ? "hamburgerActive" : ''}
          style={{
            height: isMenuShown ? '100vh' : 0
            , marginTop: '0px', marginLeft: `${!isMenuShown ? "-70%" : "0"}`
          }}
        >

          <label className="title">Artículos mas buscados</label>
          <div >
            <label className="">Lorem ipsum dolor</label>
            <label className="">Lorem ipsum dolor</label>
            <label className="">Lorem ipsum dolor</label>
            <label className="">Lorem ipsum dolor</label>
            <label className="">Lorem ipsum dolor</label>
            <label className="">Lorem ipsum dolor</label>
          </div>
          <label className="title">Buscar por departamento</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
          <label className="">Lorem ipsum dolor</label>
        </div>
        {isMenuShown &&
          <div style={{
            cursor: 'pointer',
            position: 'fixed',
            marginLeft: '6px',
            marginTop: '6px',
            zIndex: 3000,
            color: 'white',
            width: '20px',
            height: '20px',
            marginLeft: '15%'
          }}
            onClick={() => {
              setisMenuShown(false);
              setHasOpacity(false);
              setDisabled(false)

            }}
          >
            <img src={cerrar} alt="logo_patria" >
            </img>
          </div>}
      </ScrollContainer>

    </>
  );
}
