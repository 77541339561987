import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import tagNuevo from "../../assets/marketplace/tagNuevo.svg";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addShopElement } from "../../redux/reducers/shopping.reducer";
import { searchWithFilter } from "../../redux/reducers/FiltersProducts.reducer.js";
import { GET_TB } from "../../graphql/Query";
import { useLazyQuery } from "@apollo/client";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GenericCardProducts({
  arrayCards = [],
  limit = 0,
  marginTop = "0%",
  flexWrap = "",
  padding = 0,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [tasaB, setTasaB] = React.useState(0);

  const [getTB] = useLazyQuery(GET_TB, {
    fetchPolicy: "no-cache",
    onCompleted({ getTB }) {
      console.log(getTB[0].tasa);
      setTasaB(getTB[0].tasa);
    },
    onError(e) {
      console.log("Error", e);
    },
  });

  React.useEffect(() => {
    getTB({
      variables: {
        a: 0,
      },
    });
  }, [tasaB]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const dispatch = useDispatch();

  const addToCartClick = (value) => {
    dispatch(addShopElement(value));
  };

  const imagenes = [
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06084_2_1024x1024@2x.jpg?v=1626215933",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06084_1_1024x1024@2x.jpg?v=1626215933",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06085_2_1024x1024@2x.jpg?v=1626216117",
    "https://cdn.shopify.com/s/files/1/0080/5345/6993/products/AM-06013_2_1024x1024@2x.jpg?v=1626277740",
  ];
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const filtrados = [];

  let count = 0;

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {arrayCards?.map(
            (
              {
                Image,
                Name,
                Description,
                Precio_de_Venta,
                id_parte,
                id_distribuidor,
              },
              index,
              array
            ) => {
              return (
                <>
                  <Grid item xs={2} sm={4} md={3} key={index}>
                    <Card
                      sx={{
                        maxWidth: 355,
                        boxShadow: " -2px 2px 14px -2px rgba(0,0,0,0.58)",
                        height: "auto",
                      }}
                      xs={2}
                      sm={4}
                      md={3}
                      key={index}
                    >
                      <CardMedia
                        style={{
                          height: "200px",
                          width: "100%",
                          marginTop: "15px",
                        }}
                        component="img"
                        //   image={Image}
                        image={imagenes[getRandomInt(0, 5)]}
                        alt="Paella dish"
                      />
                      <CardContent>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontWeight="500"
                          fontSize={"14px"}
                          fontStyle={"roboto"}
                          marginTop="5px"
                        >
                          {Name}
                        </Typography>
                        <div
                          style={{
                            boxSizing: "border-box",
                            width: "350px",
                            height: "40px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="black"
                            fontWeight="700"
                            fontSize={"16px"}
                            fontStyle={"roboto"}
                            marginTop="5px"
                          >
                            {Description ? Description : "Descripcion"}
                          </Typography>
                        </div>

                        <Typography
                          variant="body2"
                          color="#027CBB"
                          fontWeight=" 750"
                          fontSize={"25px"}
                        >
                          {Precio_de_Venta}
                          -- $ {parseInt(Precio_de_Venta, 10) * 0.16 + tasaB}
                        </Typography>
                      </CardContent>
                      <div
                        style={{
                          display: "flex",
                          margin: "space-bewtween",
                          boxSizing: "border-box",
                          width: "350px",
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            background: "#027CBB",
                            width: "40%",
                            color: "white",
                            margin: " unset",
                            fontWeight: " 750",
                            marginLeft: "5%",
                            border: "1px solid #027CBB",
                            borderRadius: "2px",
                          }}
                          onClick={(e) => addToCartClick(array[index])}
                        >
                          Agregar al carrito
                        </button>
                        <div
                          style={{
                            marginLeft: "5%",
                            color: "#027CBB",
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                        >
                          <Link
                            style={{ color: "#027CBB", cursor: "pointer"}}
                            to={`/insideProduct?id_parte=${id_parte}&id_distribuidor=${id_distribuidor}`}
                            onClick={() => {
                              dispatch(
                                searchWithFilter(JSON.stringify("empty"))
                              );
                            }}
                          >
                            Ver más detalle
                          </Link>
                        </div>
                      </div>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      ></ExpandMore>
                    </Card>
                  </Grid>
                </>
              );
            }
          )}
        </Grid>
      </div>
      <br />
    </>
  );
}
