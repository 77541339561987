import { useEffect, useState } from 'react';
import useScript from './useScript';
import { formConfig } from '../components/MercadoPago/formConfig.js';
import { useMutation } from '@apollo/client';
import { SELL_SHOPPING_CART } from '../graphql/Mutation';
import { useSelector } from 'react-redux';

export default function useMercadoPago() {
	const [resultPayment, setResultPayment] = useState(undefined);
	const [sendSell, { loading }] = useMutation(SELL_SHOPPING_CART);
	const items = useSelector((state) => state.rootReducers.shoop);

	const { MercadoPago } = useScript(
		'https://sdk.mercadopago.com/js/v2',
		'MercadoPago'
	);

	const getTotalAmount = () => {
		let money = 0;
		items.forEach((element) => {
			money +=
				Number(element.Precio_de_Venta) * element.cuantity +
				10 * element.cuantity;
		});

		return Number(money.toFixed(2));
	};

	const getVariables = () =>
		items.map((item) => ({
			ID: item.ID,
			id_parte: item.id_parte,
			id_grupo: item.id_grupo,
			id_distribuidor: item.id_distribuidor,
			email: item.email,
			Proveedor: item.Proveedor,
			Precio_de_Venta: item.Precio_de_Venta,
			cuantity: item.cuantity,
		}));

	useEffect(() => {
		if (MercadoPago) {
			const mp = new MercadoPago(
				process.env.PUBLIC_KEY_MP || 'TEST-be69a611-9be1-4166-bf0a-53458a06ed5e'
			);
			const cardForm = mp.cardForm({
				amount: getTotalAmount().toString(),
				autoMount: true,
				form: formConfig,
				callbacks: {
					onFormMounted: (error) => {
						if (error)
							return console.warn('Form Mounted handling error: ', error);
					},

					onSubmit: async (event) => {
						event.preventDefault();

						const {
							paymentMethodId: payment_method_id,
							issuerId: issuer_id,
							cardholderEmail: email,
							amount,
							token,
							installments,
							identificationNumber,
							identificationType,
						} = cardForm.getCardFormData();

						const { data } = await sendSell({
							variables: {
								token,
								issuer_id,
								payment_method_id,
								items: getVariables(),
								userToken: localStorage.getItem('token'),
								installments: Number(installments),
								description: 'Cart products from ballena',
								amount,
								email,
								indentification: {
									type: identificationType,
									number: identificationNumber,
								},
							},
						});

						setResultPayment(data.setSellShoppingCart);
					},
				},
			});
		}
	}, [MercadoPago]);

	return [resultPayment, loading];
}
