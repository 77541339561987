import React from 'react';
import { useQuery, useLazyQuery } from '@apollo/client'
import {
    GET_ALL_MARCAS
} from '../../graphql/Query';

const FilterByDistributor = ({ optionSelect, value, filterByDistribuidor }) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'left' }}>
            <select
                value={value}
                style={{ width: 300, borderRadius: 5, padding: 5 }}
                onChange={filterByDistribuidor}
            >
                <option selected value=''>Elige un cliente</option>
                {optionSelect.map(({ name, email,id_group}, index) => {
                    if (name == 'Distribuidor3') {
                        return <option key={name} value={`${name}_${email}_${id_group}`}>FORD GUAMUCHIL</option>
                    }if(name == 'Distribuidor 1'){
                        return <option key={name} value={`${name}_${email}_${id_group}`}>FORD CIUDAD OBREGON</option>
                    }else{
                        return <option key={name} value={`${name}_${email}_${id_group}`}>FORD CULIACAN TRES RIOS</option>
                    }
                    return (
                        <option key={name} value={`${name}_${email}_${id_group}`}>{name} </option>
                    );
                })}
              {/*   <option value='Elige una marca'>Elige una marca</option>
               {
                   value =='Elige una marca'? console.log('mama'):console.log('papa')
               } */}
            </select>
        </div>
    )
}

export default FilterByDistributor;
