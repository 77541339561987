import React, { useEffect, useState } from "react";
import facebook from "../../assets/facebook-icon.svg";

function Footer() {
  const [showWindow, setShowWindow] = useState(false);


  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768 ) {
      setShowWindow(true);
    }
  }, []);
  return (
    <>
          {showWindow ?  <div className="textcuad d-flex justify-content-around " style={{color:'white'}}>
        <div className="textLeft">
          <label className="title">Contacto</label>
          <div className="divIcons d-flex justify-content-around">
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
              >
                <g>
                  <path
                    d="M462.491,468.206l-33.938,33.937c-6.062,6.031-23.812,9.844-24.343,9.844c-107.435,0.905-210.869-41.279-286.882-117.31
      C41.097,318.46-1.136,214.619,0.036,106.872c0-0.063,3.891-17.312,9.938-23.312l33.937-33.968
      c12.453-12.437,36.295-18.062,52.998-12.5l7.156,2.406c16.703,5.562,34.155,23.999,38.78,40.967l17.093,62.717
      c4.64,17-1.594,41.186-14.031,53.623l-22.687,22.687c22.25,82.467,86.919,147.121,169.339,169.402l22.687-22.688
      c12.438-12.438,36.687-18.656,53.687-14.031l62.718,17.125c16.937,4.594,35.374,22.03,40.968,38.748l2.375,7.156
      C480.552,431.926,474.928,455.769,462.491,468.206z M287.996,255.993h31.999c0-35.343-28.655-63.998-63.998-63.998v31.999
      C273.636,223.994,287.996,238.368,287.996,255.993z M415.992,255.993c0-88.373-71.623-159.996-159.995-159.996v32
      c70.591,0,127.996,57.436,127.996,127.996H415.992z M255.997,0v31.999c123.496,0,223.993,100.497,223.993,223.994h31.999
      C511.989,114.622,397.368,0,255.997,0z"
                    fill="white"
                  />
                </g>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 90 90"
              >
                <g>
                  <path
                    id="WhatsApp"
                    fill="White"
                    d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522
      c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982
      c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537
      c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938
      c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537
      c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333
      c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882
      c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977
      c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344
      c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223
      C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"
                  />
                </g>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 421.83 421.83"
              >
                <g>
                  <g>
                    <path
                      fill="White"
                      d="M227.145,0c-43.087,0-81.886,9.425-115.724,28.488c-33.839,19.027-60.166,46.205-78.414,80.858
        C14.759,143.929,5.723,183.33,5.723,227.019c0,60.13,17.15,108.638,51.909,143.22s83.693,51.591,144.638,51.591
        c40.854,0,77.74-7.051,110.409-21.331c3.543-1.559,6.024-5.315,6.024-9.177v-32.457c0-3.224-1.665-6.413-4.323-8.256
        c-2.657-1.807-6.236-2.232-9.248-1.098c-35.823,13.642-70.051,20.339-102.862,20.339c-47.516,0-82.169-12.579-106.157-37.063
        c-24.059-24.484-36.461-59.563-36.461-107.468c0-35.327,6.803-65.941,20.161-92.268c13.323-26.291,32.28-46.134,57.508-60.236
        c25.157-14.138,54.921-21.331,89.823-21.331c27.425,0,51.165,5.634,71.787,16.76c20.622,11.091,36.177,26.575,47.339,47.126
        c11.161,20.48,16.866,44.504,16.866,72.602c0,26.858-4.5,48.012-12.756,63.532c-8.256,15.555-16.406,20.941-27.283,20.941
        c-8.575,0-12.827-2.197-16.335-6.874s-6.13-13.358-6.13-26.575v-5.386l6.094-114.201c0.213-4.394-2.799-8.752-6.98-10.063
        c-11.835-3.791-24.98-6.697-39.437-8.787c-14.35-2.091-27.354-3.154-39.012-3.154c-33.484,0-62.008,11.587-83.055,34.335
        c-21.012,22.713-31.465,52.583-31.465,87.732c0,30.224,7.406,55.488,22.961,74.339c15.591,18.921,38.232,28.665,64.913,28.665
        c18.213,0,34.724-4.5,48.614-13.606c8.079-5.35,14.988-12.083,20.87-19.807c3.472,6.839,8.114,13.004,14.031,18.213
        c11.409,10.063,25.972,15.201,41.988,15.201c19.028,0,36.567-6.201,51.236-18.106c14.598-11.906,25.689-28.453,33.343-48.933
        c7.618-20.445,11.374-42.839,11.374-66.969c0-36.744-7.795-69.732-23.563-98.433c-15.766-28.702-38.407-51.166-67.25-66.757
        C296.452,7.689,263.57,0,227.145,0L227.145,0z M221.298,147.437c9.992,0,19.276,0.921,27.886,2.657l-2.976,55.346v0.035
        c-1.382,24.378-6.591,41.669-14.031,52.051c-7.476,10.382-16.583,14.917-30.933,14.917c-13.677,0-21.756-3.791-27.744-11.587
        c-5.953-7.76-9.85-21.047-9.85-40.429c0-24.52,5.563-42.307,15.697-54.709C189.479,153.319,202.448,147.437,221.298,147.437
        L221.298,147.437z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <ul>
            <li className="">Atención a clientes: (55) 5555 5555</li>
            <li className="">Cobranza: (55) 5555 5555</li>
            <li className="">Comercial y otros temas: (55) 5555 5555</li>
            <li className="">contacto@ballena.com</li>
          </ul>
        </div>
        <div className="textCenter d-flex align-items-end justify-content-center">
          <label>Copyright 2021 | Todos los derechos reservados | México</label>
        </div>
        <div className="textRight">
          <label className="textop">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam culpa
            deleniti.
          </label>
          <div className="divIcons d-flex justify-content-end justify-content-evenly">
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
                aria-labelledby="amqbxayirv404s143789hz1iay8xpltz"
                class="crayons-icon"
              >
                <title id="amqbxayirv404s143789hz1iay8xpltz">Facebook</title>
                <path
                  fill="White"
                  d="M15.402 21v-6.966h2.333l.349-2.708h-2.682V9.598c0-.784.218-1.319 1.342-1.319h1.434V5.857a19.188 19.188 0 00-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58v1.996h-2.338v2.708h2.338V21H4a1 1 0 01-1-1V4a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1h-4.598z"
                ></path>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
                aria-labelledby="a43hy35ab2y7q258qis972s8itjqocol"
                class="crayons-icon"
              >
                <title id="a43hy35ab2y7q258qis972s8itjqocol">Twitter</title>
                <path
                  d="M22.162 5.656a8.383 8.383 0 01-2.402.658A4.196 4.196 0 0021.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 00-7.126 3.814 11.874 11.874 0 01-8.62-4.37 4.168 4.168 0 00-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 01-1.894-.523v.052a4.185 4.185 0 003.355 4.101 4.211 4.211 0 01-1.89.072A4.185 4.185 0 007.97 16.65a8.395 8.395 0 01-6.191 1.732 11.83 11.83 0 006.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.495 8.495 0 002.087-2.165l-.001-.001z"
                  fill="White"
                ></path>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 438.536 438.536"
              >
                <g>
                  <path
                    fill="White"
                    d="M414.41,24.123C398.333,8.042,378.963,0,356.315,0H82.228C59.58,0,40.21,8.042,24.126,24.123
      C8.045,40.207,0.003,59.576,0.003,82.225v274.084c0,22.647,8.042,42.018,24.123,58.102c16.084,16.084,35.454,24.126,58.102,24.126
      h274.084c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102V82.225
      C438.532,59.576,430.49,40.204,414.41,24.123z M133.618,367.157H67.666V169.016h65.952V367.157z M127.626,132.332
      c-6.851,6.567-15.893,9.851-27.124,9.851h-0.288c-10.848,0-19.648-3.284-26.407-9.851c-6.76-6.567-10.138-14.703-10.138-24.41
      c0-9.897,3.476-18.083,10.421-24.556c6.95-6.471,15.942-9.708,26.98-9.708c11.039,0,19.89,3.237,26.553,9.708
      c6.661,6.473,10.088,14.659,10.277,24.556C137.899,117.625,134.477,125.761,127.626,132.332z M370.873,367.157h-65.952v-105.92
      c0-29.879-11.036-44.823-33.116-44.823c-8.374,0-15.42,2.331-21.128,6.995c-5.715,4.661-9.996,10.324-12.847,16.991
      c-1.335,3.422-1.999,8.75-1.999,15.981v110.775h-65.952c0.571-119.529,0.571-185.579,0-198.142h65.952v27.974
      c13.867-21.681,33.558-32.544,59.101-32.544c22.84,0,41.21,7.52,55.104,22.554c13.895,15.037,20.841,37.214,20.841,66.519v113.64
      H370.873z"
                  />
                </g>
              </svg>
            </a>
          </div>
          <label>Políticas de privacidad | Términos y condiciones</label>
        </div>
      </div> : <div className="textcuad d-flex  " style={{color:'white'}}>
        <div className="textLeft">
          <label className="title">Contacto</label>
          <div className="divIcons d-flex justify-content-around">
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
              >
                <g>
                  <path
                    d="M462.491,468.206l-33.938,33.937c-6.062,6.031-23.812,9.844-24.343,9.844c-107.435,0.905-210.869-41.279-286.882-117.31
      C41.097,318.46-1.136,214.619,0.036,106.872c0-0.063,3.891-17.312,9.938-23.312l33.937-33.968
      c12.453-12.437,36.295-18.062,52.998-12.5l7.156,2.406c16.703,5.562,34.155,23.999,38.78,40.967l17.093,62.717
      c4.64,17-1.594,41.186-14.031,53.623l-22.687,22.687c22.25,82.467,86.919,147.121,169.339,169.402l22.687-22.688
      c12.438-12.438,36.687-18.656,53.687-14.031l62.718,17.125c16.937,4.594,35.374,22.03,40.968,38.748l2.375,7.156
      C480.552,431.926,474.928,455.769,462.491,468.206z M287.996,255.993h31.999c0-35.343-28.655-63.998-63.998-63.998v31.999
      C273.636,223.994,287.996,238.368,287.996,255.993z M415.992,255.993c0-88.373-71.623-159.996-159.995-159.996v32
      c70.591,0,127.996,57.436,127.996,127.996H415.992z M255.997,0v31.999c123.496,0,223.993,100.497,223.993,223.994h31.999
      C511.989,114.622,397.368,0,255.997,0z"
                    fill="white"
                  />
                </g>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 90 90"
              >
                <g>
                  <path
                    id="WhatsApp"
                    fill="White"
                    d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522
      c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982
      c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537
      c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938
      c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537
      c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333
      c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882
      c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977
      c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344
      c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223
      C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"
                  />
                </g>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 421.83 421.83"
              >
                <g>
                  <g>
                    <path
                      fill="White"
                      d="M227.145,0c-43.087,0-81.886,9.425-115.724,28.488c-33.839,19.027-60.166,46.205-78.414,80.858
        C14.759,143.929,5.723,183.33,5.723,227.019c0,60.13,17.15,108.638,51.909,143.22s83.693,51.591,144.638,51.591
        c40.854,0,77.74-7.051,110.409-21.331c3.543-1.559,6.024-5.315,6.024-9.177v-32.457c0-3.224-1.665-6.413-4.323-8.256
        c-2.657-1.807-6.236-2.232-9.248-1.098c-35.823,13.642-70.051,20.339-102.862,20.339c-47.516,0-82.169-12.579-106.157-37.063
        c-24.059-24.484-36.461-59.563-36.461-107.468c0-35.327,6.803-65.941,20.161-92.268c13.323-26.291,32.28-46.134,57.508-60.236
        c25.157-14.138,54.921-21.331,89.823-21.331c27.425,0,51.165,5.634,71.787,16.76c20.622,11.091,36.177,26.575,47.339,47.126
        c11.161,20.48,16.866,44.504,16.866,72.602c0,26.858-4.5,48.012-12.756,63.532c-8.256,15.555-16.406,20.941-27.283,20.941
        c-8.575,0-12.827-2.197-16.335-6.874s-6.13-13.358-6.13-26.575v-5.386l6.094-114.201c0.213-4.394-2.799-8.752-6.98-10.063
        c-11.835-3.791-24.98-6.697-39.437-8.787c-14.35-2.091-27.354-3.154-39.012-3.154c-33.484,0-62.008,11.587-83.055,34.335
        c-21.012,22.713-31.465,52.583-31.465,87.732c0,30.224,7.406,55.488,22.961,74.339c15.591,18.921,38.232,28.665,64.913,28.665
        c18.213,0,34.724-4.5,48.614-13.606c8.079-5.35,14.988-12.083,20.87-19.807c3.472,6.839,8.114,13.004,14.031,18.213
        c11.409,10.063,25.972,15.201,41.988,15.201c19.028,0,36.567-6.201,51.236-18.106c14.598-11.906,25.689-28.453,33.343-48.933
        c7.618-20.445,11.374-42.839,11.374-66.969c0-36.744-7.795-69.732-23.563-98.433c-15.766-28.702-38.407-51.166-67.25-66.757
        C296.452,7.689,263.57,0,227.145,0L227.145,0z M221.298,147.437c9.992,0,19.276,0.921,27.886,2.657l-2.976,55.346v0.035
        c-1.382,24.378-6.591,41.669-14.031,52.051c-7.476,10.382-16.583,14.917-30.933,14.917c-13.677,0-21.756-3.791-27.744-11.587
        c-5.953-7.76-9.85-21.047-9.85-40.429c0-24.52,5.563-42.307,15.697-54.709C189.479,153.319,202.448,147.437,221.298,147.437
        L221.298,147.437z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <ul>
            <li className="">Atención a clientes: (55) 5555 5555</li>
            <li className="">Cobranza: (55) 5555 5555</li>
            <li className="">Comercial y otros temas: (55) 5555 5555</li>
            <li className="">contacto@ballena.com</li>
          </ul>
        </div>
        <div className="textRight">
          
          <div className="divIcons d-flex justify-content-around ">
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
                aria-labelledby="amqbxayirv404s143789hz1iay8xpltz"
                class="crayons-icon"
              >
                <title id="amqbxayirv404s143789hz1iay8xpltz">Facebook</title>
                <path
                  fill="White"
                  d="M15.402 21v-6.966h2.333l.349-2.708h-2.682V9.598c0-.784.218-1.319 1.342-1.319h1.434V5.857a19.188 19.188 0 00-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58v1.996h-2.338v2.708h2.338V21H4a1 1 0 01-1-1V4a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1h-4.598z"
                ></path>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="img"
                aria-labelledby="a43hy35ab2y7q258qis972s8itjqocol"
                class="crayons-icon"
              >
                <title id="a43hy35ab2y7q258qis972s8itjqocol">Twitter</title>
                <path
                  d="M22.162 5.656a8.383 8.383 0 01-2.402.658A4.196 4.196 0 0021.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 00-7.126 3.814 11.874 11.874 0 01-8.62-4.37 4.168 4.168 0 00-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 01-1.894-.523v.052a4.185 4.185 0 003.355 4.101 4.211 4.211 0 01-1.89.072A4.185 4.185 0 007.97 16.65a8.395 8.395 0 01-6.191 1.732 11.83 11.83 0 006.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.495 8.495 0 002.087-2.165l-.001-.001z"
                  fill="White"
                ></path>
              </svg>
            </a>
            <a className="" href="https://www.facebook.com">
              {/* <img
                className=""
                src={facebook}
                width="20"
                height="20"
                alt="facebook-icon"
              /> */}
              <svg
                version="1.1"
                width="24"
                height="24"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 438.536 438.536"
              >
                <g>
                  <path
                    fill="White"
                    d="M414.41,24.123C398.333,8.042,378.963,0,356.315,0H82.228C59.58,0,40.21,8.042,24.126,24.123
      C8.045,40.207,0.003,59.576,0.003,82.225v274.084c0,22.647,8.042,42.018,24.123,58.102c16.084,16.084,35.454,24.126,58.102,24.126
      h274.084c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102V82.225
      C438.532,59.576,430.49,40.204,414.41,24.123z M133.618,367.157H67.666V169.016h65.952V367.157z M127.626,132.332
      c-6.851,6.567-15.893,9.851-27.124,9.851h-0.288c-10.848,0-19.648-3.284-26.407-9.851c-6.76-6.567-10.138-14.703-10.138-24.41
      c0-9.897,3.476-18.083,10.421-24.556c6.95-6.471,15.942-9.708,26.98-9.708c11.039,0,19.89,3.237,26.553,9.708
      c6.661,6.473,10.088,14.659,10.277,24.556C137.899,117.625,134.477,125.761,127.626,132.332z M370.873,367.157h-65.952v-105.92
      c0-29.879-11.036-44.823-33.116-44.823c-8.374,0-15.42,2.331-21.128,6.995c-5.715,4.661-9.996,10.324-12.847,16.991
      c-1.335,3.422-1.999,8.75-1.999,15.981v110.775h-65.952c0.571-119.529,0.571-185.579,0-198.142h65.952v27.974
      c13.867-21.681,33.558-32.544,59.101-32.544c22.84,0,41.21,7.52,55.104,22.554c13.895,15.037,20.841,37.214,20.841,66.519v113.64
      H370.873z"
                  />
                </g>
              </svg>
            </a>
          </div>
          <label>Políticas de privacidad | Términos y condiciones</label>
        </div>
        <div className="textCenter d-flex ">
          <label>Copyright 2021 </label>
          <label>Todos los derechos reservados | México</label>
        </div>
        </div>
        }


    </>


    


   

    
   
  );
}

export default Footer;
