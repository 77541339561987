import React from "react";
import { Link } from "react-router-dom";

import Qa from "../../assets/Qa.svg";
import cuenta from "../../assets/usuario.svg";
import campana from "../../assets/campana.svg";

function Icons() {
  return (
    <div className=" d-flex justify-content-around icons">
      <Link>
        <img src={Qa} />
      </Link>
      <Link>
        <img src={cuenta} />
      </Link>
      <Link>
        <img src={campana} />
      </Link>
    </div>
  );
}

export default Icons;