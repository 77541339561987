import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Switch } from "antd";

import "../../styles/SwitchContainer/style.scss";

export default function SwitchContainer() {
  const [toggle, setToggle] = useState(false);

  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };
  return (
    <div className="d-flex flex-column switchContainer">
      <h2>¿Qué notificaciones quieres recibir?</h2>
      <div className="d-flex labelContainer">
        <label>Piezas vencidas</label>
        <Switch onClick={toggler} />
      </div>
      <div className="d-flex labelContainer">
        <label>Piezas vencidas</label>
        <Switch onClick={toggler} />
      </div>
      <div className="d-flex labelContainer">
        <label>Piezas vencidas</label>
        <Switch onClick={toggler} />
      </div>
      <div className="d-flex labelContainer">
        <label>Piezas vencidas</label>
        <Switch onClick={toggler} />
      </div>
      <div className="d-flex labelContainer">
        <label>Piezas vencidas</label>
        <Switch onClick={toggler} />
      </div>
      <div className="d-flex labelContainer">
        <label>Piezas vencidas</label>
        <Switch onClick={toggler} />
      </div>
    </div>
  );
}
