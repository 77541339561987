import { height } from "@mui/system";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";

function ChartBestSellinngsPieces({
  dataBarPieces,
  indexAxis = "x",
  width = 1,
  boxShadow = "0px 6px 7px 1px rgba(0,0,0,0.39)",
  borderRadius = 0,
  title = "Piezas más vendidas",
  labels = ["LLANTAS", "RINES", "FAROS", "BATERIAS"],
  label = "# de Piezas",
  height = 0,
}) {
  const validateWidth = width === 0 ? null : width;
  const validateHeight = height === 0 ? null : height;
  const data = {
    labels,
    datasets: [
      {
        label: label,
        tooltipTemplate: "<%= label %> - <%= value %>",
        backgroundColor: "#017CBB",
        borderColor: "#026ba1",
        borderWidth: 1,
        hoverBackgroundColor: "#017CBB",
        cutout: "75%",
        spacing: 5,
        borderRadius: borderRadius,
        /*  hoverBorderColor: 'rgba(255,99,132,1)', */
        data: dataBarPieces,

      },
    ],
  };

  return (
    <div
      style={{
        background: "white",
        boxShadow: boxShadow,
        padding: "10px",
        height: validateHeight,
        width: validateWidth,
      }}
    >
      <Bar
        id="myChart"
        data={data}
        style={{ height: 278, width: 400 }}
        
        options={{
          indexAxis: indexAxis,
          onHover: (event) => {
            event.native.target.style.cursor = "pointer";
          },
          responsive: true,
          
          plugins: {
            legend: {
              legend: {
                display: true,
                position: "top",
              },
            },

            title: {
              display: true,
              text: title,
              align: "start",
              color: "#022741",
              font: {
                size: 18,
                weight: "700",
              },
            },
          },
          
         
        }}
      />
    </div>
  );
}
export default ChartBestSellinngsPieces;
