import React, { Suspense, lazy } from "react";

import NavComp from "../Navbar/index";
import SecondNav from "../Ecommerce/secondNav";
import TopImage from "../Ecommerce/topImage";
import ContentMenu from "./content";
import Footer from "../Footer";
// import ContentMenuLoader from "../Loaders/Cliente.jsx";

import "../../styles/Ecommerce/style.scss";
import "../../styles/Cards/style.scss";

//imgHome
import Test2 from "../../assets/imgHome/Test2.png";

// const ContentMenu = lazy(() => import("./content"));

function Client() {
  return (
    <React.Fragment>
      <NavComp />
      <div className="mainHomeTop d-flex flex-column" id="mainHomeTop">
        <SecondNav />
        <TopImage />
        <ContentMenu />
        {/* <Suspense fallback={<ContentMenuLoader />}>
          <ContentMenu />
        </Suspense> */}
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Client;
