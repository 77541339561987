import { Box, CircularProgress } from '@mui/material';

const loadingStyleProps = {
	display: 'flex',
	width: '100%',
	height: '50vh',
	justifyContent: 'center',
	alignItems: 'center',
};

const withLoading = (Component) => {
	return function RenderContitional({ condition, ...props }) {
		if (condition)
			return (
				<Box sx={loadingStyleProps}>
					<CircularProgress />
				</Box>
			);
		return <Component {...props} />;
	};
};

export default withLoading;
