import React, { useState, useEffect } from "react";
import backgroundImage from "../../assets/marketplace/top-image-Market.jpg";
import { useQuery, useLazyQuery } from "@apollo/client";
import { AiOutlineLine, AiFillCaretDown } from "react-icons/ai";
import { ImMenu } from "react-icons/im";
import { MdClose } from "react-icons/md";
import { ImSearch } from "react-icons/im";
import { AiFillCaretLeft } from "react-icons/ai";
import { IoArrowBackOutline } from "react-icons/io5";
import {
  GET_MARKET_CATEGORIES,
  GET_MARKET,
  get_All_Market,
  GET_ALL_TRADE,
  GET_NUMBER_PAGES,
  GET_NUM_PRODUCTS,
  GET_MARKET2,
} from "../../graphql/Query";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import classes from "../../styles/Start/Start.module.scss";
import GenericCardCategory from "./GenericCardCategory";
import GenericCardProduct from "./GebericCardProduct";
import { searchWithFilter } from "../../redux/reducers/FiltersProducts.reducer.js";
import { resultsFilters } from "../../redux/selectors/filters.selector.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import FilersComponent from "./FiltersComponent";
import GenericCardProducts from "../Dashboard/GenericCardProducts";
import GenericCard from "../Dashboard/GenericCard";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import NavBarShop from "../NavBarShop";
import { useParams } from "react-router-dom";

export default function StartMarket() {
  const dispatch = useDispatch();

  const shoppingValues = useSelector((state) => state.rootReducers.filters);

  const [categorias, setCategorias] = useState([]);
  const [categorias2, setCategorias2] = useState([]);
  const [allCategorias, setAllCategorias] = useState([]);
  const [open, setOpen] = useState(false);
  const [busqueda, setBusqueda] = useState(false);
  const [categoriasbyFilter, setCategoriasbyFilter] = useState();
  const [loading, setLoading] = useState(true);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [arrayCards, setArrayCards] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [numberPages, setNumberPages] = useState([]);
  const [numberPagesbyCat, setNumberPagesbyCat] = useState(0);
  const [page, setPage] = React.useState(1);
  const [pagebyCat, setPagebyCat] = React.useState(1);
  const [allProductsMarket, setAllProductsMarket] = useState([]);
  const [contadorAllProducts, setContadorAllProducts] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
    setLoading(true);
    get_All_Market_Category({
      variables: {
        a: value.toString(),
      },
    });
  };

  const handleChangebyCat = (event, value) => {
    setPagebyCat(value);
    setLoading(true);
    console.log("true");
    get_All_Market2({
      variables: {
        pag: value,
        id: "",
        marca: "",
        categoria: categoryId.toString(),
        likecats: categoryId.toString(),
      },
    });
  };

  const [get_All_Market_Category] = useLazyQuery(GET_MARKET_CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted({ getCategories }) {
      setCategorias(getCategories);
      setCategorias2(getCategories);
      setLoading(false);
      //console.log("_______________________________");
      //console.log(getCategories);
    },
  });

  useQuery(GET_NUMBER_PAGES, {
    fetchPolicy: "no-cache",
    onCompleted({ getNumCategories }) {
      //console.log(getNumCategories.length);
      setNumberPages(Math.ceil(getNumCategories.length / 12));
      setAllCategorias(getNumCategories);
    },
  });

  useEffect(() => {
    //console.log("state");
    //console.log(resultsFilters);
    //console.log(shoppingValues);

    get_All_Market_Category({
      variables: {
        a: "1",
      },
    });
    // get_All_Market({
    //   variables: {
    //     pag: 0,
    //     id: "",
    //     marca: "",
    //     categoria: "",
    //     likecats: "",
    //   },
    // });
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    // //console.log("--------useEfect--------");
    // //console.log(shoppingValues);
    if (shoppingValues.state && JSON.parse(shoppingValues.state) == "empty") {
      //console.log("emptyiii");
      setLoading(false);
      setBusqueda(false);
    } else {
      if (shoppingValues.state && JSON.parse(shoppingValues.state)) {
        console.log(JSON.parse(shoppingValues.state));
        setCategoriasbyFilter(JSON.parse(shoppingValues.state));
        setLoading(false);
        setBusqueda(true);
      } else {
        console.log(shoppingValues.state && JSON.parse(shoppingValues.state));
        setCategoriasbyFilter([]);
        setLoading(true);
        setBusqueda(false);
      }
    }
  }, [shoppingValues]);

  const handler = (param) => {
    console.log(param);
    setShowAllProducts(param);
    // !param && setLoading(false);
    param && categoriaActual(params.category, params.categoryId);
  };

  const categoriaActual = (param, Id) => {
    console.log(param, "categoriaActual");
    console.log(Id, "categoriaActual");
    setCategory(param);
    setCategoryId(Id);
    setShowAllProducts(param);
    setLoading(true);
    get_All_Market2({
      variables: {
        pag: 1,
        id: "",
        marca: "",
        categoria: Id,
        likecats: "",
      },
    });
    get_pages_All_Market({
      variables: {
        pag: 1,
        id: "",
        marca: "",
        categoria: Id,
        likecats: "",
      },
    });
  };

  const changePaginationCat = (param) => {
    get_All_Market({
      variables: {
        pag: page,
        id: "",
        marca: "",
        categoria: categoryId.toString(),
        likecats: "",
      },
    });
  };

  const [get_All_Market] = useLazyQuery(GET_MARKET, {
    fetchPolicy: "no-cache",
    onCompleted({ getMarketProducts }) {
      dispatch(searchWithFilter(JSON.stringify(getMarketProducts)));
      //console.log("+++++++++++++++++++++++++++++");
      //console.log(getMarketProducts);
    },
  });

  const [get_All_Market2] = useLazyQuery(GET_MARKET2, {
    fetchPolicy: "no-cache",
    onCompleted({ getMarketProducts2 }) {
      setArrayCards(getMarketProducts2);
      let allProducts = [];
      let contador = 0;
      setArrayCards(getMarketProducts2);
      getMarketProducts2?.map((obj) => {
        obj.statusMarket == true && allProducts.push(obj);
        contador += 1;
      });
      setContadorAllProducts(Math.ceil(contador / 40));

      // dispatch(searchWithFilter(JSON.stringify(getMarketProducts)));
      setAllProductsMarket(allProducts);
      console.log("+++++++++++++++++++++++++++++");
      console.log(getMarketProducts2);
    },
  });

  const [get_pages_All_Market] = useLazyQuery(GET_NUM_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted({ getNumProducts }) {
      console.log("????????????????????????");
      console.log(getNumProducts);
      setNumberPagesbyCat(Math.ceil(getNumProducts.count / 40));
    },
  });

  const params = useParams();
  useEffect(() => {
    console.log("before");
    if (params.category) {
      console.log("if");
      handler(true);
    } else {
      handler(false);
      console.log("false");
    }
  }, []);

  return (
    <>
      <NavBarShop />

      <div className={classes.categories_sub_nav}>
        <div
          className={
            open
              ? classes.categorias_menu_hamburguesa_open
              : classes.categorias_menu_hamburguesa
          }
        >
          <div
            className={
              open
                ? classes.categorias_menu_hamburguesa_sub_open
                : classes.categorias_menu_hamburguesa_sub
            }
            role="presentation"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ImMenu className={classes.menu_hamburguesa_item} /> Categorias
          </div>
          <MdClose
            onClick={() => {
              setOpen(false);
            }}
            className={open ? classes.close_left_menu : classes.display_none}
          />
          <div
            className={
              open ? classes.categorias_menu_lateral : classes.display_none
            }
          >
            <h5>Articulos más buscados</h5>
            <div className={classes.container_items_categorias_menu}>
              {categorias.map((cat) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    categoriaActual(
                      cat.Categorias,
                      cat.id_Categoria.toString()
                    );
                    setOpen(false);
                  }}
                  className={classes.items_categorias}
                >
                  {cat.Categorias}
                </div>
              ))}
            </div>
          </div>
          {/* <div className={classes.categorias_menu_backdrop}> </div> */}
        </div>
        <input type="text" className={classes.categorias_general_buscador} />
      </div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "25% 75%",
        }}
        className={classes.filters_cont}
      >
        <FilersComponent theme={"white"} />
      </div>
      <div className={classes.container_general_body}>
        {busqueda ? (
          loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "10%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress disableShrink size={100} />
            </div>
          ) : categoriasbyFilter.length > 0 ? (
            <>
              <div className={classes.categories_title}>
                <AiOutlineLine className={classes.categories_title_line} />{" "}
                Resultados
              </div>
              <GenericCardProduct
                arrayCards={categoriasbyFilter}
                limit={2}
                categories={allCategorias}
              />
            </>
          ) : (
            <div style={{ color: "red" }}>
              No encontramos publicaciones relacionadas con tu búsqueda. Revisa
              que las palabras estén bien escritas.[1]
            </div>
          )
        ) : categorias.length > 0 ? (
          showAllProducts && categoryId.length > 0 ? (
            arrayCards.length > 0 ? (
              <>
                <div></div>
                <div className={classes.container_pagination}>
                  <div className={classes.root}>
                    <Pagination
                      count={contadorAllProducts}
                      page={pagebyCat}
                      onChange={handleChangebyCat}
                    />
                  </div>
                </div>
                {/* <button
                  className={classes.back_button}
                  onClick={() => handler(false)}
                >
                  <IoArrowBackOutline />
                  Categorías
                </button> */}
                <div className={classes.categories_title}>
                  <AiOutlineLine className={classes.categories_title_line} />{" "}
                  {"Todos los productos de "} {category}
                </div>
                <GenericCard
                  categories={[{ Categorias: categoryId }]}
                  limit={2}
                  changePaginationCat={changePaginationCat}
                  arrayCards={allProductsMarket}
                />
              </>
            ) : (
              <>
                <div></div>
                <div className={classes.container_pagination}>
                  <div className={classes.root}>
                    <Pagination
                      count={numberPagesbyCat}
                      page={pagebyCat}
                      onChange={handleChangebyCat}
                    />
                  </div>
                </div>
                {/* <button
                  className={classes.back_button}
                  onClick={() => handler(false)}
                >
                  <IoArrowBackOutline />
                  Categorías
                </button> */}
                <div className={classes.categories_title}>
                  <AiOutlineLine className={classes.categories_title_line} />{" "}
                  {"Sin productos para "} {category}
                </div>
              </>
            )
          ) : (
            <>
              <div className={classes.categories_title}>
                <AiOutlineLine className={classes.categories_title_line} />{" "}
                Categorias
              </div>

              {!loading ? (
                <>
                  <div className={classes.container_pagination}>
                    <div className={classes.root}>
                      <Pagination
                        count={numberPages}
                        page={page}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <GenericCardCategory
                    arrayCards={[]}
                    limit={2}
                    categories={categorias}
                    handler={handler}
                    categoriaActual={categoriaActual}
                  />
                </>
              ) : loading && arrayCards.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "10%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress disableShrink size={100} />
                </div>
              ) : loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "10%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress disableShrink size={100} />
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  No encontramos publicaciones relacionadas con tu búsqueda.
                  Revisa que las palabras estén bien escritas. [2]
                </div>
              )}
            </>
          )
        ) : loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: "10%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress disableShrink size={100} />
          </div>
        ) : (
          <div style={{ color: "red" }}>
            No encontramos publicaciones relacionadas con tu búsqueda. Revisa
            que las palabras estén bien escritas. [3]
          </div>
        )}
      </div>
    </>
  );
}
