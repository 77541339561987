import React, { useState, useEffect } from 'react';

import LogoCom from './logo';
import IconsCom from './icons';
import '../../styles/NavBar/style.scss';
import MenuMobileDis from '../DashboardDistributor/MenuMobileDis';
import MenuMobile from '../Dashboard/MenuMobile';
import { useQuery } from '@apollo/client';
import { GET_ALL_DISTRIBUTORS } from '../../graphql/Query';
import { useLocation } from 'react-router-dom';

const NavBar = () => {
	const [showWindow, setShowWindow] = useState(false);
	const [compareEmail, setCompareEmail] = useState(true);
	const location = useLocation();
	let correo = sessionStorage.getItem('auxEmail');

	useQuery(GET_ALL_DISTRIBUTORS, {
		fetchPolicy: 'no-cache',
		onCompleted({ getAllDistributors }) {
			let emaill = [];
			let dis = [];
			getAllDistributors?.forEach((obj) => {
				return dis.push(obj.email);
			});
			emaill = dis.filter((obj) => obj === correo);
			//console.log('email',emaill)

			if (correo !== null || correo !== 0) {
				if (emaill == correo) {
					setCompareEmail(true);
				} else if (emaill !== correo) {
					setCompareEmail(false);
				}
			}
		},
	});

	useEffect(() => {
		const sizeWindow = window.screen.width;
		console.log(location);
		if (sizeWindow >= 768) {
			setShowWindow(true);
		}
	}, []);
	if (compareEmail) {
		return (
			<nav>
				<LogoCom />
				{showWindow ? <IconsCom /> : <MenuMobileDis />}
			</nav>
		);
	}
	return (
		<nav>
			<LogoCom />
			{showWindow ? <IconsCom /> : <MenuMobile />}
		</nav>
	);
};

export default NavBar;
