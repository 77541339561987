import React, { useState } from "react";
import classes from "../../styles/filterAdmin/filterAdmin.module.scss";

const FiltersAdmin = ({
  optionSelect,
  value,
  checkedFilter,
  checkedGeneral,
  idMarca,
  type,
  active,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [start, setStart] = useState("¿Como quieres comenzar?");
  const [mutiselect, setMutiselect] = useState(1);
  const [GD, setGD] = useState("Grupo Distribuidor");
  const [M, setM] = useState("Marca");
  const [M2, setM2] = useState("Marca");
  const [D, setD] = useState("Distribuidores Hermanos");
  const [P, setP] = useState("Periodo");
  const [general, setGeneral] = useState("¿Como quieres hacer la consulta?");

  const periodo = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  var date = new Date();
  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckboxes2 = () => {
    var checkboxes = document.getElementById("checkboxes2");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckboxesP = () => {
    var checkboxes = document.getElementById("selecgraph1a");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckboxesP2 = () => {
    var checkboxes = document.getElementById("selecgraph2a");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckboxesP3 = () => {
    var checkboxes = document.getElementById("selecgraph3a");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckboxesDisr = () => {
    var checkboxes = document.getElementById("checkboxesd");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };
  const showCheckboxesDisrH = () => {
    var checkboxes = document.getElementById("checkboxesdh");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckboxquestion = () => {
    var checkboxes = document.getElementById("checkboxesquestion");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };

  const showCheckbofiltergeneral = () => {
    var checkboxes = document.getElementById("checkboxesgeneral");
    if (!expanded) {
      checkboxes.style.display = "block";
      setExpanded(true);
    } else {
      checkboxes.style.display = "none";
      setExpanded(false);
    }
  };
  const changeState = () => {
    checkedFilter();
  };

  const changefilters = () => checkedFilter;

  function Name(mes) {
    const date = new Date();
    if (mes == 0) return "Enero" + "-" + date.getFullYear();
    if (mes == 1) return "Febrero" + date.getFullYear();
    if (mes == 2) return "Marzo" + date.getFullYear();
    if (mes == 3) return "Abril" + date.getFullYear();
    if (mes == 4) return "Mayo" + date.getFullYear();
    if (mes == 5) return "Junio" + date.getFullYear();
    if (mes == 6) return "Julio" + date.getFullYear();
    if (mes == 7) return "Agosto" + date.getFullYear();
    if (mes == 8) return "Septiembre" + date.getFullYear();
    if (mes == 9) return "Octubre" + date.getFullYear();
    if (mes == 10) return "Noviembre" + date.getFullYear();
    if (mes == 11) return "Diciembre" + date.getFullYear();
    if (mes == -12) return "Enero" + (date.getFullYear() - 1);
    if (mes == -11) return "Febrero" + (date.getFullYear() - 1);
    if (mes == -10) return "Marzo" + (date.getFullYear() - 1);
    if (mes == -9) return "Abril" + (date.getFullYear() - 1);
    if (mes == -8) return "Mayo" + (date.getFullYear() - 1);
    if (mes == -7) return "Junio" + (date.getFullYear() - 1);
    if (mes == -6) return "Julio" + (date.getFullYear() - 1);
    if (mes == -5) return "Agosto" + (date.getFullYear() - 1);
    if (mes == -4) return "Septiembre" + (date.getFullYear() - 1);
    if (mes == -3) return "Octubre" + (date.getFullYear() - 1);
    if (mes == -2) return "Noviembre" + (date.getFullYear() - 1);
    if (mes == -1) return "Diciembre" + (date.getFullYear() - 1);
  }

  if (type == "marcas") {
    return (
      <>
        <form style={{ zIndex: "3" }}>
          <div className={classes.multiselect2}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxes();
              }}
            >
              <select>
                <option>{M}</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="checkboxes" className={classes.checkboxes}>
              {optionSelect?.map(({ id_Marca, desc_Marca }, index) => {
                return (
                  <label for={`checkbox-marca-${index}`}>
                    <input
                      type="checkbox"
                      id={`checkbox-marca-${index}`}
                      onChange={checkedFilter}
                      onClick={() => {
                        setM(desc_Marca);
                        // setD("Distribuidores Hermanos");
                        const temp = document.getElementById("options-dis");
                        temp && (temp.innerHTML = "Distribuidores Hermanos");
                      }}
                    />
                    {desc_Marca}
                  </label>
                );
              })}
              <label for="checkbox-marca-null">
                <input
                  type="checkbox"
                  id={`checkbox-marca-null`}
                  onChange={checkedFilter}
                />
                NO REGISTRADO
              </label>
            </div>
          </div>
        </form>
      </>
    );
  }
  if (type == "marcas2") {
    return (
      <>
        <form style={{ zIndex: "3" }}>
          <div className={classes.multiselect2}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxes2();
              }}
            >
              <select>
                <option>{M2}</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="checkboxes2" className={classes.checkboxes}>
              {optionSelect?.map(({ id_Marca, desc_Marca }, index) => {
                return (
                  <label for={`checkbox-marca2-${index}`}>
                    <input
                      type="checkbox"
                      id={`checkbox-marca2-${index}`}
                      onChange={checkedFilter}
                      onClick={() => {
                        setM2(desc_Marca);
                      }}
                    />
                    {desc_Marca}
                  </label>
                );
              })}
              <label for="checkbox-marca-null">
                <input
                  type="checkbox"
                  id={`checkbox-marca-null`}
                  onChange={checkedFilter}
                />
                NO REGISTRADO
              </label>
            </div>
          </div>
        </form>
      </>
    );
  }
  if (type == "distribuidores") {
    return (
      <>
        <form style={{ zIndex: "3" }}>
          <div className={classes.multiselect}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxesDisr();
              }}
            >
              <select>
                <option id="options-dis">{D}</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="checkboxesd" className={classes.checkboxes}>
              {optionSelect?.map(({ id_distribuidor, name }, index) => {
                return (
                  <label for={`checkbox-distribuidor-${id_distribuidor}`}>
                    <input
                      type="checkbox"
                      id={`checkbox-distribuidor-${id_distribuidor}`}
                      onChange={checkedFilter}
                      onClick={() => {
                        setD(name ? name : "NO REGISTRADO");
                      }}
                    />
                    {name ? name : "NO REGISTRADO"}
                  </label>
                );
              })}
            </div>
          </div>
        </form>
      </>
    );
  }
  if (type == "distribuidoreshermanos") {
    return (
      <>
        <form style={{ zIndex: "3" }}>
          <div className={classes.multiselect}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxesDisrH();
              }}
            >
              <select>
                <option>{GD}</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="checkboxesdh" className={classes.checkboxes}>
              {optionSelect?.map(({ id_fathers, name }, index) => {
                return (
                  <label for={`checkbox-fathers-${id_fathers}`}>
                    <input
                      type="checkbox"
                      id={`checkbox-fathers-${id_fathers}`}
                      onChange={checkedFilter}
                      onClick={() => {
                        setGD(name);
                      }}
                    />
                    {name}
                  </label>
                );
              })}
            </div>
          </div>
        </form>
      </>
    );
  }

  if (type == "question") {
    return (
      <>
        <form className={classes.formGeneral}>
          <div
            className={
              mutiselect == 1
                ? classes.multiselect
                : mutiselect == 2
                ? classes.multiselect2
                : classes.multiselect
            }
          >
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxquestion();
              }}
            >
              <select>
                <option>{start}</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="checkboxesquestion" className={classes.checkboxes}>
              <label for={`checkbox-question-marcas`}>
                <input
                  type="checkbox"
                  id={`checkbox-question-marcas`}
                  onChange={checkedFilter}
                  onClick={() => {
                    setStart("Marcas");
                    setMutiselect(2);
                  }}
                />
                Marcas
              </label>
              <label for={`checkbox-question-gd`}>
                <input
                  type="checkbox"
                  id={`checkbox-question-gd`}
                  onChange={checkedFilter}
                  onClick={() => {
                    setStart("Grupo distribuidor");
                    setMutiselect(3);
                  }}
                />
                Grupo distribuidor
              </label>
            </div>
          </div>
        </form>
      </>
    );
  }

  if (type == "graph") {
    return (
      <>
        <form className={classes.formGeneral2}>
          <div className={classes.multiselect2}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxesP();
              }}
            >
              <select>
                <option id="selectPeriodo">Periodo</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="selecgraph1a" className={classes.checkboxes}>
              <label for="selecgraph11">
                <input
                  type="checkbox"
                  id="selecgraph11"
                  name={`Hace 1 mese`}
                  name2={Name(date.getMonth() - 1)}
                  onChange={checkedFilter}
                  onClick={() => {
                    setP("Hace 1 mes");
                    sessionStorage.setItem(
                      "periodo",
                      Name(date.getMonth() - 1)
                    );
                  }}
                />
                {Name(date.getMonth() - 1)}
              </label>
              {periodo?.map(({ id_fathers, name }, index) => {
                return (
                  <label for={`selecgraph1${index + 2}`}>
                    <input
                      type="checkbox"
                      id={`selecgraph1${index + 2}`}
                      name={`Hace ${index + 2} meses`}
                      name2={Name(date.getMonth() - (index + 2))}
                      onChange={checkedFilter}
                      onClick={() => {
                        setP(`Hace ${index + 2} meses`);
                        sessionStorage.setItem(
                          "periodo",
                          Name(date.getMonth() - (index + 2))
                        );
                      }}
                    />
                    {Name(date.getMonth() - (index + 2))}
                  </label>
                );
              })}
            </div>
          </div>
        </form>
      </>
    );
  }

  if (type == "graph2") {
    return (
      <>
        <form className={classes.formGeneral2}>
          <div className={classes.multiselect2}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxesP2();
              }}
            >
              <select>
                <option id="selectPeriodo2">Periodo</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="selecgraph2a" className={classes.checkboxes}>
              <label for="selecgraph21">
                <input
                  type="checkbox"
                  id="selecgraph21"
                  name={`Hace 1 mese`}
                  name2={Name(date.getMonth() - 1)}
                  onChange={checkedFilter}
                  onClick={() => {
                    setP("Hace 1 mes");
                    sessionStorage.setItem(
                      "periodo2",
                      Name(date.getMonth() - 1)
                    );
                  }}
                />
                {Name(date.getMonth() - 1)}
              </label>
              {periodo?.map(({ id_fathers, name }, index) => {
                return (
                  <label for={`selecgraph2${index + 2}`}>
                    <input
                      type="checkbox"
                      id={`selecgraph2${index + 2}`}
                      name={`Hace ${index + 2} meses`}
                      name2={Name(date.getMonth() - (index + 2))}
                      onChange={checkedFilter}
                      onClick={() => {
                        setP(`Hace ${index + 2} meses`);
                        sessionStorage.setItem(
                          "periodo2",
                          Name(date.getMonth() - (index + 2))
                        );
                      }}
                    />
                    {Name(date.getMonth() - (index + 2))}
                  </label>
                );
              })}
            </div>
          </div>
        </form>
      </>
    );
  }

  if (type == "graph3") {
    return (
      <>
        <form className={classes.formGeneral2}>
          <div className={classes.multiselect2}>
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckboxesP3();
              }}
            >
              <select>
                <option id="selectPeriodo3">Periodo</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="selecgraph3a" className={classes.checkboxes}>
              <label for="selecgraph31">
                <input
                  type="checkbox"
                  id="selecgraph31"
                  name={`Hace 1 mese`}
                  name2={Name(date.getMonth() - 1)}
                  onChange={checkedFilter}
                  onClick={() => {
                    setP("Hace 1 mes");
                    sessionStorage.setItem(
                      "periodo3",
                      Name(date.getMonth() - 1)
                    );
                  }}
                />
                {Name(date.getMonth() - 1)}
              </label>
              {periodo?.map(({ id_fathers, name }, index) => {
                return (
                  <label for={`selecgraph3${index + 2}`}>
                    <input
                      type="checkbox"
                      id={`selecgraph3${index + 2}`}
                      name={`Hace ${index + 2} meses`}
                      name2={Name(date.getMonth() - (index + 2))}
                      onChange={checkedFilter}
                      onClick={() => {
                        setP(`Hace ${index + 2} meses`);
                        sessionStorage.setItem(
                          "periodo3",
                          Name(date.getMonth() - (index + 2))
                        );
                      }}
                    />
                    {Name(date.getMonth() - (index + 2))}
                  </label>
                );
              })}
            </div>
          </div>
        </form>
      </>
    );
  }

  if (type == "filtergeneral") {
    return (
      <>
        <form className={classes.formGeneral}>
          <div
            className={
              mutiselect == 1
                ? classes.multiselect
                : mutiselect == 2
                ? classes.multiselect2
                : classes.multiselect
            }
          >
            <div
              className={active ? classes.selectBox : classes.selectBox2}
              role="presentation"
              onClick={() => {
                active && showCheckbofiltergeneral();
              }}
            >
              <select>
                <option id="general-filter">{general}</option>
              </select>
              <div className={classes.overSelect}></div>
            </div>
            <div id="checkboxesgeneral" className={classes.checkboxes}>
              <label for={`checkbox-general-Venta`}>
                <input
                  type="checkbox"
                  id={`checkbox-general-Venta`}
                  name="precioventa"
                  onChange={checkedGeneral}
                  onClick={() => {
                    setGeneral("Venta");
                  }}
                />
                Venta
              </label>
              <label for={`checkbox-general-Utilidad`}>
                <input
                  type="checkbox"
                  id={`checkbox-general-Utilidad`}
                  name="Utilidad"
                  onChange={checkedGeneral}
                  onClick={() => {
                    setGeneral("Utilidad");
                  }}
                />
                Utilidad
              </label>
              <label for={`checkbox-general-Volumen`}>
                <input
                  type="checkbox"
                  id={`checkbox-general-Volumen`}
                  name="celda_M"
                  onChange={checkedGeneral}
                  onClick={() => {
                    setGeneral("Volumen");
                  }}
                />
                Volumen
              </label>
            </div>
          </div>
        </form>
      </>
    );
  }
};

export default FiltersAdmin;
