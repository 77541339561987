import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const downloadExcel = (dataXLSX, excelName) => {
    const worksheet = XLSX.utils.json_to_sheet(dataXLSX, { sheetStubs: true });
    const workbook = {
        Sheets: {
            data: worksheet,
        },
        SheetNames: ['data'],
    };
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });
    //console.log('excelBuffer', excelBuffer);
    saveAsExcel(excelBuffer, excelName);
}

const saveAsExcel = (buffer, filename) => {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    // saveAs(data, filename, '_export_' + new Date().getTime() +'.xlsx')
    const fileDownloadUrl = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = fileDownloadUrl;
    link.setAttribute('download', filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
};
export default downloadExcel
