import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GET_PAY_DATA } from '../../graphql/Query';

const PaymentResult = () => {
	const { id } = useParams();
	const { data, loading } = useQuery(GET_PAY_DATA, { variables: { id } });

	console.log(data);
	if (loading) return <CircularProgress />;
	return (
		<div>
			<h2>{data.getPaymentInformation.sellId}</h2>
			<h2>Estatus: {data.getPaymentInformation.status}</h2>
			<h3>Monto total: {data.getPaymentInformation.totalAmount}</h3>
			<div>
				<h3>Partes:</h3>
				<hr />
				{data.getPaymentInformation &&
					data.getPaymentInformation.items.map((item) => (
						<div>
							<h3>Precio: {item.Precio_de_Venta}</h3>
							<h3>Parte: {item.parte}</h3>
							<h3>Cantidad: {item.cantidad}</h3>
							<h3>Costos de envio: {item.cantidad * 10 }</h3>
							<hr />
						</div>
					))}
			</div>
		</div>
	);
};

export default PaymentResult;
