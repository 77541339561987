import React from 'react';
import Table from 'react-bootstrap/Table';

function TableResults() {

  const headings = [
    {
      heading:'Num. Parte'
    },
    {
      heading:'Disponibilidad'
    },
    {
      heading:'Precio'
    },
    {
      heading:'Fecha de llegada'
    },
    {
      heading:'Estatus'
    }
  ]

  const example = [
    {
      numPart: '1',
      availability:'Descripción',
      price:'150',
      arrivalDate:'12/10/2021',
      status:'Estatus'
    },
    {
      numPart: '2',
      availability:'Descripción',
      price:'50',
      arrivalDate:'12/10/2021',
      status:'Estatus'
    },
    {
      numPart: '3',
      availability:'Descripción',
      price:'350',
      arrivalDate:'12/10/2021',
      status:'Estatus'
    },
  ]
    return (
      <Table responsive borderless>
        <thead>
          <tr>
            {headings.map((header, index) => (
              <th style={{fontSize:14}} key={index}>{header.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
            {example.map((data, index) => (
              <tr key={index} style={{fontSize:14}} >
                <td >{data.numPart}</td>
                <td >{data.availability}</td>
                <td >${data.price}</td>
                <td >{data.arrivalDate}</td>
                <td >{data.status}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
  export default TableResults;