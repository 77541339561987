import { Gallery, StyledImage, Linea, MoreOptions, Column, SubTitle, DropDawnContent } from "./styles.styled";
import ContainerDrop from "./ContainerDrop";


function DropProducts(props) {
    const { arrayImage, text, showDrop } = props
   // //console.log('VALOR' + text);
    return (
        <DropDawnContent>
        
                <Gallery>
                    {arrayImage.map(image => (
                        <StyledImage>
                            <img src={image} width={'60px'} />
                        </StyledImage>
                    ))}
                </Gallery>
                <div>
                    <Linea />
                    <br>

                    </br>
                    <SubTitle>
                        También en {text}
                    </SubTitle>
                    <br>

                    </br>
                </div>
                <MoreOptions>
                    <Column>
                        <div>
                            Lorem ipsum dolor
                        </div>
                        <div>
                            Lorem ipsum dolor
                        </div>
                    </Column>
                    <Column>
                        <div>
                            Lorem ipsum dolor
                        </div>
                        <div>
                            Lorem ipsum dolor
                        </div>
                    </Column>
                    <Column>
                        <div>
                            Lorem ipsum dolor
                        </div>
                        <div>
                            Lorem ipsum dolor
                        </div>
                    </Column>

                </MoreOptions>

            
        </DropDawnContent>

    )
}

export default DropProducts;