import React, { useState, useEffect } from "react";
import { GET_ALL_PRODUCTS, GET_NUM_ALL_PRODUCTS } from "../../graphql/Query";
import { useQuery, useLazyQuery } from "@apollo/client";
import NavComp from "../Navbar/index";
import SecondNav from "./secondNav";
import TopImage from "./topImage";
import "../../styles/Ecommerce/style.scss";
import "../../styles/Cards/style.scss";
import Footer from "../Footer/footer";
import { AiOutlineLine } from "react-icons/ai";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import GenericCardProducts from "../Dashboard/GenericCardProducts";

function AllProducts() {
  const [styles, setStyles] = useState({});
  const [arrayCards, setArrayCards] = useState([]);
  const [isMenuShown, setisMenuShown] = useState(false);
  const [hasOpacity, setHasOpacity] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [totalPag, setTotalPag] = useState([]);
  const [page, setPage] = useState(1);

    useQuery(GET_NUM_ALL_PRODUCTS, {
        fetchPolicy: 'no-cache',
        onCompleted({ getNumAllProducts }) {
            debugger
            let count = getNumAllProducts[0].count;
            let totalPg = Math.ceil(count / 12)
            setTotalPag(totalPg)
        }
    })

  const [getAllProducts] = useLazyQuery(GET_ALL_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted({ getAllProducts }) {
      setArrayCards(getAllProducts);
    },
    onError(err) {
      //console.log(err)
    },
  });

  const handleChangePage = (value) => {
    setPage(value);
    //console.log(value)
    getAllProducts({
      variables: {
        e: localStorage.getItem("e"),
        pag: parseInt(value),
      },
    });
  };

  useEffect(() => {
    setStyles({
      opacity: hasOpacity ? "1.5" : "",
      backgroundColor: hasOpacity ? " rgba(0,0,0,0.6)" : "",
      filter: hasOpacity ? "brightness(0.4)" : "",
    });
    if (hasOpacity) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";
  }, [hasOpacity]);

  useEffect(() => {
    const email = localStorage.getItem("e");
    getAllProducts({
      variables: {
        e: "admin@ballena.com",
        pag: page,
      },
    });
  }, []);

  return (
    <React.Fragment>
      <NavComp />
      <div
        className="mainHomeTop d-flex flex-column"
        style={{ backgroundColor: "#d4e1ec", backgroundColor: "#d4e1ec" }}
      >
        <SecondNav
          setHasOpacity={setHasOpacity}
          isMenuShown={isMenuShown}
          setisMenuShown={setisMenuShown}
          disabled={disabled}
          setDisabled={setDisabled}
        />
        <div
          style={styles}
          onClick={() => {
            setisMenuShown(false);
            setHasOpacity(false);
            setDisabled(false);
          }}
        >
          <TopImage />
          <div className="mainMidContainer ">
            <div
              style={{
                display: "flex",
                width: "100%",
                paddingTop: "2%",
                paddingLeft: "2%",
              }}
            >
              <AiOutlineLine color="#A6DBFD" fontSize=" xx-large" />
              <h3 style={{ fontWeight: 700 }}>Todos los productos</h3>
              <div style={{ display: "flex", marginLeft: "56%" }}>
                <Pagination
                  onChange={handleChangePage}
                  page={page}
                  count={totalPag}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
            <div>
              <div
                className="containerNewItems d-flex"
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {arrayCards.length === 0 ? (
                  <Box sx={{ display: "grid", placeItems: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div className="containerMid2">
                    <div className="containerNewItems2">
                      <GenericCardProducts
                        padding={"0px 37px 37px"}
                        marginTop="5%"
                        flexWrap="wrap"
                        arrayCards={arrayCards}
                        limit={arrayCards}
                        style={{ height: 500 }}
                      />
                    </div>
                  </div>
                )}
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AllProducts;
