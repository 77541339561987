import * as React from 'react';
import LogoCom from './logo';
import IconsCom from './icons';
import '../../styles/NavBar/style.scss';

const NavBarShop = () => {
	return (
		<nav>
			<LogoCom />
			<IconsCom />
		</nav>
	);
};

export default NavBarShop;
