import React from "react";

import { Link } from "react-router-dom";

import SearchBar from "../Dashboard/searchBar";
import SwitchContainer from "./switchContainer";

export default function MainContainer() {
  return (
    <div className="container-fluid">
      <SearchBar />
      <SwitchContainer />
    </div>
  );
}
