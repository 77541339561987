import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useLazyQuery } from "@apollo/client";

import {
  GET_ABC_NUMBER,
  GET_POT_OBSOLETOS,
  GET_BEST_SELL_PART,
  GET_BEST_DIS_PRO_FITS,
  GET_ABCD_DISTRIBUIDOR,
  GET_ALL_DISTRIBUTORS,
  GET_MOV_DISTRIBUTOR,
  GET_TYPE_SALE_DISTRIBUTOR,
  GET_ALL_TRADE,
  GET_NUM_ALL_PRODUCTS,
  GET_ABCD_DISTRIBUIDOR_ADMIN,
  GET_ALL_DIST,
  GET_ALL_FATHERS,
  GET_MOV_DISTRIBUIDOR_ADMIN,
  GET_TYPE_SALE_ADMIN,
  GET_BEST_SELL_PART_ADMIN,
  GET_POT_OBSOLETOS_ADMIN,
  GET_POT_OBSOLETOS_ADMIN_TABLE,
  GET_ALL_TRADE_LAST,
  GET_THREE_IN_ONE,
  CONSULTA_HISTORICO,
  SAVE_HISTORICO_ABC,
  SAVE_CONSULTA_HISTORICO_MOV,
  SAVE_CONSULTA_HISTORICO_VENTA,
  RUN_ALGORITHM,
  GET_NAME_FILTERS,
} from "../../graphql/Query";
import ChartDealers from "../ChartDahsboard/ChartDealers";
import FilterByDistributor from "../Dashboard/FilterByDistributor";
import FiltersAdmin from "../Dashboard/FiltersAdmin";
import StatsTable from "../Dashboard/statsTable";
import ChartBestSellinngsPieces from "../ChartDahsboard/ChartBestSellinngsPieces";
import downloadExcel from "../../helpers/DownloadXLSX";
import classesD from "../../styles/Admin/Dashboard.module.scss";
import classes from "../../styles/Admin/detailFilterGeneral.module.scss";
import {
  BsFillArrowDownRightSquareFill,
  BsFillArrowUpLeftSquareFill,
} from "react-icons/bs";
import Pagination from "@material-ui/lab/Pagination";

const Administrator = () => {
  let history = useHistory();
  const [dataAux, setDataAux] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableExport, setDataTableExport] = useState([]);
  const [dataChartExportMov, setDataChartExportMov] = useState([]);
  const [dataChartExportVenta, setDataChartExportVenta] = useState([]);
  const [dataDonut, setDataDonut] = useState([]);
  const [dataBarPieces, setDataBarPieces] = useState([]);
  const [labelsBarPieces, setLabelsBarPieces] = useState([]);
  const [dataBarDis, setDataBarDis] = useState([]);
  const [dataBarPiecesAux, setDataBarPiecesAux] = useState([]);
  const [dataBarDisAux, setDataBarDisAux] = useState([]);
  const [optionSelect, setOptionSelect] = useState([]);
  const [dataDonutTypeMov, setDataDonutTypeMov] = useState([]);
  const [dataLabelDonutMov, setDataLabelDonutMov] = useState([]);
  const [dataDonutTypeVenta, setDataDonutTypeVenta] = useState([]);
  const [dataLabelDonutVenta, setDataLabelDonutVenta] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");
  const [idGroup, setIdGroup] = useState(0);
  const [optionTypeMov, setOptionTypeMov] = useState("");
  const [numeroTotal, setNumeroTotal] = useState("");
  const [numTotal, setNumTotal] = useState("");
  const [numTotalTypMov, setNumTotalTypMov] = useState("");
  const [showWindow, setShowWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countLoader, setCountLoader] = useState(0);
  const [marcas, setMarcas] = useState([]);
  const [marcas2, setMarcas2] = useState([]);
  const [Mamedist, setMamedist] = useState([]);
  const [nameFathers, setNameFathers] = useState([]);
  const [loadingtableadata, setLoadingtableadata] = useState(true);
  const [limitepaginas, setLimitepaginas] = useState(1);
  const [distActive, setDistActive] = useState(0);
  const [disHerActive, setDisHerActive] = useState(0);
  const [againthis, setAgainthis] = useState(false);
  const [userT, setUserT] = useState("");
  const [fatherStaticId, setFatherStaticId] = useState("");
  const [marcasStaticId, setMarcasrStaticId] = useState("");
  const [idDist2, setIdDist2] = useState("");
  const [dinamicTextFilter, setDinamicTextFilter] = useState(
    "del Algoritmo de Ballena"
  );
  const [tableDetailAbc, setTableDetailAbc] = useState([]);
  const [tableDetailtipo, setTableDetailtipo] = useState([]);
  const [tableDetailbest, setTableDetailbest] = useState([]);
  const [expandedAbcDetail, setExpandedAbcDetail] = useState(false);
  const [expandedtipoDetail, setExpandedtipoDetail] = useState(false);
  const [expandedbestDetail, setExpandedbestDetail] = useState(false);
  const [currentFilterGeneral, setCurrentFilterGenerall] = useState("");

  const [namegraph1, setNamegraph1] = useState("Periodo");
  const [namegraph2, setNamegraph2] = useState("Periodo");
  const [namegraph3, setNamegraph3] = useState("Periodo");

  const [graph1, setGraph1] = useState(false);
  const [graph2, setGraph2] = useState(false);
  const [graph3, setGraph3] = useState(false);
  const [graph4, setGraph4] = useState(false);
  const [numTotalTypMovPrecio, setNumTotalTypMovPrecio] = useState("");
  const [numeroPrecio, setNumeroPrecio] = useState("");
  const [numPreciotypeVenta, setNumPreciotypeVenta] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const [historicABC, setHistoricABC] = useState(false);
  const [historicMov, setHistoricMOV] = useState(false);
  const [historicVenta, setHistoricVENTA] = useState(false);
  const [totalesPublicados, setTotalesPublicados] = useState([
    0, 0, 0, 0, 0, 0,
  ]);
  const [loadingPublicados, setLoadingPublicados] = useState(true);

  const [numberPages, setNumberPages] = useState();
  const [infoFilters, setInfoFilters] = useState([]);

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setLimitepaginas(value);
    paginacion(value);
    setLoadingtableadata(true);
  };

  const [getNameFilters] = useLazyQuery(GET_NAME_FILTERS, {
    fetchPolicy: "no-cache",
    onCompleted({ getNameFilters }) {
      console.log("*************");
      console.log(getNameFilters);
      setInfoFilters(getNameFilters);
    },
    onError(err) {
      console.log("*************");
      console.log(err);
    },
  });

  useQuery(GET_ALL_DISTRIBUTORS, {
    fetchPolicy: "no-cache",

    onCompleted({ getAllDistributors }) {
      /*   const reponse=JSON.parse(getAllDistributors); */
      setOptionSelect(getAllDistributors);
      setCountLoader((count) => count + 1);
      //console.log("GET_ALL_DISTRIBUTORS");
    },
    onError(e) {
      //console.log("Error", e);
    },
  });
  useQuery(GET_BEST_DIS_PRO_FITS, {
    fetchPolicy: "no-cache",
    onCompleted({ getBestDisProfits }) {
      const bestDisProfits = getBestDisProfits.map(
        ({ total_amount, distribuidor }) => {
          return {
            label: distribuidor,
            backgroundColor: "#80BDDD",
            borderColor: "#026ba1",
            borderRadius: 5,
            borderWidth: 1,
            hoverBackgroundColor: "#026ba1",
            data: [total_amount],
          };
        }
      );
      setDataBarDis(bestDisProfits);
      setDataBarDisAux(bestDisProfits);
      setCountLoader((count) => count + 1);
      //console.log("GET_BEST_DIS_PRO_FITS");
    },
  });
  // useQuery(GET_BEST_SELL_PART, {
  //     fetchPolicy: 'no-cache',
  //     onCompleted({ getBestSellPart }) {
  //         let response = JSON.parse(getBestSellPart);
  //         let arrayPiezas = [];
  //         let labels = []
  //         let disFilter = []
  //         response.forEach(obj => {
  //             arrayPiezas.push(obj.celda_M)
  //             labels.push(obj.parte)
  //         });
  //         disFilter = [...labels]
  //         disFilter.map((obj, i) => {
  //             if (obj == '<NULL>') {
  //                 disFilter[i] = 'OTROS'
  //             }
  //             disFilter.push()

  //         })
  //         setDataBarPieces(arrayPiezas)
  //         setLabelsBarPieces(disFilter)
  //         //console.log(labels)
  //         //console.log(disFilter)

  //         setDataBarPiecesAux(response)
  //         setCountLoader((count) => count + 1)
  //         //console.log("GET_BEST_SELL_PART")
  //     },
  //     onError(err) {
  //         //console.log('ERROR: getBestSellPart', err)
  //     }
  // })
  const [getBestSellPartAdmin] = useLazyQuery(GET_BEST_SELL_PART_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getBestSellPartAdmin }) {
      let response = JSON.parse(getBestSellPartAdmin);
      let arrayPiezas = [];
      let labels = [];
      let disFilter = [];
      response.forEach((obj) => {
        arrayPiezas.push(obj.celda_M);
        labels.push(obj.parte);
      });
      disFilter = [...labels];
      disFilter.map((obj, i) => {
        if (obj == "<NULL>") {
          disFilter[i] = "OTROS";
        }
        disFilter.push();
      });
      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(disFilter);
      //console.log(labels);
      //console.log(disFilter);

      setDataBarPiecesAux(response);
      setCountLoader((count) => count + 1);
      setGraph4(true);
      //console.log("GET_BEST_SELL_PART_ADMIN");
      //console.log(getBestSellPartAdmin);
    },
    onError(err) {
      //console.log("ERROR: getBestSellPart", err);
    },
  });
  //QUERY PARA GRAFICA ANALISIS ABC
  useQuery(GET_ABC_NUMBER, {
    fetchPolicy: "no-cache",
    onCompleted({ getABCNumber }) {
      let count = [];
      getABCNumber?.forEach((obj) => {
        return count.push(obj.count);
      });
      if (count.length >= 1) {
        const suma = count?.reduce((prev, next) => prev + next);
        setNumTotal(parseInt(suma));
      } else {
        setNumTotal(parseInt(count[0]));
      }
      setDataDonut(count);
      setCountLoader((count) => count + 1);
      //console.log("GET_ABC_NUMBER");
    },
  });
  const [getPotObsoletos] = useLazyQuery(GET_POT_OBSOLETOS, {
    fetchPolicy: "no-cache",
    onCompleted({ getPotObsoletos }) {
      // debugger
      setDataAux(getPotObsoletos);

      let listLabelsExcel = [];
      setDataTableExport(
        getPotObsoletos?.map((obj) => {
          const statusMarket =
            obj.statusMarket == false
              ? "No disponible en MP"
              : "Disponible en MP";
          return {
            id_parte: obj.id_parte,
            Name: obj.Name,
            tipo_movimiento: obj.tipo_movimiento,
            distribuidor: obj.distribuidor,
            PrecioVenta: obj.PrecioVenta,
            Costo_pieza: obj.Costo_pieza,
            statusMarket,
          };
        })
      );
      // loadingtableadata(false);
      //console.log("listLabelsExcel", listLabelsExcel);
      setCountLoader((count) => count + 1);
      //console.log("getPotObsoletos");
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getPotObsoletosAdmin] = useLazyQuery(GET_POT_OBSOLETOS_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getPotObsoletoAdmin }) {
      setDataAux(getPotObsoletoAdmin);
      setLoadingtableadata(false);
      //  setDataTable(getPotObsoletoAdmin);
      showObsoletTable(getPotObsoletoAdmin).then(function (res) {
        changeLoading(res);
      });
      let listLabelsExcel = [];
      // setDataTableExport(getPotObsoletoAdmin?.map((obj) => {
      //     const statusMarket = obj.statusMarket == false ? 'No disponible en MP' : 'Disponible en MP'
      //     return {
      //         id_parte: obj.id_parte,
      //         Name: obj.Name,
      //         tipo_movimiento: obj.tipo_movimiento,
      //         distribuidor: obj.distribuidor,
      //         PrecioVenta: obj.PrecioVenta,
      //         Costo_pieza: obj.Costo_pieza,
      //         statusMarket
      //     }
      // }))
      //console.log("listLabelsExcel", listLabelsExcel);
      setCountLoader((count) => count + 1);
      //console.log("getPotObsoletoAdmin");
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getPotObsoletosAdmincount] = useLazyQuery(
    GET_POT_OBSOLETOS_ADMIN_TABLE,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getPotObsoletoAdminTabl }) {
        console.log("getPotObsoletoAdminTabl");
        console.log(getPotObsoletoAdminTabl);
        const obsoletospotenciales = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "Potencial obsoleto"
        );
        const obsoletospublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == false
        );

        const obsoletos1 = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "obsoleto"
        );
        const obsoletospublicados1 = obsoletos1.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados2 = obsoletos1.filter(
          (pot) => pot.statusMarket == false
        );
        console.log(getPotObsoletoAdminTabl.length);
        console.log(obsoletospublicados.length);
        console.log(obsoletosnopublicados.length);
        console.log(obsoletospublicados1.length);
        console.log(obsoletosnopublicados2.length);
      },
      onError(err) {
        console.log(err);
      },
    }
  );

  const [getPotObsoletosAdminTable] = useLazyQuery(
    GET_POT_OBSOLETOS_ADMIN_TABLE,
    {
      fetchPolicy: "no-cache",
      onCompleted({ getPotObsoletoAdminTabl }) {
        console.log("getPotObsoletoAdminTabl");
        console.log(getPotObsoletoAdminTabl);
        const totalnop = getPotObsoletoAdminTabl.filter(
          (pot) => pot.statusMarket == false
        );
        const totalsip = getPotObsoletoAdminTabl.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletospotenciales = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "Potencial obsoleto"
        );
        const obsoletospublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados = obsoletospotenciales.filter(
          (pot) => pot.statusMarket == false
        );

        const obsoletos1 = getPotObsoletoAdminTabl.filter(
          (pot) => pot.tipo_movimiento == "obsoleto"
        );
        const obsoletospublicados1 = obsoletos1.filter(
          (pot) => pot.statusMarket == true
        );
        const obsoletosnopublicados2 = obsoletos1.filter(
          (pot) => pot.statusMarket == false
        );
        console.log(getPotObsoletoAdminTabl.length);
        console.log(obsoletospublicados.length);
        console.log(obsoletosnopublicados.length);
        console.log(obsoletospublicados1.length);
        console.log(obsoletosnopublicados2.length);
        const temp2 = [
          getPotObsoletoAdminTabl.length,
          totalnop.length,
          totalsip.length,
          obsoletospublicados.length,
          obsoletosnopublicados.length,
          obsoletospublicados1.length,
          obsoletosnopublicados2.length,
        ];
        let temp = [...temp2];
        setLoadingPublicados(false);
        setTotalesPublicados(temp);
        setNumberPages(getPotObsoletoAdminTabl.length / 200);
        setDataTableExport(
          getPotObsoletoAdminTabl?.map((obj) => {
            const statusMarket =
              obj.statusMarket == false
                ? "No disponible en MP"
                : "Disponible en MP";
            return {
              id_parte: obj.id_parte,
              Name: obj.Name,
              tipo_movimiento: obj.tipo_movimiento,
              distribuidor: obj.distribuidor,
              PrecioVenta: obj.PrecioVenta,
              Costo_pieza: obj.Costo_pieza,
              statusMarket,
            };
          })
        );
        //console.log("getPotObsoletoAdminTabl");
        //console.log(getPotObsoletoAdminTabl);
      },
      onError(err) {
        //console.log(err);
      },
    }
  );

  /**obtenemos las marcas para el filto de marcas */

  const [getmarcaslastFilter2] = useLazyQuery(GET_ALL_TRADE, {
    fetchPolicy: "no-cache",
    onCompleted({ getDistinctMarca }) {
      //console.log("¡¡??¡¡??¡¡??");
      //console.log(getDistinctMarca);
      setMarcas2(getDistinctMarca);
    },
    onError(e) {
      //console.log("Error", e);
      console.table(e);
    },
  });

  const [getmarcaslastFilter] = useLazyQuery(GET_ALL_TRADE_LAST, {
    fetchPolicy: "no-cache",
    onCompleted({ getDistinctMarca2 }) {
      //console.log("¡***********marcas by filter");
      //console.log(getDistinctMarca2);
      setMarcas(getDistinctMarca2);
    },
    onError(e) {
      //console.log("Error", e);
      console.table(e);
    },
  });
  /**obtenemos los distintos distribuidores para el filtro de distribuidores */

  const [distByfilter] = useLazyQuery(GET_ALL_DIST, {
    fetchPolicy: "no-cache",
    onCompleted({ getDistinctDist }) {
      //console.log("00000");
      setMamedist(getDistinctDist);
      //console.log("getDistinctDist");
    },
    onError(err) {
      //console.log(err);
    },
  });

  useQuery(GET_ALL_FATHERS, {
    fetchPolicy: "no-cache",
    onCompleted({ getDistinctFathers }) {
      //console.log("2222");
      //console.log(getDistinctFathers);
      setNameFathers(getDistinctFathers);
    },
    onError(e) {
      //console.log("Error", e);
      console.table(e);
    },
  });

  /**obtenemos los distintos padres para el filtro de padres */
  // useQuery(GET_ALL_DIST, {
  //     fetchPolicy: 'no-cache',
  //     onCompleted({ getDistinctDist }) {
  //     //console.log('00000')
  //     setMamedist(getDistinctDist)
  //     },
  //     onError(e) {
  //         //console.log("Error", e)
  //         console.table(e)
  //     }
  // });
  useQuery(GET_NUM_ALL_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted({ getNumAllProducts }) {
      //console.log(getNumAllProducts);
    },
  });
  // querie para filtrar grafica detal dona
  const [getABCDistribuidor] = useLazyQuery(GET_ABCD_DISTRIBUIDOR, {
    fetchPolicy: "no-cache",
    onCompleted({ getABCDistributor }) {
      //console.log("bbbbbbbbbbbbb");
      //console.log(getABCDistributor);
      let count = [];
      getABCDistributor?.forEach((obj) => {
        return count.push(obj.count);
      });
      //console.log(count);
      setDataDonut(count);
      if (count.length >= 1) {
        const suma = count?.reduce((prev, next) => prev + next);
        setNumeroTotal(parseInt(suma) || 0);
        //console.log("la suma es -----");
        //console.log(parseInt(suma) || 0);
        // setCountLoader((count) => count + 1)
      }

      //console.log("getABCDistribuidor");
    },
    onError(err) {
      //console.log(err);
    },
  });

  // filtros ABC admin
  const [getABCDistribuidorAdmin] = useLazyQuery(GET_ABCD_DISTRIBUIDOR_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getABCDistributorbyAdmin }) {
      let count = [];
      let totPrecio = [];
      let amount = [];
      //console.log("llega");
      //console.log(getABCDistributorbyAdmin);
      getABCDistributorbyAdmin?.forEach((obj) => {
        return (
          count.push(obj.total),
          totPrecio.push(obj.costoTotal),
          amount.push(obj.amount)
        );
      });
      //console.log("%%%%%%%%%%%%%%%%%%%");
      //console.log(count);
      const sum = parseInt(totPrecio);
      setNumeroPrecio(formatter.format(filterFloat(sum)));
      setTableDetailAbc(amount);
      //console.log(count);
      setDataDonut(count);
      setNumeroTotal(count);
      //console.log("44444-->" + count);
      if (count.length >= 1) {
        //console.log("enrta al if de >1");
        const suma = count?.reduce((prev, next) => prev + next);
        setNumeroTotal(parseInt(suma) || 0);
        //console.log(suma);
        // setCountLoader((count) => count + 1)
      }
      currentFilterGeneral != "" && setNumeroTotal(currentFilterGeneral);
      currentFilterGeneral == "Volumen de venta" && setNumeroPrecio(sum);

      setGraph1(true);
      //console.log("getABCDistributorbyAdmin");

      setTimeout(function () {
        namechange2("el");
      }, 500);

      function namechange2(oc) {
        const select1 = document.getElementById("selectPeriodo");
        select1 && (select1.innerHTML = namegraph1);
        //console.log(oc);
        //console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  // filtros ABC admin
  const [getMovAdmin] = useLazyQuery(GET_MOV_DISTRIBUIDOR_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getMovDistributorAdmin }) {
      // debugger;
      //console.log("step----------------1");
      //console.log(getMovDistributorAdmin);
      let disFilter = [];
      let filterCount = [];
      let totPrecio = 0;
      let details = [];
      let labels = Object?.keys(
        getMovDistributorAdmin?.length > 0 && getMovDistributorAdmin[0]
      );
      //console.log("step----------------2");
      //console.log(getMovDistributorAdmin);
      let temp = Object?.values(
        getMovDistributorAdmin?.length > 0 && getMovDistributorAdmin[0]
      );
      //console.log("step----------------3");
      labels.map((obj, i) => {
        if (obj !== "__typename" && obj !== "costoTotal") {
          disFilter.push(obj);
          //console.log(obj);
        }
      });
      //console.log(temp);
      let datCount = temp.filter(
        (obj) => obj !== "MovData" && obj !== "costoTotal"
      );
      //console.log(datCount);
      getMovDistributorAdmin?.forEach((obj) => {
        //console.log(obj.costoTotal);
        //console.log(obj);
        details.push(obj.Lento);
        details.push(obj.Rapido);
        details.push(obj.Estancado);
        details.push(obj.Inactivo);
        details.push(obj.Potencial_obsoleto);
        details.push(obj.Obsoleto);

        if (obj.costoTotal != "null") {
          return (totPrecio =
            obj.Lento +
            obj.Rapido +
            obj.Estancado +
            obj.Inactivo +
            obj.Potencial_obsoleto +
            obj.Obsoleto);
        }
      });
      setTableDetailtipo(details);
      let suma2 = 0;
      getMovDistributorAdmin?.forEach((obj) => {
        //console.log(obj.costoTotal);
        //console.log(obj);
        if (obj.costoTotal != "null") {
          return (suma2 = obj.costoTotal);
        }
      });
      const sum = parseInt(totPrecio);
      //console.log(sum);
      //console.log(formatter.format(filterFloat(sum)));
      setNumTotalTypMovPrecio(formatter.format(filterFloat(sum)));

      //console.log("step----------------4");
      filterCount = datCount.filter(
        (obj) => obj !== "MovData" && obj !== "costoTotal"
      );
      setDataLabelDonutMov(disFilter);
      setDataDonutTypeMov(details);
      const suma = filterCount?.reduce((prev, next) => prev + next);
      setNumTotalTypMov(parseInt(suma2));
      currentFilterGeneral != "" && setNumTotalTypMov(currentFilterGeneral);
      currentFilterGeneral == "Volumen de venta" &&
        setNumTotalTypMovPrecio(sum);

      setDataChartExportMov(
        getMovDistributorAdmin?.map((obj) => {
          return {
            Rapido: obj.Rapido,
            Lento: obj.Lento,
            Estancado: obj.Estancado,
            Inactivo: obj.Inactivo,
            Potencial_obsoleto: obj.Potencial_obsoleto,
            Obsoleto: obj.Obsoleto,
          };
        })
      );
      //console.log("step----------------5");
      setCountLoader((count) => count + 1);
      setGraph2(true);
      //console.log("getMovDistributorAdmin");
      // document.getElementById("selectPeriodo").innerHTML = "dddd";
      setTimeout(function () {
        namechange2("el");
      }, 500);

      function namechange2(oc) {
        const select1 = document.getElementById("selectPeriodo2");
        select1 && (select1.innerHTML = namegraph2);
        //console.log(oc);
        //console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  //QUERY PARA GRAFICA DE TIPO DE MOVIENTO
  const [getMovDistributor] = useLazyQuery(GET_MOV_DISTRIBUTOR, {
    fetchPolicy: "no-cache",
    onCompleted({ getMovDistributor }) {
      // debugger;
      //console.log("333333333333");
      //console.log(getMovDistributor);
      let disFilter = [];
      let filterCount = [];
      let totPrecio = 0;
      let labels = Object?.keys(getMovDistributor[0]);
      let datCount = Object?.values(getMovDistributor[0]);
      labels.map((obj, i) => {
        if (obj !== "__typename") {
          disFilter.push(obj);
        }
      });
      getMovDistributor?.forEach((obj) => {
        if (obj.costoTotal != "null") {
          return (totPrecio += obj.costoTotal);
        }
      });
      const sum = parseInt(totPrecio);
      setNumTotalTypMovPrecio(formatter.format(filterFloat(sum)));
      filterCount = datCount.filter((obj) => obj !== "MovData");
      setDataLabelDonutMov(disFilter);
      setDataDonutTypeMov(datCount);
      const suma = filterCount?.reduce((prev, next) => prev + next);
      setNumTotalTypMov(parseInt(suma));
      setDataChartExportMov(
        getMovDistributor?.map((obj) => {
          return {
            Rapido: obj.Rapido,
            Lento: obj.Lento,
            Estancado: obj.Estancado,
            Inactivo: obj.Inactivo,
            Potencial_obsoleto: obj.Potencial_obsoleto,
            Obsoleto: obj.Obsoleto,
          };
        })
      );
      setCountLoader((count) => count + 1);
      //console.log("getMovDistributor");
    },
    onError(err) {
      //console.log(err);
    },
  });
  const [getTypeSaleDistributor] = useLazyQuery(GET_TYPE_SALE_DISTRIBUTOR, {
    fetchPolicy: "no-cache",
    onCompleted({ getTypeSaleDistributor }) {
      // debugger
      let labels = [];
      let count = [];
      let costoTotalPrecio = 0;
      getTypeSaleDistributor?.forEach((obj) => {
        //console.log(obj);
        //console.log("hhhhhhhhhhhhhhhhhh");
        if (obj.costoTotal != "null" || obj.tipo_venta != "costoTotal") {
          return (
            labels.push(obj.tipo_venta),
            count.push(obj.count),
            (costoTotalPrecio += obj.costoTotal)
          );
        }
      });
      labels.map((obj, i) => {
        if (obj == null) {
          labels[i] = "OTROS";
        }
      });

      if (count.length >= 1) {
        const suma = count?.reduce((prev, next) => prev + next);
        setNumTotal(parseInt(suma));
      } else {
        setNumTotal(parseInt(count[0]));
      }
      const sum = parseInt(costoTotalPrecio);
      setNumPreciotypeVenta(formatter.format(filterFloat(sum)));
      setDataLabelDonutVenta(labels);
      setDataDonutTypeVenta(count);
      setDataChartExportVenta(getTypeSaleDistributor);
      setCountLoader((count) => count + 1);
      //console.log("getTypeSaleDistributor");
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getTypeSaleAdmin] = useLazyQuery(GET_TYPE_SALE_ADMIN, {
    fetchPolicy: "no-cache",
    onCompleted({ getTypeSaleAdmin }) {
      // debugger;
      let labels = [];
      let count = [];
      let totPrecio = 0;
      let amount = [];

      getTypeSaleAdmin?.forEach((obj) => {
        //console.log("555555555555555555555");
        //console.log(obj.costoTotal);
        //console.log(obj);
        if (obj.costoTotal != "null" && obj.costoTotal != "costoTotal") {
          return (
            labels.push(obj.tipo_venta),
            count.push(obj.count),
            (totPrecio += obj.costoTotal ? obj.costoTotal : 0),
            amount.push(obj.costoTotal)
          );
        }
      });
      labels.map((obj, i) => {
        if (obj == null) {
          labels[i] = "OTROS";
        }
      });

      if (count.length >= 1) {
        const suma = count?.reduce((prev, next) => prev + next);
        setNumTotal(parseInt(suma));
      } else {
        setNumTotal(parseInt(count[0]));
      }
      //console.log(totPrecio);
      const sum = parseInt(totPrecio);
      //console.log(sum);
      //console.log(formatter.format(filterFloat(sum)));
      setNumPreciotypeVenta(formatter.format(filterFloat(sum)));
      currentFilterGeneral != "" && setNumTotal(currentFilterGeneral);
      currentFilterGeneral == "Volumen de venta" && setNumPreciotypeVenta(sum);

      setDataLabelDonutVenta(labels);
      setDataDonutTypeVenta(count);
      setTableDetailbest(amount);
      setDataChartExportVenta(getTypeSaleAdmin);
      setCountLoader((count) => count + 1);
      setGraph3(true);
      //console.log("getTypeSaleAdmin");

      setTimeout(function () {
        namechange2("el");
      }, 500);

      function namechange2(oc) {
        const select1 = document.getElementById("selectPeriodo3");
        select1 && (select1.innerHTML = namegraph3);
        //console.log(oc);
        //console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [getThreeinOne] = useLazyQuery(GET_THREE_IN_ONE, {
    fetchPolicy: "no-cache",
    onCompleted({ getThreeInOne }) {
      //console.log(getThreeInOne);
      //console.log("getThreeInOne");

      /**ABC DATA */
      let count = [];
      //console.log("llega");
      //console.log(getThreeInOne.res1);
      getThreeInOne.res1?.forEach((obj) => {
        return count.push(obj.total);
      });
      //console.log(count);
      setDataDonut(count);
      setNumeroTotal(count);
      //console.log("44444-->" + count);
      if (count.length >= 1) {
        //console.log("enrta al if de >1");
        const suma = count?.reduce((prev, next) => prev + next);
        setNumeroTotal(parseInt(suma) || 0);
        //console.log(suma);
        // setCountLoader((count) => count + 1)
      }
      setGraph1(true);

      /**SECOND */
      // debugger
      //console.log("step----------------1");
      //console.log(getThreeInOne.res2);
      let disFilter = [];
      let filterCount = [];
      let labels = Object?.keys(
        getThreeInOne.res2?.length > 0 && getThreeInOne.res2[0]
      );
      //console.log("step----------------2");
      let datCount = Object?.values(
        getThreeInOne.res2?.length > 0 && getThreeInOne.res2[0]
      );
      //console.log("step----------------3");
      labels.map((obj, i) => {
        if (obj !== "__typename") {
          disFilter.push(obj);
        }
      });

      //console.log("step----------------4");
      filterCount = datCount.filter((obj) => obj !== "MovData");
      setDataLabelDonutMov(disFilter);
      setDataDonutTypeMov(datCount);
      const suma = filterCount?.reduce((prev, next) => prev + next);
      setNumTotalTypMov(parseInt(suma));
      setDataChartExportMov(
        getThreeInOne.res2?.map((obj) => {
          return {
            Rapido: obj.Rapido,
            Lento: obj.Lento,
            Estancado: obj.Estancado,
            Inactivo: obj.Inactivo,
            Potencial_obsoleto: obj.Potencial_obsoleto,
            Obsoleto: obj.Obsoleto,
          };
        })
      );
      //console.log("step----------------5");
      setCountLoader((count) => count + 1);
      setGraph2(true);
      //console.log("getMovDistributorAdmin");

      /**THIRD */
      let labels2 = [];
      let count2 = [];
      getThreeInOne.res3?.forEach((obj) => {
        return labels2.push(obj.tipo_venta), count2.push(obj.count);
      });
      labels2.map((obj, i) => {
        if (obj == null) {
          labels2[i] = "OTROS";
        }
      });

      if (count2.length >= 1) {
        const suma = count2?.reduce((prev, next) => prev + next);
        setNumTotal(parseInt(suma));
      } else {
        setNumTotal(parseInt(count2[0]));
      }

      setDataLabelDonutVenta(labels2);
      setDataDonutTypeVenta(count2);
      setDataChartExportVenta(getThreeInOne.res3);
      setCountLoader((count2) => count2 + 1);
      setGraph3(true);
      //console.log("getTypeSaleAdmin");
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [consultaHistorico] = useLazyQuery(CONSULTA_HISTORICO, {
    fetchPolicy: "no-cache",
    onCompleted({ consultaHistorial }) {
      //console.log(consultaHistorial);
      //console.log("consultaHistorial");
      if (consultaHistorial.ok) {
        startGraphs();
      } else {
        runAlgori({
          variables: {
            celdaL: "",
          },
        });
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [saveHistoricABC] = useLazyQuery(SAVE_HISTORICO_ABC, {
    fetchPolicy: "no-cache",
    onCompleted({ saveHistoricABC }) {
      //console.log(saveHistoricABC);
      //console.log("saveHistoricABC");
      setHistoricABC(true);
      if (saveHistoricABC.ok) {
        setTimeout(
          function () {
            saveHistoricMOV({
              variables: {
                a: "",
              },
            });
          },

          10000
        );
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [saveHistoricMOV] = useLazyQuery(SAVE_CONSULTA_HISTORICO_MOV, {
    fetchPolicy: "no-cache",
    onCompleted({ saveHistoricMOV }) {
      //console.log(saveHistoricMOV);
      //console.log("saveHistoricMOV");
      setHistoricMOV(true);
      if (saveHistoricMOV.ok) {
        setTimeout(function () {
          saveHistoricVENTA({
            variables: {
              a: "",
            },
          });
        }, 10000);
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [saveHistoricVENTA] = useLazyQuery(SAVE_CONSULTA_HISTORICO_VENTA, {
    fetchPolicy: "no-cache",
    onCompleted({ saveHistoricVENTA }) {
      //console.log(saveHistoricVENTA);
      //console.log("saveHistoricVENTA");
      setHistoricVENTA(true);
      if (saveHistoricVENTA.ok) {
        setTimeout(function () {
          startGraphs();
        }, 10000);
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  const [runAlgori] = useLazyQuery(RUN_ALGORITHM, {
    fetchPolicy: "no-cache",
    onCompleted({ runAlgorithm }) {
      //console.log(runAlgorithm);
      //console.log("runAlgorithm");
      if (runAlgorithm.ok) {
        setTimeout(function () {
          saveHistoricABC({
            variables: {
              a: "",
            },
          });
        }, 10000);
      }
    },
    onError(err) {
      //console.log(err);
    },
  });

  const showObsoletTable = async (data) => {
    //console.log("{{{{{{{{{{{{{{{first}}}}}}}}}}}}}}}");
    setDataTable(data);
  };
  const changeLoading = (res) => {
    //console.log("change{{{{{{{{");
    setLoadingtableadata(false);
  };

  const startGraphs = () => {
    const tipo = localStorage.getItem("typeUser");
    setUserT(tipo);
    let marcasFiltradas2b = sessionStorage.getItem("idMarcaAut2");
    //console.log("??????????---->>>" + marcasFiltradas2b);
    getmarcaslastFilter2({
      variables: {
        e: marcasFiltradas2b == "no" ? marcasFiltradas2b.toString() : "",
      },
    });

    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
    const email = sessionStorage.getItem("auxEmail");
    sessionStorage.setItem("emailGeneral", email);
    //console.log("emailCiente", email);
    // getABCDistribuidor({
    //     variables: {
    //         e: email
    //     }
    // })
    // getPotObsoletos({
    //     variables: {
    //         tipMov: "",
    //         email: email

    //     }
    // })

    // getTypeSaleDistributor({
    //     variables: {
    //         e: email
    //     }
    // })
    // setLoadingPublicados(true)
    const marcasFiltradas = [];
    const idDistiltrado = [];
    const idFather = [];
    marcas &&
      marcas.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca-${index}`);
        temp.checked && marcasFiltradas.push(id_Marca);
      });
    Mamedist &&
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked && idDistiltrado.push(id_distribuidor);
      });
    nameFathers &&
      nameFathers.map(({ id_fathers, name }, index) => {
        let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
        temp?.checked && idFather.push(id_fathers);
      });

    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());
    // setLoadingPublicados(true)
    if (tipo == 1) {
      setFatherStaticId("no");
      setMarcasrStaticId("no");
      setIdDist2("no");

      // getThreeinOne({
      //   variables: {
      //     a: marcasFiltradas.toString(),
      //     b: idDistiltrado.toString(),
      //     c: idFather.toString(),
      //   },
      // });
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });

      getPotObsoletosAdmincount({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    } else if (tipo == 2 || tipo == 3 || tipo == 4) {
      setIdDist2("no");
      setFatherStaticId(sessionStorage.getItem("idFather2"));
      setMarcasrStaticId(sessionStorage.getItem("idMarcaAut2"));
      const idFatherfazt = sessionStorage.getItem("idFather2");
      const idMarcasFazt = sessionStorage.getItem("idMarcaAut2");
      searchMarcasF("", idFatherfazt);
      sessionStorage.setItem("idFather", idFatherfazt.toString());
      sessionStorage.setItem("marcasFiltradas", idMarcasFazt.toString());
      getNameFilters({
        variables: {
          a: idMarcasFazt != "no" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
        },
      });

      getABCDistribuidorAdmin({
        variables: {
          a: idMarcasFazt != "no" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
        },
      });

      getMovAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
        },
      });

      getTypeSaleAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
        },
      });

      getBestSellPartAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });

      getPotObsoletosAdmincount({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: idDistiltrado.toString(),
          c: idFatherfazt.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    } else if (tipo == 5) {
      setFatherStaticId(sessionStorage.getItem("idFather2"));
      setMarcasrStaticId(sessionStorage.getItem("idMarcaAut2"));
      setIdDist2(sessionStorage.getItem("idDist2"));
      const bidFatherfazt = sessionStorage.getItem("idFather2");
      const bidDist2 = sessionStorage.getItem("idDist2");
      const idMarcasFazt = sessionStorage.getItem("idMarcaAut2");
      //console.log(")))))))))))))))))))))))))))))");
      //console.log(idMarcasFazt);
      //console.log(bidDist2);
      sessionStorage.setItem("idDistiltrado", idDist2.toString());
      sessionStorage.setItem("idFather", bidFatherfazt.toString());

      sessionStorage.setItem("marcasFiltradas", idMarcasFazt.toString());

      searchMarcasF(idDist2, bidFatherfazt);
      getNameFilters({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
        },
      });

      getABCDistribuidorAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
        },
      });

      getMovAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
        },
      });

      getTypeSaleAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
        },
      });

      getBestSellPartAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });

      getPotObsoletosAdmincount({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: idMarcasFazt != "false" ? idMarcasFazt.toString() : "",
          b: bidDist2.toString(),
          c: bidFatherfazt.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }
  };

  useEffect(() => {
    if (countLoader >= 1) {
      setIsLoading(false);
    }
  }, [countLoader]);

  useEffect(() => {
    /** Save historic and runAlgoritm */
    consultaHistorico({
      variables: {
        a: "",
      },
    });
  }, [againthis]);

  // useEffect(() => {
  //   if (historicVenta) {
  //     startGraphs();
  //   }
  // }, [historicVenta]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const filterFloat = (__val__) => {
    const preg = /^([0-9]+\.?[0-9]{0,2})$/;
    if (preg.test(__val__) === true) {
      return __val__;
    }
    return "";
  };

  const options = [
    { value: "Potencial obsoleto", label: "Potencialmente obsoletos" },
    { value: "Obsoleto", label: "Obsoletos" },
  ];
  const filterByTypMove = ({ target }) => {
    const marcasFiltradas = [];
    const idDistiltrado = [];
    const idFather = [];
    marcas &&
      marcas.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca-${index}`);
        temp.checked && marcasFiltradas.push(id_Marca);
      });
    Mamedist &&
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked && idDistiltrado.push(id_distribuidor);
      });
    nameFathers &&
      nameFathers.map(({ id_fathers, name }, index) => {
        let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
        temp?.checked && idFather.push(id_fathers);
      });

    marcas2 &&
      marcas2.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca2-${index}`);
        temp && temp.checked && marcasFiltradas.push(id_Marca);
      });
    marcasStaticId &&
      marcasStaticId != "" &&
      marcasStaticId != "no" &&
      marcasFiltradas.push(marcasStaticId);

    fatherStaticId &&
      fatherStaticId != "" &&
      fatherStaticId != "no" &&
      idFather.push(fatherStaticId);

    idDist2 && idDist2 != "" && idDist2 != "no" && idDistiltrado.push(idDist2);

    setOptionTypeMov(target.value);
    // setLoadingPublicados(true)
    const email = sessionStorage.getItem("auxEmail");
    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());

    // setLoadingtableadata(false);

    if (target.value == "") {
      const email = sessionStorage.getItem("auxEmail");
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: "",
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: "",
          email: email,
        },
      });
    } else {
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: target.value,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: target.value,
          email: email,
        },
      });
    }
  };
  const filterByDistribuidor = ({ target }) => {
    const { value } = target;
    //console.log("grupo", value);
    if (value === "") {
      //console.log("/////////////////////////////");
      setNumeroTotal("");
      setNumeroPrecio("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];

      dataBarPiecesAux.forEach((obj) => {
        arrayPiezas.push(obj.celda_M);
        labels.push(obj.parte);
      });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      getABCDistribuidor({
        variables: {
          e: sessionStorage.getItem("emailGeneral"),
        },
      });
      getPotObsoletos({
        variables: {
          tipMov: "",
          email: sessionStorage.getItem("emailGeneral"),
        },
      });
      getTypeSaleDistributor({
        variables: {
          e: sessionStorage.getItem("emailGeneral"),
        },
      });
      getMovDistributor({
        variables: {
          e: sessionStorage.getItem("emailGeneral"),
        },
      });
    } else {
      setNumeroTotal("");
      setNumeroPrecio("");
      setNumTotal("");
      setNumTotalTypMov("");
      const typeDisSplit = String(value).split("_");
      //console.log("***************************");
      //console.log(typeDisSplit);
      //console.log(value);
      setOptionSelected(value);
      sessionStorage.setItem("auxEmail", typeDisSplit[1]);
      const byDistribuidor = dataAux?.filter(
        (item) => item.distribuidor === typeDisSplit[0]
      );
      const byDisBarPieces = dataBarPiecesAux.filter(
        (item) => item.distribuidor === typeDisSplit[0]
      );
      const byDataBarDis = dataBarDisAux.filter(
        (item) => item.label === typeDisSplit[0]
      );
      const group = typeDisSplit[2];
      setIdGroup(group);

      setDataTable(byDistribuidor);
      let sum4 = 0;
      let sum5 = 0;
      byDisBarPieces.forEach((obj) => {
        if (obj.id_parte === 4) {
          sum4 += obj.celda_M;
        } else if (obj.id_parte === 5) {
          sum5 += obj.celda_M;
        }
      });
      setDataBarPieces([sum4, sum5]);

      setDataBarDis(byDataBarDis);

      // getABCDistribuidor({
      //     variables: {
      //         e: typeDisSplit[1]
      //     }
      // })
      // getMovDistributor({
      //     variables: {
      //         e: typeDisSplit[1]
      //     }
      // })
      /**filtros combinados, desde aqui manejamos los filtros de marcas */

      const marcasFiltradas = [];
      const idDistiltrado = [];
      const idFather = [];
      marcas.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca-${index}`);
        temp.checked && marcasFiltradas.push(id_Marca);
      });
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked && idDistiltrado.push(id_distribuidor);
      });
      nameFathers.map(({ id_fathers, name }, index) => {
        let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
        temp?.checked && idFather.push(id_fathers);
      });

      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      // if (marcasStaticId != "no") {
      //   marcasFiltradas = marcasStaticId;
      // }

      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // getPotObsoletos({
      //     variables: {
      //         tipMov: "Potencial obsoleto",
      //         email: typeDisSplit[1]

      //     }
      // })
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // getTypeSaleDistributor({
      //     variables: {
      //         e: typeDisSplit[1]

      //     }
      // })
    }
  };

  const cleanfilters = () => {
    setGraph1(false);
    setGraph2(false);
    setGraph3(false);
    setGraph4(false);
    setLoadingtableadata(true);

    const email = sessionStorage.getItem("auxEmail");
    sessionStorage.setItem("emailGeneral", email);
    //console.log("emailCiente", email);

    const marcasFiltradas = [];
    const idDistiltrado = [];
    const idFather = [];
    marcas &&
      marcas.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca-${index}`);
        temp.checked && marcasFiltradas.push(id_Marca);
      });
    Mamedist &&
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked && idDistiltrado.push(id_distribuidor);
      });
    nameFathers &&
      nameFathers.map(({ id_fathers, name }, index) => {
        let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
        temp?.checked && idFather.push(id_fathers);
      });

    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());

    if (fatherStaticId != "no") {
      idFather = fatherStaticId;
    }
    if (idDist2 != "no") {
      idDistiltrado = idDist2;
    }

    // setLoadingPublicados(true)

    getABCDistribuidorAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
      },
    });

    getMovAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
      },
    });

    getTypeSaleAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
      },
    });

    getBestSellPartAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
      },
    });
    // setLoadingtableadata(false);
    getPotObsoletosAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: optionTypeMov,
        email: email,
        limit: limitepaginas,
        orderby: orderBy,
      },
    });
    setLoadingPublicados(true);
    getPotObsoletosAdminTable({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: optionTypeMov,
        email: email,
      },
    });
    // getMovDistributor({
    //     variables: {
    //         e: email
    //     }
    // })
  };
  const searchGroupDis = (marcas, fathers) => {
    //console.log("yyyyyyyyyyyyy");
    //console.log(fathers);
    let fathers2 = "yy";
    fathers && (fathers2 = fathers);
    setDistActive(true);
    distByfilter({
      variables: {
        a: marcas,
        b: fathers2,
      },
    });
  };

  const searchFatherDis = (marcas, fathers) => {
    setDisHerActive(true);
    distByfilter({
      variables: {
        a: marcas,
        b: fathers,
      },
    });
  };

  const searchMarcasF = (dist, fathers) => {
    setDisHerActive(true);
    let marcasFiltradas2b = sessionStorage.getItem("idMarcaAut2");
    //console.log("??????????---->>>" + marcasFiltradas2b);
    getmarcaslastFilter({
      variables: {
        a: fathers,
        b: dist,
        c: marcasFiltradas2b != "no" ? marcasFiltradas2b : "",
      },
    });
  };

  const searchDiabale = (marcas2) => {
    //console.log("¡¡¡¡¡¡¡¡¡¡¡¡¡¡¡??????????????");
    //console.log(marcas2);

    if (marcas2 == 1) {
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked = false;
      });
      setDistActive(false);
      const checktemporal = document.getElementById("checkboxesd");
      checktemporal && (checktemporal.style.display = "none");
    } else {
      setDisHerActive(false);
      setDistActive(false);
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked = false;
      });

      marcas.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca-${index}`);
        temp.checked = false;
      });
      const checktemporal = document.getElementById("checkboxesd");
      checktemporal && (checktemporal.style.display = "none");

      const checktemporal2 = document.getElementById("checkboxes");
      checktemporal2 && (checktemporal2.style.display = "none");
    }
  };
  const resetfiltergeneral = () => {
    const temp = document.getElementById("general-filter");
    temp && (temp.innerHTML = "¿Como quieres comenzar?");
    const temp2 = document.getElementById("checkbox-general-Venta");
    temp2 && (temp2.checked = false);
    const temp3 = document.getElementById("checkbox-general-Utilidad");
    temp3 && (temp3.checked = false);
    const temp4 = document.getElementById("checkbox-general-Volumen");
    temp4 && (temp4.checked = false);
  };
  const checkedFilter = ({ target }) => {
    //console.log("11111111111111111111111111111111111");
    const box2 = document.getElementById("checkboxes2");
    const dh = document.getElementById("checkboxesdh");
    const quest = document.getElementById("checkboxesquestion");
    document.getElementById("checkboxes").style.display = "none";
    box2 && (box2.style.display = "none");
    const checktemporal = document.getElementById("checkboxesd");
    checktemporal && (checktemporal.style.display = "none");
    dh && (dh.style.display = "none");
    quest && (quest.style.display = "none");

    if (target.id.includes("checkbox-question-marcas")) {
      if (document.getElementById("checkbox-question-marcas").checked) {
        document.getElementById("filterbydist").style.display = "none";
        document.getElementById("filterbyMarca").style.display = "flex";
        if (document.getElementById("checkbox-question-gd").checked) {
          // cleanfilters();
        }
        document.getElementById("checkbox-question-gd").checked = false;
      }
    }

    /**marcas without another filter */
    const marcasFiltradas2 = [];

    if (target.id.includes("checkbox-question-gd")) {
      if (document.getElementById("checkbox-question-gd").checked) {
        document.getElementById("filterbyMarca").style.display = "none";
        document.getElementById("filterbydist").style.display = "flex";
        if (document.getElementById("checkbox-question-marcas").checked) {
          // cleanfilters();
        }
        document.getElementById("checkbox-question-marcas").checked = false;
        marcas2.map(({ id_Marca }, index) => {
          let temp = document.getElementById(`checkbox-marca2-${index}`);
          temp.checked = false;
          temp.checked && marcasFiltradas2.push(id_Marca);
        });
        // cleanfilters();
      }
    }

    marcas2.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca2-${index}`);
      if (target.id.includes("checkbox-marca2")) {
        Mamedist.map(({ id_distribuidor, name }, index) => {
          let temp = document.getElementById(
            `checkbox-distribuidor-${id_distribuidor}`
          );
          temp.checked = false;
        });

        nameFathers.map(({ id_fathers, name }, index) => {
          let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
          temp.checked = false;
        });
        target.id != temp.id && (temp.checked = false);
        temp && temp.checked && marcasFiltradas2.push(id_Marca);
        temp &&
          temp.checked &&
          sessionStorage.setItem("marcasFiltradas", id_Marca.toString());
      }
    });

    const marcasFiltradas = [];
    const marcasFiltradasNull = [];
    const idDistiltrado = [];
    const idFather = [];
    //console.log(target);
    marcas.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca-${index}`);
      target.id.includes("checkbox-marca") &&
        target.id != temp.id &&
        (temp.checked = false);
      let tempnull = document.getElementById(`checkbox-marca-null`);
      target.id.includes("checkbox-marca") &&
        target.id !== tempnull?.id &&
        (tempnull.checked = false);
      temp.checked && marcasFiltradas.push(id_Marca);
    });
    Mamedist.map(({ id_distribuidor, name }, index) => {
      let temp = document.getElementById(
        `checkbox-distribuidor-${id_distribuidor}`
      );
      target.id.includes("checkbox-distribuidor") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp.checked && idDistiltrado.push(id_distribuidor);
    });
    nameFathers.map(({ id_fathers, name }, index) => {
      let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
      target.id.includes("checkbox-fathers") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp?.checked && idFather.push(id_fathers);
    });

    let tempnull = document.getElementById(`checkbox-marca-null`);
    tempnull?.checked && marcasFiltradas.push("contienenulo");
    let tempnull2 = document.getElementById(`checkbox-distribuidor-null`);
    tempnull2?.checked && idDistiltrado.push("contienenulo");

    //console.log("******======");
    //console.log(marcasFiltradas);
    //console.log(idDistiltrado);
    //console.log(idFather);
    //console.log("llega1");
    marcasFiltradas.length > 0 &&
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());
    //console.log(target.id);
    if (target.id.includes("marca")) {
      marcasFiltradas.length > 0
        ? searchGroupDis(
            marcasFiltradas[0]?.toString(),
            idFather[0]?.toString()
          )
        : searchDiabale(1);
    }
    if (target.id.includes("fathers")) {
      idFather.length > 0
        ? searchMarcasF(marcasFiltradas[0]?.toString(), idFather[0]?.toString())
        : searchDiabale(2);
    }

    /**filtros combinados, desde aqui manejamos los filtros de marcas */

    if (
      marcasFiltradas2.length > 0 &&
      !target.id.includes("checkbox-question-marcas") &&
      !target.id.includes("checkbox-question-gd") &&
      !target.id.includes("checkbox-fathers-") &&
      !target.id.includes("selecgraph1") &&
      !target.id.includes("selecgraph2") &&
      !target.id.includes("selecgraph3")
    ) {
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      setGraph4(false);
      setLoadingtableadata(true);
      setNumeroPrecio("");
      setNumeroTotal("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];
      resetfiltergeneral();
      //   dataBarPiecesAux.forEach(obj => {
      //       arrayPiezas.push(obj.celda_M)
      //       labels.push(obj.parte)
      //   });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }

      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      const email = sessionStorage.getItem("auxEmail");

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }

    if (
      idDistiltrado.length > 0 &&
      marcasFiltradas.length > 0 &&
      idFather.length > 0 &&
      !target.id.includes("checkbox-question-marcas") &&
      !target.id.includes("checkbox-question-gd") &&
      !target.id.includes("checkbox-fathers-") &&
      !target.id.includes("selecgraph1") &&
      !target.id.includes("selecgraph2") &&
      !target.id.includes("selecgraph3")
    ) {
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      setGraph4(false);
      setLoadingtableadata(true);

      setNumeroTotal("");
      setNumeroPrecio("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];
      resetfiltergeneral();
      //   dataBarPiecesAux.forEach(obj => {
      //       arrayPiezas.push(obj.celda_M)
      //       labels.push(obj.parte)
      //   });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      const email = sessionStorage.getItem("auxEmail");

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }

    if (target.id.includes("selecgraph1")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph1(sessionStorage.getItem("periodo"));
      //console.log(target);
      //console.log(target.name2);
      //console.log(target.name + "wwwwwwwwwwwwwwwwwwwwwwwwwwww");
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph1${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo").innerHTML = target.name;
      //console.log(target);
      setGraph1(false);
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    if (target.id.includes("selecgraph2")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);

      setNamegraph2(sessionStorage.getItem("periodo2"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph2${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo2").innerHTML = target.name;
      //console.log(target);
      setGraph2(false);
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    if (target.id.includes("selecgraph3")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);

      setNamegraph3(sessionStorage.getItem("periodo3"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph3${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo3").innerHTML = target.name;
      //console.log(target);
      setGraph3(false);
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    // getPotObsoletos({
    //     variables: {
    //         tipMov: "",
    //         email: email

    //     }
    // })
  };

  const checkedFilter2 = ({ target }) => {
    //console.log("222222222222222222222");
    const box2 = document.getElementById("checkboxes2");
    const dh = document.getElementById("checkboxesdh");
    const quest = document.getElementById("checkboxesquestion");
    document.getElementById("checkboxes").style.display = "none";
    box2 && (box2.style.display = "none");
    const checktemporal = document.getElementById("checkboxesd");
    checktemporal && (checktemporal.style.display = "none");
    dh && (dh.style.display = "none");
    quest && (quest.style.display = "none");

    if (target.id.includes("checkbox-question-marcas")) {
      if (document.getElementById("checkbox-question-marcas").checked) {
        document.getElementById("filterbydist").style.display = "none";
        document.getElementById("filterbyMarca").style.display = "flex";
        if (document.getElementById("checkbox-question-gd").checked) {
          // cleanfilters();
        }
        document.getElementById("checkbox-question-gd").checked = false;
      }
    }

    /**marcas without another filter */
    const marcasFiltradas2 = [];

    if (target.id.includes("checkbox-question-gd")) {
      if (document.getElementById("checkbox-question-gd").checked) {
        document.getElementById("filterbyMarca").style.display = "none";
        document.getElementById("filterbydist").style.display = "flex";
        if (document.getElementById("checkbox-question-marcas").checked) {
          // cleanfilters();
        }
        document.getElementById("checkbox-question-marcas").checked = false;
        marcas2.map(({ id_Marca }, index) => {
          let temp = document.getElementById(`checkbox-marca2-${index}`);
          temp.checked = false;
          temp.checked && marcasFiltradas2.push(id_Marca);
        });
        // cleanfilters();
      }
    }

    marcas2.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca2-${index}`);
      target.id.includes("checkbox-marca2") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp && temp.checked && marcasFiltradas2.push(id_Marca);
    });

    const marcasFiltradas = [];
    const marcasFiltradasNull = [];
    let idDistiltrado = [];
    let idFather = [];
    //console.log(target);
    marcas.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca-${index}`);
      target.id.includes("checkbox-marca") &&
        target.id != temp.id &&
        (temp.checked = false);
      let tempnull = document.getElementById(`checkbox-marca-null`);
      target.id.includes("checkbox-marca") &&
        target.id !== tempnull?.id &&
        (tempnull.checked = false);
      temp.checked && marcasFiltradas.push(id_Marca);
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        target.id.includes("checkbox-marca") &&
          target.id != temp.id &&
          (temp.checked = false);
        temp.checked && idDistiltrado.push(id_distribuidor);
      });
    });
    Mamedist.map(({ id_distribuidor, name }, index) => {
      let temp = document.getElementById(
        `checkbox-distribuidor-${id_distribuidor}`
      );
      target.id.includes("checkbox-distribuidor") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp.checked && idDistiltrado.push(id_distribuidor);
    });
    nameFathers.map(({ id_fathers, name }, index) => {
      let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
      target.id.includes("checkbox-fathers") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp && temp?.checked && idFather.push(id_fathers);
    });

    let tempnull = document.getElementById(`checkbox-marca-null`);
    tempnull?.checked && marcasFiltradas.push("contienenulo");
    let tempnull2 = document.getElementById(`checkbox-distribuidor-null`);
    tempnull2?.checked && idDistiltrado.push("contienenulo");

    //console.log("******======");
    //console.log(marcasFiltradas);
    //console.log(idDistiltrado);
    //console.log(idFather);

    //console.log(target.id);
    if (target.id.includes("marca")) {
      marcasFiltradas.length > 0
        ? searchGroupDis(
            marcasFiltradas[0]?.toString(),
            idFather[0]?.toString()
          )
        : searchDiabale(1);
    }
    if (target.id.includes("fathers")) {
      idFather.length > 0
        ? searchMarcasF(marcasFiltradas[0]?.toString(), idFather[0]?.toString())
        : searchDiabale(2);
    }

    //console.log("llega1");
    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());

    /**filtros combinados, desde aqui manejamos los filtros de marcas */

    if (
      marcasFiltradas2.length > 0 &&
      !target.id.includes("checkbox-marca-") &&
      !target.id.includes("checkbox-question-marcas") &&
      !target.id.includes("selecgraph1") &&
      !target.id.includes("selecgraph2") &&
      !target.id.includes("selecgraph3")
    ) {
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      setGraph4(false);
      setLoadingtableadata(true);
      setNumeroTotal("");
      setNumeroPrecio("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];
      resetfiltergeneral();
      //   dataBarPiecesAux.forEach(obj => {
      //       arrayPiezas.push(obj.celda_M)
      //       labels.push(obj.parte)
      //   });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }

      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      const email = sessionStorage.getItem("auxEmail");

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }

    if (
      idDistiltrado.length > 0 &&
      marcasFiltradas.length > 0 &&
      !target.id.includes("checkbox-marca-") &&
      !target.id.includes("checkbox-question-marcas") &&
      !target.id.includes("selecgraph1") &&
      !target.id.includes("selecgraph2") &&
      !target.id.includes("selecgraph3")
    ) {
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      setGraph4(false);
      setLoadingtableadata(true);
      setNumeroPrecio("");
      setNumeroTotal("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];
      resetfiltergeneral();
      //   dataBarPiecesAux.forEach(obj => {
      //       arrayPiezas.push(obj.celda_M)
      //       labels.push(obj.parte)
      //   });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      const email = sessionStorage.getItem("auxEmail");

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }

    if (target.id.includes("selecgraph1")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph1(sessionStorage.getItem("periodo"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph1${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo").innerHTML = target.name;
      //console.log(target);
      setGraph1(false);
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    if (target.id.includes("selecgraph2")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph2(sessionStorage.getItem("periodo2"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph2${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo2").innerHTML = target.name;
      //console.log(target);
      setGraph2(false);
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    if (target.id.includes("selecgraph3")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph3(sessionStorage.getItem("periodo3"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph3${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo3").innerHTML = target.name;
      //console.log(target);
      setGraph3(false);
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }
    // getPotObsoletos({
    //     variables: {
    //         tipMov: "",
    //         email: email

    //     }
    // })
  };

  const checkedFilter3 = ({ target }) => {
    //console.log("333333333333333333333");
    const box2 = document.getElementById("checkboxes2");
    const dh = document.getElementById("checkboxesdh");
    const quest = document.getElementById("checkboxesquestion");
    const boxd = document.getElementById("checkboxesd");
    document.getElementById("checkboxes").style.display = "none";
    box2 && (box2.style.display = "none");
    boxd && (boxd.style.display = "none");
    dh && (dh.style.display = "none");
    quest && (quest.style.display = "none");

    if (target.id.includes("checkbox-question-marcas")) {
      if (document.getElementById("checkbox-question-marcas").checked) {
        document.getElementById("filterbydist").style.display = "none";
        document.getElementById("filterbyMarca").style.display = "flex";
        if (document.getElementById("checkbox-question-gd").checked) {
          // cleanfilters();
        }
        document.getElementById("checkbox-question-gd").checked = false;
      }
    }

    /**marcas without another filter */
    const marcasFiltradas2 = [];

    if (target.id.includes("checkbox-question-gd")) {
      if (document.getElementById("checkbox-question-gd").checked) {
        document.getElementById("filterbyMarca").style.display = "none";
        document.getElementById("filterbydist").style.display = "flex";
        if (document.getElementById("checkbox-question-marcas").checked) {
          // cleanfilters();
        }
        document.getElementById("checkbox-question-marcas").checked = false;
        marcas2.map(({ id_Marca }, index) => {
          let temp = document.getElementById(`checkbox-marca2-${index}`);
          temp.checked = false;
          temp.checked && marcasFiltradas2.push(id_Marca);
        });
        // cleanfilters();
      }
    }

    marcas.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca2-${index}`);
      target.id.includes("checkbox-marca2") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp && temp.checked && marcasFiltradas2.push(id_Marca);
    });

    const marcasFiltradas = [];
    const marcasFiltradasNull = [];
    let idDistiltrado = [];
    let idFather = [];
    //console.log(target);
    marcas.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca-${index}`);
      target.id.includes("checkbox-marca") &&
        target.id != temp.id &&
        (temp.checked = false);
      let tempnull = document.getElementById(`checkbox-marca-null`);
      target.id.includes("checkbox-marca") &&
        target.id !== tempnull?.id &&
        (tempnull.checked = false);
      temp.checked && marcasFiltradas.push(id_Marca);
    });
    Mamedist.map(({ id_distribuidor, name }, index) => {
      let temp = document.getElementById(
        `checkbox-distribuidor-${id_distribuidor}`
      );
      target.id.includes("checkbox-distribuidor") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp && temp.checked && idDistiltrado.push(id_distribuidor);
    });
    nameFathers.map(({ id_fathers, name }, index) => {
      let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
      target.id.includes("checkbox-fathers") &&
        target.id != temp.id &&
        (temp.checked = false);
      temp && temp?.checked && idFather.push(id_fathers);
    });

    let tempnull = document.getElementById(`checkbox-marca-null`);
    tempnull?.checked && marcasFiltradas.push("contienenulo");
    let tempnull2 = document.getElementById(`checkbox-distribuidor-null`);
    tempnull2?.checked && idDistiltrado.push("contienenulo");

    //console.log("******======");
    //console.log(marcasFiltradas);
    //console.log(idDistiltrado);
    //console.log(idFather);
    //console.log(target.id);
    // if (target.id.includes('marca')){
    //     marcasFiltradas.length > 0 ? searchGroupDis(marcasFiltradas[0]?.toString(), idFather[0]?.toString()) : searchDiabale(1);
    // }
    // if (target.id.includes('fathers')){
    //     idFather.length > 0 ? searchMarcasF(marcasFiltradas[0]?.toString(), idFather[0]?.toString()) :  searchDiabale(2);
    // }

    //console.log("llega1");
    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());

    /**filtros combinados, desde aqui manejamos los filtros de marcas */

    if (
      marcasFiltradas2.length > 0 &&
      !target.id.includes("checkbox-question-marcas") &&
      !target.id.includes("selecgraph1") &&
      !target.id.includes("selecgraph2") &&
      !target.id.includes("selecgraph3")
    ) {
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      setGraph4(false);
      setLoadingtableadata(true);
      setNumeroPrecio("");
      setNumeroTotal("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];
      resetfiltergeneral();
      //   dataBarPiecesAux.forEach(obj => {
      //       arrayPiezas.push(obj.celda_M)
      //       labels.push(obj.parte)
      //   });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }

      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      const email = sessionStorage.getItem("auxEmail");

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas2.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }

    if (
      marcasFiltradas.length > 0 &&
      !target.id.includes("checkbox-question-marcas") &&
      !target.id.includes("selecgraph1") &&
      !target.id.includes("selecgraph2") &&
      !target.id.includes("selecgraph3")
    ) {
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      setGraph4(false);
      setLoadingtableadata(true);
      setNumeroPrecio("");
      setNumeroTotal("");
      setNumTotal("");
      setNumTotalTypMov("");
      setOptionSelected("");
      let arrayPiezas = [];
      let labels = [];
      resetfiltergeneral();
      //   dataBarPiecesAux.forEach(obj => {
      //       arrayPiezas.push(obj.celda_M)
      //       labels.push(obj.parte)
      //   });

      setDataBarPieces(arrayPiezas);
      setLabelsBarPieces(labels);
      setDataBarDis(dataBarDisAux);

      if (fatherStaticId != "no") {
        idFather = fatherStaticId;
      }
      if (idDist2 != "no") {
        idDistiltrado = idDist2;
      }
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });

      const email = sessionStorage.getItem("auxEmail");

      getBestSellPartAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
        },
      });
      // setLoadingtableadata(false);
      getPotObsoletosAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
          limit: limitepaginas,
          orderby: orderBy,
        },
      });
      setLoadingPublicados(true);
      getPotObsoletosAdminTable({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          tipMov: optionTypeMov,
          email: email,
        },
      });
    }

    // getPotObsoletos({
    //     variables: {
    //         tipMov: "",
    //         email: email

    //     }
    // })

    if (target.id.includes("selecgraph1")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph1(sessionStorage.getItem("periodo"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph1${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }

      //console.log(target);
      setGraph1(false);
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    if (target.id.includes("selecgraph2")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph2(sessionStorage.getItem("periodo2"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph2${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo2").innerHTML = target.name;
      //console.log(target);
      setGraph2(false);
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }

    if (target.id.includes("selecgraph3")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      setNamegraph3(sessionStorage.getItem("periodo3"));
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      for (let i = 0; i < 12; i++) {
        let temp = document.getElementById(`selecgraph3${i + 1}`);
        temp.id !== target.id && (temp.checked = false);
      }
      document.getElementById("selectPeriodo3").innerHTML = target.name;
      //console.log(target);
      setGraph3(false);
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          d: target.name,
        },
      });
    }
  };

  const checkedGeneral = ({ target }) => {
    //console.log("666666666666666666");
    var checkboxes = document.getElementById("checkboxesgeneral");
    checkboxes.style.display = "none";
    const box2 = document.getElementById("checkboxes2");
    const dh = document.getElementById("checkboxesdh");
    const quest = document.getElementById("checkboxesquestion");
    document.getElementById("checkboxes").style.display = "none";
    box2 && (box2.style.display = "none");
    const checktemporal = document.getElementById("checkboxesd");
    checktemporal && (checktemporal.style.display = "none");
    dh && (dh.style.display = "none");
    quest && (quest.style.display = "none");

    if (target.id.includes("checkbox-general-Venta")) {
      document.getElementById("checkbox-general-Volumen").checked = false;
      document.getElementById("checkbox-general-Utilidad").checked = false;
      setDinamicTextFilter("por Venta");
      setCurrentFilterGenerall("Venta");
      document.getElementById("general-filter").innerHTML = "Venta";
    }
    if (target.id.includes("checkbox-general-Volumen")) {
      document.getElementById("checkbox-general-Venta").checked = false;
      document.getElementById("checkbox-general-Utilidad").checked = false;
      setDinamicTextFilter("por Volumen");
      setCurrentFilterGenerall("Volumen de venta");
      document.getElementById("general-filter").innerHTML = "Volumen de venta";
    }

    if (target.id.includes("checkbox-general-Utilidad")) {
      document.getElementById("checkbox-general-Volumen").checked = false;
      document.getElementById("checkbox-general-Venta").checked = false;
      setDinamicTextFilter("por Utilidad");
      setCurrentFilterGenerall("Utilidad");
      document.getElementById("general-filter").innerHTML = "Utilidad";
    }

    const marcasFiltradas = [];
    const marcasFiltradasNull = [];
    const idDistiltrado = [];
    const idFather = [];

    marcas2.map(({ id_Marca }, index) => {
      //console.log(id_Marca);
      let temp = document.getElementById(`checkbox-marca2-${index}`);
      //console.log(temp);
      temp &&
        temp.checked && //console.log("-----entra-----------");
        temp &&
        temp.checked &&
        marcasFiltradas.push(id_Marca);
    });

    marcas.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca-${index}`);
      let tempnull = document.getElementById(`checkbox-marca-null`);
      temp.checked && marcasFiltradas.push(id_Marca);
    });
    Mamedist.map(({ id_distribuidor, name }, index) => {
      let temp = document.getElementById(
        `checkbox-distribuidor-${id_distribuidor}`
      );
      temp.checked && idDistiltrado.push(id_distribuidor);
    });
    nameFathers.map(({ id_fathers, name }, index) => {
      let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
      temp?.checked && idFather.push(id_fathers);
    });

    let tempnull = document.getElementById(`checkbox-marca-null`);
    tempnull?.checked && marcasFiltradas.push("contienenulo");
    let tempnull2 = document.getElementById(`checkbox-distribuidor-null`);
    tempnull2?.checked && idDistiltrado.push("contienenulo");

    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());

    if (target.id.includes("checkbox-general-Utilidad")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      // setNamegraph1(sessionStorage.getItem("periodo"));
      //console.log(target);
      //console.log(target.name2);
      //console.log(target.name + "wwwwwwwwwwwwwwwwwwwwwwwwwwww");
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      //console.log(target);
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
    }
    if (target.id.includes("checkbox-general-Venta")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      // setNamegraph1(sessionStorage.getItem("periodo"));
      //console.log(target);
      //console.log(target.name2);
      //console.log(target.name + "wwwwwwwwwwwwwwwwwwwwwwwwwwww");
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      //console.log(target);
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
    }
    if (target.id.includes("checkbox-general-Volumen")) {
      marcasStaticId &&
        marcasStaticId != "" &&
        marcasStaticId != "no" &&
        marcasFiltradas.push(marcasStaticId);

      fatherStaticId &&
        fatherStaticId != "" &&
        fatherStaticId != "no" &&
        idFather.push(fatherStaticId);

      idDist2 &&
        idDist2 != "" &&
        idDist2 != "no" &&
        idDistiltrado.push(idDist2);
      // setNamegraph1(sessionStorage.getItem("periodo"));
      //console.log(target);
      //console.log(target.name2);
      //console.log(target.name + "wwwwwwwwwwwwwwwwwwwwwwwwwwww");
      //console.log("llega1");
      sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
      sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
      sessionStorage.setItem("idFather", idFather.toString());
      //console.log(target);
      setGraph1(false);
      setGraph2(false);
      setGraph3(false);
      getABCDistribuidorAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
      getMovAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
      getTypeSaleAdmin({
        variables: {
          a: marcasFiltradas.toString(),
          b: idDistiltrado.toString(),
          c: idFather.toString(),
          e: target.name,
        },
      });
    }
  };
  function paginacion(filtro) {
    const marcasFiltradas = [];
    const marcasFiltradasNull = [];
    const idDistiltrado = [];
    const idFather = [];
    marcas.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca-${index}`);
      temp.checked && marcasFiltradas.push(id_Marca);
    });
    marcas2.map(({ id_Marca }, index) => {
      let temp = document.getElementById(`checkbox-marca2-${index}`);
      temp && temp.checked && marcasFiltradas.push(id_Marca);
    });
    Mamedist.map(({ id_distribuidor, name }, index) => {
      let temp = document.getElementById(
        `checkbox-distribuidor-${id_distribuidor}`
      );
      temp.checked && idDistiltrado.push(id_distribuidor);
      // temp.checked && id_distribuidor!=null && idDistiltrado.push(id_distribuidor)
      // temp.checked && id_distribuidor == null && idDistiltrado.push('contienenulo')
    });
    nameFathers.map(({ id_fathers, name }, index) => {
      let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
      temp && temp?.checked && idFather.push(id_fathers);
    });

    let tempnull = document.getElementById(`checkbox-marca-null`);
    tempnull?.checked && marcasFiltradas.push("contienenulo");

    let tempnull2 = document.getElementById(`checkbox-distribuidor-null`);
    tempnull2?.checked && idDistiltrado.push("contienenulo");

    const email = sessionStorage.getItem("auxEmail");
    sessionStorage.setItem("marcasFiltradas", marcasFiltradas.toString());
    sessionStorage.setItem("idDistiltrado", idDistiltrado.toString());
    sessionStorage.setItem("idFather", idFather.toString());

    getPotObsoletosAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: optionTypeMov,
        email: email,
        limit: filtro,
        orderby: orderBy,
      },
    });
    setLoadingPublicados(true);
    getPotObsoletosAdminTable({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: optionTypeMov,
        email: email,
      },
    });
  }

  function Name(mes) {
    const date = new Date();
    if (mes == 0)
      return date.getDate() + "/" + "Enero" + "/" + date.getFullYear();
    if (mes == 1)
      return date.getDate() + "/" + "Febrero" + "/" + date.getFullYear();
    if (mes == 2)
      return date.getDate() + "/" + "Marzo" + "/" + date.getFullYear();
    if (mes == 3)
      return date.getDate() + "/" + "Abril" + "/" + date.getFullYear();
    if (mes == 4)
      return date.getDate() + "/" + "Mayo" + "/" + date.getFullYear();
    if (mes == 5)
      return date.getDate() + "/" + "Junio" + "/" + date.getFullYear();
    if (mes == 6)
      return date.getDate() + "/" + "Julio" + "/" + date.getFullYear();
    if (mes == 7)
      return date.getDate() + "/" + "Agosto" + "/" + date.getFullYear();
    if (mes == 8)
      return date.getDate() + "/" + "Septiembre" + "/" + date.getFullYear();
    if (mes == 9)
      return date.getDate() + "/" + "Octubre" + "/" + date.getFullYear();
    if (mes == 10)
      return date.getDate() + "/" + "Noviembre" + "/" + date.getFullYear();
    if (mes == 11)
      return date.getDate() + "/" + "Diciembre" + "/" + date.getFullYear();
  }

  const reOrderdata = ({ target }) => {
    //console.log("se ejecuta");
    const temo = dataTable.sort((x, y) => x.id_parte.localeCompare(y.id_parte));
    setDataTable(temo);
    //console.log("end");
  };

  const reOrderData = (type) => {
    console.log(type);
    // setLoadingtableadata(true);
    setOrderBy(type);
    const email = sessionStorage.getItem("auxEmail");
    // })
    const marcasFiltradas = [];
    const idDistiltrado = [];
    const idFather = [];
    marcas &&
      marcas.map(({ id_Marca }, index) => {
        let temp = document.getElementById(`checkbox-marca-${index}`);
        temp.checked && marcasFiltradas.push(id_Marca);
      });
    Mamedist &&
      Mamedist.map(({ id_distribuidor, name }, index) => {
        let temp = document.getElementById(
          `checkbox-distribuidor-${id_distribuidor}`
        );
        temp.checked && idDistiltrado.push(id_distribuidor);
      });
    nameFathers &&
      nameFathers.map(({ id_fathers, name }, index) => {
        let temp = document.getElementById(`checkbox-fathers-${id_fathers}`);
        temp?.checked && idFather.push(id_fathers);
      });

    getPotObsoletosAdmin({
      variables: {
        a: marcasFiltradas.toString(),
        b: idDistiltrado.toString(),
        c: idFather.toString(),
        tipMov: optionTypeMov,
        email: email,
        limit: limitepaginas,
        orderby: type,
      },
    });
  };

  const date2 = new Date();

  if (isLoading) {
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Skeleton variant="rectangular" width={1300} height={318} size={100} />
    </div>;
  }
  return (
    <div className="chartContainer">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            color: "#02274190",
            fontWeight: "bold",
            fontSize: 25,
            fontStyle: "normal",
            top: 118,
            font: "Roboto",
            lineHeight: "140%",
            marginLeft: "3%",
          }}
        >
          {(userT == 1 && "DASHBOARD ADMINISTRADOR") ||
            (userT == 2 && "DASHBOARD DIRECTOR DE GRUPO") ||
            (userT == 3 && "DASHBOARD DIRECTOR DE MARCA") ||
            (userT == 4 && "DASHBOARD DIRECTOR DE DISTRIBUIDOR") ||
            (userT == 5 && "DASHBOARD COLABORADOR")}
        </p>
        <p>{Name(date2.getMonth())}</p>
      </div>
      <div
        style={{
          display: "flex",
          width: "95%",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div>
          {infoFilters.length > 0 && (
            <>
              <span>{infoFilters[0].distribuidor}</span>
            </>
          )}
        </div>
      </div>

      <br />
      {/* <FilterByDistributor
                filterByDistribuidor={filterByDistribuidor}
                optionSelect={optionSelect}
                value={optionSelected}
            /> */}

      {userT == 1 && (
        <>
          <div
            id="Filterquestion"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: "2%",
            }}
          >
            <FiltersAdmin
              checkedFilter={checkedFilter}
              optionSelect={nameFathers}
              value={optionSelected}
              type="question"
              active={true}
            />
            <div>
              {" "}
              <FiltersAdmin
                checkedGeneral={checkedGeneral}
                optionSelect={nameFathers}
                value={optionSelected}
                type="filtergeneral"
                active={true}
              />
              <p
                style={{
                  marginTop: "-40px",
                }}
              >
                Estas viendo la informacion {dinamicTextFilter}
              </p>
            </div>
          </div>
          <div
            id="filterbydist"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "85%",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "2%",
              display: "none",
              marginBottom: "30px",
            }}
          >
            <FiltersAdmin
              checkedFilter={checkedFilter}
              optionSelect={nameFathers}
              value={optionSelected}
              type="distribuidoreshermanos"
              active={true}
            />

            <FiltersAdmin
              checkedFilter={checkedFilter}
              optionSelect={marcas}
              value={optionSelected}
              type="marcas"
              active={disHerActive}
            />

            <FiltersAdmin
              checkedFilter={checkedFilter}
              optionSelect={Mamedist}
              value={optionSelected}
              type="distribuidores"
              active={distActive}
            />
          </div>

          <div
            id="filterbyMarca"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "85%",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "2%",
              display: "none",
              marginBottom: "30px",
              zIndex: "3",
            }}
          >
            <FiltersAdmin
              checkedFilter={checkedFilter}
              optionSelect={marcas2}
              value={optionSelected}
              type="marcas2"
              active={true}
            />
          </div>
        </>
      )}
      {userT == 2 && (
        // setDisHerActive(true),
        // searchMarcasF('', fatherStaticId),
        <div
          id="filterbydist"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "85%",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "2%",
            marginBottom: "30px",
          }}
        >
          <FiltersAdmin
            checkedFilter={checkedFilter2}
            optionSelect={marcas}
            value={optionSelected}
            type="marcas"
            active={true}
          />
          <FiltersAdmin
            checkedFilter={checkedFilter2}
            optionSelect={Mamedist}
            value={optionSelected}
            type="distribuidores"
            active={distActive}
          />
        </div>
      )}
      {userT == 3 && (
        // setDisHerActive(true),
        // searchMarcasF('', fatherStaticId),
        <div
          id="filterbydist"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "85%",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "2%",
            marginBottom: "30px",
          }}
        >
          <FiltersAdmin
            checkedFilter={checkedFilter2}
            optionSelect={marcas}
            value={optionSelected}
            type="marcas"
            active={true}
          />
          <FiltersAdmin
            checkedFilter={checkedFilter2}
            optionSelect={Mamedist}
            value={optionSelected}
            type="distribuidores"
            active={distActive}
          />
        </div>
      )}
      {userT == 4 && (
        // setDisHerActive(true),
        // searchMarcasF('', fatherStaticId),
        <div
          id="filterbydist"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "85%",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "2%",
            marginBottom: "30px",
          }}
        >
          <FiltersAdmin
            checkedFilter={checkedFilter3}
            optionSelect={marcas}
            value={optionSelected}
            type="marcas"
            active={true}
          />
        </div>
      )}
      {userT == 5 && (
        // setDisHerActive(true),
        // searchMarcasF('', fatherStaticId),
        <div
          id="filterbydist"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "85%",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "2%",
            marginBottom: "30px",
          }}
        >
          <FiltersAdmin
            checkedFilter={checkedFilter3}
            optionSelect={marcas}
            value={optionSelected}
            type="marcas"
            active={true}
          />
        </div>
      )}

      {showWindow ? (
        <div className={classesD.graphRow}>
          {graph1 ? (
            <div
              className={classesD.graphColumn}
              style={{
                position: "relative",
              }}
            >
              {/* <FiltersAdmin
                checkedFilter={checkedFilter}
                optionSelect={nameFathers}
                value={optionSelected}
                type="graph"
                active={true}
              /> */}
              <ChartDealers
                height="100%"
                dataDonut={dataDonut}
                numeroToTal={numeroTotal
                  .toString()
                  ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                numeroPrecio={numeroPrecio}
                handleClick={(data, item) => {
                  sessionStorage.setItem("chart", "Análisis ABC");
                  const index = item[0]?.index;
                  const value = data.datasets[0].data[index];
                  const label = data.labels[index];
                  if (label === "Elemento A")
                    history.push(
                      `/dashboardMovimientos?mov=A&&chart=AnalisisABC`
                    );
                  else if (label === "Elemento B")
                    history.push(
                      `/dashboardMovimientos?mov=B&&chart=AnalisisABC`
                    );
                  else if (label === "Elemento C")
                    history.push(
                      `/dashboardMovimientos?mov=C&&chart=AnalisisABC`
                    );
                }}
              />
              {expandedAbcDetail && (
                <div className={classes.GeneralDetails}>
                  DETALLE
                  <table>
                    <tr>
                      <td>Tipo</td>
                      <td>Total</td>
                    </tr>
                    {tableDetailAbc.map((tipo, index) => (
                      <>
                        <tr>
                          <td>
                            TIPO
                            {(index == 0 && "A") ||
                              (index == 1 && "B") ||
                              (index == 2 && "C")}
                          </td>
                          <td>
                            {" "}
                            {currentFilterGeneral != "Volumen de venta" && "$"}
                            {tipo}
                          </td>
                        </tr>
                      </>
                    ))}
                  </table>
                </div>
              )}
              {
                <BsFillArrowDownRightSquareFill
                  className={
                    expandedAbcDetail
                      ? classes.iconopenDetail
                      : classes.iconopenDetail2
                  }
                  onClick={() => setExpandedAbcDetail(!expandedAbcDetail)}
                />
              }
            </div>
          ) : (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={400}
                height={318}
                size={100}
              />
            </div>
          )}
          {graph2 ? (
            <div
              style={{
                position: "relative",
              }}
              className={classesD.graphColumn}
            >
              {/* <FiltersAdmin
                checkedFilter={checkedFilter}
                optionSelect={nameFathers}
                value={optionSelected}
                type="graph2"
                active={true}
              /> */}
              <ChartDealers
                title="Tipo de movimiento"
                numeroPrecio={numTotalTypMovPrecio}
                height="100%"
                /*  backgroundColor={[
                   "#03a9f4",
                   "#cddc39",
                   "#ff5722",
                   "#e91e63",
                   "#009688",
                   "#6394b8",
                 ]} */
                dataDonut={dataDonutTypeMov}
                labels={dataLabelDonutMov}
                numeroToTal={numTotalTypMov
                  .toString()
                  ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                handleClick={(data, item) => {
                  sessionStorage.setItem("chart", "Tipo de Movimiento");
                  const index = item[0]?.index;
                  const value = data.datasets[0].data[index];
                  const label = data.labels[index];
                  history.push(
                    `/dashboardMovimientos?mov=${label}&&option=${optionSelected}&&idGroup=${idGroup}&&chart=TypeMov`
                  );
                }}
              />
              {expandedtipoDetail && (
                <div className={classes.GeneralDetails}>
                  DETALLE
                  <table>
                    <tr>
                      <td>Tipo</td>
                      <td>Total</td>
                    </tr>
                    {tableDetailtipo.map((tipo, index) => (
                      <>
                        <tr>
                          <td>
                            {(index == 0 && "Rapido") ||
                              (index == 1 && "Lento") ||
                              (index == 2 && "Estancado") ||
                              (index == 3 && "Inactivo") ||
                              (index == 4 && "Potencial Obsoleto") ||
                              (index == 5 && "Obsoleto")}
                          </td>
                          <td>
                            {" "}
                            {currentFilterGeneral != "Volumen de venta" && "$"}
                            {tipo}
                          </td>
                        </tr>
                      </>
                    ))}
                  </table>
                </div>
              )}
              {
                <BsFillArrowDownRightSquareFill
                  className={
                    expandedtipoDetail
                      ? classes.iconopenDetail
                      : classes.iconopenDetail2
                  }
                  onClick={() => setExpandedtipoDetail(!expandedtipoDetail)}
                />
              }
            </div>
          ) : (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={400}
                height={318}
                size={100}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classesD.graphRow}>
          <div className={classesD.graphColumn}>
            {dataDonut.length > 0 && (
              /*  <ChartDealers
                height="262px"
                weight="335px" */
              <ChartDealers
                height="100%"
                num={3.5}
                dataDonut={dataDonut}
                numeroPrecio={numeroPrecio}
                numeroToTal={numeroTotal}
                handleClick={(data, item) => {
                  sessionStorage.setItem("chart", "Análisis ABC");
                  const index = item[0]?.index;
                  const value = data.datasets[0].data[index];
                  const label = data.labels[index];
                  if (label === "Elemento A")
                    history.push(
                      `/dashboardMovimientos?mov=A&&chart=AnalisisABC`
                    );
                  else if (label === "Elemento B")
                    history.push(
                      `/dashboardMovimientos?mov=B&&chart=AnalisisABC`
                    );
                  else if (label === "Elemento C")
                    history.push(
                      `/dashboardMovimientos?mov=C&&chart=AnalisisABC`
                    );
                }}
              />
            )}
          </div>
          {/* <div style={{ height: "5%", padding: "4%" }}></div> */}
          <div className={classesD.graphColumn}>
            {dataDonutTypeMov.length > 0 && (
              <ChartDealers
                title="Tipo de movimiento"
                height="100%"
                numeroPrecio={numTotalTypMovPrecio}
                num={4.3}
                /*  backgroundColor={[
                   "#03a9f4",
                   "#cddc39",
                   "#ff5722",
                   "#e91e63",
                   "#009688",
                   "#6394b8",
                 ]} */
                dataDonut={dataDonutTypeMov}
                labels={dataLabelDonutMov}
                numeroToTal={numTotalTypMov}
                handleClick={(data, item) => {
                  sessionStorage.setItem("chart", "Tipo de Movimiento");
                  const index = item[0]?.index;
                  const value = data.datasets[0].data[index];
                  const label = data.labels[index];
                  history.push(
                    `/dashboardMovimientos?mov=${label}&&option=${optionSelected}&&idGroup=${idGroup}&&chart=TypeMov`
                  );
                }}
              />
            )}
          </div>
        </div>
      )}
      {showWindow ? (
        <div className={classesD.graphRow}>
          {graph3 ? (
            <div
              style={{
                position: "relative",
              }}
              className={classesD.graphColumn}
            >
              {/* <FiltersAdmin
                checkedFilter={checkedFilter}
                optionSelect={nameFathers}
                value={optionSelected}
                type="graph3"
                active={true}
              /> */}
              <ChartDealers
                title="Tipo de venta"
                height="100%"
                //  backgroundColor={["#e75226", "#39dcc5", "#46aaf2"]}
                dataDonut={dataDonutTypeVenta}
                labels={dataLabelDonutVenta}
                numeroToTal={numTotal
                  .toString()
                  ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                numeroPrecio={numPreciotypeVenta}
                handleClick={(data, item) => {
                  //console.log("data", data);

                  sessionStorage.setItem("chart", "Tipo de Venta");
                  const index = item[0]?.index;
                  const value = data.datasets[0].data[index];
                  const label = data.labels[index];
                  {
                    label === "Hojalatería y pintura"
                      ? history.push(
                          `/dashboardMovimientos?mov=HyP&&chart=TypeVenta`
                        )
                      : label != "OTROS" &&
                        history.push(
                          `/dashboardMovimientos?mov=${label}&&chart=TypeVenta`
                        );
                  }
                }}
              />
              {expandedbestDetail && (
                <div className={classes.GeneralDetails}>
                  DETALLE
                  <table>
                    <tr>
                      <td>Tipo</td>
                      <td>Total</td>
                    </tr>
                    {tableDetailbest.map((tipo, index) => (
                      <>
                        <tr>
                          <td>{dataLabelDonutVenta[index]}</td>
                          <td>
                            {" "}
                            {currentFilterGeneral != "Volumen de venta" && "$"}
                            {tipo}
                          </td>
                        </tr>
                      </>
                    ))}
                  </table>
                </div>
              )}
              {
                <BsFillArrowDownRightSquareFill
                  className={
                    expandedbestDetail
                      ? classes.iconopenDetail
                      : classes.iconopenDetail2
                  }
                  onClick={() => setExpandedbestDetail(!expandedbestDetail)}
                />
              }
            </div>
          ) : (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={400}
                height={318}
                size={100}
              />
            </div>
          )}
          {graph4 ? (
            <div className={classesD.graphColumn}>
              <ChartBestSellinngsPieces
                borderRadius={12}
                height={"100%"}
                width={"90%"}
                dataBarPieces={dataBarPieces}
                labels={labelsBarPieces}
              />
            </div>
          ) : (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={400}
                height={318}
                size={100}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classesD.graphRow}>
          {dataDonutTypeVenta.length > 0 ? (
            <div className={classesD.graphColumn}>
              <ChartDealers
                title="Tipo de venta"
                height="100%"
                num={4.3}
                // backgroundColor={["#e75226", "#39dcc5", "#46aaf2"]}
                dataDonut={dataDonutTypeVenta}
                labels={dataLabelDonutVenta}
                numeroToTal={numTotal}
                numeroPrecio={numPreciotypeVenta}
                handleClick={(data, item) => {
                  //console.log("data", data);

                  sessionStorage.setItem("chart", "Tipo de Venta");
                  const index = item[0]?.index;
                  const value = data.datasets[0].data[index];
                  const label = data.labels[index];
                  {
                    label === "Hojalatería y pintura"
                      ? history.push(
                          `/dashboardMovimientos?mov=HyP&&chart=TypeVenta`
                        )
                      : history.push(
                          `/dashboardMovimientos?mov=${label}&&chart=TypeVenta`
                        );
                  }
                }}
              />
            </div>
          ) : (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={400}
                height={318}
                size={100}
              />
            </div>
          )}
          {/* <div style={{ height: "5%", padding: "4%" }}></div> */}
          {graph4 ? (
            <div className={classesD.graphColumn}>
              {/*  <ChartBestSellinngsPieces
              height={"265px"}
              width={"335px"} */}
              <ChartBestSellinngsPieces
                borderRadius={12}
                height={"100%"}
                width={"90%"}
                dataBarPieces={dataBarPieces}
                labels={labelsBarPieces}
              />
            </div>
          ) : (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={400}
                height={318}
                size={100}
              />
            </div>
          )}
        </div>
      )}

      {showWindow ? (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            marginBottom: "2%",
          }}
        >
          {loadingtableadata ? (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={300}
                height={30}
                size={100}
              />
            </div>
          ) : (
            <select
              style={{
                width: 300,
                borderRadius: 5,
                padding: 5,
                fontWeight: "bold",
                border: "2px solid #027CBB80",
                height: 30,
                padding: "3px 6px",
              }}
              value={optionTypeMov}
              onChange={(e) => {
                filterByTypMove(e);
                setLoadingtableadata(true);
              }}
            >
              <option selected value="">
                Elige un tipo de movimiento
              </option>
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          )}
        </div>
      ) : (
        <div className="filter">
          {loadingtableadata ? (
            <div className={classesD.graphColumn}>
              <Skeleton
                variant="rectangular"
                width={300}
                height={30}
                size={100}
              />
            </div>
          ) : (
            <select
              style={{
                width: 300,
                borderRadius: 5,
                padding: 5,
                fontWeight: "bold",
                border: "2px solid #027CBB80",
                height: 30,
                padding: "3px 6px",
              }}
              value={optionTypeMov}
              onChange={(e) => {
                filterByTypMove(e);
                setLoadingtableadata(true);
              }}
            >
              <option selected value="">
                Elige un tipo de movimiento
              </option>
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          )}
        </div>
      )}
      {loadingtableadata ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={1300}
            height={318}
            size={100}
          />
        </div>
      ) : (
        <>
          <StatsTable
            id="tableDashboard"
            dataTable={dataTable}
            optionSelect={optionSelected}
            reOrderdata={reOrderData}
            idGroup={idGroup}
          />
          <div className="buttons-paginacion-obsoleto">
            <div className="root">
              <Pagination
                count={Math.ceil(numberPages)}
                page={page}
                onChange={handleChange}
              />
            </div>

            {/* <button
              onClick={() => {
                limitepaginas >= 1 && setLimitepaginas(limitepaginas - 1);
                paginacion(limitepaginas - 1);
                setLoadingtableadata(true);
              }}
              className="margin-right"
            >
              {"<"}
            </button> */}
            {/* {limitepaginas + 1} */}
            {/* <button
              onClick={() => {
                setLimitepaginas(limitepaginas + 1);
                paginacion(limitepaginas + 1);
                setLoadingtableadata(true);
              }}
              className="margin-left"
            >
              {">"}
            </button> */}
          </div>
        </>
      )}

      <br />
      <input
        type="button"
        value="Exportar tabla"
        className="btn btn-primary"
        onClick={() => downloadExcel(dataTableExport, "tabla-partes")}
      />
      <div style={{ align: "right", width: "200px" }}></div>
    </div>
  );
};

export default Administrator;
